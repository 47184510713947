import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as Sentry from '@sentry/angular-ivy';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    const extractedError = this._extractError(error) || 'Handled unknown error';
    //const eventId = Sentry.captureException(extractedError); // sends handled errors to sentry
    if(environment.allowConsoleErrors) {
      console.error(extractedError);
    }
  }

  // copied from @sentry/angular/errorhandler.ts::_defaultExtractor()
  private _extractError(errorCandidate: unknown): unknown {
    let error = errorCandidate;

    if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
      error = (error as { ngOriginalError: Error }).ngOriginalError;
    }

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    return null;
  }
}
