import { EventEmitter, Injectable, Output, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OrderItem } from '../../../interfaces/product.interface';
import { NavigationService } from '@modules/navigation/services';
import { take } from 'rxjs/operators';
import {
  CancelGroupOrder,
  CheckUpsell,
  RemoveFromOrder,
  UpdateBasketItem,
  UpdateToCurrentBasket,
  ValidateOrder,
} from '../../../store/actions/order.actions';
import { Select, Store } from '@ngxs/store';
import { Order } from '../../../interfaces/order.interface';
import { MenuService } from '@modules/menu/services';
import { ToastService } from '../../../services/toast.service';
import { SetProduct } from '../../../store/actions/menu.actions';
import { ModeService } from '../../../services/mode.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { GroupOrder } from '../../../interfaces/group-order.interface';
import { User } from '../../../interfaces/user.interface';
import { PromotionalContent } from '../../../vendors/directus/interfaces/promotional-content.interface';
import { ErrorService } from '../../../services/error.service';

@Injectable()
export class CartService {
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;
  @Select(state => state.app.promotionalContent) promotionalContent$: Observable<PromotionalContent>;

  basketProdID = '';
  errorMessage = '';
  cartGuests: string[] = [];
  page;

  // Loaders
  cartProductLoading = [];
  continueButtonLoading = false;
  buttonLoading = false;

  checkoutClicked = new EventEmitter<void>();

  constructor(
    private store: Store,
    private navigation: NavigationService,
    private toast: ToastService,
    public modalService: NgbModal,
    private sanitizer: DomSanitizer,
    public modeService: ModeService,
    private menuService: MenuService,
    private errorService: ErrorService
  ) {
    this.order$.subscribe(order => {
      if (order) {
        order.items?.forEach((item: OrderItem) => {
          if (!this.cartGuests.includes(item.guestName)) {
            this.cartGuests.push(item.guestName);
          }
        });
      }
    });
    this.groupOrder$.subscribe(groupOrder => {
      if (groupOrder && !groupOrder.groupOrderIsOpen) {
        this.store.dispatch(new CancelGroupOrder()).subscribe(() => {
          this.navigation.navigateToConfirmationPage(groupOrder.order.orderID);
        });
      }
    });
  }

  getCart$(): Observable<{}> {
    return of({});
  }

  async checkoutButtonClicked() {
    this.buttonLoading = true;
    try {
      const order = await this.order$.pipe(take(1)).toPromise();

      if (this.modeService.mode === 'kiosk') {
        await this.store.dispatch(new ValidateOrder()).toPromise();
      }
      const state = await this.store.dispatch(new CheckUpsell(order.orderID)).toPromise();

      if (state.order.upsells && state.order.upsells.items.length > 0) {
        await this.navigation.navigateToUpsellPage();
      } else {
        await this.navigation.navigateToCheckoutPage();
        this.checkoutClicked.emit();
      }
    } catch (error) {
      this.toast.danger(this.errorService.checkoutError(error));
    } finally {
      this.buttonLoading = false;
    }
  }

  updateQuantClicked(updateInfo) {
    this.cartProductLoading.push(updateInfo.product.orderItemID.toString());
    if (updateInfo.product.quantity === 1 && updateInfo.quant === -1) {
      this.removeProduct(updateInfo.product);
      this.cartProductLoading.splice(this.cartProductLoading.indexOf(updateInfo.product.orderItemID, 1));
    } else {
      // this.store.dispatch(new UpdateOrderItem(updateInfo.product, updateInfo.product.quantity + updateInfo.increment));
      this.store.dispatch(new UpdateBasketItem(updateInfo.product, updateInfo.product.quantity + updateInfo.quant)).subscribe(
        () => {
          this.store.dispatch(new UpdateToCurrentBasket(null));
          this.cartProductLoading.splice(this.cartProductLoading.indexOf(updateInfo.product.orderItemID, 1));
        },
        error => {
          console.log(error);
          this.toast.danger(typeof error === 'string' ? error : error.message);
          this.store.dispatch(new UpdateToCurrentBasket(null));
          this.cartProductLoading.splice(this.cartProductLoading.indexOf(updateInfo.product.orderItemID, 1));
        }
      );
    }
  }

  // editItemClick(product: Product) {
  //   if (tableside and maybe mobile) {
  //     this.toggleCart();
  //   }
  //   this.editItemClicked.emit(product);
  // }

  editItemClick(product: OrderItem, modifyModalRef: TemplateRef<any>, clickCart: any) {
    this.menuService.settingProduct = true;
    let itemCat;
    this.menuService.menu.categories.forEach(cat => {
      if (cat.products.find((prod: any) => prod.productID === product.productID)) {
        itemCat = cat.nameSlug ? cat.nameSlug : cat.categoryID;
      }
    });
    const productSlug = product.nameSlug ? product.nameSlug : product.productID;
    if (this.modeService.mode !== 'kiosk' && product.showAsModal) {
      // tslint:disable-next-line:max-line-length
      this.store
        .dispatch(
          new SetProduct(
            this.menuService.restaurantID,
            itemCat,
            product.nameSlug ? product.nameSlug : product.productID,
            this.menuService.selectedHandoffType
          )
        )
        .subscribe(() => (this.menuService.settingProduct = false));
      this.basketProdID = product.orderItemID;
      this.modalService.dismissAll();
      this.modalService
        .open(modifyModalRef, {
          centered: true,
          animation: true,
        })
        .closed.subscribe(() => clickCart());
    } else {
      // tslint:disable-next-line:max-line-length
      this.navigation.navigateToMenuPage(
        this.menuService.restaurantID,
        itemCat,
        productSlug,
        'modify',
        product.productID,
        product.orderItemID
      );
    }
  }

  removeProduct(product: OrderItem) {
    this.cartProductLoading.push(product.orderItemID.toString());
    // tslint:disable-next-line:max-line-length
    this.store
      .dispatch(new RemoveFromOrder(product))
      .toPromise()
      .then(() => this.cartProductLoading.splice(this.cartProductLoading.indexOf(product.orderItemID, 1)))
      .catch(() => this.cartProductLoading.splice(this.cartProductLoading.indexOf(product.orderItemID, 1)));
  }

  continueOrderingClicked() {
    this.continueButtonLoading = true;
    this.navigation.navigateToMenuPage(this.menuService.restaurantID).then(() => {
      this.continueButtonLoading = false;
    });
  }
}
