import { Component, Input, Output, EventEmitter, AfterViewInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { Reward } from 'src/interfaces/reward.interface';
import { MobileService } from 'src/services/mobile.service';
import { RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Branding } from '../../../../vendors/directus/interfaces/branding.interface';
import { LoyaltyReward } from '../../../../interfaces/loyalty-reward.interface';
import { Order } from '../../../../interfaces/order.interface';
import { MobileAppSettings } from '../../../../vendors/directus/interfaces/mobile-app-settings.interface';
import { ThemeColor } from '../../../../vendors/directus/interfaces/theme-color.interface';
import { ModeService } from '../../../../services/mode.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalStateModel } from '../../../../store/state.model';

@Component({
  selector: 'app-reward-card',
  templateUrl: './reward-card.component.html',
  styleUrls: ['./reward-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('400ms ease-in')),
    ]),
  ],
})
export class RewardCardComponent implements AfterViewInit {
  @Select(state => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.user.loyaltyRewards) loyaltyRewards$: Observable<LoyaltyReward[]>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  @Select(state => state.app.mobileAppSettings) mobileAppSettings$: Observable<MobileAppSettings>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select((state: GlobalStateModel) => state.app.canRedeem) canRedeem$: Observable<boolean>;

  private subs: Subscription[] = [];

  displayBalances: RewardsBalances;
  @Input() isCart = false;
  @Input() reward: Reward;
  @Input() canApply: boolean;
  @Input() loadingReward = null;
  @Input() isStaged: boolean;
  @Input() currencyCode: string;
  @Input() disabled = false;

  url = this.router.url;

  buttonBottom: number;

  @Output() clickedRedeem = new EventEmitter<string | number>();
  @Output() clickedRedeemInStore = new EventEmitter<any>();
  @Output() clickedRemove = new EventEmitter<string | number>();
  @Output() clickedUnstage = new EventEmitter<void>();

  @ViewChild('infoModal') infoModalRef: TemplateRef<any>;

  constructor(
    // private navbarService: NavBarService,
    public mobile: MobileService,
    public modeService: ModeService,
    public modalService: NgbModal,
    private router: Router
  ) {
    this.subs.push(
      this.rewardPoints$.subscribe(balances => {
        if (balances) {
          // this.pointsPercentage = this.getPointsPecentage(balances);
          this.displayBalances = balances;
        }
      })
    );
  }

  ngAfterViewInit() {
    // if (this.rewardImage.elementRef.nativeElement.complete) {
    //   this.buttonBottom = this.rewardImage.elementRef.nativeElement.height - 16
    // } else {
    //   this.rewardImage.elementRef.nativeElement.onload = () => {
    //     this.buttonBottom = this.rewardImage.elementRef.nativeElement.height - 16
    //   }
    // }
  }

  onImageLoad(event: Event) {
    console.log(event);
  }

  redeemClicked() {
    this.clickedRedeem.emit(this.reward.rewardID);
  }

  redeemInStoreClicked() {
    this.clickedRedeemInStore.emit();
  }

  removeClicked() {
    this.clickedRemove.emit(this.reward.rewardID);
  }

  unstageReward() {
    this.clickedUnstage.emit();
  }

  openInfoModal(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.modalService.dismissAll();
    this.modalService.open(this.infoModalRef, {
      centered: true,
      animation: true,
      windowClass: 'card-info-modal',
    });
  }
}
