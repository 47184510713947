import { Injectable } from '@angular/core';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import moment, { Moment } from 'moment-timezone';
import { Cacheable } from 'ts-cacheable';

import { OrderHistoryProvider } from 'src/providers/order-history-provider.interface';
import { OrderProvider } from 'src/providers/order-provider.interface';
import { MenuProvider } from 'src/providers/menu-provider.interface';
import { LocationProvider } from 'src/providers/location-provider.interface';

import { ImageContentService } from '../directus/content.service';
import { ToastService } from '../../services/toast.service';
import { OloAPIService } from '../olo/olo-api.service';
import { OLOMappingService } from '../olo/olo-mapping.service';

import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { CardDetails } from 'src/interfaces/card-details.interface';
import { OrderItem, Product } from 'src/interfaces/product.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { User } from 'src/interfaces/user.interface';
import { Order } from 'src/interfaces/order.interface';
import { Location } from 'src/interfaces/location.interface';
import { Address } from 'src/interfaces/address.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { CateringLink } from 'src/interfaces/catering-link.interface';
import { Reward } from 'src/interfaces/reward.interface';
import { UserProvider } from 'src/providers/user-provider.interface';
import { PassResetResponse } from 'src/interfaces/pass-reset-response.interface';
import { SSOLogin } from 'src/interfaces/sso-login.model';
import { CreateAccount } from '../../interfaces/create-account.interface';
import { GiftCardItem } from '../../interfaces/gift-card-item.interface';

import { CreateUserRequest } from './interfaces/create-user-request.interface';
import { Address as OloAddress } from './interfaces/address.interface';
import { RestaurantDeliveryRequest } from './interfaces/restaurant-delivery-request.interface';
import { DirectusService } from '../directus/directus.service';

import { TastyIgniterAPIService } from './tasty-igniter-api.service';
import { TastyIgniterMappingService } from './tasty-igniter-mapping.service';
import { LocalBasketService } from './services/local-basket/local-basket.service';
import { GroupOrder } from '../../interfaces/group-order.interface';
import { Preferences as Storage } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class TastyIgniterProviderService implements UserProvider, OrderProvider, OrderHistoryProvider, MenuProvider, LocationProvider {
  private basketGuidKey = 'OLOBasketGuid';
  private authTokenKey = 'OLOAuthToken';
  private showHiddenLocations = false;

  integrationCMSName = 'olo';

  orderKey = 'TastyIgniterDEOrder';

  // For Tableside, we need to send some fields that are not natively present in an Olo order.
  customData: Array<{ key: string; value: string }> = [];

  private oloAuthTokenSubject = new BehaviorSubject<string>(null);
  oloAuthToken$ = this.oloAuthTokenSubject.asObservable();

  constructor(
    private oloAPI: OloAPIService,
    private mapping: OLOMappingService,
    private tastyIgniterAPI: TastyIgniterAPIService,
    private tiMapping: TastyIgniterMappingService,
    private contentService: ImageContentService,
    private directusService: DirectusService,
    private toast: ToastService,
    private localBasket: LocalBasketService
  ) {
    this.oloAuthToken$.subscribe(() => {
      /* console.log(res) */
    });
    this.getAuthToken();
  }

  setManualFire(orderID: string | number): Observable<Order> {
    return this.getCurrentOrder(null);
  }

  getResetPasswordCode(email: string): Observable<any> {
    return this.forgotPassword(email);
  }

  deleteAccount(userID: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  completeSSOLogin(token: SSOLogin) {
    this.oloAPI.getBrandLevelSettings().subscribe(brandSettings => {
      let pname;
      if (brandSettings.loginproviders.find(lp => lp.type === 'external')) {
        pname = brandSettings.loginproviders.find(lp => lp.type === 'external').provider;
      } else {
        pname = this.mapping.ssoLoginProviderName(token.provider);
      }
      this.oloAPI.createOrGetSSOLinkedOLO(pname, token.token, this.getBasketGuid()).subscribe(
        user => {
          console.log(user);
          this.storeAuthToken(user.authtoken);
        },
        err => {
          throw this.mapping.ssoLoginError(err);
        }
      );
    });
  }

  getSSOLoginSubject() {
    return of(null);
  }

  isOauthEnabled(): Observable<boolean> {
    return of(false);
  }

  redirectToOauthPage() {}

  is3rdPartyWrapped(): boolean {
    return false;
  }

  cateringLink(): Observable<CateringLink> {
    // This could change when we get a catering integration
    const link: CateringLink = {
      enabled: false,
      link: '',
      linkGuest: '',
      clientId: '',
    };
    return of(link);
  }

  // LOCATION

  notifyOfArrival(orderID: string, extraMessage: string): Observable<Order> {
    return this.oloAPI.notifyOfArrival(orderID, extraMessage).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            const order = this.mapping.orderToOrder(res, location);
            return this.contentService.getOrderItemsWithSlugsandImages(order);
          })
        );
      })
    );
  }

  getLocations(withCalendars?: boolean): Observable<Location[]> {
    return this.oloAPI.getAllParticipatingRestaurants(this.showHiddenLocations).pipe(
      switchMap(res => {
        const locations = res.restaurants.map(restaurant => this.mapping.restaurantToLocation(restaurant));
        return combineLatest(locations.map(loc => this.contentService.getLocationWithMapIconURL(loc))).pipe(
          switchMap(locs => {
            return combineLatest(locs.map(loc => this.getLocationWithHours(loc))).pipe(
              switchMap(locsWithHours => {
                return combineLatest(locsWithHours.map(loc => this.contentService.getSingleLocationWithSlug(loc)));
              })
            );
          })
        );
      })
    );
  }

  getLocation(locationID: number): Observable<Location> {
    return this.tastyIgniterAPI.getRestaurant(locationID).pipe(
      switchMap(restaurant => {
        // tslint:disable-next-line:max-line-length
        const loc = this.tiMapping.restaurantToLocation(
          restaurant.data,
          restaurant.included.find(inc => inc.type === 'working_hours').attributes
        );
        return of<Location>(loc);
        // return this.contentService.getLocationWithMapIconURL(loc).
        //   pipe(switchMap(location => {
        //     return this.getLocationWithHours(location).
        //       pipe(switchMap(locWithHours => {
        //         return this.contentService.getSingleLocationWithSlug(
        //             locWithHours);
        //       }));
        //   }));
      })
    );
  }

  getLocationBySlug(slugID: string): Observable<Location> {
    return this.getLocationsNear(0, 0, 0, 0).pipe(
      map(locations => {
        return locations.find(location => location.slugURL === slugID);
      })
    );
  }

  getLocationsNear(latitude: number, longitude: number, milesRadius: number, maxResults: number): Observable<Location[]> {
    // tslint:disable-next-line:max-line-length
    return this.tastyIgniterAPI
      .getNearbyParticipatingRestaurants(latitude, longitude, milesRadius, maxResults, moment().toDate(), moment().add(1, 'week').toDate())
      .pipe(
        switchMap(res => {
          const locations = res.data.map(restaurant =>
            this.tiMapping.restaurantToLocation(restaurant, res.included.find(inc => inc.type === 'working_hours').attributes)
          );
          console.log(locations);
          if (locations.length === 0) {
            return of<Location[]>([]);
          } else {
            return of<Location[]>([...locations]);
            // return combineLatest(locations.map(
            //   loc => this.contentService.getLocationWithMapIconURL(loc))).
            //   pipe(switchMap((locs: any[]) => {
            //     return locs;
            //   }));
          }
        })
      );
  }

  getLocationtableNumber(tableNumber: number, locationID: number): Observable<string> {
    return of(tableNumber.toString());
  }

  // tslint:disable-next-line: max-line-length
  checkForDelivery(
    restaurantID: number,
    handoffType: HandoffType.delivery | HandoffType.dispatch,
    timeWanted: Date,
    address: Address
  ): Observable<{ canDeliver: boolean; failureReason?: string }> {
    let requestBody: RestaurantDeliveryRequest = {
      handoffmode: handoffType === HandoffType.delivery ? 'delivery' : 'dispatch',
      timewantedmode: 'asap',
      street: address.address1,
      city: address.city,
      zipcode: address.zipCode,
    };
    if (moment(timeWanted).isAfter(moment(), 'minute')) {
      requestBody = {
        ...requestBody,
        timewantedmode: 'advance',
        timewantedutc: moment(timeWanted).utc().format('YYYYMMDD HH:mm'),
      };
    }
    if (requestBody.handoffmode && requestBody.timewantedmode && requestBody.street && requestBody.city && requestBody.zipcode) {
      return this.oloAPI.checkRestaurantDelivery(restaurantID, requestBody).pipe(
        map(res => {
          return {
            canDeliver: res.candeliver,
            failureReason: res.candeliver ? '' : res.message,
          };
        })
      );
    } else {
      return throwError(
        new Error(
          // tslint:disable-next-line:max-line-length
          'Something went wrong, this may not be a valid delivery address. Please try your address again or try entering a different address.'
        )
      );
    }
  }

  // USER

  logIn(username: string, password: string): Observable<string> {
    return this.oloAPI.authenticateUser(username, password).pipe(
      switchMap(userRes => {
        return this.storeAuthToken(userRes.token).pipe(
          map(() => {
            return userRes.token;
          })
        );
      }),
      catchError(err => {
        return throwError(this.mapping.loginError(err));
      })
    );
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logOut(userID: string): Observable<string> {
    return this.oloAPI.disableAuthToken(userID).pipe(
      map(userDetails => {
        this.removeAuthToken();
        this.removeBasketGuid();
        return userDetails.authtoken;
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.oloAPI.startForgotPasswordProcess(email);
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.oloAPI.authenticateUser(email, oldPassword).pipe(
      switchMap(userRes => {
        return this.oloAPI.changePassword(userRes.token, oldPassword, newPassword);
      })
    );
  }

  resetPassword(newPassword: string): Observable<PassResetResponse> {
    throw new Error('Method not implemented.');
  }

  // tslint:disable-next-line: max-line-length
  updateUserInfo(user: User): Observable<User> {
    return this.oloAPI.updateUserDetails(user.userID, user.firstName, user.lastName, user.email).pipe(
      switchMap(userDetails => {
        const updatePrefs = {
          marketingsms: String(user.sMSOptIn),
          optin: String(user.emailOptIn),
          upsell: 'true',
          emailreceipts: 'true',
          followups: 'true',
        };
        return this.getAuthToken().pipe(
          switchMap(id => {
            return this.oloAPI.updateUserContactNumber(id, user.phoneNumber).pipe(
              switchMap(newPhone => {
                return this.oloAPI.updateUserCommunicationPreferences(id, updatePrefs).pipe(
                  switchMap(newPrefs => {
                    return this.storeAuthToken(userDetails.authtoken).pipe(
                      map(() => {
                        let newUser = this.mapping.userDetailsToUser(userDetails);
                        newUser = {
                          ...newUser,
                          emailOptIn: newPrefs.optin === 'true',
                          sMSOptIn: newPrefs.marketingsms === 'true',
                          phoneNumber: newPhone.contactdetails,
                        };
                        return newUser;
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  createAccount(newAccount: CreateAccount): Observable<User> {
    const req: CreateUserRequest = {
      emailaddress: newAccount.email,
      firstname: newAccount.firstName,
      lastname: newAccount.lastName,
      contactnumber: newAccount.phone,
      password: newAccount.password,
      reference: '',
      basketid: null,
    };
    return this.oloAPI.createUser(req).pipe(
      switchMap(userReq => {
        return this.storeAuthToken(userReq.authtoken).pipe(
          switchMap(() => {
            const updatePrefs = {
              marketingsms: String(newAccount.smsOptIn),
              optin: String(newAccount.emailOptIn),
              upsell: 'true',
              emailreceipts: 'true',
              followups: 'true',
            };
            return this.getAuthToken().pipe(
              switchMap(id => {
                return this.oloAPI.updateUserCommunicationPreferences(id, updatePrefs).pipe(
                  map(newPrefs => {
                    let newUser = this.mapping.createUserResponseToUser(userReq);
                    newUser = {
                      ...newUser,
                      emailOptIn: newPrefs.optin === 'true',
                      sMSOptIn: newPrefs.marketingsms === 'true',
                    };
                    return newUser;
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  logInAsGuest(): Observable<string> {
    return of(null);
  }

  getUserInfo(userID: string): Observable<User> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.getUserDetails(id).pipe(
          switchMap(userDetails => {
            return this.oloAPI.getUserContactNumber(id).pipe(
              switchMap(contact => {
                return this.oloAPI.getUserCommunicationPreferences(id).pipe(
                  map(comPrefs => {
                    return {
                      ...this.mapping.userDetailsToUser(userDetails),
                      phoneNumber: contact.contactdetails,
                      emailOptIn: comPrefs.optin === 'true',
                      sMSOptIn: comPrefs.marketingsms === 'true',
                    };
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  getLoggedInUserInfo(): Observable<User> {
    return this.getAuthToken().pipe(
      switchMap(token => {
        return token && token !== 'null' ? this.getUserInfo(token) : of(null);
      })
    );
  }

  // MENU

  getMenuByHandoffType(locationID: string, handoffType: HandoffType): Observable<Menu> {
    if (isNaN(locationID as any)) {
      return this.getLocationsNear(0, 0, 0, 0).pipe(
        switchMap(locations => {
          const currentLocation = locations.find(location => {
            return location.slugURL === locationID;
          });
          return this.tastyIgniterAPI.getRestaurantCategories(currentLocation.locationID).pipe(
            switchMap(categories => {
              const deCategories = this.tiMapping.tiCategoriesToDECategories(currentLocation.locationID, categories);
              return this.tastyIgniterAPI.getRestaurantMenu(currentLocation.locationID).pipe(
                switchMap(menu => {
                  const deMenu = this.tiMapping.tiMenusToDEProducts(currentLocation.locationID, menu, deCategories);
                  return this.contentService.getMenuWithImages(deMenu);
                })
              );
            })
          );
        })
      );
    } else {
      return this.tastyIgniterAPI.getRestaurantCategories(locationID).pipe(
        switchMap(categories => {
          const deCategories = this.tiMapping.tiCategoriesToDECategories(locationID, categories);
          return this.tastyIgniterAPI.getRestaurantMenu(locationID).pipe(
            switchMap(menu => {
              const deMenu = this.tiMapping.tiMenusToDEProducts(locationID, menu, deCategories);
              return this.contentService.getMenuWithImages(deMenu);
            })
          );
        })
      );
    }
  }

  @Cacheable({
    maxCacheCount: 100,
  })
  getProduct(locationID: string, handoffType: HandoffType, menuID: string, categoryID: string, productID: number): Observable<Product> {
    return this.getMenuByHandoffType(locationID, handoffType).pipe(
      switchMap(deMenu => {
        const cat = deMenu.categories.find(cate => {
          return cate.categoryID === categoryID;
        });
        const prod = cat.products.find(produ => {
          return produ.productID === productID.toString();
        });
        return of(prod);
      })
    );
  }

  // getProviderUpsells(basketGuid: string | number): Observable<Upsells> {
  //   return this.oloAPI.getEligibleUpsellItems(basketGuid).
  //       pipe(switchMap(res => {
  //         const upsells = this.mapping.toUpsell(res);
  //         return this.contentService.getUpsellsWithImages(upsells);
  //       }));
  // }

  // addProviderUpsell(
  //     basketGuid: string, body: AddUpsellItemsRequest): Observable<Order> {
  //   return this.oloAPI.addUpsellItemsToBasket(basketGuid, body).pipe(switchMap(res => {
  //     return this.getLocation(res.vendorid).pipe(switchMap(location => {
  //       return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(switchMap(billingSchemes => {
  //         const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
  //         return this.contentService.getOrderItemsWithSlugsandImages(order);
  //       }));
  //     }), catchError(err => {
  //       return throwError(this.mapping.oloError(err));
  //     }));
  //   }), catchError(err => {
  //     return throwError(this.mapping.oloError(err));
  //   }));
  // }

  getProviderUpsells(basketGuid: string | number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  addProviderUpsell(basketGuid: string, body: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  // ORDER

  getCurrentOrder(userID: string): Observable<Order> {
    return this.localBasket.getCurrentOrder();
  }

  startOrder(userID: string, locationID: number, handoffType: HandoffType, tableNumber: string): Observable<Order> {
    return this.getLocation(locationID).pipe(
      switchMap(location => {
        console.log(location);
        return this.localBasket.startOrder(location, handoffType, tableNumber);
      })
    );
  }

  clearOrder(): Observable<string> {
    return this.localBasket.clearOrder();
  }

  setOrderLocation(userID: string, locationID: number, orderID: string): Observable<Order> {
    return this.getLocation(locationID).pipe(
      switchMap(location => {
        return this.localBasket.setOrderLocation(location);
      })
    );
  }

  transferBasket(orderID: string, locationID: number): Observable<Order> {
    return this.getLocation(locationID).pipe(
      switchMap(location => {
        console.log(location);
        return this.localBasket.transferBasket(location);
      })
    );
  }

  addToOrder(orderItem: OrderItem, orderID: string, userID: string): Observable<Order> {
    return this.localBasket.addToOrder(orderItem);
  }

  removeFromOrder(orderItem: OrderItem, orderID: string, userID: string): Observable<Order> {
    return this.localBasket.removeFromOrder(orderItem);
  }

  updateBasketItem(orderItem: OrderItem, orderID: string, userID: string, quant: number): Observable<Order> {
    return this.localBasket.updateBasketItem(orderItem);
  }

  setHandoffType(orderID: string, userID: string, handoffType: HandoffType): Observable<Order> {
    return this.localBasket.setHandoffType(handoffType);
  }

  // TODO: We need to validate against some kind of back end database of valid addresses
  // tslint:disable-next-line: max-line-length
  setDispatchOrDeliveryAddress(orderID: string, userID: string, address: Address, handoffType: HandoffType): Observable<Order> {
    return this.localBasket.setDispatchOrDeliveryAddress(address, handoffType);
  }

  setSpecialInstructions(orderID: string | number, specialInst: string): Observable<Order> {
    return this.localBasket.setSpecialInstructions(specialInst);
  }

  setTimePreference(orderID: string, userID: string, time: Date): Observable<Order> {
    return this.localBasket.setTimePreference(time);
  }

  setTimePreferenceToASAP(orderID: string, userID: string): Observable<Order> {
    return this.localBasket.setTimePreference('asap');
  }

  setTip(userID: string, orderID: string, tipCents: number): Observable<Order> {
    return this.localBasket.setTip(userID, orderID, tipCents);
  }

  setBasketCustomfield(orderId: string, customId: number | string, value: any): Observable<Order> {
    return this.localBasket.setBasketCustomfield(customId, value);
  }

  addCoupon(orderID: string, couponCode: string): Observable<Order> {
    return this.oloAPI.applyCoupon(orderID, couponCode).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  removeCoupon(orderID: string): Observable<Order> {
    return this.oloAPI.removeCoupon(orderID).pipe(
      switchMap(res => {
        return this.oloAPI.getBasket(orderID).pipe(
          switchMap(basketRes => {
            return this.getLocation(res.vendorid).pipe(
              switchMap(location => {
                return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
                  switchMap(billingSchemes => {
                    const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                    return this.contentService.getOrderItemsWithSlugsandImages(order);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  validateOrder(userID: string, locationID: number, orderID: string): Observable<Order> {
    return this.localBasket.validateOrder(userID, locationID, orderID);
  }

  // tslint:disable-next-line:max-line-length
  submitPayment(
    orderID: string,
    cardDetails: CardDetails,
    applyCents: number,
    shouldSave: boolean,
    token: string,
    inst?: string
  ): Observable<Order> {
    return this.submitBasket(cardDetails);
  }

  // tslint:disable-next-line:max-line-length
  submitPaymentAsGuest(
    orderID: string,
    cardDetails: CardDetails,
    applyCents: number,
    shouldSave: boolean,
    token: string,
    inst?: string
  ): Observable<any> {
    return this.submitBasket(cardDetails).pipe(
      map((order: Order) => {
        // const orderCompletion = {
        //   status_id: 2,
        //   status_comment: 'Payment received, order is pending until order time'
        // };
        // return this.tastyIgniterAPI.completeOrder(orderCompletion, parseInt(order.orderID, 10)).pipe(switchMap(() => {
        //   return this.tastyIgniterAPI.submitOrderPayment(cardDetails, parseInt(order.orderID, 10)).pipe(map(() => {
        return order;
        // }, catchError(e => {
        //   return e;
        // })));
        // }));
      })
    );
  }

  cancelOrder(orderID: string): Observable<Order> {
    return this.oloAPI.cancelOrder(orderID).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          map(location => {
            return this.mapping.orderToOrder(res, location);
          })
        );
      })
    );
  }

  checkDeliveryStatus(orderGuid: string, authToken: string): Observable<any> {
    if (authToken) {
      return this.oloAPI.checkOrderDispatchStatus(orderGuid, authToken);
    } else {
      return this.oloAPI.checkGuestOrderDispatchStatus(orderGuid);
    }
  }

  getOrderStatus(orderGuid: string): Observable<Order> {
    return this.tastyIgniterAPI.checkOrderStatus(orderGuid).pipe(
      switchMap(order => {
        return this.getLocation(order.data.attributes.location_id).pipe(
          map(location => {
            return this.tiMapping.orderToOrder(order, location);
          })
        );
      })
    );
  }

  editOrder(orderID: string): Observable<Order> {
    return this.oloAPI.editOrder(orderID).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              map(billingSchemes => {
                this.storeBasketGuid(res.id);
                return this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
              })
            );
          })
        );
      })
    );
  }

  getGiftCardBalance(basketGuid: string, cardNumber: string, pin: string): Observable<GiftCardItem> {
    return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
      switchMap(schemes => {
        const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
        return this.oloAPI.getGiftCardBalance(basketGuid, billingscheme.id, cardNumber, pin).pipe(
          map(gcb => {
            return this.mapping.toGiftCard(gcb, cardNumber, pin);
          })
        );
      })
    );
  }

  // HISTORY

  getOrderHistory(userID: string): Observable<Order[]> {
    return this.getAuthToken().pipe(
      switchMap(token => {
        if (token) {
          return this.oloAPI.getUserRecentOrders(token).pipe(
            switchMap(res => {
              if (res.orders.length === 0) {
                return of([]);
              } else {
                return combineLatest(res.orders.map(order => this.getOrderWithLocationWithHours(order.vendorid, order)));
              }
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  reorderFromHistory(authToken: string, externalOrderRef: string, orderID: string, ignoreUnavailableProducts: boolean): Observable<Order> {
    // tslint:disable-next-line:max-line-length
    return this.oloAPI.checkOrderStatus(orderID).pipe(
      switchMap(prevOrder => {
        // tslint:disable-next-line:max-line-length
        return this.oloAPI.createBasketFromPreviousOrder(authToken, externalOrderRef, orderID, ignoreUnavailableProducts).pipe(
          switchMap(basketResponse => {
            this.storeBasketGuid(basketResponse.id);
            if (prevOrder.deliverymode === 'delivery' || prevOrder.deliverymode === 'dispatch') {
              return this.getLocation(basketResponse.vendorid).pipe(
                switchMap(location => {
                  const address = {
                    city: prevOrder.deliveryaddress.city,
                    streetaddress: prevOrder.deliveryaddress.streetaddress,
                    zipcode: prevOrder.deliveryaddress.zipcode,
                    // phonenumber: user.phoneNumber,
                    isdefault: false,
                  } as OloAddress;
                  if (prevOrder.deliverymode === 'delivery') {
                    return this.oloAPI.setBasketDeliveryAddress(basketResponse.id, address).pipe(
                      switchMap(basketWithAddress => {
                        // tslint:disable-next-line:max-line-length
                        return this.oloAPI
                          .setHandoffMethod(basketWithAddress.id, prevOrder.deliverymode === 'dispatch' ? 'dispatch' : 'delivery')
                          .pipe(
                            switchMap(lastOrder => {
                              return this.oloAPI.getAllBillingSchemesAndAccounts(lastOrder.id).pipe(
                                switchMap(billingSchemes => {
                                  const order = this.mapping.createBasketResponseToOrder(
                                    lastOrder,
                                    location,
                                    billingSchemes.billingschemes
                                  );
                                  return this.contentService.getOrderItemsWithSlugsandImages(order);
                                })
                              );
                            }),
                            catchError(() => {
                              return of(null);
                            })
                          );
                      })
                    );
                  } else if (prevOrder.deliverymode === 'dispatch') {
                    return this.oloAPI.setBasketDispatchAddress(basketResponse.id, address).pipe(
                      switchMap(basketWithAddress => {
                        // tslint:disable-next-line:max-line-length
                        return this.oloAPI
                          .setHandoffMethod(basketWithAddress.id, prevOrder.deliverymode === 'dispatch' ? 'dispatch' : 'delivery')
                          .pipe(
                            switchMap(lastOrder => {
                              return this.oloAPI.getAllBillingSchemesAndAccounts(lastOrder.id).pipe(
                                switchMap(billingSchemes => {
                                  const order = this.mapping.createBasketResponseToOrder(
                                    lastOrder,
                                    location,
                                    billingSchemes.billingschemes
                                  );
                                  return this.contentService.getOrderItemsWithSlugsandImages(order);
                                })
                              );
                            }),
                            catchError(() => {
                              return of(null);
                            })
                          );
                      })
                    );
                  }
                })
              );
            } else {
              return this.getLocation(basketResponse.vendorid).pipe(
                switchMap(location => {
                  return this.oloAPI.getAllBillingSchemesAndAccounts(basketResponse.id).pipe(
                    switchMap(billingSchemes => {
                      const order = this.mapping.createBasketResponseToOrder(basketResponse, location, billingSchemes.billingschemes);
                      return this.contentService.getOrderItemsWithSlugsandImages(order);
                    })
                  );
                })
              );
            }
          })
        );
      })
    );
  }

  removeCard(card: SavedCard): Observable<SavedCard> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.deleteUserBillingAccount(id, Number(card.savedCardID)).pipe(
          map(() => {
            return null;
          })
        );
      })
    );
  }

  saveCardAsDefault(card: SavedCard): Observable<SavedCard> {
    return this.getAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.setUserDefaultCreditCard(id, Number(card.savedCardID)).pipe(
          map(() => {
            return null;
          })
        );
      })
    );
  }

  getSavedCards(userID: string | number, isAccountPage: boolean): Observable<SavedCard[]> {
    return this.getAuthToken().pipe(
      switchMap(token => {
        if (token) {
          if (isAccountPage) {
            return this.oloAPI.getUserBillingAccounts(token).pipe(
              map(res => {
                return res.billingaccounts
                  .filter(account => account.accounttype === 'creditcard')
                  .map(account => this.mapping.toCreditCard(account));
              })
            );
          } else {
            return this.oloAPI.getQualifyingUserBillingAccounts(token, this.getBasketGuid()).pipe(
              map(res => {
                return res.billingaccounts
                  .filter(account => account.accounttype === 'creditcard')
                  .map(account => this.mapping.toCreditCard(account));
              })
            );
          }
        } else {
          return of([]);
        }
      })
    );
  }

  // Common rewards functions, need to be non-private to allow access to loyalty providers

  getRewards(userID: string | number, locationID: string): Observable<Reward[]> {
    return this.getAuthToken().pipe(
      switchMap(oloAuth => {
        if (oloAuth) {
          return this.oloAPI.getUsersQualifyingRewards(oloAuth, locationID).pipe(
            map(res => {
              return res.rewards.map(r => this.mapping.toReward(r));
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  redeemReward(reward: Reward): Observable<Order> {
    return this.oloAPI.applyRewards(this.getBasketGuid(), reward.membershipID, [reward.externalRef.toString()]).pipe(
      switchMap(() => {
        return this.oloAPI.validateBasket(this.getBasketGuid(), false).pipe(
          switchMap(() => {
            return this.oloAPI.getBasket(this.getBasketGuid()).pipe(
              switchMap(basketRes => {
                return this.getLocation(basketRes.vendorid).pipe(
                  switchMap(location => {
                    return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
                      switchMap(billingSchemes => {
                        const order = this.mapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                        return this.contentService.getOrderItemsWithSlugsandImages(order);
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  removeAppliedReward(reward: Reward): Observable<Order> {
    return this.oloAPI.removeAppliedReward(this.getBasketGuid(), Number(reward.rewardID)).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.mapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  submitBasket(cardDetails: CardDetails): Observable<Order> {
    // return this.getAuthToken().pipe(switchMap(id => {
    //   return this.tastyIgniterAPI.validateBasket(orderID, false).pipe(switchMap(order => {
    //     if (cardDetails.paymentType === PaymentTypes.creditCard) {
    return this.localBasket.getCurrentOrder().pipe(
      switchMap(basket => {
        const body = this.tiMapping.basketToOrderSubmission(basket, cardDetails);
        return this.tastyIgniterAPI.submitOrder(body).pipe(
          switchMap(res => {
            this.storeBasketGuid('');
            sessionStorage.setItem('deliveryBasketGuid', res.id);
            // return this.getLocation(res.vendorid).pipe(map(location => {
            //   return this.mapping.orderToOrder(res, location);
            // }));
            return this.tastyIgniterAPI.confirmOrder(res.data.id).pipe(
              switchMap(() => {
                return this.localBasket.getCurrentOrder().pipe(
                  map((order: Order) => {
                    order.orderID = res.data.id;
                    return order;
                  })
                );
              })
            );
          })
        );
      })
    );
    //     }
    //   }));
    // }));
  }

  addDonation(basketGuid: string, id: number, amount: number): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  private storeBasketGuid(basketGuid: string) {
    sessionStorage.setItem(this.basketGuidKey, basketGuid);
  }

  private getBasketGuid(): string {
    return sessionStorage.getItem(this.basketGuidKey);
  }

  private removeBasketGuid(): void {
    sessionStorage.removeItem(this.basketGuidKey);
  }

  private storeAuthToken(token: string): Observable<null> {
    Storage.set({ key: this.authTokenKey, value: token }).then(() => {
      this.oloAuthTokenSubject.next(token);
    });
    return of(null);
    // localStorage.setItem(this.authTokenKey, token);
  }

  private getAuthToken(): Observable<string> {
    return from(Storage.get({ key: this.authTokenKey })).pipe(
      map((res: any) => {
        const authToken = res.value;
        const token = authToken && authToken !== 'null' ? authToken : null;
        // console.log(authToken, token);
        this.oloAuthTokenSubject.next(token);
        return token;
      })
    );
  }

  private removeAuthToken(): void {
    Storage.remove({ key: this.authTokenKey });
    localStorage.removeItem(this.authTokenKey);
  }

  private getLocationWithHours(location: Location): Observable<Location> {
    // tslint:disable-next-line:max-line-length
    return this.oloAPI.getRestaurantOperatingHoursForThisWeek(Number(location.locationID), location.orderAheadDays).pipe(
      map(calendarRes => {
        return this.mapping.getLocationWithHours(location, calendarRes.calendar);
      }),
      catchError(() => {
        return of(location);
      })
    );
  }

  private getOrderWithLocationWithHours(vendorid: number, order: any): Observable<Order> {
    return this.getLocation(vendorid).pipe(
      map(location => {
        return this.mapping.orderToOrder(order, location);
      })
    );
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<any> {
    return undefined;
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    return undefined;
  }

  joinGroupOrder(groupGuid: string, name: string): Observable<GroupOrder> {
    return undefined;
  }

  // tslint:disable-next-line:max-line-length
  startGroupOrder(
    restaurantID: number,
    basketGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return undefined;
  }

  // tslint:disable-next-line:max-line-length
  updateGroupOrder(
    restaurantID: number,
    basketGuid: string,
    groupGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return undefined;
  }

  getCurrentGroupOrder(restaurantID: number, basketGuid: string, name: string): Observable<GroupOrder> {
    return undefined;
  }

  getGroupOrder(groupID: string, name: string): Observable<GroupOrder> {
    return undefined;
  }

  clearGroupOrder(): Observable<GroupOrder> {
    return undefined;
  }

  getAvailableOrderDays(locationID: string | number, orderID: string | number): Observable<Date[]> {
    return undefined;
  }

  getAvailableOrderTimes(locationID: string | number, orderID: string | number, date: Date): Observable<Moment[]> {
    return undefined;
  }

  checkIfCanSupportMultipleRewards(orderID: string): Observable<boolean> {
    return of(false);
  }
}
