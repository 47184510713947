import { Injectable } from '@angular/core';

import {Observable, of} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {MainSettings} from '../../../vendors/directus/interfaces/main-settings.interface';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {Capacitor} from '@capacitor/core';
import {ModeService} from '../../../services/mode.service';
import {NavigationService} from '@modules/navigation/services';
import {MobileService} from '../../../services/mobile.service';
import {DirectusService} from '../../../vendors/directus/directus.service';
import {OloConfiguration} from '../../../vendors/directus/interfaces/olo-configuration.interface';
import {User} from '../../../interfaces/user.interface';

@Injectable()
export class BrochureGuard  {
    @Select(state => state.app.mainSettings) settings$: Observable<MainSettings>;

    constructor(
      private mode: ModeService,
      private navigation: NavigationService,
      private mobile: MobileService,
      private directus: DirectusService,
      private store: Store
    ) {}

    canActivate(): Observable<boolean> {
      return this.store.select(state => state.user.user).pipe(filter(u => u !== null), take(1), switchMap((user: User) => {
        if (!user.isGuest) {
          return this.directus.getOloConfiguration().pipe(switchMap((oloConfig: OloConfiguration) => {
            if (oloConfig.wrap_olo_serve && Capacitor.getPlatform() !== 'web') {
              this.navigation.navigateToWelcomePage();
              return of(false);
            } else {
              return this.shouldRouteToLocations();
            }
          }));
        } else {
          return this.shouldRouteToLocations();
        }
      }));
    }

    shouldRouteToLocations(): Observable<boolean> {
      return this.settings$.pipe(filter(s => s !== null), map(settings => {
        const isWeb = this.mode.mode !== 'tableside'
          && this.mode.mode !== 'kiosk'
          && (Capacitor.getPlatform() === 'web'
          || (Capacitor.getPlatform() !== 'web' && !this.mobile.isMobile));
        if (settings.automatically_route_to_locations_page && isWeb) {
          this.navigation.navigateToLocationsPage(true);
          return false;
        } else {
          if (settings.show_welcome_for_guests) {
            this.navigation.navigateToWelcomePage();
            return false;
          } else {
            return true;
          }
        }
      }));
    }
}
