import { Injectable } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SBRouteData } from '../models';

@Injectable()
export class NavigationService {
    // tslint:disable-next-line:variable-name
    _sideNavVisible$ = new BehaviorSubject(true);
    // tslint:disable-next-line:variable-name
    _routeData$ = new BehaviorSubject({} as SBRouteData);
    // tslint:disable-next-line:variable-name
    _currentURL$ = new BehaviorSubject('');

    // Routes that need to be built OTF need to be built different

    routeSlugs = {
      home: [''],
      locations: ['locations'],
      menu: ['menu'],
      login: ['user', 'login'],
      createAccount: ['user', 'create-account'],
      forgotPassword: ['user', 'forgot-password'],
      welcome: ['welcome'],
      account: ['account'],
      profile: ['profile'],
        incompleteProfile: ['incomplete-profile'],
        inbox: ['profile', 'inbox'],
      previousOrders: ['profile', 'orders'],
      editProfile: ['profile', 'edit'],
      savedCards: ['profile', 'saved-cards'],
      rewards: ['profile', 'rewards'],
      activity: ['profile', 'activity'],
      cart: ['cart'],
      checkout: ['checkout', 'details'],
      upsell: ['checkout', 'upsells'],
      confirmation: ['checkout', 'confirmation'],
      error: ['404'],
      privacyPolicy: ['privacy-policy'],
      termsAndConditions: ['terms-and-conditions'],
      more: ['more'],
      startGroupOrder: ['group-order', 'start'],
      groupOrderDetails: ['group-order', 'details'],
      updateGroupOrder: ['group-order', 'update'],
      joinGroupOrder: ['group-order', 'join']
    };

    constructor(public route: ActivatedRoute, public router: Router) {
        this.router.events
            .pipe(filter(event => event instanceof ChildActivationEnd))
            .subscribe(event => {
                let snapshot = (event as ChildActivationEnd).snapshot;
                while (snapshot.firstChild !== null) {
                    snapshot = snapshot.firstChild;
                }
                this._routeData$.next(snapshot.data as SBRouteData);
                this._currentURL$.next(router.url);
            });
    }

    sideNavVisible$(): Observable<boolean> {
        return this._sideNavVisible$;
    }

    toggleSideNav(visibility?: boolean) {
        if (typeof visibility !== 'undefined') {
            this._sideNavVisible$.next(visibility);
        } else {
            this._sideNavVisible$.next(!this._sideNavVisible$.value);
        }
    }

    routeData$(): Observable<SBRouteData> {
        return this._routeData$;
    }

    currentURL$(): Observable<string> {
        return this._currentURL$;
    }

    // COMMON NAVIGATION FUNCTIONS

    // Brochure

    navigateToHomePage(mode?: string, locationID?: string): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.home, {queryParams: {
          mode,
          locationID
        } });
    }

    navigateToMorePage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.more, { });
    }

    // Locations

    navigateToLocationsPage(fromHome: boolean = false): Promise<boolean> {
      // tslint:disable-next-line:max-line-length
        return this.router.navigate(this.routeSlugs.locations, fromHome ? { fragment: 'home', } : { });
    }

    navigateToPickupLocationsPage(toMenu: boolean = false): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.locations, { queryParams: {switchTo: 'pickup'}, fragment: toMenu ? 'menu' : null });
    }

    navigateToDeliveryLocationsPage(toMenu: boolean = false): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.locations, { queryParams: {switchTo: 'delivery'}, fragment: toMenu ? 'menu' : null });
    }

    navigateToLocationDetailsPage(id: string): Promise<boolean> {
        const locationDetails = [...this.routeSlugs.locations];
        locationDetails.push(id);
        return this.router.navigate(locationDetails, { });
    }

    // Menu

    // tslint:disable-next-line:max-line-length
    navigateToMenuPage(id: string, category?: string, product?: string, modify?: string, productID?: string, orderItemID?: string): Promise<boolean> {
        if (category) {
            if (product) {
                if (modify) {
                    if (productID && orderItemID) {
                        return this.router.navigate(['menu', id, category, product, modify], {
                          queryParams: {
                            basketProd: productID,
                            basketProdID: orderItemID
                          },
                        });
                    } else {
                        return this.router.navigate(['menu', id, category, product, modify], { });
                    }
                } else {
                    return this.router.navigate(['menu', id, category, product], { });
                }
            } else {
                return this.router.navigate(['menu', id, category], { });
            }
        } else {
            return this.router.navigate(['menu', id], { });
        }
    }

    // tslint:disable-next-line:max-line-length
    navigateToMenuPageDeleteParams(id: string, category?: string, product?: string, modify?: string, productID?: string, orderItemID?: string): Promise<boolean> {
        if (category) {
            if (product) {
                if (modify) {
                    if (productID && orderItemID) {
                        return this.router.navigate(['menu', id, category, product, modify], {
                          queryParams: {
                            basketProd: productID,
                            basketProdID: orderItemID
                          }
                        });
                    } else {
                        return this.router.navigate(['menu', id, category, product, modify]);
                    }
                } else {
                    return this.router.navigate(['menu', id, category, product]);
                }
            } else {
                return this.router.navigate(['menu', id, category]);
            }
        } else {
            return this.router.navigate(['menu', id]);
        }
    }

    // Auth

    navigateToLoginPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.login, { });
    }

    navigateToCreateAccountPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.createAccount, { });
    }

    navigateToForgotPasswordPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.forgotPassword, { });
    }

    navigateToWelcomePage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.welcome, { });
    }

    // Account

    navigateToAccountPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.account, { });
    }

    navigateToProfilePage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.profile, { });
    }

    navigateToPreviousOrdersPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.previousOrders, { });
    }

    navigateToEditProfilePage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.editProfile, { });
    }

    navigateToSavedCardsPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.savedCards, { });
    }

    navigateToRewardsPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.rewards, { });
    }

    navigateToInboxPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.inbox, { });
    }

    navigateToEarnModal(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.rewards, { fragment: 'earn' });
    }

    navigateToActivityPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.activity, { });
    }

    navigateToIncompleteProfilePage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.incompleteProfile, { });
    }

    // Cart

    navigateToCartPage(id: string): Promise<boolean> {
        const cart = [...this.routeSlugs.cart];
        cart.push(id);
        return this.router.navigate(cart, { });
    }

    navigateToCartPageDeleteParams(id: string): Promise<boolean> {
        const cart = [...this.routeSlugs.cart];
        cart.push(id);
        return this.router.navigate(cart);
    }

    // Group Order

    navigateToStartGroupOrderPage(): Promise<boolean> {
        const startGroupOrder = [...this.routeSlugs.startGroupOrder];
        return this.router.navigate(startGroupOrder);
    }

    handlePostLoginNavigation(): void {
        const returnUrl = localStorage.getItem('returnUrl');
        if (returnUrl) {
            localStorage.removeItem('returnUrl');
            this.router.navigateByUrl(returnUrl);
        }
    }

    navigateToGroupOrderDetailsPage(): Promise<boolean> {
      const groupOrderDetails = [...this.routeSlugs.groupOrderDetails];
      return this.router.navigate(groupOrderDetails, { });
    }

    navigateToUpdateGroupOrderPage(id: string): Promise<boolean> {
      const updateGroupOrder = [...this.routeSlugs.updateGroupOrder, id];
      return this.router.navigate(updateGroupOrder, { });
    }

    navigateToJoinGroupOrderPage(id: string): Promise<boolean> {
      const joinGroupOrder = [...this.routeSlugs.joinGroupOrder, id];
      return this.router.navigate(joinGroupOrder, { });
    }

    // Checkout

    navigateToCheckoutPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.checkout, { });
    }

    navigateToUpsellPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.upsell, { });
    }

    navigateToConfirmationPage(id: string): Promise<boolean> {
        const confirmation = [...this.routeSlugs.confirmation];
        confirmation.push(id);
        return this.router.navigate(confirmation, { });
    }

    // Error

    navigateTo404Page(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.error, { });
    }

    // Custom

    navigateToPrivacyPolicyPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.privacyPolicy, { });
    }

    navigateToTermsAndConditionsPage(): Promise<boolean> {
        return this.router.navigate(this.routeSlugs.termsAndConditions, { });
    }

    navigateToKioskStartOrderPage(location: string): Promise<boolean> {
        return this.router.navigateByUrl(`?mode=kiosk&location=${location}`);
    }


    // SLUG GENERATION FUNCTIONS

    getHomePageSlug(): string {
        return '/' + this.routeSlugs.home.join('/');
    }

    getLocationsPageSlug(): string {
        return '/' + this.routeSlugs.locations.join('/');
    }

    getLocationDetailsPageSlug(id: string): string {
        const locationDetails = [...this.routeSlugs.locations];
        locationDetails.push(id);
        return '/' + locationDetails.join('/');
    }

    // This needs to be thought out
    getMenuPageSlug(): string {
        return '/' + this.routeSlugs.menu.join('/');
    }

    getLoginPageSlug(): string {
        return '/' + this.routeSlugs.login.join('/');
    }

    getCreateAccountPageSlug(): string {
        return '/' + this.routeSlugs.createAccount.join('/');
    }

    getForgotPasswordPageSlug(): string {
        return '/' + this.routeSlugs.forgotPassword.join('/');
    }

    getWelcomePageSlug(): string {
        return '/' + this.routeSlugs.welcome.join('/');
    }

    getAccountPageSlug(): string {
        return '/' + this.routeSlugs.account.join('/');
    }

    getProfilePageSlug(): string {
        return '/' + this.routeSlugs.profile.join('/');
    }

    getInboxPageSlug(): string {
        return '/' + this.routeSlugs.inbox.join('/');
    }

    getPreviousOrdersPageSlug(): string {
        return '/' + this.routeSlugs.previousOrders.join('/');
    }

    getEditProfilePageSlug(): string {
        return '/' + this.routeSlugs.editProfile.join('/');
    }

    getSavedCardsPageSlug(): string {
        return '/' + this.routeSlugs.savedCards.join('/');
    }

    getRewardsPageSlug(): string {
        return '/' + this.routeSlugs.rewards.join('/');
    }

    getActivityPageSlug(): string {
        return '/' + this.routeSlugs.activity.join('/');
    }

    getCartPageSlug(id: string): string {
        const cart = [...this.routeSlugs.cart];
        cart.push(id);
        return '/' + cart.join('/');
    }

    getCheckoutPageSlug(): string {
        return '/' + this.routeSlugs.checkout.join('/');
    }

    getUpsellPageSlug(): string {
        return '/' + this.routeSlugs.upsell.join('/');
    }

    getConfirmationPageSlug(id: string): string {
        const confirmation = [...this.routeSlugs.confirmation];
        confirmation.push(id);
        return '/' + confirmation.join('/');
    }

    get404PageSlug(): string {
        return '/' + this.routeSlugs.error.join('/');
    }

    getPrivacyPolicyPageSlug(): string {
        return '/' + this.routeSlugs.privacyPolicy.join('/');
    }

    getTermsAndConditionsPageSlug(): string {
        return '/' + this.routeSlugs.termsAndConditions.join('/');
    }
}
