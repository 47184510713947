import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DirectusConnectorService } from './directus-connector.service';
import { ContentProvider } from 'src/providers/content-provider.interface';
import { VendorSetupProvider } from 'src/providers/vendor-setup-provider.interface';
import { VendorSetup } from './interfaces/vendor.interface';
import { MainSettings } from './interfaces/main-settings.interface';
import { Branding } from './interfaces/branding.interface';
import { NavbarSettings } from './interfaces/navbar-settings.interface';
import { Category } from './interfaces/category.interface';
import { ThemeColor } from './interfaces/theme-color.interface';
import { Product } from './interfaces/product.interface';
import { Banner } from './interfaces/banner.interface';
import { KioskContent } from './interfaces/kiosk-content.interface';
import { Ingredient } from './interfaces/ingredient.interface';
import { ModifierGroup } from './interfaces/modifier-group.interface';
import { PunchhConfiguration } from './interfaces/punchh-configuration.interface';
import { TextField } from './interfaces/text-field.interface';
import { ContactForm } from './interfaces/contact-form.interface';
import { Location } from './interfaces/location.interface';
import { ItwercsConfiguration } from './interfaces/itwercs-configuration.interface';
import { DatacapConfiguration } from './interfaces/datacap-configuration.interface';
import { PwaSettings } from './interfaces/pwa-settings.interface';
import { OloConfiguration } from './interfaces/olo-configuration.interface';
import { Cacheable } from 'ts-cacheable';
import { PersonicaConfiguration } from './interfaces/personica-configuration.interface';
import { NovaDineConfiguration } from './interfaces/novadine-configuration.interface';
import { PaytronixConfiguration } from './interfaces/paytronix-configuration.interface';
import { SpendgoConfiguration } from './interfaces/spendgo-configuration.interface';
import { MobileAppSettings } from './interfaces/mobile-app-settings.interface';
import { Onboarding } from './interfaces/onboarding.interface';
import { CustomIcons } from './interfaces/custom-icons.interface';
import { PromotionalContent } from './interfaces/promotional-content.interface';
import { KioskConfig } from './interfaces/kiosk-config.interface';
import { Features } from './interfaces/features.interface';
import { RecaptchaConfiguration } from './interfaces/recaptcha-configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class DirectusService implements VendorSetupProvider, ContentProvider {
  private vendorSetup: VendorSetup;
  private contentProducts: Product[];
  private currentProduct: Product;
  private branding: Branding;
  private navbarSettings: NavbarSettings;
  private themeColors: ThemeColor[];
  private brochure: any;
  private brochureContent: any;
  private promotionalContent: any;
  private subnav: any;
  private textFields: TextField;
  private contactForm: ContactForm[];
  private accountSubnav: any;
  private customPages: any;
  private locations: any;
  // private contactForm: any;
  private mainSettings: MainSettings;
  private banners: Banner[];
  private kioskContent: any;
  private contentCategories: Category[];
  private contentIngredients: Ingredient[];
  private modifierGroups: any[];
  private contentPunchhConfig: PunchhConfiguration;
  private contentItwercsConfig: ItwercsConfiguration;
  private contentDatacapConfig: DatacapConfiguration;
  private contentPwaSettings: PwaSettings;
  private contentOloSettings: OloConfiguration;
  private contentPersonicaSettings: PersonicaConfiguration;
  private contentSpendgoSettings: SpendgoConfiguration;
  private singleLocation: any;
  private contentNovadineSettings: NovaDineConfiguration;
  private contentPaytronixSettings: PaytronixConfiguration;
  private contentMobileAppSettings: MobileAppSettings;
  private contentOnboardingSlides: Onboarding[];
  private contentCustomIcons: CustomIcons;
  private oloConfiguration: OloConfiguration;
  private features: Features[];
  private recaptchaConfiguration: RecaptchaConfiguration;

  constructor(private connectorService: DirectusConnectorService) {}

  getInitialData() {
    this.getContentOptions().subscribe(() => {});
    this.getContentCategories().subscribe(() => {});
    this.getContentProducts().subscribe(() => {});
    this.getModifierGroups().subscribe(() => {});
  }

  getContentOptions(): Observable<Ingredient[]> {
    return this.contentIngredients
      ? of(this.contentIngredients)
      : this.connectorService.getIngredients().pipe(
          tap(res => (this.contentIngredients = res.data)),
          map(res => res.data)
        );
  }

  getModifierGroups(): Observable<ModifierGroup[]> {
    return this.modifierGroups
      ? of(this.modifierGroups)
      : this.connectorService.getModifierGroups().pipe(
          tap(res => (this.modifierGroups = res.data)),
          map(res => res.data)
        );
  }

  getVendorSetup(): Observable<VendorSetup> {
    return this.vendorSetup
      ? of(this.vendorSetup)
      : this.connectorService.getVendorSetup().pipe(
          tap(res => (this.vendorSetup = res.data)),
          map(res => res.data)
        );
  }

  getBranding(): Observable<Branding> {
    return this.branding
      ? of(this.branding)
      : this.connectorService.getBranding().pipe(
          tap(res => (this.branding = res.data)),
          map(res => res.data)
        );
  }

  getNavbarSettings(): Observable<NavbarSettings> {
    return this.navbarSettings
      ? of(this.navbarSettings)
      : this.connectorService.getNavbarSettings().pipe(
          tap(res => (this.navbarSettings = res.data)),
          map(res => res.data)
        );
  }

  getTextFields(): Observable<TextField> {
    return this.textFields
      ? of(this.textFields)
      : this.connectorService.getTextFields().pipe(
          tap(res => (this.textFields = res.data)),
          map(res => res.data)
        );
  }

  getContactForms(): Observable<ContactForm[]> {
    return this.contactForm
      ? of(this.contactForm)
      : this.connectorService.getContactForm().pipe(
          tap(res => (this.contactForm = res.data)),
          map(res => res.data)
        );
  }

  getColors(): Observable<ThemeColor[]> {
    return this.themeColors
      ? of(this.themeColors)
      : this.connectorService.getThemeColors().pipe(
          tap(res => (this.themeColors = res.data)),
          map(res => res.data)
        );
  }

  getBrochure(): Observable<any> {
    return this.brochure
      ? of(this.brochure)
      : this.connectorService.getBrochure().pipe(
          tap(res => (this.brochure = res.data)),
          map(res => res.data)
        );
  }

  getBrochureContent(): Observable<any> {
    return this.brochureContent
      ? of(this.brochureContent)
      : this.connectorService.getBrochureContent().pipe(
          tap(res => (this.brochureContent = res.data)),
          map(res => res.data)
        );
  }

  getPromotionalContent(): Observable<PromotionalContent> {
    return this.promotionalContent
      ? of(this.promotionalContent)
      : this.connectorService.getPromotionalContent().pipe(
          tap(res => (this.promotionalContent = res.data)),
          map(res => res.data)
        );
  }

  getCustomPages(): Observable<any> {
    return this.customPages
      ? of(this.customPages)
      : this.connectorService.getCustomPages().pipe(
          tap(res => (this.customPages = res.data)),
          map(res => res.data)
        );
  }

  getSubnav(): Observable<any> {
    return this.subnav
      ? of(this.subnav)
      : this.connectorService.getSubnav().pipe(
          tap(res => (this.subnav = res.data)),
          map(res => res.data)
        );
  }

  getAccountSubnav(): Observable<any> {
    return this.accountSubnav
      ? of(this.accountSubnav)
      : this.connectorService.getAccountSubnav().pipe(
          tap(res => (this.accountSubnav = res.data)),
          map(res => res.data)
        );
  }

  getContactForm(): Observable<any> {
    return this.contactForm
      ? of(this.contactForm)
      : this.connectorService.getContactForm().pipe(
          tap(res => (this.contactForm = res.data)),
          map(res => res.data)
        );
  }

  getSettings(): Observable<MainSettings> {
    return this.mainSettings
      ? of(this.mainSettings)
      : this.connectorService.getMainSettings().pipe(
          tap(res => (this.mainSettings = res.data)),
          map(res => res.data)
        );
  }

  getBanners(): Observable<Banner[]> {
    return this.banners
      ? of(this.banners)
      : this.connectorService.getBanners().pipe(
          tap(res => (this.banners = res.data)),
          map(res => res.data)
        );
  }

  getKioskContent(): Observable<KioskContent> {
    return this.kioskContent
      ? of(this.kioskContent)
      : this.connectorService.getKioskContent().pipe(
          tap(res => (this.kioskContent = res.data)),
          map(res => res.data)
        );
  }

  getContentProducts(): Observable<Product[]> {
    return this.contentProducts
      ? of(this.contentProducts)
      : this.connectorService.getProducts().pipe(
          tap(res => (this.contentProducts = res.data)),
          map(res => res.data)
        );
  }

  getSingleProductByID(productID: string): Observable<Product> {
    return this.currentProduct
      ? of(this.currentProduct)
      : this.connectorService.getProductInfo(productID).pipe(
          tap(res => (this.currentProduct = res.data)),
          map(res => res.data)
        );
  }

  getContentCategories(): Observable<Category[]> {
    return this.contentCategories
      ? of(this.contentCategories)
      : this.connectorService.getCategories().pipe(
          tap(res => (this.contentCategories = res.data)),
          map(res => res.data)
        );
  }

  getPunchhSettings(): Observable<PunchhConfiguration> {
    return this.contentPunchhConfig
      ? of(this.contentPunchhConfig)
      : this.connectorService.getPunchhConfiguration().pipe(
          tap(res => (this.contentPunchhConfig = res.data)),
          map(res => res.data)
        );
  }

  getItwercsSettings(): Observable<ItwercsConfiguration> {
    return this.contentItwercsConfig
      ? of(this.contentItwercsConfig)
      : this.connectorService.getItwercsConfiguration().pipe(
          tap(res => (this.contentItwercsConfig = res.data)),
          map(res => res.data)
        );
  }

  getDatacapSettings(): Observable<DatacapConfiguration> {
    return this.contentDatacapConfig
      ? of(this.contentDatacapConfig)
      : this.connectorService.getDatacapConfiguration().pipe(
          tap(res => (this.contentDatacapConfig = res.data)),
          map(res => res.data)
        );
  }

  getOloSettings(): Observable<OloConfiguration> {
    return this.contentOloSettings
      ? of(this.contentOloSettings)
      : this.connectorService.getOloConfiguration().pipe(
          tap(res => (this.contentOloSettings = res.data)),
          map(res => res.data)
        );
  }

  getPersonicaSettings(): Observable<PersonicaConfiguration> {
    return this.contentPersonicaSettings
      ? of(this.contentPersonicaSettings)
      : this.connectorService.getPersonicaConfiguration().pipe(
          tap(res => (this.contentPersonicaSettings = res.data)),
          map(res => res.data)
        );
  }

  getSpendgoSettings(): Observable<SpendgoConfiguration> {
    return this.contentSpendgoSettings
      ? of(this.contentSpendgoSettings)
      : this.connectorService.getSpendgoConfiguration().pipe(
          tap(res => (this.contentSpendgoSettings = res.data)),
          map(res => res.data)
        );
  }

  getPaytronixSettings(): Observable<PaytronixConfiguration> {
    return this.contentPaytronixSettings
      ? of(this.contentPaytronixSettings)
      : this.connectorService.getPaytronixConfiguration().pipe(
          tap(res => (this.contentPaytronixSettings = res.data)),
          map(res => res.data)
        );
  }

  getPwaSettings(): Observable<PwaSettings> {
    return this.contentPwaSettings
      ? of(this.contentPwaSettings)
      : this.connectorService.getPwaSettings().pipe(
          tap(res => (this.contentPwaSettings = res.data)),
          map(res => res.data)
        );
  }

  @Cacheable({
    maxCacheCount: 100,
  })
  getLocations(): Observable<Location[]> {
    return this.locations
      ? of(this.locations)
      : this.connectorService.getLocations().pipe(
          tap(res => (this.locations = res.data)),
          map(res => res.data)
        );
  }

  getSingleLocation(name: string): Observable<Location> {
    return this.singleLocation
      ? of(this.singleLocation)
      : this.connectorService.getLocationInfo(name).pipe(
          tap(res => (this.singleLocation = res.data)),
          map(res => res.data)
        );
  }

  @Cacheable({
    maxCacheCount: 100,
  })
  getSingleLocationByID(locationID: string): Observable<Location> {
    return this.connectorService.getLocationByID(locationID).pipe(
      tap(res => (this.singleLocation = res.data)),
      map(res => res.data)
    );
  }

  @Cacheable({
    maxCacheCount: 100,
  })
  getSingleLocationBySlug(slug: string): Observable<Location> {
    return this.connectorService.getLocationBySlug(slug).pipe(
      tap(res => (this.singleLocation = res.data)),
      map(res => res.data)
    );
  }

  postContactForm(data: any): Observable<any> {
    return this.connectorService.sendContactForm(data);
  }

  sendFeedback(data: any): Observable<any> {
    return this.connectorService.sendFeedback(data).pipe(
      tap(res => (this.singleLocation = res.data)),
      map(res => res.data)
    );
  }

  sendServiceRequest(data: any): Observable<any> {
    return this.connectorService.sendServiceRequest(data).pipe(
      tap(res => (this.singleLocation = res.data)),
      map(res => res.data)
    );
  }

  getNovadineSettings(): Observable<NovaDineConfiguration> {
    return this.contentNovadineSettings
      ? of(this.contentNovadineSettings)
      : this.connectorService.getNovaDineConfiguration().pipe(
          tap(res => (this.contentNovadineSettings = res.data)),
          map(res => res.data)
        );
  }

  getKioskConfig(kioskNumber: string): Observable<KioskConfig> {
    return this.connectorService.getKioskConfig(kioskNumber).pipe(
      tap(res => (this.singleLocation = res.data)),
      map(res => res.data)
    );
  }

  getKioskConfigs(): Observable<any> {
    return this.connectorService.getKioskConfigs().pipe(
      tap(res => (this.singleLocation = res.data)),
      map(res => res.data)
    );
  }

  getTrancloudConfig(): Observable<any> {
    return this.connectorService.getDatacapConfiguration().pipe(
      tap(res => (this.singleLocation = res.data)),
      map(res => res.data)
    );
  }

  getMobileAppSettings(): Observable<MobileAppSettings> {
    return this.contentMobileAppSettings
      ? of(this.contentMobileAppSettings)
      : this.connectorService.getMobileAppSettings().pipe(
          tap(res => (this.contentMobileAppSettings = res.data)),
          map(res => res.data)
        );
  }

  getOnboardingSlides(): Observable<Onboarding[]> {
    return this.contentOnboardingSlides
      ? of(this.contentOnboardingSlides)
      : this.connectorService.getOnboardingSlides().pipe(
          tap(res => (this.contentOnboardingSlides = res.data)),
          map(res => res.data)
        );
  }

  getCustomIcons(): Observable<CustomIcons> {
    return this.contentCustomIcons
      ? of(this.contentCustomIcons)
      : this.connectorService.getCustomIcons().pipe(
          tap(res => (this.contentCustomIcons = res.data)),
          map(res => res.data)
        );
  }

  getOloConfiguration(): Observable<OloConfiguration> {
    return this.oloConfiguration
      ? of(this.oloConfiguration)
      : this.connectorService.getOloConfiguration().pipe(
          tap(res => (this.oloConfiguration = res.data)),
          map(res => res.data)
        );
  }

  getFeatures(): Observable<Features[]> {
    return this.features
      ? of(this.features)
      : this.connectorService.getFeatures().pipe(
          tap(res => (this.features = res.data)),
          map(res => res.data)
        );
  }

  getRecaptchaConfig(): Observable<RecaptchaConfiguration> {
    return this.recaptchaConfiguration
      ? of(this.recaptchaConfiguration)
      : this.connectorService.getRecaptchaConfig().pipe(
          tap(res => (this.recaptchaConfiguration = res.data)),
          map(res => res.data)
        );
  }
}
