<ng-container *ngIf="vendorSetup$ | async as vendorSetup"><!--Only show on mobile-->
  <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
    <div class="tab-bar w-100" [style.background-color]="mobileAppSettings.background_color">

      <!--    Turn tab into component-->
      <!--    Each tab link, icon, and title will be configured via the CMS-->
      <ng-container *ngIf="user$ | async as user">
        <button type="button" class="tab" routerLinkActive #rlaHome="routerLinkActive" [routerLink]="user.isGuest ? '/' :'/welcome'"
                [routerLinkActiveOptions]="{exact: true}" [style.background-color]="mobileAppSettings.background_color">
          <div class="tab-icon">
            <img
                [src]="rlaHome.isActive ? mobileAppSettings.active_home_icon?.data?.full_url : mobileAppSettings.inactive_home_icon?.data?.full_url"
                [alt]="mobileAppSettings.home_section_copy">
          </div>

          <div class="tab-title"
               [style.color]="rlaHome.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">
            {{mobileAppSettings.home_section_copy}}
          </div>
        </button>
      </ng-container>
      <!--   Order Tab-->
      <ng-container *ngIf="vendorSetup.order_provider !== 'none'">
        <button type="button" class="tab" routerLinkActive #rlaOrder="routerLinkActive" routerLink="/menu" [routerLinkActiveOptions]="{exact: false}" [style.background-color]="mobileAppSettings.background_color">
          <div class="tab-icon">
            <img [src]="(rlaOrder.isActive || router.url.includes('locations')) ? mobileAppSettings.active_order_icon?.data?.full_url : mobileAppSettings.inactive_order_icon?.data?.full_url" [alt]="mobileAppSettings.order_section_copy">
          </div>

          <div class="tab-title" [style.color]="(rlaOrder.isActive || router.url.includes('locations')) ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">
            {{mobileAppSettings.order_section_copy}}
          </div>
        </button>
      </ng-container>

      <!--  Rewards tab-->
      <ng-container *ngIf="vendorSetup.loyalty_provider !== 'none'">
        <button type="button" class="tab" routerLinkActive #rlaRewards="routerLinkActive" routerLink="/profile/rewards" [routerLinkActiveOptions]="{exact: true}" [style.background-color]="mobileAppSettings.background_color">
          <div class="tab-icon">
            <img [src]="rlaRewards.isActive ? mobileAppSettings.active_rewards_icon?.data?.full_url : mobileAppSettings.inactive_rewards_icon?.data?.full_url" [alt]="mobileAppSettings.rewards_section_copy">
          </div>

          <div class="tab-title" [style.color]="rlaRewards.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">
            {{mobileAppSettings.rewards_section_copy}}
          </div>
        </button>
      </ng-container>

      <!--   Locations Tab-->
<!--      <ng-container *ngIf="vendorSetup.location_provider !== 'none'">-->
<!--        <button type="button" class="tab" routerLinkActive #rlaLocation="routerLinkActive" routerLink="/locations" [routerLinkActiveOptions]="{exact: false}" [style.background-color]="mobileAppSettings.background_color">-->
<!--          <div class="tab-icon">-->
<!--            <img [src]="rlaLocation.isActive ? mobileAppSettings.active_locations_icon?.data?.full_url : mobileAppSettings.inactive_locations_icon?.data?.full_url" [alt]="mobileAppSettings.locations_section_copy">-->
<!--          </div>-->

<!--          <div class="tab-title" [style.color]="rlaLocation.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">-->
<!--            {{mobileAppSettings.locations_section_copy}}-->
<!--          </div>-->
<!--        </button>-->
<!--      </ng-container>-->
      <!--   Profile Tab-->
<!--      <ng-container *ngIf="user$ | async as user">-->
<!--        <ng-container *ngIf="vendorSetup.user_provider !== 'none' && !user.isGuest">-->
<!--          <button type="button" class="tab" routerLinkActive #rlaLocation="routerLinkActive" routerLink="/profile"-->
<!--                  [routerLinkActiveOptions]="{exact: false}" [style.background-color]="mobileAppSettings.background_color">-->
<!--            <div class="tab-icon">-->
<!--              <img-->
<!--                  [src]="rlaLocation.isActive ? mobileAppSettings.active_locations_icon?.data?.full_url : mobileAppSettings.inactive_locations_icon?.data?.full_url"-->
<!--                  [alt]="mobileAppSettings.locations_section_copy">-->
<!--            </div>-->

<!--            <div class="tab-title"-->
<!--                 [style.color]="rlaLocation.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">-->
<!--              {{mobileAppSettings.locations_section_copy}}-->
<!--            </div>-->
<!--          </button>-->
<!--        </ng-container>-->
<!--      </ng-container>-->
      <!--      More Tab  -->
      <ng-container>
        <button type="button" class="tab" routerLinkActive #rlaMore="routerLinkActive" routerLink="/more"
                [routerLinkActiveOptions]="{exact: true}" [style.background-color]="mobileAppSettings.background_color">
          <div class="tab-icon">
            <img
                [src]="rlaMore.isActive ? mobileAppSettings.active_more_icon?.data?.full_url : mobileAppSettings.inactive_more_icon?.data?.full_url"
                [alt]="mobileAppSettings.more_section_copy">
          </div>

          <div class="tab-title" [style.color]="rlaMore.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">
            {{mobileAppSettings.more_section_copy}}
          </div>
        </button>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
