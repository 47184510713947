import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { MobileService } from 'src/services/mobile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { NavbarSettings } from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import { NavigationService } from '@modules/navigation/services';
import { GlobalStateModel } from '../../../../../store/state.model';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { Capacitor } from '@capacitor/core';
import { VendorSetup } from '../../../../../vendors/directus/interfaces/vendor.interface';
import { PayInStorePage } from '@modules/loyalty/components/pay-in-store/pay-in-store.page';
import { RedeemPointsFromScanner } from '../../../../../store/actions/user.actions';
import { DineEngineError } from '../../../../../interfaces/dineengine-error.interface';
import { ToastService } from '../../../../../services/toast.service';
import { CustomIcons } from '../../../../../vendors/directus/interfaces/custom-icons.interface';
import { Location } from '@angular/common';
import { SubscriptionComponent } from '@common/components';
import { MobileAppSettings } from '../../../../../vendors/directus/interfaces/mobile-app-settings.interface';
import { DirectusService } from '../../../../../vendors/directus/directus.service';
import { TextField } from '../../../../../vendors/directus/interfaces/text-field.interface';
import { InboxMessage } from '../../../../../interfaces/inbox-message.interface';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'app-mobile-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class MobileHeaderBarComponent extends SubscriptionComponent implements OnDestroy {
  @Select(state => state.app.navbarSettings)
  navbarSettings$: Observable<NavbarSettings>;
  @Select((state: GlobalStateModel) => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select((state: GlobalStateModel) => state.app.customIcons)
  customIcons$: Observable<CustomIcons>;
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
  @Select((state: GlobalStateModel) => state.app.mobileAppSettings)
  mobileAppSettings$: Observable<MobileAppSettings>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;

  @Input() title = 'back';
  @Input() routeBack: string;
  @Input() menu: boolean;
  @Input() user: User = null;
  @Input() order: Order;
  @Input() cartIcon;
  @Input() showBack = false;
  @Input() inboxMessages?: InboxMessage[];

  @Output() addNewOrderType = new EventEmitter<any>();
  @Output() startNewOrder = new EventEmitter<any>();

  capacitor = Capacitor; // for template logic
  isCheckin = false;
  qrCode = new EventEmitter();
  mobileAppLayout = 'classic';
  isOloWrapped = false;
  redeemsCodeSuccessText = 'Code Redeemed';

  constructor(
    public mobile: MobileService,
    public router: Router,
    public location: Location,
    private navigation: NavigationService,
    private directus: DirectusService,
    private modalController: ModalController,
    private store: Store,
    private toast: ToastService,
    public route: ActivatedRoute,
    public menuController: MenuController,
    public notificationService: NotificationService
  ) {
    super();
    this.notificationService.updateNotifications().subscribe(() => {});
    this.subs.push(
      this.navbarSettings$.subscribe(navbar => {
        if (navbar) {
          this.cartIcon = navbar.cart_type;
        }
      }),
      this.mainSettings$.subscribe(settings => {
        if (settings) {
          this.mobileAppLayout = settings.mobile_app_layout;
        }
      }),
      this.vendorSetup$.subscribe(vendorSetup => {
        if (vendorSetup) {
          if (vendorSetup.loyalty_provider === 'paytronix') {
            this.directus.getPaytronixSettings().subscribe(ptxConfig => {
              this.isCheckin = !!ptxConfig.enable_checkin_in_store;
            });
          }
        }
      }),
      this.qrCode.subscribe(qr => {
        this.store
          .dispatch(new RedeemPointsFromScanner(qr))
          .toPromise()
          .then(() => {
            this.modalController.dismiss();
            this.toast.showDismissableIonicToast(this.redeemsCodeSuccessText, 'de-ionic-success-toast', 8000);
          })
          .catch(error => {
            if (error instanceof DineEngineError) {
              if (error.message.includes('range')) {
                this.toast.showDismissableIonicToast('Invalid Receipt Number', 'de-ionic-error-toast', 8000);
              } else {
                this.toast.showDismissableIonicToast(error.message, 'de-ionic-error-toast', 8000);
              }
            } else {
              this.toast.showDismissableIonicToast(error.message, 'de-ionic-error-toast', 8000);
            }
          });
      }),
      this.textFields$.subscribe(textFields => {
        if (textFields) {
          this.redeemsCodeSuccessText = textFields.redeem_code_success_text;
        }
      })
    );
  }

  backButton() {
    this.router.navigateByUrl(this.routeBack);
  }

  routeToCart() {
    if (this.order && this.order.location) {
      if (!this.router.url.includes('/cart')) {
        this.navigation.navigateToCartPage(this.order.location.locationID);
      } else {
        const menuID = this.order.location.slugURL ? this.order.location.slugURL : this.order.location.locationID;
        this.navigation.navigateToMenuPage(menuID);
      }
    } else {
      this.navigation.navigateToLocationsPage();
    }
  }

  openDropdown(dropdownId: string): void {
    const dropdown = document.getElementById(dropdownId);
    if (dropdown) {
      dropdown.click();
    }
  }

  basketCount(products) {
    return products.reduce((total, p) => {
      return p.quantity + total;
    }, 0);
  }

  newOrderTypeClicked() {
    this.addNewOrderType.emit();
  }

  startOrder(order) {
    this.startNewOrder.emit(order);
  }

  openEarn() {
    if (this.user.isGuest) {
      this.navigation.navigateToLoginPage();
    } else {
      this.modalController
        .create({
          animated: true,
          component: PayInStorePage,
          componentProps: {
            value: 123,
            displayUser: this.user,
            displayOrder: this.order,
            displayLoc: this.order?.location,
            qrEmitter: this.qrCode,
            isCheckin: this.isCheckin,
          },
          cssClass: 'pay-in-store',
          showBackdrop: false,
          mode: 'ios',
        })
        .then(modal => modal.present());
    }
  }
}
