import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Color from 'color';
import { DirectusService } from '../vendors/directus/directus.service';
import { Select } from '@ngxs/store';
import { forkJoin, Observable } from 'rxjs';
import { ThemeColor } from '../vendors/directus/interfaces/theme-color.interface';
import { filter, take } from 'rxjs/operators';
import { GlobalStateModel } from '../store/state.model';
import { NavbarSettings } from '../vendors/directus/interfaces/navbar-settings.interface';
import { Image } from '../vendors/directus/interfaces/image.interface';
import { Order } from '../interfaces/order.interface';

interface Colors {
  primary: string;
  secondary: string;
  tertiary: string;
  light: string;
  medium: string;
  dark: string;
  primary_txt: string;
  secondary_txt: string;
  primary_hover: string;
  secondary_hover: string;
  primary_hover_txt: string;
  secondary_hover_txt: string;
  landing_page_overlay_color: string;
  landing_page_overlay_opacity: string;
  card_background_color: string;
  card_text_color: string;
}

interface Others {
  thumbnailWidth: number;
  thumbnailHeight: number;
  thumbnailSkeletonWidth: number;
  thumbnailRadius: string;
  thumbnailSkeletonRadius: string;
  header_font_url: string;
  header_font_specifier: string;
  paragraph_font_url: string;
  paragraph_font_specifier: string;
  background_image: Image;
}

interface ElementStyles {
  button_radius: string;
  card_radius: string;
  input_radius: string;
  button_text_transform: string;
}

interface NavColors {
  navbar_background_color: string;
  navbar_icon_color: string;
  navbar_text_color: string;
  navbar_mobile_background_color: string;
  navbar_mobile_icon_color: string;
  navbar_mobile_text_color: string;
  button_color: string;
  button_text_color: string;
  emphasized_text_color: string;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select((state: GlobalStateModel) => state.app.navbarSettings)
  navbarSettings$: Observable<NavbarSettings>;
  @Select((state: GlobalStateModel) => state.order.order)
  order$: Observable<Order>;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.init();
    this.order$.subscribe(order => {
      if (order && order.location && order.location.conceptPrimaryColor && order.location.conceptSecondaryColor) {
        this.removeManualOverrideTheme();
        this.manualOverrideTheme(order.location.conceptPrimaryColor, order.location.conceptSecondaryColor);
      } else {
        this.removeManualOverrideTheme();
      }
    });
  }

  init() {
    let usedCache = false;
    if (
      localStorage.getItem('colors') &&
      localStorage.getItem('others') &&
      localStorage.getItem('elementStyles') &&
      localStorage.getItem('navColors')
    ) {
      const colors = JSON.parse(localStorage.getItem('colors')) as Colors;
      const others = JSON.parse(localStorage.getItem('others')) as Others;
      const elementStyles = JSON.parse(localStorage.getItem('elementStyles')) as ElementStyles;
      const navColors = JSON.parse(localStorage.getItem('navColors')) as NavColors;
      this.setTheme(colors, others, navColors);
      this.setFont(others);
      this.setBackgroundOverlay(colors);
      this.setElementStyles(elementStyles);
      usedCache = true;
    }
    this.getAllThemeVariables().subscribe(({ theme, navbarSettings }) => {
      const colors: Colors = {
        primary: theme[0].primary_color,
        secondary: theme[0].secondary_color,
        tertiary: theme[0].tertiary_color,
        light: theme[0].light_color,
        medium: theme[0].medium_color,
        dark: theme[0].dark_color,
        primary_txt: theme[0].primary_button_text,
        secondary_txt: theme[0].secondary_button_text,
        primary_hover: theme[0].primary_button_hover,
        secondary_hover: theme[0].secondary_button_hover,
        primary_hover_txt: theme[0].primary_button_hover_text,
        secondary_hover_txt: theme[0].secondary_button_hover_text,
        landing_page_overlay_color: theme[0].landing_page_overlay_color,
        landing_page_overlay_opacity: theme[0].landing_page_overlay_opacity,
        card_background_color: theme[0].card_background_color,
        card_text_color: theme[0].card_text_color,
      };
      localStorage.setItem('colors', JSON.stringify(colors));
      const others: Others = {
        thumbnailWidth: 110,
        thumbnailHeight: 110,
        thumbnailSkeletonWidth: 60,
        thumbnailRadius: '8px',
        thumbnailSkeletonRadius: '8px',
        header_font_url: theme[0].header_font_url,
        header_font_specifier: theme[0].header_font_specifier,
        paragraph_font_url: theme[0].paragraph_font_url,
        paragraph_font_specifier: theme[0].paragraph_font_specifier,
        background_image: theme[0].background_image,
      };
      const elementStyles: ElementStyles = {
        button_radius: theme[0].button_radius,
        card_radius: theme[0].card_radius,
        input_radius: theme[0].input_radius,
        button_text_transform: theme[0].button_text_transform,
      };
      const navColors: NavColors = {
        navbar_background_color: navbarSettings.background_color,
        navbar_icon_color: navbarSettings.icon_color,
        navbar_text_color: navbarSettings.text_color,
        navbar_mobile_background_color: navbarSettings.mobile_background_color,
        navbar_mobile_icon_color: navbarSettings.mobile_icon_color,
        navbar_mobile_text_color: navbarSettings.mobile_text_color,
        button_color: navbarSettings.button_color,
        button_text_color: navbarSettings.button_text_color,
        emphasized_text_color: navbarSettings.emphasized_text_color,
      };
      if (theme[0].thumbnail_dimension === 'wide') {
        console.log('others wide');
        others.thumbnailWidth = 80;
      } else if (theme[0].thumbnail_dimension === 'wider') {
        console.log('others wideer');
        others.thumbnailWidth = 106.6666666667;
      }
      if (theme[0].thumbnail_border === 'square') {
        others.thumbnailRadius = '0px';
        others.thumbnailSkeletonRadius = '0px';
      } else if (theme[0].thumbnail_border === 'circle') {
        others.thumbnailRadius = '50%';
      }
      if (theme) {
        localStorage.setItem('colors', JSON.stringify(colors));
        localStorage.setItem('others', JSON.stringify(others));
        localStorage.setItem('elementStyles', JSON.stringify(elementStyles));
        localStorage.setItem('navColors', JSON.stringify(navColors));
        this.setTheme(colors, others, navColors);
        this.setFont(others);
        this.setBackgroundOverlay(colors);
        this.setElementStyles(elementStyles);
      } else {
        console.log('no themes');
      }
    });
  }

  setFont(others) {
    const style = document.createElement('style');
    if (others.header_font_url && others.paragraph_font_url) {
      style.innerHTML = `
  @import ${others.header_font_url};
  @import ${others.paragraph_font_url};
  body {
    font-family: ${others.paragraph_font_specifier} !important;
    --ion-font-family: ${others.paragraph_font_specifier}, sans-serif;
  }
  h1 {
    font-family: ${others.header_font_specifier} !important;
  }
  .header-font {
    font-family: ${others.header_font_specifier} !important;
    color: var(--ion-text-color);
  }
  .paragraph-font {
    font-family: ${others.paragraph_font_specifier} !important;
  }
  `;
      document.head.appendChild(style);
    } else {
      style.innerHTML = `
  @import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Heebo&family=Open+Sans&display=swap');
  body {
    font-family: 'Open Sans', sans-serif;
    --ion-font-family: 'Open Sans', sans-serif;
  }
  .paragraph-font {
    font-family: 'Open Sans', sans-serif;
  }
  .header-font {
    font-family: 'Heebo', sans-serif;
  }
  h1 {
    font-family: 'Heebo', sans-serif;
  }
  `;
      document.head.appendChild(style);
    }
  }

  setBackgroundOverlay(colors) {
    const style = document.createElement('style');
    if (colors.landing_page_overlay_color && colors.landing_page_overlay_opacity) {
      style.innerHTML = `
.landing-background-overlay {
background-color: ${colors.landing_page_overlay_color};
opacity: ${colors.landing_page_overlay_opacity};
-webkit-opacity: ${colors.landing_page_overlay_opacity};
filter: opacity(${colors.landing_page_overlay_opacity});
background: linear-gradient(${colors.landing_page_overlay_color}, transparent);
}
`;
      document.head.appendChild(style);
    }
  }

  setElementStyles(elementStyles) {
    const style = document.createElement('style');
    style.innerHTML = `
  :root {
    --cms-btn-radius: ${elementStyles.button_radius};
    --cms-card-radius: ${elementStyles.card_radius};
    --cms-input-radius: ${elementStyles.input_radius};
    --button-text-transform: ${elementStyles.button_text_transform};
  }
  `;
    document.head.appendChild(style);
  }

  // Override all global variables with a new theme
  setTheme(colors: Colors, others: Others, navColors: NavColors) {
    const cssText = CSSTextGenerator(colors, others, navColors);
    this.setGlobalCSS(cssText);
    // this.store.dispatch(new UpdateTheme({ theme: cssText }));
    // this.storage.set('theme', cssText);
  }

  // Define a single CSS variable
  setVariable(name, value) {
    this.document.documentElement.style.setProperty(name, value);
  }

  manualOverrideTheme(primaryColor: string, secondaryColor: string) {
    const style = document.createElement('style');
    const shadeRatio = 0.15;
    const tintRatio = 0.15;
    style.id = 'concept-branding';
    style.innerHTML = `
    body {
        --ion-color-primary: ${primaryColor};
      --ion-color-primary-rgb: ${hexToRGB(primaryColor)};
      --ion-color-primary-contrast: ${contrast(primaryColor)};
      --ion-color-primary-contrast-rgb: ${hexToRGB(contrast(primaryColor))};
      --ion-color-primary-shade:  ${Color(primaryColor).darken(shadeRatio).hex()};
      --ion-color-primary-tint:  ${Color(primaryColor).lighten(tintRatio).hex()};
      --ion-color-secondary: ${secondaryColor};
      --ion-color-secondary-rgb: ${hexToRGB(secondaryColor)};
      --ion-color-secondary-contrast: ${contrast(secondaryColor)};
      --ion-color-secondary-contrast-rgb: ${hexToRGB(contrast(secondaryColor))};
      --ion-color-secondary-shade:  ${Color(secondaryColor).darken(shadeRatio).hex()};
      --ion-color-secondary-tint: ${Color(secondaryColor).lighten(tintRatio).hex()};
      --ion-color-primary-txt: ${contrast(primaryColor)};
      --ion-color-primary-txt-rgb: ${hexToRGB(contrast(primaryColor))};
      --ion-color-secondary-txt: ${contrast(secondaryColor)};
      --ion-color-secondary-txt-rgb: ${hexToRGB(contrast(secondaryColor))};
      --ion-color-primary-hover: ${Color(primaryColor).darken(shadeRatio).hex()};
      --ion-color-secondary-hover: ${Color(secondaryColor).darken(shadeRatio).hex()};
      --ion-color-primary-hover-txt: ${contrast(primaryColor)};
      --ion-color-secondary-hover-txt: ${contrast(secondaryColor)};
      --ion-color-card-background-color: ${primaryColor};
      --ion-color-card-text-color: ${contrast(primaryColor)};
      --ion-color-primary-hover-rgb: ${hexToRGB(Color(primaryColor).darken(shadeRatio).hex())};
      --ion-color-secondary-hover-rgb: ${hexToRGB(Color(secondaryColor).darken(shadeRatio).hex())};
      --ion-color-primary-hover-txt-rgb: ${hexToRGB(contrast(primaryColor))};
      --ion-color-secondary-hover-txt-rgb: ${hexToRGB(contrast(secondaryColor))};
      --ion-color-card-background-color-rgb: ${hexToRGB(primaryColor)};
      --ion-color-card-text-color-rgb: ${hexToRGB(contrast(primaryColor))};
          --nav-background-color: ${contrast(secondaryColor)};
    --nav-icon-color: ${contrast(contrast(secondaryColor))};
    --nav-text-color: ${contrast(contrast(secondaryColor))};
    --nav-mobile-background-color: ${secondaryColor};
    --nav-mobile-icon-color: ${contrast(secondaryColor)};
    --nav-mobile-text-color: ${contrast(secondaryColor)};
        --nav-button-color: ${primaryColor};
    --nav-button-text-color: ${contrast(primaryColor)};
        --nav-emphasized-text-color: ${primaryColor};
    }`;
    document.body.prepend(style);
    this.setVariable('--swiper-theme-color', 'var(--ion-color-light) !important');
  }

  removeManualOverrideTheme() {
    const style = document.getElementById('concept-branding');
    if (style) {
      style.remove();
    }
  }

  private setGlobalCSS(css: string) {
    this.document.documentElement.style.cssText = css;
  }

  get storedTheme() {
    return 'nothing';
    //     const themeSelect = this.store.selectSnapshot<Settings>(state => state.users.users);
    // return this.store.get('theme');
  }

  private getAllThemeVariables(): Observable<{
    theme: ThemeColor[];
    navbarSettings: NavbarSettings;
  }> {
    return forkJoin({
      theme: this.theme$.pipe(
        filter(theme => !!theme),
        take(1)
      ),
      navbarSettings: this.navbarSettings$.pipe(
        filter(navbarSettings => !!navbarSettings),
        take(1)
      ),
    });
  }
}

const defaults = {
  primary: '#3880ff',
  secondary: '#0cd1e8',
  tertiary: '#7044ff',
  success: '#00721F',
  warning: '#ffce00',
  danger: '#f04141',
  dark: '#222428',
  medium: '#989aa2',
  light: '#f4f5f8',
};

function CSSTextGenerator(colors: Colors, others: Others, navColors: NavColors) {
  const {
    primary,
    secondary,
    tertiary,
    dark,
    medium,
    light,
    primary_txt,
    secondary_txt,
    primary_hover,
    secondary_hover,
    primary_hover_txt,
    secondary_hover_txt,
    card_background_color,
    card_text_color,
  } = colors;

  const success = defaults.success;
  const warning = defaults.warning;
  const danger = defaults.danger;

  const shadeRatio = 0.15;
  const tintRatio = 0.15;

  const {
    navbar_background_color,
    navbar_icon_color,
    navbar_text_color,
    navbar_mobile_background_color,
    navbar_mobile_icon_color,
    navbar_mobile_text_color,
    button_color,
    button_text_color,
    emphasized_text_color,
  } = navColors;

  return `
    --ion-color-base: ${light};
    --ion-color-contrast: ${dark};
    --ion-background-color: ${light};
    --ion-text-color: ${dark};
    --ion-toolbar-background-color: ${contrast(light, 0.1)};
    --ion-toolbar-text-color: ${contrast(dark, 0.1)};
    --ion-item-background-color: ${contrast(light, 0.3)};
    --ion-item-text-color: ${contrast(dark, 0.3)};
    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: ${hexToRGB(primary)};
    --ion-color-primary-contrast: ${contrast(primary)};
    --ion-color-primary-contrast-rgb: ${hexToRGB(contrast(primary))};
    --ion-color-primary-shade:  ${Color(primary).darken(shadeRatio).hex()};
    --ion-color-primary-tint:  ${Color(primary).lighten(tintRatio).hex()};
    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: ${hexToRGB(secondary)};
    --ion-color-secondary-contrast: ${contrast(secondary)};
    --ion-color-secondary-contrast-rgb: ${hexToRGB(contrast(secondary))};
    --ion-color-secondary-shade:  ${Color(secondary).darken(shadeRatio).hex()};
    --ion-color-secondary-tint: ${Color(secondary).lighten(tintRatio).hex()};
    --ion-color-tertiary:  ${tertiary};
    --ion-color-tertiary-rgb: ${hexToRGB(tertiary)};
    --ion-color-tertiary-contrast: ${contrast(tertiary)};
    --ion-color-tertiary-contrast-rgb: ${hexToRGB(contrast(tertiary))};
    --ion-color-tertiary-shade: ${Color(tertiary).darken(shadeRatio).hex()};
    --ion-color-tertiary-tint:  ${Color(tertiary).lighten(tintRatio).hex()};
    --ion-color-success: ${success};
    --ion-color-success-rgb: ${hexToRGB(success)};
    --ion-color-success-contrast: ${contrast(success)};
    --ion-color-success-contrast-rgb: ${hexToRGB(contrast(success))};
    --ion-color-success-shade: ${Color(success).darken(shadeRatio).hex()};
    --ion-color-success-tint: ${Color(success).lighten(tintRatio).hex()};
    --ion-color-warning: ${warning};
    --ion-color-warning-rgb: ${hexToRGB(warning)};
    --ion-color-warning-contrast: ${contrast(warning)};
    --ion-color-warning-contrast-rgb: ${hexToRGB(contrast(warning))};
    --ion-color-warning-shade: ${Color(warning).darken(shadeRatio).hex()};
    --ion-color-warning-tint: ${Color(warning).lighten(tintRatio).hex()};
    --ion-color-danger: ${danger};
    --ion-color-danger-rgb: ${hexToRGB(danger)};
    --ion-color-danger-contrast: ${contrast(danger)};
    --ion-color-danger-contrast-rgb: ${hexToRGB(contrast(danger))};
    --ion-color-danger-shade: ${Color(danger).darken(shadeRatio).hex()};
    --ion-color-danger-tint: ${Color(danger).lighten(tintRatio).hex()};
    --ion-color-dark: ${dark};
    --ion-color-dark-rgb: ${hexToRGB(dark)};
    --ion-color-dark-contrast: ${contrast(dark)};
    --ion-color-dark-contrast-rgb: ${hexToRGB(contrast(dark))};
    --ion-color-dark-shade: ${Color(dark).darken(shadeRatio).hex()};
    --ion-color-dark-tint: ${Color(dark).lighten(tintRatio).hex()};
    --ion-color-medium: ${medium};
    --ion-color-medium-rgb: ${hexToRGB(medium)};
    --ion-color-medium-contrast: ${contrast(medium)};
    --ion-color-medium-contrast-rgb: ${hexToRGB(contrast(medium))};
    --ion-color-medium-shade: ${Color(medium).darken(shadeRatio).hex()};
    --ion-color-medium-tint: ${Color(medium).lighten(tintRatio).hex()};
    --ion-color-light: ${light};
    --ion-color-light-rgb: ${hexToRGB(light)};
    --ion-color-light-contrast: $${contrast(light)};
    --ion-color-light-contrast-rgb: ${hexToRGB(contrast(light))};
    --ion-color-light-shade: ${Color(light).darken(shadeRatio).hex()};
    --ion-color-light-tint: ${Color(light).lighten(tintRatio).hex()};
    --thumbnail-radius: ${others.thumbnailRadius};
    --thumbnail-skeleton-radius: ${others.thumbnailSkeletonRadius};
    --thumbnail-width: ${others.thumbnailWidth}px;
    --thumbnail-height: ${others.thumbnailHeight}px;
    --ion-color-primary-txt: ${primary_txt};
    --ion-color-secondary-txt: ${secondary_txt};
    --ion-color-primary-hover: ${primary_hover};
    --ion-color-secondary-hover: ${secondary_hover};
    --ion-color-primary-hover-txt: ${primary_hover_txt};
    --ion-color-secondary-hover-txt: ${secondary_hover_txt};
    --ion-color-card-background-color: ${card_background_color};
    --ion-color-card-text-color: ${card_text_color};
    --nav-background-color: ${navbar_background_color};
    --nav-icon-color: ${navbar_icon_color};
    --nav-text-color: ${navbar_text_color};
    --nav-mobile-background-color: ${navbar_mobile_background_color};
    --nav-mobile-icon-color: ${navbar_mobile_icon_color};
    --nav-mobile-text-color: ${navbar_mobile_text_color};
    --nav-button-color: ${button_color};
    --nav-button-text-color: ${button_text_color};
    --nav-emphasized-text-color: ${emphasized_text_color};
    --ion-color-primary-txt-rgb: ${hexToRGB(primary_txt)};
    --ion-color-secondary-txt-rgb: ${hexToRGB(secondary_txt)};
    --ion-color-primary-hover-rgb: ${hexToRGB(primary_hover)};
    --ion-color-secondary-hover-rgb: ${hexToRGB(secondary_hover)};
    --ion-color-primary-hover-txt-rgb: ${hexToRGB(primary_hover_txt)};
    --ion-color-secondary-hover-txt-rgb: ${hexToRGB(secondary_hover_txt)};
    --ion-color-card-background-color-rgb: ${hexToRGB(card_background_color)};
    --ion-color-card-text-color-rgb: ${hexToRGB(card_text_color)};
    `;
}

function contrast(color, ratio = 1.25) {
  color = Color(color);
  return color.isDark() ? '#ffffff' : '#000000';
}

function hexToRGB(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)].join(', ') : null;
}
