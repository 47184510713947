<ng-container *ngIf="message">
    <div class="card-outer horizontal" (click)="openInboxMessage()">
        <div class="card-shell">
            <div class="card-body p-3">
                <div class="body-top">
                    <div class="card-title header-font">
                        {{message.title ?? 'Message'}}
                    </div>
                    <!-- Show for messages that are unread only -->
                    <ng-container *ngIf="!message.read">
                        <div class="top-badges">
                            <div class="badge bg-primary">
                                New
                            </div>
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="message.date">
                    <div class="body-bottom">
                        <div class="card-paragraph">
                            {{message.date | date: 'shortDate'}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <ng-container *ngIf="message.imageURL">
                <div class="card-image-section">
                    <img class="card-image" [src]="message.imageURL"
                         [alt]="message.title">
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="notification">
    <div class="card-outer horizontal" (click)="openInboxMessage()">
        <div class="card-shell">
            <div class="card-body p-3">
                <div class="body-top">
                    <div class="card-title header-font">
                        {{!!notification.title ? notification.title : notification.body}}
                    </div>
                    <ng-container *ngIf="notification.subtitle">
                        <div class="card-paragraph">
                            {{notification.subtitle}}
                        </div>
                    </ng-container>
                    <!-- Show for messages that are unread only -->
                    <ng-container *ngIf="!(notification | checkIfUnreadNotification)">
                        <div class="top-badges">
                            <div class="badge badge-bg-primary">
                                New
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
