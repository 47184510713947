<ng-container *ngIf="event.rewardsEarned">
  <ng-container *ngFor="let rewardEarned of event.rewardsEarned">
    <div class="font-size-body-regular">
      {{ rewardEarned.quantity }}x {{ rewardEarned.label }} Earned
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="event.rewardsUsed">
  <ng-container *ngFor="let rewardUsed of event.rewardsUsed">
    <ng-container *ngIf="!rewardUsed.isExpiration">
      <div class="font-size-body-regular">
        {{ rewardUsed.quantity }}x {{ rewardUsed.label }} Used
      </div>
    </ng-container>
    <ng-container *ngIf="rewardUsed.isExpiration">
      <div class="font-size-body-regular">
        {{ rewardUsed.quantity }}x {{ rewardUsed.label }} Expired
      </div>
    </ng-container>
  </ng-container>
</ng-container>
