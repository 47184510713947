<ng-container *ngIf="googleMapsLoaded$ | async">
  <google-map [center]="{lat:centerLat, lng: centerLong}" [zoom]="zoom"
      width="100%" height="100%" [options]="mapOptions">
    <map-marker *ngIf="latitude && longitude" [position]="{lat:latitude, lng:longitude}" [options]="selfMarkerOptions">
    </map-marker>
    <map-marker #marker="mapMarker" *ngFor="let markerPosition of markers; let i = index; trackBy: markerTrackBy"
      [position]="{lat:markerPosition.latitude, lng: markerPosition.longitude}" [options]="markerPosition.location | locationMarker"
      (mapClick)="openInfoWindow(marker, markerPosition.location)">
    </map-marker>
    <map-info-window>
      <ng-container *ngTemplateOutlet="locationInfoWindow"></ng-container>
    </map-info-window>
  </google-map>
</ng-container>

<ng-template #locationInfoWindow>
  <ng-container *ngIf="(mainSettings$ | async) as mainSettings">
    <div class="d-flex flex-column gap-3 grow-1 p-3">
      <div class="d-flex flex-column gap-2 grow-1">

        <ng-container *ngIf="mainSettings.location_card_emphasis else locationName">
          <div class="w-100">
            <div class="fw-bold font-size-body-regular" style="width: 90%;">{{ loc?.address.address1 }}</div>
            <div class="fw-bold font-size-body-regular" *ngIf="loc?.address.address2">{{ loc?.address.address2 }}
            </div>
            <div class="fw-bold font-size-body-regular">{{ loc?.address.city }}, {{ loc?.address.state }}
              {{ loc?.address.zipCode }}
            </div>
          </div>
        </ng-container>

        <ng-template #locationName>
            <div class="fw-bold font-size-body-regular" style="width: 90%;">{{ loc?.name }}</div>
        </ng-template>

        <div class="d-flex flex-row flex-nowrap w-100">
          <ng-container *ngIf="loc?.isOpen">
            <div class="open-badge">
              Open
            </div>
          </ng-container>
          <!-- closed but allows advanced orders -->
          <ng-container *ngIf="!loc?.isOpen && loc?.supportsAdvancedOrders">
            <div class="preorder-badge">
              Preorder
            </div>
          </ng-container>
          <!--                    closed and does not allow advanced orders-->
          <ng-container *ngIf="!loc?.isOpen && !loc?.supportsAdvancedOrders">
            <div class="closed-badge">
              Closed
            </div>
          </ng-container>
        </div>

        <ng-container *ngIf="mainSettings.location_card_emphasis else locationAddress">
          <div class="w-100 font-size-body-small">
            {{ loc?.name }}
          </div>
        </ng-container>

        <ng-template #locationAddress>
          <div class="w-100 font-size-body-small">
            <div>
              {{ loc?.address.address1 }}
            </div>
            <div *ngIf="loc?.address.address2">
              {{ loc?.address.address2 }}
            </div>
            <div>
              {{ loc?.address.city }}, {{ loc?.address.state }} {{ loc?.address.zipCode }}
            </div>
          </div>
        </ng-template>

        <div class="w-100">
          <div class="w-100 font-size-body-small">
            {{ loc?.phoneNumber }}
          </div>
          <!-- <div class="w-100 font-size-body-small">
            {{loc?.pickupStart | date: 'shortTime'}} - {{loc?.pickupEnd | date: 'shortTime'}}
          </div> -->
        </div>

        <!-- <div class="w-100 font-size-body-small fw-bold">
          {{ loc?.handoffMethods }}
        </div> -->

        <div class="d-flex flex-row flex-nowrap gap-2">
          <div class="pointer de-primary-link" (click)="locationInfoButtonClick.emit(loc)">
            Location Info
          </div>
          <div>
            &nbsp;|&nbsp;
          </div>
          <div class="pointer de-primary-link" (click)="prepareGoogleLink(loc)">
            Directions
          </div>
        </div>
        <div *ngIf="!loc?.isLive" class="text-danger d-flex flex-row flex-nowrap gap-1">
          <i class="bi bi-exclamation-triangle"></i>
          Online Ordering Temporarily Unavailable
        </div>
      </div>

      <ng-container *ngIf="loc?.isLive">
        <div
          *ngIf="locationLoading !== loc?.locationID && (loc?.supportsPickup || loc?.supportsCurbside || loc?.supportsDriveThru)"
          class="d-flex flex-row justify-content-end align-items-center gap-2 pointer font-size-body-regular select-location-arrow fw-bold"
          (click)="orderNowButtonClick.emit(loc)">{{ loc?.isOpen ? 'Order Now' : 'Order Ahead' }}<i
          class="bi bi-arrow-right-circle font-size-subheading-1 d-flex"></i></div>
        <div
          *ngIf="locationLoading !== loc?.locationID && !(loc?.supportsPickup || loc?.supportsCurbside || loc?.supportsDriveThru)"
          (click)="orderNowButtonClick.emit(loc)"
          class="d-flex flex-row justify-content-end align-items-center gap-2 pointer font-size-body-regular select-location-arrow fw-bold">
          Get Delivery<i class="bi bi-arrow-right-circle font-size-subheading-1 d-flex"></i></div>
        <div *ngIf="locationLoading === loc?.locationID" class="select-location-btn-loader"></div>
      </ng-container>
      <!--          <ion-button *ngIf="loc.isLive" class="w-100 de-primary-btn" (click)="orderNowButtonClick.emit(loc)">-->
      <!--            ORDER NOW-->
      <!--          </ion-button>-->
    </div>
  </ng-container>
</ng-template>
