import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {Router} from '@angular/router';
import * as Sentry from '@sentry/browser';
import { MenuController, } from '@ionic/angular';
import {NavbarSettings} from '../../../../vendors/directus/interfaces/navbar-settings.interface';
import packageJson from '../../../../../package.json';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {MainSettings} from '../../../../vendors/directus/interfaces/main-settings.interface';

@Component({
    selector: 'app-footer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './footer.component.html',
    styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
    @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

    @Input() navbarSettings: NavbarSettings;
    @Input() showReportBug: boolean = true;

    public version: string = packageJson.version;

    constructor(
        public menu: MenuController,
        public router: Router
    ) {}

    openFeedbackForm() {
        this.menu.close();
        Sentry.showReportDialog(
            {eventId: Sentry.captureMessage('User Submission')});
    }

}
