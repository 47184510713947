import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MobileService } from 'src/services/mobile.service';

@Component({
  selector: 'app-transfer-order-modal',
  templateUrl: './transfer-order-modal.component.html',
  styleUrls: ['./transfer-order-modal.component.scss'],
})
export class TransferOrderModalComponent {

  @Input() isCancelOrder;
  @Input() errorMessage;

  @Output() confirmChangeEmit = new EventEmitter<any>();
  @Output() keepOldLocationEmit = new EventEmitter<any>();

  text = 'We were unable to transfer the items in your basket. If you change to this location you will have to re-add your items to your cart. Do you still want to switch locations?';

  constructor(
    public activeModal: NgbActiveModal,
    public mobile: MobileService
  ) {}

  confirmChange() {
    this.confirmChangeEmit.emit();
  }

  keepOldLocation() {
    this.keepOldLocationEmit.emit();
  }
}
