<ng-container *ngIf="(user$ | async) as user">
  <ng-container *ngIf="!user.isGuest">
    <ng-container *ngIf="!authService.isWrapped">
      <ng-container *ngIf="(pastOrders$ | async) as pastOrders">
        <div class="past-orders-container">
          <div class="w-100">
            <ng-container *ngIf="pastOrders && pastOrders.length > 0; else noOrders">
              <div class="d-flex flex-row gap-3 w-100 overflow-auto">
                <ng-container *ngFor="let order of pastOrders; index as index">
                  <!-- (clickedAddToOrder)="addToOrder(order)" -->
                  <app-past-order-card
                    (cancelOrder)="appCommonService.cancelOrderClicked(order)"
                    (clickedReorder)="appCommonService.reorder(order)"
                    (clickedViewConfirmation)="appCommonService.viewConfirmation(order)"
                    (editOrder)="appCommonService.editOrderClicked(order)"
                    [isLoading]="appCommonService.orderLoading === order.orderID"
                    [order]="order"
                    [showShadow]="true"
                  ></app-past-order-card>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <ng-template #noOrders>
            <div class="past-orders-empty">
              <h5 class="past-orders-heading header-font pb-1">
                You have no past orders.
              </h5>

              <div class="past-orders-body pb-3">
                Check back after placing an order.
              </div>

              <app-order-now-button
                class="w-50"
              ></app-order-now-button>
            </div>
          </ng-template>

        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
