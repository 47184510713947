<div class="more-card" [style.background-image]="branding.default_more_tile_background_image?.data?.full_url | cssUrl" (click)="tileClick.emit()">
  <div class="icon-container pb-2">
    <!--        image should be set to this for default, and then pull from cms if there is an entry-->
<!--    TODO: switch to image with page icon-->
    <i [class]="(customPage.icon | bootstrapIcon)" class="d-flex align-item-center" style="font-size: 2rem"></i>
  </div>
  <div class="more-title text-break">
    {{customPage.title}}
  </div>
</div>
