import { Menu } from 'src/interfaces/menu.interface';
import { Category } from '../../interfaces/category.interface';
import { Product } from '../../interfaces/product.interface';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { MenuService } from '../../services/vendor-config-service/menu.service';
import { SetCategory, SetMenu, SetProduct, SetStaticMenu, ToggleFullMenu } from '../actions/menu.actions';
import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { produce } from 'immer';

import { ContentService } from '../../services/vendor-config-service/content-provider.service';
import { HandoffType } from '../../interfaces/handoff-type.enum';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { UtilityService } from '@modules/utility/services';
import { GlobalStateModel } from '../state.model';

export interface MenuStateModel {
  menu: Menu;
  selectedCategory: Category;
  selectedProduct: Product;
  staticMenu: Menu;
  showFullMenu: boolean;
  allProductsAlwaysAvailable: boolean;
}

@State<MenuStateModel>({
  name: 'menu',
  defaults: {
    menu: null,
    selectedCategory: null,
    selectedProduct: null,
    staticMenu: null,
    showFullMenu: false,
    allProductsAlwaysAvailable: true,
  },
})
@Injectable()
export class MenuState implements NgxsOnInit {
  lastMenuLocation;
  lastMenuHandoff;

  constructor(
    private menuService: MenuService,
    private store: Store,
    private contentService: ContentService,
    private analytics: AnalyticsService,
    private utility: UtilityService
  ) {}

  ngxsOnInit(ctx: StateContext<MenuStateModel>) {
    return ctx.dispatch(new SetStaticMenu());
  }

  @Action(SetMenu)
  setMenu(ctx: StateContext<MenuStateModel>, action: SetMenu) {
    return this.menuService.getService().pipe(
      switchMap(provider => {
        return provider.getMenuByHandoffType(action.locationID, action.handoffType).pipe(
          map(menu => {
            const sortedMenu = produce(menu, draft => {
              // tslint:disable-next-line:max-line-length
              draft.categories.sort(
                (a, b) => (a.categoryOrder != null ? a.categoryOrder : Infinity) - (b.categoryOrder != null ? b.categoryOrder : Infinity)
              );
              draft.categories.forEach(cat => {
                cat.hasAvailableProducts = !!cat.products.some(prod => prod.currentlyAvailable);
              });
            });
            return ctx.patchState({
              menu: sortedMenu,
              allProductsAlwaysAvailable: !sortedMenu.categories.some(cat => cat.products.some(prod => !prod.currentlyAvailable)),
            });
          })
        );
      })
    );
  }

  @Action(SetCategory)
  setCategory(ctx: StateContext<MenuStateModel>, action: SetCategory) {
    const currentCat: Category = ctx.getState().selectedCategory;
    if (currentCat && currentCat.nameSlug !== action.categoryName) {
      ctx.patchState({
        selectedCategory: null,
      });
    }
    return this.menuService.getService().pipe(
      switchMap(provider => {
        return provider.getMenuByHandoffType(action.locationID, action.handoffType).pipe(
          switchMap(menu => {
            ctx.patchState({
              menu,
            });
            let category: Category;
            if (isNaN(Number(action.categoryName))) {
              category = menu.categories.find(cat => cat.nameSlug === action.categoryName);
            } else {
              category = menu.categories.find(cat => cat.categoryID === action.categoryName);
            }
            return combineLatest(
              category.products.map(p =>
                provider.getProduct(action.locationID, action.handoffType, menu.menuID, category.categoryID, p.productID, false)
              )
            ).pipe(
              tap(products => {
                const newProds = produce(products, draft => {
                  draft.forEach(p => {
                    // tslint:disable-next-line:max-line-length
                    p.requiresModification =
                      p.optionGroups && p.optionGroups.length && p.optionGroups.some(og => this.utility.checkIfModificationRequired(og));
                    p.canModify = p.optionGroups && p.optionGroups.length > 0;
                    if (p.requiresModification && !p.canModify) {
                      console.log('!!!WARNING!!! A product requires modification but cannot be modified, which should never happen!', p);
                    }
                  });
                });
                category = produce(category, draft => {
                  draft.products = newProds;
                });
                const prodAnalytics = [];
                category.products.forEach((prod, index) => {
                  prodAnalytics.push({
                    item_id: prod.nameSlug,
                    item_name: prod.name,
                    index,
                    item_list_name: category.name,
                    item_list_id: category.nameSlug,
                    price: prod.priceCents / 100,
                    currency: 'USD',
                  });
                });
                const options = {
                  name: 'view_item_list',
                  params: {
                    items: prodAnalytics,
                    item_list_name: category.name,
                    item_list_id: category.nameSlug,
                  },
                };
                this.analytics.firebaseGenericLog(options);
                this.analytics.analyticsOnCategoryView(
                  category.products,
                  this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
                );
                ctx.patchState({
                  selectedCategory: category,
                });
              })
            );
          })
        );
      })
    );
  }

  @Action(SetProduct)
  setProduct(ctx: StateContext<MenuStateModel>, action: SetProduct) {
    const currentProd: Product = ctx.getState().selectedProduct;
    if (currentProd && currentProd.nameSlug !== action.productName) {
      ctx.patchState({
        selectedProduct: null,
      });
    }
    return this.menuService.getService().pipe(
      switchMap(provider => {
        return provider.getMenuByHandoffType(action.locationID, action.handoffType).pipe(
          switchMap(menu => {
            let category: Category;
            let product: Product;
            if (isNaN(Number(action.categoryName))) {
              category = menu.categories.find(cat => cat.nameSlug === action.categoryName);
            } else {
              category = menu.categories.find(cat => cat.categoryID === action.categoryName);
            }
            if (isNaN(Number(action.productName))) {
              product = category.products.find(prod => prod.nameSlug === action.productName);
            } else {
              product = category.products.find(prod => prod.productID === action.productName);
            }
            const options = {
              name: 'select_item',
              params: {
                items: [
                  {
                    item_id: product.nameSlug,
                    item_name: product.name,
                    price: product.priceCents / 100,
                    item_category: category.name,
                    item_list_name: category.name,
                    item_list_id: category.nameSlug,
                    currency: 'USD',
                  },
                ],
                item_list_name: action.categoryName,
                item_list_id: category.nameSlug,
              },
            };
            this.analytics.firebaseGenericLog(options);
            this.analytics.analyticsOnProductClick(
              product,
              this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
            );
            return provider
              .getProduct(action.locationID, action.handoffType, menu.menuID, category.categoryID, product.productID, true)
              .pipe(
                map(product2 => {
                  const options2 = {
                    name: 'view_item',
                    params: {
                      currency: 'USD',
                      items: [
                        {
                          item_id: product2.nameSlug,
                          item_name: product2.name,
                          item_category: category.name,
                          price: product2.priceCents / 100,
                          currency: 'USD',
                        },
                      ],
                    },
                  };
                  this.analytics.firebaseGenericLog(options2);
                  return ctx.patchState({
                    selectedProduct: product2,
                  });
                })
              );
          })
        );
      })
    );
  }

  @Action(SetStaticMenu)
  setStaticMenu(ctx: StateContext<MenuStateModel>, action: SetStaticMenu) {
    return this.contentService.getService().pipe(
      switchMap(cService => {
        return cService.getLocations().pipe(
          switchMap(cLocations => {
            const menuLocation = cLocations.find(l => l.is_static_menu);
            if (menuLocation) {
              return this.menuService.getService().pipe(
                switchMap(mService => {
                  return mService.getMenuByHandoffType(menuLocation.menu_id, HandoffType.pickup).pipe(
                    map(menu => {
                      return ctx.patchState({
                        staticMenu: menu,
                      });
                    })
                  );
                })
              );
            } else {
              return of(
                ctx.patchState({
                  staticMenu: null,
                })
              );
            }
          })
        );
      })
    );
  }

  @Action(ToggleFullMenu)
  toggleFullMenu(ctx: StateContext<MenuStateModel>, action: ToggleFullMenu) {
    return this.store
      .selectOnce(state => state.menu.showFullMenu)
      .pipe(
        map(showFull => {
          return ctx.patchState({
            showFullMenu: !showFull,
          });
        })
      );
  }
}
