import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DineEngineMapMarker } from './map-marker.interface';
import { Location } from '../../../../interfaces/location.interface';
import { TimeFrame } from '../../../../interfaces/time-frame.interface';
import { Observable, Subscription } from 'rxjs';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import moment from 'moment-timezone';
import { GeocodingService } from '@modules/locations/services/geocoding.service';
import { Select } from '@ngxs/store';
import { Branding } from '../../../../vendors/directus/interfaces/branding.interface';
import { filter } from 'rxjs/operators';
import { Geolocation } from '@capacitor/geolocation';
import { GlobalStateModel } from '../../../../store/state.model';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';

@Component({
  selector: 'app-simple-map',
  templateUrl: './simple-map.component.html',
  styleUrls: ['./simple-map.component.scss'],
})
export class SimpleMapComponent implements AfterContentInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.app.branding) branding$: Observable<Branding>;
  @Select((state: GlobalStateModel) => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.app.googleMapsLoaded)
  googleMapsLoaded$: Observable<boolean>;

  @Input() pageFrom: string;
  @Input() centerLat: number;
  @Input() centerLong: number;
  @Input() zoom: number;
  @Input() markers: DineEngineMapMarker[];
  @Input() apiKey: string;
  @Input() markerIcon: string;
  @Input() locationLoading;
  @Input() set hoveredIndex(val: number) {
    this.hoveredPinIndex = val;
    // locationMarker = ({
    //   icon: {
    //     scaledSize: {
    //       width: 70,
    //       height: 70,
    //     }
    //   },
    // } as google.maps.MarkerOptions);
  }

  @Output() locationInfoButtonClick = new EventEmitter<any>();
  @Output() orderNowButtonClick = new EventEmitter<Location>();

  @Input() set lat(val: number) {
    this.latitude = val;
    // this.centerLat = val;
  }
  @Input() set lng(val: number) {
    this.longitude = val;
    // this.centerLong = val;
  }
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  dist: string;
  handoffMethods: string;
  showMap = false;
  headerDisabled: true;

  pickupStart: Date;
  pickupEnd: Date;
  deliveryStart: Date;
  deliveryEnd: Date;
  distance;
  hoveredPinIndex: number;

  latitude = null;
  longitude = null;

  private subs: Subscription[] = [];

  private hoursText: string;

  private hoursArray: Array<any>;

  loc: Location;
  userIcon = {
    url: '../../../assets/images/personal-location-pin.gif',
    scaledSize: {
      width: 48,
      height: 48,
    },
    anchor: {
      x: 24,
      y: 24,
    },
  };

  mapOptions: google.maps.MapOptions = {
    clickableIcons: false,
    // gestureHandling: 'none',
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
  };

  circleOptions: google.maps.CircleOptions = {
    clickable: false,
    draggable: false,
    editable: false,
    fillColor: 'blue',
    fillOpacity: 0.7,
    radius: 1000,
    strokeWeight: 1.75,
    strokeColor: 'white',
  };

  selfMarkerOptions = {
    icon: {
      scaledSize: {
        width: 36,
        height: 36,
      },
      url: '../../../assets/images/personal-location-pin.gif',
    },
  } as google.maps.MarkerOptions;

  locationMarker = {
    icon: {
      scaledSize: {
        width: 60,
        height: 60,
      },
    },
  } as google.maps.MarkerOptions;

  styles = [
    {
      url: '../../../assets/images/location-pin-stacked.svg',
      width: 60.862,
      height: 78.005,
      anchorText: [22, 0],
      textColor: '#FFFFFF',
      textSize: 14,
    },
  ];

  infoWindowOpened = null;
  previous_info_window = null;

  constructor(private geo: GeocodingService) {
    this.branding$.pipe(filter(b => b !== null)).subscribe(branding => {
      this.locationMarker = {
        icon: {
          scaledSize: {
            width: 60,
            height: 60,
          },
          url: branding.location_pin_icon.data.full_url,
        },
      } as google.maps.MarkerOptions;
    });
  }

  ngAfterContentInit() {
    // Fix for grey map if you switch between desktop and mobile
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  showPopup(loc: Location, dist: string, infoWindow) {
    if (this.previous_info_window == null) {
      this.previous_info_window = infoWindow;
    } else {
      this.infoWindowOpened = infoWindow;
      this.previous_info_window.close();
    }
    this.previous_info_window = infoWindow;

    this.hoursArray = [];
    this.dist = dist;
    this.loc = loc;
    for (let i = 0; i < loc.pickupHours.length; i++) {
      this.getDays(loc.pickupHours[i]);
    }
    this.hoursText = this.writeHours();

    this.setTimes(loc);
    this.determineHandoff(loc);
  }

  close_window() {
    if (this.previous_info_window != null) {
      this.previous_info_window.close();
    }
  }

  // Form a Google Maps link to direct you from your current location to the selected address
  prepareGoogleLink(loc: Location) {
    const preppedAddress = encodeURIComponent(loc.address.address1.replace(/[ ]+/g, '+'));
    if (window) {
      window.open(
        'https://www.google.com/maps/dir/Current+Location/' + preppedAddress + '+' + loc.address.state + '+' + loc.address.zipCode,
        '_blank',
        'noopener=yes'
      );
    } else {
      document.location.href =
        'https://www.google.com/maps/dir/Current+Location/' + preppedAddress + '+' + loc.address.state + '+' + loc.address.zipCode;
    }
  }

  private getDays(hours: TimeFrame) {
    // Should we display AM or PM?
    const start2 = hours.start.getHours() <= 12 ? 'AM' : 'PM';
    const end2 = hours.end.getHours() <= 12 ? 'AM' : 'PM';
    // Subtract 12 from the hours needed or leave it be.
    const startHour =
      hours.start.getHours() <= 12 ? this.appendZeroes(hours.start.getHours()) : this.appendZeroes(hours.start.getHours() - 12);
    const endHour =
      hours.start.getHours() <= 12 ? this.appendZeroes(hours.start.getHours()) : this.appendZeroes(hours.start.getHours() - 12);
    // Finally, construct the displayed time
    const startTime = startHour + ':' + this.appendZeroes(hours.start.getMinutes());
    const endTime = endHour + ':' + this.appendZeroes(hours.end.getMinutes());

    const day = hours.start.getDay();
    this.hoursArray[day] = {
      day,
      start: startTime,
      end: endTime,
      startFrame: start2,
      endFrame: end2,
    };
  }

  private writeHours() {
    let hoursHTML = '';
    let sameHours = true;

    for (let i = 1; i < this.hoursArray.length; i++) {
      if (this.hoursArray[i].start !== this.hoursArray[0].start || this.hoursArray[i].end !== this.hoursArray[0].end) {
        sameHours = false;
        break;
      }
    }
    if (sameHours && this.hoursArray.length > 0) {
      // Easy! Just group-ordering the days together.
      hoursHTML = `<br /><span>Sunday － Saturday: ${this.hoursArray[0].start} ${this.hoursArray[0].startFrame} - ${this.hoursArray[0].end} ${this.hoursArray[0].endFrame}</span>`;
    } else if (this.hoursArray.length > 0) {
      // Write hours for all days of the week
      for (let i = 0; i < this.hoursArray.length; i++) {
        hoursHTML += `<br /><span>${DaysOfWeek[this.hoursArray[i].day]}: ${this.hoursArray[i].start} ${this.hoursArray[i].startFrame} - ${
          this.hoursArray[i].end
        } ${this.hoursArray[i].endFrame}</span>`;
      }
    } else if (this.hoursArray.length === 0) {
      // There are no hours listed.
      hoursHTML += '';
    }
    return hoursHTML;
  }

  createMarkerOptions(marker: DineEngineMapMarker) {
    return {
      icon: {
        scaledSize: {
          width: 60,
          height: 60,
        },
        url: marker.iconURL,
      },
    } as google.maps.MarkerOptions;
  }

  createCenter() {
    return {
      lat: this.centerLat,
      lng: this.centerLong,
    } as google.maps.LatLngLiteral;
  }

  openInfoWindow(marker: MapMarker, location: Location) {
    this.loc = location;
    this.infoWindow.open(marker);
  }

  hoursString(day: TimeFrame) {
    return `${moment(day.start, 'YYYYMMDD HH:mm').format('ddd: h:mm A')} - ${moment(day.end, 'YYYYMMDD HH:mm').format('h:mm A')}`;
  }

  getTypeOfGoogle() {
    return typeof google;
  }

  private setTimes(location: Location) {
    if (!location) {
      return;
    }
    const now = new Date();
    if (location.pickupHours) {
      let pickupHours = location.pickupHours.find(hours => this.doesOverlap(hours.start, hours.end, now));
      if (!pickupHours) {
        pickupHours = location.pickupHours.find(hours => this.isSameDay(hours.start, now));
      }
      if (!pickupHours) {
        this.pickupStart = null;
        this.pickupEnd = null;
      } else {
        this.pickupStart = pickupHours.start;
        this.pickupEnd = pickupHours.end;
      }
    }
    if (location.dispatchHours) {
      let deliveryHours = location.dispatchHours.find(hours => this.doesOverlap(hours.start, hours.end, now));
      if (!deliveryHours) {
        deliveryHours = location.pickupHours.find(hours => this.isSameDay(hours.start, now));
      }
      if (!deliveryHours) {
        this.deliveryStart = null;
        this.deliveryEnd = null;
      } else {
        this.deliveryStart = deliveryHours.start;
        this.deliveryEnd = deliveryHours.end;
      }
    }
    if (this.pageFrom === 'details') {
      Geolocation.getCurrentPosition({ enableHighAccuracy: true, timeout: 500 })
        .then(pos => {
          this.distance = this.geo.findDistance(
            location.address.latitude,
            location.address.longitude,
            pos.coords.latitude,
            pos.coords.longitude
          );
          this.distance = this.geo.precisionRound(this.distance, 1);
        })
        .catch(err => {
          console.log('Geolocation Error:', err);
        });
    }
  }

  private determineHandoff(location: Location) {
    this.handoffMethods = 'Offers ';
    const methods = [];
    if (location.supportsPickup) {
      methods.push('Pickup');
    }
    if (location.supportsCurbside) {
      methods.push('Curbside');
    }
    if (location.supportsDispatch || location.supportsDelivery) {
      methods.push('Delivery');
    }
    if (location.cateringLink) {
      methods.push('Catering');
    }
    if (methods.length === 1) {
      this.handoffMethods += methods[0];
    } else if (methods.length === 2) {
      this.handoffMethods += methods[0] + ' & ' + methods[1];
    } else if (methods.length >= 3) {
      this.handoffMethods += methods[0] + ', ' + methods[1] + ' & ' + methods[2];
    }
  }

  markerTrackBy(index: number): string {
    return `${index}`;
  }

  private appendZeroes(n) {
    if (n <= 9) {
      return '0' + n;
    }
    return n;
  }

  private doesOverlap(start: Date, end: Date, query: Date): boolean {
    return new Date(start).getTime() <= new Date(query).getTime() && new Date(end).getTime() > new Date(query).getTime();
  }

  private isSameDay(dt1: Date, dt2: Date): boolean {
    return new Date(dt1).getDay() === new Date(dt2).getDay();
  }
}

export enum DaysOfWeek {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}
