import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProxyBaseURLService } from 'src/services/proxy-base-url.service';
import { from } from 'rxjs';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root',
})
export class OLOHttpService {
  private vendorName = 'olo';

  private headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
    private urlService: ProxyBaseURLService
  ) {
    const mode = sessionStorage.getItem('olo-mode') || '';
    if (mode) {
      this.headers = this.headers.set('X-Olo-Mode', mode);
    }
  }

  get<T>(resource: string): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.getHeaders().pipe(
          switchMap(headers => {
            return this.http.get<T>(baseURL + resource, { headers }).pipe(
              map(
                res => {
                  return res;
                },
                catchError(e => {
                  return e;
                })
              )
            );
          })
        );
      })
    );
  }

  put<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.getHeaders().pipe(
          switchMap(headers => {
            return this.http.put<T>(baseURL + resource, body, { headers }).pipe(
              map(
                res => {
                  return res;
                },
                catchError(e => {
                  return e;
                })
              )
            );
          })
        );
      })
    );
  }

  post<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.getHeaders().pipe(
          switchMap(headers => {
            if (sessionStorage.getItem('recaptchaToken')) {
              headers = headers.set('X-Captcha-Token', sessionStorage.getItem('recaptchaToken'));
            }
            return this.http.post<T>(baseURL + resource, body, { headers }).pipe(
              map(
                res => {
                  return res;
                },
                catchError(e => {
                  return e;
                })
              )
            );
          })
        );
      })
    );
  }

  patch<T>(resource: string, body: any): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.getHeaders().pipe(
          switchMap(headers => {
            return this.http.patch<T>(baseURL + resource, body, { headers }).pipe(
              map(
                res => {
                  return res;
                },
                catchError(e => {
                  return e;
                })
              )
            );
          })
        );
      })
    );
  }

  delete<T>(resource: string): Observable<T> {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => {
        return this.getHeaders().pipe(
          switchMap(headers => {
            return this.http.delete<T>(baseURL + resource, { headers }).pipe(
              map(
                res => {
                  return res;
                },
                catchError(e => {
                  return e;
                })
              )
            );
          })
        );
      })
    );
  }

  private getHeaders(): Observable<HttpHeaders> {
    return from(Device.getId()).pipe(
      map(id => {
        if (sessionStorage.getItem('olo-mode')) {
          return new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Olo-Mode': sessionStorage.getItem('olo-mode'),
            'X-Device-Id': id.identifier,
          });
        } else {
          return new HttpHeaders({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Device-Id': id.identifier,
          });
        }
      })
    );
  }
}
