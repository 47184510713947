import { Component, Input, OnInit } from '@angular/core';
import { InboxMessageModalComponent } from '@modules/loyalty/components';
import { ModalController } from '@ionic/angular';
import { InboxMessage } from '../../../../interfaces/inbox-message.interface';
import { PushNotificationSchema } from '@capacitor/push-notifications';

@Component({
  selector: 'app-inbox-card',
  templateUrl: './inbox-card.component.html',
  styleUrls: ['inbox-card.component.scss'],
})
export class InboxCardComponent implements OnInit {
  @Input() message: InboxMessage;
  @Input() notification: PushNotificationSchema;

  constructor(private modal: ModalController) {}
  ngOnInit() {
    console.log(this.message);
    console.log(this.notification);
  }

  openInboxMessage() {
    this.modal
      .create({
        component: InboxMessageModalComponent,
        componentProps: {
          message: this.message,
          notification: this.notification,
        },
      })
      .then(modal => modal.present());
  }
}
