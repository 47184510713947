<div class="mobile-profile-page d-flex flex-column gap-3 px-3 pt-3 px-lg-0 pt-lg-0" style="overflow-y: auto">
  <ng-container *ngIf="!isIncompleteProfile">
    <div class="display-back-button w-100 container p-0">
      <div
        class="color-dark d-flex align-items-center fw-bold gap-2"
        (click)="routeToProfile()"
        *ngIf="displayVendorSetup?.loyalty_provider !== 'none'">
        <i class="bi bi-arrow-left"></i>
        Back
      </div>
    </div>
  </ng-container>

  <div class="w-100 container d-flex justify-content-center p-0">
    <div class="account-card bg-light de-border cms-card-radius grow-1 basis-0 mt-0">
      <div style="display: grid; grid-gap: 20px">
        <form [formGroup]="form" style="width: 100%; display: grid; grid-gap: 16px">
          <div class="d-flex flex-nowrap gap-3 justify-content-between">
            <div class="w-100 d-flex flex-row flex-nowrap gap-2 align-items-center">
              <i class="bi bi-person font-size-subheading-1 color-primary"></i>
              <div class="text-start fw-bold font-size-subheading-1 header-font" id="editProfileHeader">
                <ng-container *ngIf="!isIncompleteProfile">Edit Profile</ng-container>
                <ng-container *ngIf="isIncompleteProfile">Complete Profile Setup</ng-container>
              </div>
            </div>
          </div>
          <ng-container *ngIf="isIncompleteProfile">
            <p class="alert alert-warning font-size-body-small mb-2">
              Your account creation is incomplete. Please fill out your remaining account information and save your changes to continue
              ordering. Users must be at least 13 years of age.
            </p>
          </ng-container>
          <div class="form-group">
            <label for="username" class="fw-bold">Email Address</label>
            <div class="input-group de-input">
              <input class="form-control de-input opt-in-field" formControlName="username" id="username" type="text" />
              <div class="input-group-text de-input">
                <div class="form-check">
                  <input class="form-check-input" formControlName="emailOpt" id="emailOpt" type="checkbox" />
                  <label class="opt-in form-check-label text-uppercase" for="emailOpt">Opt-In</label>
                </div>
              </div>
            </div>
            <ng-container *ngIf="textFields$ | async as textField">
              <ng-container *ngIf="textField.checkout_account_creation_email_opt_in_copy; else emailDefault">
                <div class="text-start w-100 font-size-small">
                  {{ textField.checkout_account_creation_email_opt_in_copy }}
                </div>
              </ng-container>
              <ng-template #emailDefault>
                <div class="text-start w-100 font-size-small">Send me emails about future deals and features.</div>
              </ng-template>
            </ng-container>
          </div>
          <!-- birthday -->
          <div class="w-100 d-flex flex-column align-content-start pe-0 pe-md-2 pb-md-0">
            <div class="d-flex flex-row flex-nowrap w-100">
              <label class="w-fit-content fw-bold">Birthday*</label>
              <div
                *ngIf="birthday.invalid && (birthday.dirty || birthday.touched)"
                class="grow-1 d-flex justify-content-end font-size-body-small align-items-center ms-2">
                <div *ngIf="birthday.errors.required" title="Birthday required" class="text-danger grow-1 text-end">Required</div>
                <div *ngIf="birthday.errors.minlength" title="Invalid Birthday" class="text-danger grow-1 text-end">Invalid Birthday</div>
              </div>
            </div>
            <div class="input-group cms-input-radius">
              <input
                type="text"
                class="form-control"
                inputmode="numeric"
                mask="00/00/0000"
                formControlName="birthday"
                placeholder="MM/DD/YYYY"
                aria-label="Enter Birthday" />
              <button *ngIf="!birthday.disabled" class="btn btn-outline-secondary" type="button" id="openDateTimeModal">
                <i class="bi bi-calendar3"></i>
              </button>
            </div>
            <ion-modal id="dateTimeModal" trigger="openDateTimeModal" class="condensed">
              <ng-template>
                <ion-datetime
                  (ionChange)="setBirthdayValue($event)"
                  class="w-100"
                  id="datetime"
                  presentation="date"
                  [showDefaultButtons]="true"
                  [max]="earliestBDayISO"></ion-datetime>
              </ng-template>
            </ion-modal>
          </div>

          <div style="display: grid; grid-gap: 16px; width: 100%; grid-template-columns: 1fr 1fr">
            <div class="form-group">
              <label for="firstName" class="fw-bold">First Name</label>
              <input class="form-control de-input" formControlName="firstname" id="firstName" type="text" />
              <ng-container *ngIf="firstName.errors && firstName.dirty">
                <small *ngIf="firstName.errors.required" class="text-danger">First Name Required</small>
                <small *ngIf="firstName.errors.pattern" class="text-danger">Invalid First Name</small>
              </ng-container>
            </div>
            <div class="form-group">
              <label for="lastName" class="fw-bold">Last Name</label>
              <input class="form-control de-input" formControlName="lastname" id="lastName" type="text" />
              <ng-container *ngIf="lastName.errors && lastName.dirty">
                <small *ngIf="lastName.errors.required" class="text-danger">Last Name Required</small>
                <small *ngIf="lastName.errors.pattern" class="text-danger">Invalid Last Name</small>
              </ng-container>
            </div>
          </div>
          <div class="form-group">
            <label for="phoneNumber" class="fw-bold">Phone Number</label>
            <div class="input-group de-input">
              <input class="form-control de-input opt-in-field" formControlName="phone" id="phoneNumber" type="tel" mask="(000) 000-0000" />
              <div class="input-group-text de-input">
                <div class="form-check">
                  <input class="form-check-input" formControlName="smsOpt" id="smsOpt" type="checkbox" />
                  <label class="opt-in form-check-label text-uppercase" for="smsOpt">Opt-In</label>
                </div>
              </div>
            </div>
            <ng-container *ngIf="textFields$ | async as textField">
              <ng-container *ngIf="textField.checkout_account_creation_sms_opt_in_copy; else smsDefault">
                <div class="text-start w-100 font-size-small mb-3">
                  {{ textField.checkout_account_creation_sms_opt_in_copy }}
                </div>
              </ng-container>
              <ng-template #smsDefault>
                <div class="text-start w-100 font-size-small mb-3">SMS data rates may apply.</div>
              </ng-template>
            </ng-container>

            <!--                        <ng-container *ngIf="loyaltyLocations">-->
            <!--                            <div class="form-group" style="margin-top: 16px">-->
            <!--                                <label for="favoriteLocation" class="fw-bold">Favorite Location</label>-->
            <!--                                <div class="input-group de-input">-->
            <!--                                    <select id="favoriteLocation" name="favoriteLocation"-->
            <!--                                            (change)="changeFavoriteLocation($event)"-->
            <!--                                            formControlName="favoriteLocation" class="form-control de-input"-->
            <!--                                            aria-describedby="favoriteLocation">-->
            <!--                                        <option value="" disabled selected>Select your favorite location</option>-->
            <!--                                        <ng-container *ngFor="let location of loyaltyLocations">-->
            <!--                                            <option [value]="location.location_id ? location.location_id : location.code">-->
            <!--                                                {{ location.name }}-->
            <!--                                                - {{ location.address.address1 ? location.address.address1 : location.address }}-->
            <!--                                            </option>-->
            <!--                                        </ng-container>-->
            <!--                                    </select>-->
            <!--                                </div>-->
            <!--                                <div *ngIf="favoriteLocation.invalid && (favoriteLocation.dirty || favoriteLocation.touched)"-->
            <!--                                     class="de-form-error">-->
            <!--                                    <div *ngIf="favoriteLocation.errors.required">Favorite Location Required</div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </ng-container>-->

            <ng-container *ngIf="loyaltyLocations">
              <div class="d-none d-lg-flex flex-column w-100">
                <label for="typeahead-focus" class="fw-bold mt-3">Favorite Location</label>
                <input
                  id="typeahead-focus"
                  type="text"
                  class="form-control"
                  formControlName="favoriteLocation"
                  [ngbTypeahead]="search"
                  [resultFormatter]="formatter"
                  (focus)="focus$.next($any($event).target.value)"
                  (click)="click$.next($any($event).target.value)"
                  #instance="ngbTypeahead"
                  [inputFormatter]="formatter"
                  [resultTemplate]="rt"
                  [editable]="false"
                  container="body" />
                <div *ngIf="favoriteLocation.invalid && (favoriteLocation.dirty || favoriteLocation.touched)" class="de-form-error">
                  <div *ngIf="favoriteLocation.errors.required">Favorite Location Required</div>
                </div>
              </div>

              <div class="form-group m-0 w-100 d-flex d-lg-none flex-column align-content-start">
                <div class="d-flex flex-row flex-nowrap w-100">
                  <label for="picker-focus" class="w-fit-content fw-bold">Favorite Location*</label>
                  <div
                    *ngIf="favoriteLocation.invalid && (favoriteLocation.dirty || favoriteLocation.touched)"
                    class="grow-1 d-flex justify-content-end font-size-body-small align-items-center ms-2">
                    <div *ngIf="favoriteLocation.errors.required" title="Favorite location required" class="text-danger grow-1 text-end">
                      Required
                    </div>
                  </div>
                </div>
                <div id="picker-focus" class="form-control" [class.text-muted]="!this.favoriteLocation.value">
                  {{ this.favoriteLocation.value ? this.favoriteLocation.value.name : 'Search for your favorite location' }}
                </div>
                <ion-picker
                  mode="ios"
                  trigger="picker-focus"
                  [columns]="[loyaltyLocations | favoriteLocationPicker]"
                  [buttons]="pickerButtons"></ion-picker>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="additionalUserFields$ | async as additionalFields">
            <ng-container *ngIf="additionalFields.length">
              <div class="d-flex flex-column flex-sm-row flex-wrap w-100 custom-field-group">
                <ng-container *ngFor="let field of additionalFields; index as index">
                  <div class="m-0 d-flex flex-column align-content-start custom-user-field">
                    <label [for]="field.providerFieldName" class="w-fit-content fw-bold mt-3"
                      >{{ field.displayName }}<ng-container *ngIf="field.required">*</ng-container></label
                    >
                    <ng-container *ngIf="field.inputType.toLowerCase() !== 'date'; else dateInput">
                      <div class="input-group">
                        <input
                          class="form-control cms-input-radius"
                          [class.border-danger]="
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).invalid &&
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).touched
                          "
                          [attr.aria-label]="field.displayName"
                          [formControl]="field.providerFieldName | getFormControlFromParent: additionalFieldsForm"
                          [id]="field.providerFieldName"
                          [type]="field.inputType"
                          [attr.inputmode]="field.inputMode" />
                      </div>
                      <div class="grow-1 d-flex form-error align-items-center">
                        <ng-container
                          *ngIf="
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).invalid &&
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).touched
                          ">
                          <div
                            *ngIf="(field.providerFieldName | getFormControlFromParent: additionalFieldsForm).errors.required"
                            class="text-danger grow-1">
                            {{ field.displayName }} required.
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-template #dateInput>
                      <div class="input-group cms-button-radius">
                        <input
                          class="form-control cms-input-radius"
                          [class.border-danger]="
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).invalid &&
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).touched
                          "
                          [attr.aria-label]="field.displayName"
                          [formControl]="field.providerFieldName | getFormControlFromParent: additionalFieldsForm"
                          [id]="field.providerFieldName"
                          type="text"
                          [max]="field.maxLength ? field.maxLength : null"
                          [maxLength]="field.maxLength ? field.maxLength : null"
                          [min]="field.minLength ? field.minLength : null"
                          [minLength]="field.minLength ? field.minLength : null"
                          [attr.inputmode]="field.inputMode"
                          mask="00/00/0000"
                          placeholder="MM/DD/YYYY" />
                        <button class="btn btn-outline-secondary" type="button" [id]="field.providerFieldName.concat('button')">
                          <i class="bi bi-calendar3"></i>
                        </button>
                      </div>
                      <div class="grow-1 d-flex form-error align-items-center">
                        <ng-container
                          *ngIf="
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).invalid &&
                            (field.providerFieldName | getFormControlFromParent: additionalFieldsForm).touched
                          ">
                          <div
                            *ngIf="(field.providerFieldName | getFormControlFromParent: additionalFieldsForm).errors.required"
                            class="text-danger grow-1">
                            {{ field.displayName }} required.
                          </div>
                        </ng-container>
                      </div>
                      <ion-modal [trigger]="field.providerFieldName.concat('button')" class="condensed">
                        <ng-template>
                          <ion-datetime
                            (ionChange)="setCustomFieldDateValue($event, field)"
                            class="w-100"
                            presentation="date"
                            [showDefaultButtons]="true"></ion-datetime>
                        </ng-template>
                      </ion-modal>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <div class="d-flex flex-nowrap justify-content-between">
            <ng-container *ngIf="!isIncompleteProfile">
              <div class="text-center d-flex align-items-center">
                <a type="button" tabindex="0" class="change-password fw-bold de-primary-link" (click)="forgotPassword()" (keydown.enter)="forgotPassword()">Change Password</a>
              </div>
            </ng-container>
            <button (click)="saveChangesClicked()" class="de-primary-btn text-nowrap" style="width: fit-content" id="confirm" type="submit">
              <ng-container *ngIf="!isLoading">Save Updates</ng-container>
              <ng-container *ngIf="isLoading">
                <div class="de-btn-loader"></div>
              </ng-container>
            </button>
          </div>
        </form>
        <ng-container *ngIf="!isIncompleteProfile">
          <ng-container *ngIf="user$ | async as user">
            <ng-container *ngIf="mainSettings$ | async as mainSettings">
              <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                <ng-container *ngIf="!user.connectedWithFacebook && mainSettings.fb_app_id && vendorSetup.loyalty_provider === 'paytronix'">
                  <button class="de-facebook-btn" (click)="connectWithFacebook()" [disabled]="facebookLoading">
                    <ng-container *ngIf="!facebookLoading">
                      <i class="bi bi-facebook mx-2"></i>
                      Connect with Facebook
                    </ng-container>
                    <ng-container *ngIf="facebookLoading">
                      <div class="de-btn-loader"></div>
                    </ng-container>
                  </button>
                </ng-container>
                <ng-container *ngIf="showAppleSignIn && enableAppleSignIn && !user.connectedWithApple">
                  <button (click)="connectWithApple()" class="de-apple-btn w-100" type="submit">
                    <ng-container *ngIf="!appleLoading">
                      <div style="display: grid; place-items: center; grid-template-columns: max-content max-content; grid-gap: 8px">
                        <i class="bi bi-apple"></i>
                        <div>Connect with Apple</div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="appleLoading">
                      <div class="de-btn-loader"></div>
                    </ng-container>
                  </button>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="w-100 container d-flex justify-content-center p-0">
    <div class="bg-light de-border cms-card-radius grow-1 basis-0 py-3 px-4" style="max-width: 736px">
      <div class="d-flex flex-nowrap gap-2 justify-content-between">
        <ng-container *ngIf="!isIncompleteProfile">
          <button type="button" class="pill-bt-sm pill-btn-danger-outline text-nowrap" (click)="deleteAccount()">Delete Account</button>
        </ng-container>
        <button type="button" class="pill-btn-sm pill-btn-default text-nowrap" id="cySignOutButton" (click)="logout()">Sign Out</button>
      </div>
    </div>
  </div>
</div>

<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>
