import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ProxyBaseURLService} from '../../services/proxy-base-url.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';
import {DirectusService} from '../directus/directus.service';

@Injectable({
  providedIn: 'root'
})
export class PersonicaHttpService {

  clientTenantSubject = new BehaviorSubject<{client_id: string, tenant_id: string}>(null);

  clientTenant$ = this.clientTenantSubject.asObservable();



  constructor(private http: HttpClient, private urlService: ProxyBaseURLService, private directus: DirectusService) {
    this.directus.getPersonicaSettings().subscribe(res => {
      this.clientTenantSubject.next({
        client_id: res.client_id,
        tenant_id: res.tenant_id
      });
    });
  }

  private vendorName = 'personica';
  private headers: HttpHeaders;

  get<T>(resource: string, application: string, useRedis: boolean, accessToken: string): Observable<T> {
    return this.clientTenant$.pipe(filter(ct => ct !== null), take(1), switchMap(ct => {
      return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
        return this.http.get<T>(baseURL + resource, {headers: this.getHeaders(application, useRedis, accessToken, ct)});
      }));
    }));

  }

  post<T>(resource: string, body: any, application: string, useRedis: boolean, accessToken: string): Observable<T> {
    return this.clientTenant$.pipe(filter(ct => ct !== null), take(1), switchMap(ct => {
      return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
        return this.http.post<T>(baseURL + resource, body, {headers: this.getHeaders(application, useRedis, accessToken, ct)});
      }));
    }));
  }

  put<T>(resource: string, body: any, application: string, useRedis: boolean, accessToken: string): Observable<T> {
    return this.clientTenant$.pipe(filter(ct => ct !== null), take(1), switchMap(ct => {
      return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
        return this.http.put<T>(baseURL + resource, body, {headers: this.getHeaders(application, useRedis, accessToken, ct)});
      }));
    }));
  }

  patch<T>(resource: string, body: any, application: string, useRedis: boolean, accessToken: string): Observable<T> {
    return this.clientTenant$.pipe(filter(ct => ct !== null), take(1), switchMap(ct => {
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
      return this.http.patch<T>(baseURL + resource, body, {headers: this.getHeaders(application, useRedis, accessToken, ct)});
    }));
    }));
  }

  delete<T>(resource: string, application: string, useRedis: boolean, accessToken: string): Observable<T> {
    return this.clientTenant$.pipe(filter(ct => ct !== null), take(1), switchMap(ct => {
      return this.urlService.getVendorBaseURL(this.vendorName).pipe(switchMap(baseURL => {
        return this.http.delete<T>(baseURL + resource, {headers: this.getHeaders(application, useRedis, accessToken, ct)});
      }));
    }));
  }

  private getHeaders(application: string, useRedis: boolean, accessToken: string, ct: {client_id: string, tenant_id: string}) {
    return  this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Application: application ? application : '',
      'access_token': accessToken ? accessToken : '',
      REDIS: useRedis !== null ? String(useRedis) : '',
      'grant_type': 'refresh_token',
      'client_id': ct.client_id,
      'clientId': ct.client_id,
      'tenant_id': ct.tenant_id,
      'tenantId': ct.tenant_id
     });
    // if (application) {
    //   console.log('application', application);
    //   headers.append('Application', application);
    // }
    // if (useRedis !== null) {
    //   headers.append('REDIS', String(useRedis));
    // }
    // if (accessToken) {
    //   console.log('access_token', accessToken);
    //   headers.append('access_token', accessToken);
    // }
    // console.log(headers);
    // return headers;
  }
}
