import {Component, Input} from '@angular/core';
import {OrdersComponent} from '../../../profile/containers/orders/base/orders.component';
import {Order} from '../../../../interfaces/order.interface';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { NavbarSettings } from '../../../../vendors/directus/interfaces/navbar-settings.interface';

@Component({
  selector: 'app-home-reorder',
  templateUrl: './home-reorder.component.html',
  styleUrls: ['./home-reorder.component.scss'],
})
export class HomeReorderComponent extends OrdersComponent {
  @Select((state: GlobalStateModel) => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;

  context;

  @Input() isMobile: boolean;
  @Input() displayPreviousOrders: Order[];

}
