<div *ngIf="order; else noorder" class="text-center">
  <div
    class="text-uppercase fw-bold fs-12"
    style="display: grid; place-items: center; width: 100%">
    <ng-container [ngSwitch]="order.handoffType">
      <div *ngSwitchCase="handoffs.pickup" class="handoff-overline">
        <div>Picking Up From</div>
      </div>
      <div *ngSwitchCase="handoffs.driveThru" class="handoff-overline">
        <div>Drive Thru Pickup From</div>
      </div>
      <div *ngSwitchCase="handoffs.curbside" class="handoff-overline">
        <div>Curbside Pickup From</div>
      </div>
      <div *ngSwitchDefault class="handoff-overline">
        <div>Delivering To</div>
      </div>
    </ng-container>
  </div>
  <div class="text-nowrap">
    <span
      class="fw-bold font-size-body-small d-inline-block align-middle text-truncate">
      <u>
        <ng-container [ngSwitch]="order.handoffType">
          <span *ngSwitchCase="handoffs.pickup">
            {{
              order.location.name
                | filterBrandNameFromLocation: filterTags
                | truncate: 24
            }}
          </span>
          <span *ngSwitchCase="handoffs.curbside">
            {{
              order.location.name
                | filterBrandNameFromLocation: filterTags
                | truncate: 24
            }}
          </span>
          <span *ngSwitchCase="handoffs.driveThru">
            {{
              order.location.name
                | filterBrandNameFromLocation: filterTags
                | truncate: 24
            }}
          </span>
          <span *ngSwitchDefault>
            {{ order.deliveryAddress?.address1 | truncate: 24 }}
          </span>
        </ng-container>
      </u>
    </span>
    <div
      class="bi bi-chevron-down d-inline-block align-middle ps-1"
      [ngStyle]="{
        color: (app$ | async).navbarSettings?.mobile_icon_color
      }"></div>
  </div>
</div>
<ng-template #noorder>
  <div class="text-center text-nowrap">
    <span
      class="fw-bold fs-16 d-inline-block align-middle text-truncate">
      <u> Select A Location </u>
    </span>
    <div
      class="bi bi-chevron-down d-inline-block align-middle ps-1"
      [ngStyle]="{
        color: (app$ | async).navbarSettings?.mobile_icon_color
      }"></div>
  </div>
</ng-template>
