import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {UserProvider} from 'src/providers/user-provider.interface';
import {User} from 'src/interfaces/user.interface';
import {PunchhAPIService} from './punchh-api.service';
import {UserRequest} from './interfaces/create-user-request.interace';
import {map} from 'rxjs/operators';
import {PunchhMappingService} from './punchh-mapping.service';
import {CateringLink} from 'src/interfaces/catering-link.interface';
import {SSOLogin} from 'src/interfaces/sso-login.model';
import {PassResetResponse} from 'src/interfaces/pass-reset-response.interface';
import {CreateAccount} from '../../interfaces/create-account.interface';
import {SignInWithAppleResponse} from '@capacitor-community/apple-sign-in';
import {PurchaseableReward, Variation} from '../../interfaces/purchaseable-reward.interface';

@Injectable({
  providedIn: 'root'
})
export class PunchhProviderService implements UserProvider {

  constructor(
    private apiService: PunchhAPIService,
    private mapping: PunchhMappingService
  ) {
  }

  getResetPasswordCode(email: string): Observable<any> {
    return this.forgotPassword(email);
  }

  getSSOLoginSubject(): Observable<SSOLogin> {
    return of(null);
  }

  isOauthEnabled(): Observable<boolean> {
    return this.apiService.isOauthEnabled();
  }

  redirectToOauthPage(): void {
    throw new Error('Method not implemented.');
  }

  cateringLink(): Observable<CateringLink> {
    throw new Error('Method not implemented.');
  }

  logIn(username: string, password: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logOut(userID: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  forgotPassword(email: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  resetPassword(newPassword: string): Observable<PassResetResponse> {
    throw new Error('Method not implemented.');
  }

  getPurchaseableRewards(): Observable<PurchaseableReward[]> {
    return of(null);
  }

  purchaseReward(reward: PurchaseableReward, variation?: Variation): Observable<boolean> {
    return of(false);
  }


  // tslint:disable-next-line: max-line-length
  updateUserInfo(user: User): Observable<User> {
    throw new Error('Method not implemented.');
  }

  // tslint:disable-next-line: max-line-length
  createAccount(newAccount: CreateAccount): Observable<User> {
    const req = ({
      email: newAccount.email,
      first_name: newAccount.firstName,
      last_name: newAccount.lastName,
      password: newAccount.password,

    }) as UserRequest;
    return this.apiService.createUser(req).pipe(map(user => {
      return this.mapping.toUser(user, []);
    }));
  }

  logInAsGuest(): Observable<string> {
    throw new Error('Method not implemented.');
  }

  getUserInfo(userID: string): Observable<User> {
    throw new Error('Method not implemented.');
  }

  getLoggedInUserInfo(): Observable<User> {
    return this.apiService.getUserInfo('').pipe(map(() => {
      return null;
    }));
  }

  is3rdPartyWrapped(): boolean {
    return false;
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    return throwError('Not implemented');
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  deleteAccount(userID: string): Observable<boolean> {
    return undefined;
  }
}
