import { Component, Output, EventEmitter, Input } from '@angular/core';
import { MobileService } from 'src/services/mobile.service';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { TextField } from '../../../../vendors/directus/interfaces/text-field.interface';
import { Order } from '../../../../interfaces/order.interface';
import { CustomField } from '../../../../interfaces/custom-fields.interface';
// import {SetPastOrders} from '../../../../store/actions/user.actions';
// import {distinctUntilChanged, filter} from 'rxjs/operators';
// import {VendorSetup} from '../../../../vendors/directus/interfaces/vendor.interface';
// import {User} from '../../../../interfaces/user.interface';

@Component({
  selector: 'app-order-type-list',
  templateUrl: './order-type-list.component.html',
  styleUrls: ['./order-type-list.component.scss'],
})
export class OrderTypeListComponent {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app) app$: Observable<any>;
  @Select(state => state.location) location$: Observable<any>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  // @Select(state => state.user.user) user$: Observable<User>;
  // @Select(state => state.user.pastOrders) pastOrders$: Observable<Order[]>;

  @Input() order: Order;
  // @Input() user: User;

  @Output() addNewOrderType = new EventEmitter<any>();
  @Output() startNewOrder = new EventEmitter<any>();

  curbsideFields: CustomField[] = [];
  savedAddress = [];
  subscriptions = [];
  chooseLocationCopy = 'Start New Order';
  displayPreviousOrders: Order[] = [];
  // displayVendorSetup: VendorSetup;
  displayOrder: Order;
  // displayUser: User;
  isLoading;
  error;
  successMessage;

  // private subs: Subscription[] = [];

  constructor(
    // private store: Store,
    public mobile: MobileService
  ) {
    this.location$.subscribe(loc => {
      if (loc && loc.userSavedAddress && loc.userSavedAddress.length) {
        this.savedAddress = loc.userSavedAddress;
      }
    });
    this.textFields$.subscribe(textFields => {
      this.chooseLocationCopy = textFields.choose_location_copy;
    });
  }

  // ngOnInit() {
  //     this.store.dispatch(new SetPastOrders());
  //     this.subs.push(this.order$.subscribe(order => {
  //         if (order) {
  //             this.displayOrder = order;
  //         }
  //         this.error = null;
  //         this.successMessage = null;
  //         this.isLoading = false;
  //     }));
  //     this.subs.push(this.user$.subscribe(user => {
  //         this.displayUser = user ? user : null;
  //     }));
  //     this.subs.push(this.pastOrders$.pipe(filter(o => o !== null),
  // tslint:disable-next-line:max-line-length
  //         distinctUntilChanged(((prev, next) => prev && prev.length === next.length && prev[0]?.orderStatus === next[0]?.orderStatus))).
  //     subscribe(orders => {
  //         this.displayPreviousOrders = orders;
  //         console.log(this.displayPreviousOrders);
  //     }));
  // }

  startOrder(order) {
    this.startNewOrder.emit(order);
  }

  addNewClicked() {
    this.addNewOrderType.emit();
  }
}
