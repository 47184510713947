import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, retry, switchMap } from 'rxjs/operators';
import { ItwercsAPIService } from './itwercs-api.service';
import { ItwercsMappingService } from './itwercs-mapping.service';
import { OrderHistoryProvider } from 'src/providers/order-history-provider.interface';
import { OrderProvider } from 'src/providers/order-provider.interface';
import { MenuProvider } from 'src/providers/menu-provider.interface';
import { LocationProvider } from 'src/providers/location-provider.interface';
import { ImageContentService } from '../directus/content.service';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { CardDetails } from 'src/interfaces/card-details.interface';
import { OrderItem, Product } from 'src/interfaces/product.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { User } from 'src/interfaces/user.interface';
import { CreateGuestRequestGuest } from './interfaces/create-guest-request.interface';
import { Order } from 'src/interfaces/order.interface';
import { Location } from 'src/interfaces/location.interface';
import { Address } from 'src/interfaces/address.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { CateringLink } from 'src/interfaces/catering-link.interface';
import { Concept } from './interfaces/concept.interface';
import moment, { Moment } from 'moment-timezone';
import { DirectusService } from '../directus/directus.service';
import { PaymentProcessingService } from '../../services/vendor-config-service/payment-processing.service';
import { SSOLogin } from 'src/interfaces/sso-login.model';
import { UserProvider } from 'src/providers/user-provider.interface';
import { PassResetResponse } from 'src/interfaces/pass-reset-response.interface';
import { CreateAccount } from '../../interfaces/create-account.interface';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { GroupOrder } from '../../interfaces/group-order.interface';

@Injectable({
  providedIn: 'root',
})
export class ItwercsProviderService implements UserProvider, OrderProvider, OrderHistoryProvider, MenuProvider, LocationProvider {
  itwercsProduct: any;
  leadThroughItems = [];

  integrationCMSName = 'itwercs';

  orderKey = 'ItwercsDEOrder';

  constructor(
    private itwercsAPI: ItwercsAPIService,
    private mapping: ItwercsMappingService,
    private contentService: ImageContentService,
    private paymentService: PaymentProcessingService,
    private directusService: DirectusService
  ) {}

  getAvailableOrderDays(locationID: string | number, orderID: string | number): Observable<Date[]> {
    return of([]);
  }
  getAvailableOrderTimes(locationID: string | number, orderID: string | number, date: Date): Observable<Moment[]> {
    return of([]);
  }

  setManualFire(orderID: string | number): Observable<Order> {
    return this.getCurrentOrder(null);
  }

  getResetPasswordCode(email: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  deleteAccount(userID: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  getSSOLoginSubject(): Observable<SSOLogin> {
    return of(null);
  }

  isOauthEnabled(): Observable<boolean> {
    return of(false);
  }

  redirectToOauthPage() {}

  cateringLink(): Observable<CateringLink> {
    // This could change when we get a catering integration
    const link: CateringLink = {
      enabled: false,
      link: '',
      linkGuest: '',
      clientId: '',
    };
    return of(link);
  }

  removeCard(card: SavedCard): Observable<SavedCard> {
    throw new Error('Method not implemented.');
  }

  saveCardAsDefault(card: SavedCard): Observable<SavedCard> {
    return of(null);
  }

  getSavedCards(userID: string | number, isAccountPage: boolean): Observable<SavedCard[]> {
    throw new Error('Method not implemented.');
  }

  addCoupon(orderID: string | number): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  notifyOfArrival(orderID: string, extraMessage: string): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  removeCoupon(orderID: string | number): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  getProviderUpsells(basketGuid: string | number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  addProviderUpsell(basketGuid: string, body: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getGiftCardBalance(basketGuid: string, cardNumber: string, pin: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getLocations(withCalendars?: boolean): Observable<Location[]> {
    return this.itwercsAPI.getAllSites().pipe(
      switchMap(res => {
        const locations = res.map(site => this.mapping.siteToLocation(site));
        return combineLatest(locations.map(loc => this.contentService.getLocationWithMapIconURL(loc)));
      }),
      retry(5)
    );
  }

  getLocation(locationID: number): Observable<Location> {
    if (isNaN(locationID as any)) {
      return this.directusService.getSingleLocationBySlug(locationID.toString()).pipe(
        switchMap(locationInfo => {
          return this.getLocation(Number(locationInfo.menu_id));
        })
      );
    } else {
      return this.itwercsAPI.getSiteByID(locationID).pipe(
        switchMap(site => {
          const loc = this.mapping.siteToLocation(site);
          return this.contentService.getLocationWithMapIconURL(loc);
        }),
        retry(5)
      );
    }
  }

  getLocationBySlug(slugID: string): Observable<Location> {
    return this.directusService.getSingleLocationBySlug(slugID).pipe(
      switchMap(locationInfo => {
        return this.getLocation(Number(locationInfo.menu_id));
      })
    );
  }

  getLocationsNear(latitude: number, longitude: number, milesRadius: number, maxResults: number): Observable<Location[]> {
    return this.itwercsAPI.getSitesNearLatLong(latitude, longitude).pipe(
      map(sites => {
        return sites.map(site => this.mapping.siteToLocation(site));
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  checkForDelivery(
    restaurantID: number,
    handoffType: HandoffType.delivery | HandoffType.dispatch,
    timeWanted: Date,
    address: Address
  ): Observable<{ canDeliver: boolean; failureReason?: string }> {
    throw new Error('Method not implemented.');
  }

  logIn(username: string, password: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logOut(userID: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  forgotPassword(email: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  resetPassword(newPassword: string): Observable<PassResetResponse> {
    throw new Error('Method not implemented.');
  }

  cancelOrder(orderID: string): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  editOrder(orderID: string): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  // tslint:disable-next-line: max-line-length
  updateUserInfo(user: User): Observable<User> {
    return of(null);
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  // tslint:disable-next-line: max-line-length
  createAccount(newAccount: CreateAccount): Observable<User> {
    let req: CreateGuestRequestGuest;
    let concept: Concept;
    return this.itwercsAPI.getConcept().pipe(
      switchMap(res => {
        concept = res;
        req = {
          FirstName: newAccount.firstName,
          LastName: newAccount.lastName,
          Phone: newAccount.phone,
          Email: newAccount.email,
          Password: newAccount.password,
          Dtl: {
            ID: 0,
            GuestID: null,
            EntID: 0,
            OrgID: 0,
            Loyalty: false,
            AllowText: newAccount.smsOptIn,
            AllowEmail: newAccount.emailOptIn,
            PerferredComm: 0,
            FavoriteSiteID: 0,
            FavoriteSite: null,
            CreatedOn: moment().format(),
          },
        };
        return this.itwercsAPI.createGuest(req, concept).pipe(
          map(userReq => {
            if (userReq.Status === 'Success') {
              sessionStorage.setItem('ItwercsDEUser', JSON.stringify(this.mapping.createGuestToUser(req, userReq.Id)));
              return JSON.parse(sessionStorage.getItem('ItwercsDEUser'));
            }
          })
        );
      })
    );
  }

  logInAsGuest(): Observable<string> {
    return of(null);
  }

  getUserInfo(userID: string): Observable<User> {
    return of(JSON.parse(sessionStorage.getItem('ItwercsDEUser')));
  }

  getLoggedInUserInfo(): Observable<User> {
    return of(null);
    // const token = ItwercsProviderService.getAuthToken();
    // return token
    //     ? this.getUserInfo(token)
    //     : of(null);
  }

  is3rdPartyWrapped(): boolean {
    return false;
  }

  getCurrentOrder(userID: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    return of(order);
  }

  clearOrder(): Observable<string> {
    this.removeOrder();
    return of(this.getBasketGuid());
  }

  startOrder(userID: string, locationID: number, handoffType: HandoffType, tableNumber: string): Observable<Order> {
    return this.getLocation(locationID).pipe(
      map(siteRes => {
        sessionStorage.setItem(this.orderKey, JSON.stringify(this.mapping.newOrder(siteRes, handoffType)));
        const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
        order.tableNumber = tableNumber;
        sessionStorage.setItem(this.orderKey, JSON.stringify(order));
        return order;
      })
    );
  }

  transferBasket(orderID: string, locationID: number): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    sessionStorage.removeItem(this.orderKey);
    return this.getLocation(locationID).pipe(
      map(location => {
        sessionStorage.setItem(this.orderKey, JSON.stringify(this.mapping.newOrder(location, order.handoffType)));
        return JSON.parse(sessionStorage.getItem(this.orderKey)) as Order;
      })
    );
  }

  validateOrder(userID: string, locationID: number, orderID: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    return this.contentService.getDestinationId(order).pipe(
      switchMap(destID => {
        return this.itwercsAPI.calculateOrderTotals(this.mapping.orderToItwercsSubmitOrder(order, destID)).pipe(
          map(calcRes => {
            order.appliedCouponCents = Number((calcRes.Order.DiscountTotal * 100).toFixed(0));
            order.taxCents = Number((calcRes.Order.TaxTotal * 100).toFixed(0));
            order.subTotalCents = Number((calcRes.Order.SubTotal * 100).toFixed(0));
            order.totalCents = Number((calcRes.Order.GrandTotal * 100).toFixed(0));
            order.paidCents = calcRes.Order.PaymentTotal * 100;
            order.balanceCents = order.totalCents - order.paidCents;
            if (order.isASAP) {
              order.orderReadyTimestamp = moment().add(order.location.orderLeadTime, 'minutes').toDate();
            }
            sessionStorage.setItem(this.orderKey, JSON.stringify(order));
            return JSON.parse(sessionStorage.getItem(this.orderKey)) as Order;
          })
        );
      })
    );
  }

  addToOrder(orderItem: OrderItem, orderID: string, userID: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    orderItem.orderItemID = this.mapping.generateGUID();
    orderItem.baseCents = orderItem.totalCents;
    orderItem.options.forEach(option => {
      orderItem.totalCents += option.addedCents;
    });
    order.items.push(orderItem);
    order.subTotalCents = 0;
    order.items.forEach(item => {
      order.subTotalCents = order.subTotalCents + item.totalCents;
    });
    return this.contentService.getOrderItemsWithSlugsandImages(order).pipe(
      switchMap((res: Order) => {
        sessionStorage.setItem(this.orderKey, JSON.stringify(res));
        return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
      })
    );
  }

  updateBasketItem(orderItem: OrderItem, orderID: string, userID: string, quant: number): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    const oldItem = order.items.filter(item => {
      if (item.orderItemID === orderItem.orderItemID) {
        return item;
      }
    });
    const newOrderItem = { ...orderItem };
    newOrderItem.baseCents = orderItem.totalCents;
    newOrderItem.options.forEach(option => {
      newOrderItem.totalCents += option.addedCents;
    });
    newOrderItem.quantity += quant - newOrderItem.quantity;
    order.items[order.items.indexOf(oldItem[0])] = newOrderItem;
    order.subTotalCents = 0;
    order.items.forEach(item => {
      order.subTotalCents = order.subTotalCents + item.totalCents;
    });
    return this.contentService.getOrderItemsWithSlugsandImages(order).pipe(
      switchMap(res => {
        sessionStorage.setItem(this.orderKey, JSON.stringify(res));
        return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
      })
    );
  }

  removeFromOrder(orderItem: OrderItem, orderID: string, userID: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    const itemToRemove = order.items.filter(item => {
      if (item.orderItemID === orderItem.orderItemID) {
        return item;
      }
    });
    order.items.splice(order.items.indexOf(itemToRemove[0]), 1);
    order.subTotalCents = 0;
    order.items.forEach(item => {
      order.subTotalCents = order.subTotalCents + item.totalCents;
    });
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
  }

  setTimePreference(orderID: string, userID: string, time: Date): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.isASAP = false;
    order.orderReadyTimestamp = moment(time).toDate();
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
  }

  setTimePreferenceToASAP(orderID: string, userID: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.isASAP = true;
    order.earliestReadyTimestamp = moment().add(order.location.orderLeadTime, 'minutes').toDate();
    order.orderReadyTimestamp = moment().add(order.location.orderLeadTime, 'minutes').toDate();
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
  }

  setHandoffType(orderID: string, userID: string, handoffType: HandoffType): Observable<Order> {
    const mode = sessionStorage.getItem('mode');
    if (mode === 'tableside') {
      handoffType = 5;
    }
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    if (isNaN(handoffType)) {
      handoffType = order.handoffType;
    }
    order.handoffType = Number(handoffType);
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)) as Order);
  }

  setDispatchOrDeliveryAddress(orderID: string, userID: string, address: Address, handoffType: HandoffType): Observable<Order> {
    return of(null);
  }

  checkDeliveryStatus(orderGuid: string, authToken: string): Observable<any> {
    return of(null);
  }

  getOrderStatus(orderGuid: string): Observable<Order> {
    return this.itwercsAPI.getOrderByID(parseInt(orderGuid, 10)).pipe(
      switchMap(order => {
        return this.getLocation(order.SiteId).pipe(
          map(location => {
            return this.mapping.itwercsOrderToOrder(order, location);
          })
        );
      }),
      retry(2)
    );
  }

  setBasketCustomfield(orderId: string, customId: number | string, value: any): Observable<Order> {
    return of(null);
  }

  setOrderLocation(userID: string, locationID: number, orderID: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    return this.getLocation(locationID).pipe(
      map(siteRes => {
        if (order) {
          order.location = siteRes;
          sessionStorage.setItem(this.orderKey, JSON.stringify(order));
          return JSON.parse(sessionStorage.getItem(this.orderKey));
        } else {
          sessionStorage.setItem(this.orderKey, JSON.stringify(this.mapping.newOrder(siteRes, order.handoffType)));
          return JSON.parse(sessionStorage.getItem(this.orderKey));
        }
      })
    );
  }

  setTip(userID: string, orderID: string, tipCents: number): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.tipCents = tipCents * 100;
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return this.validateOrder(userID, Number(order.location.locationID), orderID).pipe(
      map(orderRes => {
        orderRes.totalCents += orderRes.tipCents;
        return orderRes;
      })
    );
  }

  submitPayment(orderID: string, cardDetails: CardDetails, applyCents: number, shouldSave: boolean, token: string): Observable<Order> {
    const user = this.mapping.cardDetailsToDEUser(cardDetails);
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    return this.contentService.getDestinationId(order).pipe(
      switchMap(destID => {
        const orderMap = this.mapping.orderToItwercsSubmitOrder(order, destID, user);
        return this.paymentService.getService().pipe(
          switchMap(service => {
            // tslint:disable-next-line:max-line-length
            return service.submitPaymentNewCard(order, 1, this.mapping.cardDetailsToCreditCard(cardDetails), token).pipe(
              switchMap(paymentRes => {
                // tslint:disable-next-line:max-line-length
                return this.contentService.getTenderTypeFromCardBrand(this.mapping.paymentResponseToOrderPayment(paymentRes)).pipe(
                  switchMap(newPaymentRes => {
                    orderMap.Payments.push(newPaymentRes);
                    return this.itwercsAPI.calculateOrderTotals(orderMap).pipe(
                      switchMap(calcRes => {
                        return this.itwercsAPI.submitOrder(calcRes.Order).pipe(
                          map(statusRes => {
                            if (statusRes.OrderStatus.includes('Incomplete')) {
                              return null;
                            } else {
                              order.orderID = String(statusRes.OrderId);
                              order.paidCents = Number(paymentRes.authAmount) * 100;
                              order.balanceCents = order.totalCents - order.paidCents;
                              sessionStorage.removeItem(this.orderKey);
                              return order;
                            }
                          })
                        );
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  getLocationtableNumber(tableNumber: number, locationID: number): Observable<string> {
    return this.itwercsAPI.getTableNumbers(locationID).pipe(
      map(tables => {
        const table = tables.find(t => t.ID === tableNumber);
        return table && table.Name ? table.Name : tableNumber.toString();
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  submitPaymentAsGuest(
    orderID: string,
    cardDetails: CardDetails,
    applyCents: number,
    shouldSave: boolean,
    token: string
  ): Observable<Order> {
    return this.submitPayment(orderID, cardDetails, applyCents, shouldSave, token);
  }

  getOrderHistory(userID: string): Observable<Order[]> {
    return of(null);
    // return this.itwercsAPI.getOrdersByGuestID(userID).pipe(switchMap(ordersRes => {
    //     return ordersRes.map(order => this.getLocation(order.SiteId).pipe(map( location => {
    //         return this.mapping.itwercsOrderToOrder(order, location)
    //     }))
    // }));
  }

  // TODO: API Endpoints not implemented yet
  // tslint:disable-next-line:max-line-length
  reorderFromHistory(authToken: string, externalOrderRef: string, orderID: string, ignoreUnavailableProducts: boolean): Observable<Order> {
    return of(null);
  }

  // tslint:disable-next-line: max-line-length
  getProduct(
    locationID: number,
    handoffType: HandoffType,
    menuID: string,
    categoryID: string,
    productID: number,
    isProductPageCall: boolean
  ): Observable<Product> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID.toString()).pipe(
        switchMap(locationInfo => {
          return this.getProduct(Number(locationInfo.menu_id), handoffType, menuID, categoryID, productID, isProductPageCall);
        })
      );
    } else {
      const order = JSON.parse(sessionStorage.getItem(this.orderKey));
      return this.contentService.getDestinationId(order).pipe(
        switchMap(destID => {
          // tslint:disable-next-line:max-line-length
          return this.itwercsAPI.getMenuItemDetails(locationID, productID, order.orderReadyTimestamp, destID).pipe(
            switchMap(itemRes => {
              return this.contentService.getProductWithImages(this.mapping.menuItemToProduct(itemRes));
            })
          );
        })
      );
    }
  }

  getMenuByHandoffType(locationID: number, handoffType: HandoffType): Observable<Menu> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID.toString()).pipe(
        switchMap(locationInfo => {
          return this.getMenuByHandoffType(Number(locationInfo.menu_id), handoffType);
        })
      );
    } else {
      return this.itwercsAPI.getFilteredScreenPages(locationID).pipe(
        switchMap(pages => {
          pages.forEach(cat => {
            cat.Buttons = cat.Buttons.filter(prod => prod.Availability);
          });
          const deMenu = this.mapping.screenPagesToMenu(locationID, pages);
          return this.contentService.getMenuWithImages(deMenu, true);
        })
      );
    }
  }

  setSpecialInstructions(orderID: string | number, inst: string): Observable<Order> {
    const order: Order = JSON.parse(sessionStorage.getItem(this.orderKey));
    order.specialInstructions = inst;
    sessionStorage.setItem(this.orderKey, JSON.stringify(order));
    return of(JSON.parse(sessionStorage.getItem(this.orderKey)));
  }

  addDonation(basketGuid: string, id: number, amount: number): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  private removeOrder(): void {
    sessionStorage.removeItem(this.orderKey);
  }

  private getBasketGuid(): string {
    return null;
    // return sessionStorage.getItem(this.basketGuidKey);
  }

  private getAuthToken(): string {
    return null;
    // return sessionStorage.getItem(this.authTokenKey);
  }

  joinGroupOrder(groupGuid: string, name: string): Observable<GroupOrder> {
    return undefined;
  }

  // tslint:disable-next-line:max-line-length
  startGroupOrder(
    restaurantID: number,
    basketGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return undefined;
  }

  // tslint:disable-next-line:max-line-length
  updateGroupOrder(
    restaurantID: number,
    basketGuid: string,
    groupGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return undefined;
  }

  getCurrentGroupOrder(restaurantID: number, basketGuid: string, name: string): Observable<GroupOrder> {
    return undefined;
  }

  getGroupOrder(groupID: string, name: string): Observable<GroupOrder> {
    return undefined;
  }

  clearGroupOrder(): Observable<GroupOrder> {
    return undefined;
  }

  checkIfCanSupportMultipleRewards(orderID: string): Observable<boolean> {
    return of(false);
  }
}
