import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DirectusService } from '../../../vendors/directus/directus.service';
import { OloConfiguration } from '../../../vendors/directus/interfaces/olo-configuration.interface';
import { map } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class MenuGuard {
  constructor(
    private directus: DirectusService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.directus.getOloConfiguration().pipe(
      map(
        (oloConfig: OloConfiguration) => {
          if (oloConfig.wrap_olo_serve && Capacitor.getPlatform() !== 'web') {
            this.router.navigateByUrl('browser-wrapper').then(r => {});
            return false;
          } else {
            return true;
          }
        },
        () => {
          return true;
        }
      )
    );
  }
}
