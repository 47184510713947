import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { filter } from 'rxjs/operators';
import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { CateringLink } from '../../../../../interfaces/catering-link.interface';
import { VendorSetup } from '../../../../../interfaces/vendor.interface';
import { AppStateModel } from '../../../../../store/state/app.state';
import { Browser } from '@capacitor/browser';
import { CustomPage, CustomPageChildNavigation } from '../../../../../vendors/directus/interfaces/custom-page.interface';
import { NavigationService } from '@modules/navigation/services';
import { NavbarSettings } from '../../../../../vendors/directus/interfaces/navbar-settings.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SetHandoffType } from '../../../../../store/actions/order.actions';
import { HandoffType } from '../../../../../interfaces/handoff-type.enum';
import { collapseHorizontallyAnimation } from 'angular-animations';
import { GlobalStateModel } from '../../../../../store/state.model';
import { GroupOrder } from '../../../../../interfaces/group-order.interface';
import { NgbDropdown, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar-desktop',
  templateUrl: './navbar-desktop.component.html',
  styleUrls: ['./navbar-desktop.component.scss'],
  animations: [
    trigger('selectorAnimation', [
      state('true', style({ left: '4px' })),
      state('false', style({ left: 'calc(50% + 4px' })),
      transition('false => true', animate('200ms ease-out')),
      transition('true => false', animate('200ms ease-out')),
    ]),
    collapseHorizontallyAnimation(),
  ],
})
export class NavbarDesktopComponent implements OnInit, AfterViewChecked {
  @Select(state => state.user.cateringLink) cateringLink$: Observable<CateringLink>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app) app$: Observable<AppStateModel>;
  @Select(state => state.user.thirdPartyWrapped) thirdPartyWrapped$: Observable<boolean>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select((state: GlobalStateModel) => state.order.groupOrder) groupOrder$: Observable<GroupOrder>;

  showingMoreSubject = new BehaviorSubject(false);

  showingMore$: Observable<boolean> = this.showingMoreSubject.asObservable();

  @Input() order: Order;
  @Input() user: User;
  @Input() branding: Branding;
  @Input() handoffOverride: boolean;
  @Input() deliveryToggleLoading: boolean;
  @Input() cateringLink = '';
  @Input() cateringLinkGuest = '';
  @Input() cartIcon;
  @Input() showPrice;
  @Input() showOrder;
  @Input() buttonText;
  @Input() timeSelectionLoading;
  @Input() currencyCode: string;
  @Input() set navbarSettings(val: NavbarSettings) {
    this.navSettings = val;
  }
  @Input() doneLoading: boolean;

  @Output() dateTimeSelected = new EventEmitter<Date | 'asap'>();
  @Output() clickedLogout = new EventEmitter<any>();
  @Output() addNewOrderType = new EventEmitter<any>();
  @Output() startNewOrder = new EventEmitter<any>();
  @Output() startPickupOrder = new EventEmitter<any>();
  @Output() startCurbsideOrder = new EventEmitter<any>();
  @Output() startDineInOrder = new EventEmitter<any>();
  @Output() startDriveThruOrder = new EventEmitter<any>();
  @Output() startDeliveryOrder = new EventEmitter<any>();
  @Output() homeButtonClicked = new EventEmitter<any>();

  @ViewChild('navbar', { static: false }) navbar: ElementRef;
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  providers;
  private showCateringLink = false;
  width = window.innerWidth;
  customPageLinks: CustomPage[] = [];
  startedOrder: Order = null;
  loginRoute = this.navigation.getLoginPageSlug();
  creatAccountRoute = this.navigation.getCreateAccountPageSlug();
  previousOrdersRoute = this.navigation.getPreviousOrdersPageSlug();
  profileRoute = this.navigation.getProfilePageSlug();
  rewardsRoute = this.navigation.getRewardsPageSlug();
  slotWidth = 0;
  showMenuLink = false;
  estMinutes;
  numberOfHandoffs: boolean[] = [];
  navSettings: NavbarSettings;
  locationID = null;

  currentGroupOrder: GroupOrder;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth;
    this.app$.pipe(filter(a => a !== null)).subscribe(app => {
      this.reloadNavigationLinks(app.customPages);
    });
    this.slotWidth = (this.navbar.nativeElement.offsetWidth - 960) / 120;
  }

  constructor(
    public router: Router,
    private navigation: NavigationService,
    private store: Store
  ) {}

  ngOnInit() {
    this.groupOrder$.subscribe(groupOrder => (this.currentGroupOrder = groupOrder));
    this.showMenuLink = this.order && !window.location.href.includes('/menu');
    this.cateringLink$.pipe(filter(l => l !== null)).subscribe(clink => {
      this.showCateringLink = clink.enabled;
      this.cateringLink = clink.link;
      this.cateringLinkGuest = clink.linkGuest;
    });
    this.vendorSetup$.pipe(filter(vs => !!vs)).subscribe(vs => {
      this.providers = vs;
    });
    combineLatest([this.app$.pipe(filter(a => a.customPages !== null)), this.order$.pipe(filter(o => o !== null))]).subscribe(
      ([app, order]) => {
        this.startedOrder = order;
        this.reloadNavigationLinks(app.customPages);
        this.estMinutes = Number(((order.earliestReadyTimestamp?.valueOf() - Date.now()) / (60 * 1000)).toFixed(0));
        if (this.estMinutes < 0) {
          this.estMinutes = 0;
        }
        this.numberOfHandoffs = [
          order.location?.supportsDelivery,
          order.location?.supportsPickup,
          order.location?.supportsCurbside,
          order.location?.supportsDriveThru,
          // order.location.supportsDineIn,
        ].filter((handoffType: boolean) => handoffType);
        this.locationID = order.location?.locationID;
      }
    );
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.showMenuLink = this.order && !val.url.includes('/menu');
      }
    });
  }

  ngAfterViewChecked() {
    this.app$.pipe(filter(a => a.customPages !== null)).subscribe(app => {
      this.reloadNavigationLinks(app.customPages);
    });
  }

  handoffModeSelect(handoffMode: string) {
    this.deliveryToggleLoading = true;
    if (handoffMode === 'pickup') {
      this.store.dispatch(new SetHandoffType(HandoffType.pickup)).subscribe({
        next: () => {
          this.deliveryToggleLoading = false;
          this.navigation.navigateToMenuPage(this.locationID);
        },
        error: () => (this.deliveryToggleLoading = false),
      });
    } else if (handoffMode === 'curbside') {
      this.store.dispatch(new SetHandoffType(HandoffType.curbside)).subscribe({
        next: () => {
          this.deliveryToggleLoading = false;
          this.navigation.navigateToMenuPage(this.locationID);
        },
        error: () => (this.deliveryToggleLoading = false),
      });
    } else if (handoffMode === 'drive-thru') {
      this.store.dispatch(new SetHandoffType(HandoffType.driveThru)).subscribe({
        next: () => {
          this.deliveryToggleLoading = false;
          this.navigation.navigateToMenuPage(this.locationID);
        },
        error: () => (this.deliveryToggleLoading = false),
      });
    } else if (handoffMode === 'dine-in') {
      this.store.dispatch(new SetHandoffType(HandoffType.dineIn)).subscribe({
        next: () => {
          this.deliveryToggleLoading = false;
          this.navigation.navigateToMenuPage(this.locationID);
        },
        error: () => (this.deliveryToggleLoading = false),
      });
    } else {
      this.deliveryToggleLoading = false;
      this.startDeliveryOrder.emit();
    }
  }

  reloadNavigationLinks(customPages: CustomPage[]) {
    if (this.navbar?.nativeElement) {
      this.slotWidth = (this.navbar.nativeElement.offsetWidth - 1000) / 120;
      this.showingMoreSubject.next(this.slotWidth >= 0 && this.snippet(this.actualPageList(customPages))[1] > 0);
      // this.customPageLinks = this.linkList(customPages);
      this.customPageLinks = customPages;
    }
  }

  hasUser(): boolean {
    return this.user && typeof this.user.userID !== 'undefined' && !this.user?.isGuest;
  }

  locationClicked() {
    this.navigation.navigateToLocationsPage();
  }

  clickDropdown() {
    const root = document.documentElement;
    const arrow = document.getElementById('down-arrow-icon');
    // tslint:disable-next-line:max-line-length
    root.style.setProperty('--drop-down-arrow-left', (arrow.offsetLeft + 75 - 9.75).toString() + 'px'); // Plus 75 for the offset of the dropdown and minus 9.75 for the arrow icon size
  }

  selectedDateTime(selectedTime: Date | 'asap') {
    this.dateTimeSelected.emit(selectedTime);
  }

  logoutClicked() {
    this.clickedLogout.emit();
  }

  newOrderTypeClicked() {
    this.addNewOrderType.emit();
  }

  startOrder(order) {
    this.startNewOrder.emit(order);
  }

  homeClicked() {
    this.homeButtonClicked.emit();
  }

  pageClicked(page) {
    if (!page.content?.length && page?.cta_link) {
      Browser.open({ url: page.cta_link });
    } else {
      this.router.navigate([page.slug]);
    }
  }

  childPageClicked(child: CustomPageChildNavigation) {
    if (child?.slug.includes('http')) {
      Browser.open({ url: child.slug });
    } else {
      this.router.navigate([child.slug], { fragment: child.fragment });
    }
  }

  linkList(pageList) {
    const [snippedPageList, skip, end] = this.snippet(pageList);
    return snippedPageList.slice(skip, end);
  }

  actualPageList(pageList) {
    const ret = this.filterNoShows(pageList);
    if (this.showCateringLink) {
      const cateringLink = this.hasUser() ? this.cateringLink : this.cateringLinkGuest;
      ret.push({ title: 'Catering', icon: 'people', cta_link: cateringLink });
    }
    return ret;
  }

  openDropdown(dropdownId: string): void {
    const dropdown = document.getElementById(dropdownId);
    if (dropdown) {
      dropdown.click();
    }
  }

  dropdownList(pageList) {
    const [snippedPageList, skip, end] = this.snippet(pageList);
    const ret = snippedPageList.slice(0, skip).concat(snippedPageList.slice(end, snippedPageList.length));
    switch (snippedPageList.length - skip) {
      case 0:
        return [ret[ret.length - 1], ret[ret.length - 2]].concat(ret.splice(0, ret.length - 2)); // Menu before Catering
      case 1:
        return [ret[ret.length - 1]].concat(ret.splice(0, ret.length - 1));
      default:
        return ret;
    }
  }

  private snippet(pageList) {
    const snippedPageList = this.actualPageList(pageList);
    const slotCount = Math.floor(this.slotWidth);
    const length = snippedPageList.length;
    let skip = snippedPageList.length - slotCount;
    if (skip >= 1) {
      skip += 1; // if we are skipping any, we have to skip one more to make room for the More dropdown
    }
    skip = Math.max(Math.min(skip, length), 0);
    const end = Math.min(Math.max(slotCount + skip, 0), length);
    return [snippedPageList, skip, end];
  }

  filterNoShows(pageList) {
    if (pageList && pageList.length > 0) {
      return pageList.filter(page => page.show_on_navbar);
    } else {
      return [];
    }
  }
}
