import { Injectable, NgZone } from '@angular/core';

import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { Preferences as Storage } from '@capacitor/preferences';
import { Geolocation } from '@capacitor/geolocation';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';

import { PaytronixAPIService } from './paytronix-api.service';
import { PaytronixMappingService } from './paytronix-mapping.service';
import { OloAPIService } from '../olo/olo-api.service';
import { OLOMappingService } from '../olo/olo-mapping.service';
import { ImageContentService } from '../directus/content.service';
import { DirectusService } from '../directus/directus.service';

import { UserProvider } from 'src/providers/user-provider.interface';
import { CateringLink } from 'src/interfaces/catering-link.interface';
import { User } from 'src/interfaces/user.interface';
import { CreateAccount } from 'src/interfaces/create-account.interface';
import { LoyaltyProvider } from 'src/providers/loyalty-provider.interface';
import { DollarReward } from 'src/interfaces/dollar-reward.interface';
import { Order } from 'src/interfaces/order.interface';
import { Reward } from 'src/interfaces/reward.interface';
import { HistoryEvent } from 'src/interfaces/history-event.interface';
import { RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { PassResetResponse } from 'src/interfaces/pass-reset-response.interface';
import { Location, LoyaltyLocation } from 'src/interfaces/location.interface';
import { MessagingProvider } from '../../providers/messaging-provider.interface';
import { EditExternalAccountsRequest } from './interfaces/edit-external-accounts-request.interface';
import { PaytronixConfiguration } from '../directus/interfaces/paytronix-configuration.interface';
import { CheckInCode } from '../../interfaces/check-in-code.interface';
import { UpdateAccount } from './interfaces/update-account.interface';
import { GiftCardPurchaseProvider } from 'src/providers/gift-card-purchase-provider.interface';

import { SSOProvider } from 'src/interfaces/sso-provider.enum';

import { UserLogInResponse } from './interfaces/user-log-in-response.model';
import { UserInfoResponse } from './interfaces/user-info-response.model';
import { SSOLogin } from 'src/interfaces/sso-login.model';
import { SaleConfigResponse } from './interfaces/sale-config-response.interface';
import { GiftCardConfig, GiftCardOrder } from '@modules/gift-card/models';
import { Capacitor } from '@capacitor/core';
import { OrderService } from '../../services/vendor-config-service/order.service';
import { OLOProviderService } from '../olo/olo-provider.service';
import { WebSaleProgramType } from './interfaces/sale-config-for-account.interface';
import { PurchaseableReward, Variation } from '../../interfaces/purchaseable-reward.interface';
import { ExecuteSaleForAccountRequest, PaymentMethodType } from './interfaces/execute-sale-for-account.interface';
import { CalculatePriceForAccountRequest } from './interfaces/calculate-price-for-account.interface';
import { GiftCard } from '../../interfaces/giftcard.interface';
import { BalanceTransferServiceError } from './interfaces/balance-transfer-service.interface';
import { TransactionResponse } from './interfaces/transaction-response.interface';
import { RechargeOrder } from '../../interfaces/recharge-order.interface';
import moment from 'moment-timezone';
import { LoyaltyReward } from '../../interfaces/loyalty-reward.interface';
import { LocalStorageKey } from '../../models/common.enum';
import { InboxMessage } from '../../interfaces/inbox-message.interface';
import { Dialog } from '@capacitor/dialog';
import { CalculatePriceResponse } from './interfaces/calculate-price-response.interface';
import { UserField } from '../../interfaces/user-field';
import { Referral } from '../../interfaces/referral.interface';

declare global {
  interface String {
    padZero(length: number): string;

    hexEncode(): string;

    hexDecode(): string;
  }
}

String.prototype.padZero = function (length: number) {
  let d = String(this);
  while (d.length < length) {
    d = '0' + d;
  }
  return d;
};

String.prototype.hexEncode = function () {
  let hex;
  let i;

  let result = '';
  for (i = 0; i < this.length; i++) {
    hex = this.charCodeAt(i).toString(16);
    result += ('000' + hex).slice(-4);
  }

  return result;
};

String.prototype.hexDecode = function () {
  let j;
  const hexes = this.match(/.{1,4}/g) || [];
  let back = '';
  for (j = 0; j < hexes.length; j++) {
    back += String.fromCharCode(parseInt(hexes[j], 16));
  }

  return back;
};

@Injectable({
  providedIn: 'root',
})
export class PaytronixProviderService implements UserProvider, LoyaltyProvider, MessagingProvider, GiftCardPurchaseProvider {
  private authTokenKey = LocalStorageKey.PAYTRONIX_AUTH_TOKEN;
  private accessTokenKey = LocalStorageKey.PAYTRONIX_ACCESS_TOKEN;
  private readNotificationIdsKey = LocalStorageKey.PAYTRONIX_READ_NOTIFICATIONS;
  private oloAuthTokenKey = LocalStorageKey.OLO_AUTH_TOKEN;
  private oloAccessTokenKey = LocalStorageKey.OLO_ACCESS_TOKEN;
  private basketGuidKey = 'OLOBasketGuid';

  private baseContentURL = 'https://pxsweb.com';
  private sandboxBaseContentURL = 'https://pxslab.com';

  private ssoLoginSubject = new BehaviorSubject<SSOLogin>(null);
  ssoLogin$ = this.ssoLoginSubject.asObservable();

  userInfo: UserInfoResponse;

  private deviceString: string;

  private paytronixSettings: PaytronixConfiguration;

  finishPushRegistration = (token: Token) => {
    // PushNotifications.removeAllDeliveredNotifications();
    this.directus.getPaytronixSettings().subscribe(paytronixSettings => {
      this.getLoggedInUserInfoRaw().subscribe(user => {
        from(App.getInfo()).subscribe(appInfo => {
          from(Device.getInfo()).subscribe(device => {
            const accountInfo: EditExternalAccountsRequest = {
              operation: 'set',
              // tslint:disable-next-line:max-line-length
              constrainByIntegration:
                device.operatingSystem === 'ios'
                  ? paytronixSettings.ios_push_integration_id
                  : paytronixSettings.android_push_integration_id,
              externalAccounts: [
                {
                  appIdentifier: appInfo.id,
                  integrationDetail: this.deviceString,
                  accountCode: token.value,
                  // tslint:disable-next-line:max-line-length
                  integration:
                    device.operatingSystem === 'ios'
                      ? paytronixSettings.ios_push_integration_id
                      : paytronixSettings.android_push_integration_id,
                },
              ],
              printedCardNumber: user.primaryCardNumbers[0],
            };
            this.apiService.editExternalAccounts(accountInfo).subscribe(
              res => {},
              error => console.log('error', error)
            );
          });
        });
      });
    });
  };

  alertOfPushNotifications = (notification: PushNotificationSchema) => {
    if (notification.data && notification.data.message_source !== 'flybuy') {
      LocalNotifications.schedule({
        notifications: [
          {
            title: notification.data
              ? notification.data.title
                ? notification.data.title
                : 'Notification'
              : notification.title
                ? notification.title
                : 'Notification',
            body: notification.data ? notification.data.message : notification.body,
            id: Number((Math.random() * 1000).toFixed(0)),
            schedule: {
              at: notification.data.time_to_live ? new Date(Date.now() + notification.data.time_to_live) : new Date(Date.now() + 2000),
            },
          },
        ],
      });
    }
  };

  constructor(
    private apiService: PaytronixAPIService,
    private mappingService: PaytronixMappingService,
    private oloAPI: OloAPIService,
    private oloMapping: OLOMappingService,
    private contentService: ImageContentService,
    private directus: DirectusService,
    private orderService: OrderService,
    private zone: NgZone
  ) {
    if (Capacitor.getPlatform() !== 'web') {
      PushNotifications.addListener('registration', (token: Token) => this.zone.run(() => this.finishPushRegistration(token)));
      if (Capacitor.getPlatform() === 'android') {
        PushNotifications.addListener('pushNotificationReceived', this.alertOfPushNotifications);
      }
    }
  }

  getMessages(userID: string): Observable<InboxMessage[]> {
    return this.getLoggedInUserInfoRaw().pipe(
      switchMap(user => {
        return this.apiService.getNotificationsForGuestByPrintedCardNumber(user.primaryCardNumbers[0]).pipe(
          switchMap(res => {
            return this.getReadNotificationIds().pipe(
              map(readNotificationIds => {
                return res.messages.map(notification =>
                  this.mappingService.ptxNotificationToInboxMessage(notification, readNotificationIds)
                );
              })
            );
          })
        );
      })
    );
  }

  markMessageAsRead(userID: string, messageID: string): Observable<InboxMessage[]> {
    return this.getReadNotificationIds().pipe(
      switchMap(readNotificationIds => {
        readNotificationIds.push(messageID);
        return this.setReadNotificationIds(readNotificationIds);
      }),
      concatMap(() => this.getMessages(userID))
    );
  }

  deleteMessage(userID: string, messageID: string): Observable<InboxMessage[]> {
    return this.getLoggedInUserInfoRaw().pipe(
      switchMap(user => {
        return this.apiService.deleteNotificationForGuestByPrintedCardNumber(user.primaryCardNumbers[0], messageID).pipe(
          concatMap(() => {
            return this.getMessages(userID);
          })
        );
      })
    );
  }

  deleteAccount(userID: string): Observable<boolean> {
    const cardNumber = this.userInfo.primaryCardNumbers[0];
    return this.apiService.deleteAccount(cardNumber).pipe(
      map(() => {
        return true;
      })
    );
  }

  getLoyaltyLocations(): Observable<LoyaltyLocation[]> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(paytronixSettings => {
        if (paytronixSettings.store_group_code) {
          return this.apiService.getLocationsByGroup(paytronixSettings.store_group_code).pipe(
            map(res => {
              return res.locations.map(location => this.mappingService.ptxStoreToLoyaltyLocation(location));
            })
          );
        } else {
          return this.apiService.getLocations().pipe(
            map(res => {
              return res.locations.map(location => this.mappingService.ptxStoreToLoyaltyLocation(location));
            })
          );
        }
      })
    );
  }

  getBalance(cardNumber: string, pin: string): Observable<number | any> {
    return this.apiService.getGiftCardBalance(cardNumber, pin);
  }

  getTransactionHistory(cardNumber: string, pin: string): Observable<TransactionResponse | any> {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 90);
    const dd = String(startDate.getDate()).padStart(2, '0');
    const mm = String(startDate.getMonth() + 1).padStart(2, '0');
    const yyyy = startDate.getFullYear();
    const dateStart = yyyy + '-' + mm + '-' + dd;
    return this.apiService.getTransactionHistory(cardNumber, pin, dateStart);
  }

  purchaseCards(order: GiftCardOrder, calculateResponse: CalculatePriceResponse): Observable<any> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(ptxSettings => {
        const saleRequest = this.mappingService.giftCardOrderToExecuteSaleRequest(order, calculateResponse, ptxSettings);
        return this.apiService.executeGiftCardSale(saleRequest);
      })
    );
  }

  getItems(flowType: string): Observable<GiftCardConfig> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(pSettings => {
        return this.apiService.getGiftCardSaleConfig(flowType).pipe(
          map((saleConfig: SaleConfigResponse) => {
            if (flowType === 'EGIFT') {
              return this.mappingService.saleConfigResponseToEGiftConfig(saleConfig);
            }
            if (flowType === 'GIFT_CARD') {
              return this.mappingService.saleConfigResponseToGiftCardConfig(saleConfig, pSettings.oauth_base_url);
            }
          })
        );
      })
    );
  }

  getPrices(order: GiftCardOrder): Observable<any> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(ptxSettings => {
        const priceRequest = this.mappingService.giftCardOrderToCalculatePriceRequest(order, ptxSettings);
        return this.directus.getPaytronixSettings().pipe(
          switchMap(ptxSettings => {
            return this.apiService.calculateGiftCardPrice(priceRequest).pipe(
              map(res => {
                return this.mappingService.calculatePriceResponseImageMapping(res as CalculatePriceResponse, ptxSettings);
              })
            );
          })
        );
      })
    );
  }

  reloadGiftCard(rechargeOrder: RechargeOrder): Observable<any> {
    const rechargeRequest = this.mappingService.giftCardDetailsToRechargeRequest(rechargeOrder);
    return this.apiService.rechargeGiftCard(rechargeRequest);
  }

  registerApp(): Observable<boolean> {
    if (Capacitor.getPlatform() !== 'web') {
      return this.directus.getPaytronixSettings().pipe(
        switchMap(paytronixSettings => {
          return this.getLoggedInUserInfoRaw().pipe(
            switchMap(user => {
              return this.getAuthToken().pipe(
                switchMap(auth => {
                  if (auth && user) {
                    return from(PushNotifications.checkPermissions()).pipe(
                      switchMap(permissionStatus => {
                        if (permissionStatus.receive === 'granted') {
                          return this.apiService.getAccountInfo(auth.access_token, String(user.accountIds[0])).pipe(
                            switchMap(() => {
                              return from(App.getInfo()).pipe(
                                switchMap(appInfo => {
                                  return from(Device.getInfo()).pipe(
                                    switchMap(result => {
                                      this.deviceString = `os=${result.operatingSystem}|osversion=${result.osVersion}|device=${result.model}|merchantId=${paytronixSettings.merchant_id}|integrator=DineEngine|version=${appInfo.version}`;
                                      return from(PushNotifications.register()).pipe(switchMap(() => of(true)));
                                    })
                                  );
                                })
                              );
                            })
                          );
                        } else {
                          return from(PushNotifications.requestPermissions()).pipe(
                            switchMap(permission => {
                              if (permission.receive === 'granted') {
                                return this.apiService.getAccountInfo(auth.access_token, String(user.accountIds[0])).pipe(
                                  switchMap(() => {
                                    return from(App.getInfo()).pipe(
                                      switchMap(appInfo => {
                                        return from(Device.getInfo()).pipe(
                                          switchMap(result => {
                                            this.deviceString = `os=${result.operatingSystem}|osversion=${result.osVersion}|device=${result.model}|merchantId=${paytronixSettings.merchant_id}|integrator=DineEngine|version=${appInfo.version}`;
                                            return from(PushNotifications.register()).pipe(switchMap(() => of(true)));
                                          })
                                        );
                                      })
                                    );
                                  })
                                );
                              } else {
                                return of(false);
                              }
                            })
                          );
                        }
                      })
                    );
                  } else {
                    return of(false);
                  }
                })
              );
            })
          );
        })
      );
    } else {
      return of(false);
    }
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<string> {
    return this.apiService.loginWithFacebook(email, accessToken, userID).pipe(
      switchMap(user => {
        return this.storeAuthToken(user).pipe(
          map(() => {
            return user.access_token;
          })
        );
      }),
      catchError(err => {
        return throwError(this.mappingService.loginError(err, true));
      })
    );
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    return this.getLoggedInUserInfoRaw().pipe(
      switchMap(user => {
        return this.apiService.connectWithFacebook(user.primaryCardNumbers[0], accessToken, userID);
      })
    );
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    return this.apiService.loginWithApple(appleResult).pipe(
      switchMap(user => {
        return this.storeAuthToken(user).pipe(
          map(() => {
            return user.access_token;
          })
        );
      }),
      catchError(err => {
        return from(App.getInfo()).pipe(
          switchMap(info => {
            return this.apiService.signUpWithApple(appleResult, info.id).pipe(
              switchMap(user => {
                return this.storeAuthToken(user).pipe(
                  map(() => {
                    return user.access_token;
                  })
                );
              }),
              catchError(error => {
                console.log(error);
                return throwError(error);
              })
            );
          })
        );
      })
    );
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string) {
    return this.getLoggedInUserInfoRaw().pipe(
      switchMap(user => {
        return from(App.getInfo()).pipe(
          switchMap(info => {
            return this.apiService.connectWithApple(appleResult, info.id, user.primaryCardNumbers[0]).pipe(
              switchMap(userAuth => {
                return this.storeAuthToken(userAuth).pipe(
                  map(() => {
                    return userAuth.access_token;
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  getSSOLoginSubject(): Observable<SSOLogin> {
    return this.ssoLogin$;
  }

  logIn(username: string, password: string): Observable<string> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(paytronixSettings => {
        return this.apiService.logIn(username, password, Number(paytronixSettings.enroll_card_template)).pipe(
          switchMap(user => {
            return this.storeAuthToken(user).pipe(
              map(() => {
                return user.access_token;
              })
            );
          }),
          catchError(err => {
            return throwError(this.mappingService.loginError(err));
          })
        );
      })
    );
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    return this.apiService.loginWithToken(token, redirectURL).pipe(
      switchMap(user => {
        return this.storeAuthToken(user).pipe(
          map(() => {
            return user.access_token;
          })
        );
        // this.storeAuthToken(user);
        // return of(user.access_token);
      }),
      catchError(err => {
        return throwError(this.mappingService.loginError(err));
      })
    );
  }

  logInByRefreshToken(rtoken): Observable<UserLogInResponse> {
    return this.apiService.logInByRefreshToken(rtoken).pipe(
      switchMap(user => {
        return this.storeAuthToken(user).pipe(
          map(() => {
            return user;
          })
        );
        // this.storeAuthToken(user);
        // return of(user);
      })
    );
  }

  logOut(userID: string): Observable<string> {
    this.deleteAuthToken();
    this.deleteOLOAuthToken();
    this.deleteOLOAccessToken();
    sessionStorage.removeItem('PaytronixCardNum');
    sessionStorage.removeItem('PaytronixAccountID');
    this.userInfo = null;
    return of(null);
  }

  forgotPassword(email: string): Observable<any> {
    return this.apiService.sendForgotPasswordEmail(email);
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  redeemPointsFromScanner(barCode: string): Observable<any> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        if (!auth) {
          return of(null);
        }
        const token = auth.access_token;
        const username = auth.username;
        return this.loadOrGetUserInfo(token, username).pipe(
          switchMap(user => {
            const cardNumber = this.userInfo.primaryCardNumbers[0];
            const accountID =
              (user.accountIds && user.accountIds.length) > 0
                ? user.accountIds[0].toString()
                : sessionStorage.getItem('PaytronixAccountID');
            return this.apiService.applyVisitCode(barCode, accountID, cardNumber).pipe(
              map(response => {
                if (response.result !== 'failure') {
                  return response;
                } else {
                  throw new Error(response.errorMessage);
                }
              })
            );
          })
        );
      })
    );
  }

  getResetPasswordCode(email: string): Observable<any> {
    return this.apiService.sendResetPasswordEmail(email);
  }

  resetPassword(newPassword: string): Observable<PassResetResponse> {
    const resetPasswordCode = sessionStorage.getItem('resetPasswordCode');
    return this.apiService.resetPassword(resetPasswordCode, newPassword);
  }

  // tslint:disable-next-line: max-line-length
  updateUserInfo(user: User, additionalFields: UserField[]): Observable<User> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        const req: UpdateAccount = {
          access_token: auth.access_token,
          client_id: '',
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          phone: user.phoneNumber,
          email_optin: user.emailOptIn,
          card_number: '',
          sms_optin: user.sMSOptIn,
          location_code: user.favoriteLocation.locationID,
          date_of_birth: moment(user.birthday).format('YYYY-MM-DD'),
        };
        return this.getLoggedInUserInfoRaw().pipe(
          switchMap(res => {
            req.card_number =
              (res.primaryCardNumbers && res.primaryCardNumbers.length) > 0
                ? res.primaryCardNumbers[0]
                : sessionStorage.getItem('PaytronixCardNum');
            return this.apiService.updateUser(req, additionalFields).pipe(
              switchMap(() => {
                this.userInfo = null;
                return this.getLoggedInUserInfo();
              })
            );
          })
        );
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  createAccount(newAccount: CreateAccount, additionalFields: UserField[]): Observable<User> {
    const req: CreateAccount = {
      email: newAccount.email,
      dob: newAccount.dob,
      firstName: newAccount.firstName,
      lastName: newAccount.lastName,
      password: newAccount.password,
      phone: newAccount.phone,
      emailOptIn: newAccount.emailOptIn,
      smsOptIn: newAccount.smsOptIn,
      favoriteLocation: newAccount.favoriteLocation,
    };
    return this.apiService.createUser(req, additionalFields).pipe(
      switchMap(user => {
        return this.storeAuthToken(user).pipe(
          switchMap(() => {
            return this.getLoggedInUserInfo();
          })
        );
      })
    );
  }

  logInAsGuest(): Observable<string> {
    return of(null);
  }

  getUserInfo(userID: string): Observable<User> {
    return this.getLoggedInUserInfo();
  }

  getUserInfoEx(token: string, username: string): Observable<User> {
    return this.loadOrGetUserInfo(token, username).pipe(
      switchMap(res => {
        return this.getLoyaltyLocations().pipe(
          switchMap(locations => {
            return this.apiService.getAccountInfo(token, String(res.accountIds[0])).pipe(
              map(info => {
                return this.mappingService.infoToUser(res, info, locations);
              })
            );
          })
        );
      })
    );
  }

  getLoggedInUserInfoRaw(): Observable<UserInfoResponse> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        if (!auth) {
          return of(null);
        }
        const token = auth.access_token;
        const username = auth.username;
        return this.tokenNeedsRefresh().pipe(
          switchMap(tokenRefresh => {
            if (tokenRefresh) {
              const rtoken = auth.refresh_token;
              return this.logInByRefreshToken(rtoken).pipe(
                switchMap(res => {
                  return this.storeAuthToken(res).pipe(
                    switchMap(() => {
                      return this.loadOrGetUserInfo(res.access_token, res.username);
                    })
                  );
                })
              );
            } else {
              return this.getAuthToken().pipe(
                switchMap(newAuth => {
                  return this.storeAuthToken(newAuth).pipe(
                    switchMap(() => {
                      return this.loadOrGetUserInfo(token, username);
                    })
                  );
                })
              );
            }
          })
        );
      })
    );
  }

  getLoggedInUserInfo(): Observable<User> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        return this.getAccessToken().pipe(
          switchMap(access => {
            if (!auth) {
              return of(null);
            }
            const token = access;
            const username = auth.username;
            return this.tokenNeedsRefresh().pipe(
              switchMap(tokenRefresh => {
                if (tokenRefresh) {
                  const rtoken = auth.refresh_token;
                  return this.logInByRefreshToken(rtoken).pipe(
                    switchMap(res => {
                      return this.setTokenAndGetInfo(token, res.username);
                    })
                  );
                } else {
                  return this.setTokenAndGetInfo(token, username);
                }
              })
            );
          })
        );
      })
    );
  }

  setTokenAndGetInfo(token: string, username: string) {
    const ssoToken: SSOLogin = {
      provider: SSOProvider.paytronix,
      token,
    };
    this.ssoLoginSubject.next(ssoToken);
    return this.getUserInfoEx(token, username);
  }

  is3rdPartyWrapped(): boolean {
    return localStorage.getItem('is3rdPartyWrapped') === 'true';
  }

  isOauthEnabled(): Observable<boolean> {
    return this.apiService.isOauthEnabled();
  }

  redirectToOauthPage() {
    return this.apiService.redirectToOauthPage();
  }

  cateringLink(): Observable<CateringLink> {
    const link: CateringLink = {
      enabled: false,
      link: '',
      linkGuest: '',
      clientId: '',
    };
    return of(link);
  }

  getPointsBalance(userID: string | number): Observable<RewardsBalances> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        if (!auth) {
          return of(null);
        }

        const token = auth.access_token;
        const username = auth.username;

        return this.loadOrGetUserInfo(token, username).pipe(
          switchMap(user => {
            // tslint:disable-next-line:max-line-length
            return this.directus.getSettings().pipe(
              switchMap(settings => {
                return this.directus.getPaytronixSettings().pipe(
                  switchMap(paytronixSettings => {
                    return this.apiService
                      .getAccountInfo(
                        token,
                        // tslint:disable-next-line:max-line-length
                        user.accountIds && user.accountIds.length > 0
                          ? user.accountIds[0].toString()
                          : sessionStorage.getItem('PaytronixAccountID')
                      )
                      .pipe(
                        map(balance => {
                          // tslint:disable-next-line:max-line-length
                          return this.mappingService.accountBalanceToRewardsBalances(
                            balance,
                            parseInt(settings.loyalty_points_threshold, 10),
                            paytronixSettings.home_wallet_code
                          );
                        })
                      );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  getOffers(userID: string | number): Observable<any> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        if (!auth) {
          return of(null);
        }
        // const token = auth.access_token;
        // const username = auth.username;
        // this.loadOrGetUserInfo(token, username).pipe(switchMap(user => {
        // return this.apiService.getAccountOffers(token, user.accountIds[0].toString());
        return of([]);
        // }));
      })
    );
  }

  getLoyaltyActivity(): Observable<HistoryEvent[]> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        if (!auth) {
          return of(null);
        }

        const token = auth.access_token;
        const username = auth.username;

        return this.loadOrGetUserInfo(token, username).pipe(
          switchMap(user => {
            // tslint:disable-next-line:max-line-length
            return this.apiService
              .getAccountHistory(
                token,
                // tslint:disable-next-line:max-line-length
                (user.accountIds && user.accountIds.length) > 0
                  ? user.accountIds[0].toString()
                  : sessionStorage.getItem('PaytronixAccountID')
              )
              .pipe(
                map(history => {
                  return this.mappingService.accountHistoryToHistoryEvents(history);
                })
              );
          })
        );
      })
    );
  }

  earnPoints(userID: string | number): Observable<any> {
    // check/postAndAccruePoints.json <-- this is also a checkin, but a posthumous so to speak.
    // it needs to be implemented in a new loyalty function because punchh has requested this as a feature
    return this.getAuthToken().pipe(
      switchMap(auth => {
        if (!auth) {
          return of(null);
        }

        const token = auth.access_token;
        const username = auth.username;

        return this.loadOrGetUserInfo(token, username).pipe(
          switchMap(user => {
            return this.apiService.createUserCheckin(token, user.primaryCardNumbers[0].toString(), new Date());
          })
        );
      })
    );
  }

  getRewards(userID: string, locationID: string): Observable<Reward[]> {
    return this.getOLOAuthToken().pipe(
      switchMap(oloAuth => {
        if (oloAuth) {
          return this.oloAPI.getUsersQualifyingRewards(oloAuth, locationID).pipe(
            map(res => {
              return res.rewards.map(r => this.oloMapping.toReward(r));
            })
          );
        } else {
          return this.orderService.getService().pipe(
            switchMap(oService => {
              if (oService instanceof OLOProviderService) {
                return this.getSSOLoginSubject().pipe(
                  switchMap(token => {
                    return this.getOloSSOToken(token.token).pipe(
                      switchMap(t => {
                        token.token = t.access_token;
                        return oService.completeSSOLoginObservable(token).pipe(
                          switchMap(oloToken => {
                            return this.oloAPI.getUsersQualifyingRewards(oloToken, locationID).pipe(
                              map(res => {
                                return res.rewards.map(r => this.oloMapping.toReward(r));
                              })
                            );
                          })
                        );
                      })
                    );
                  })
                );
              } else {
                return of(null);
              }
            })
          );
        }
      })
    );
  }

  getAvailableLoyaltyRewards(userID: string): Observable<LoyaltyReward[]> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        const token = auth.access_token;
        const username = auth.username;

        return this.loadOrGetUserInfo(token, username).pipe(
          switchMap(user => {
            // tslint:disable-next-line:max-line-length
            return this.directus.getPaytronixSettings().pipe(
              switchMap(pSettings => {
                return this.apiService
                  .getSaleConfigurationForAccount(
                    WebSaleProgramType.REWARDS_FROM_POINTS,
                    auth.printedCardNumber ? auth.printedCardNumber : sessionStorage.getItem('PaytronixCardNum')
                  )
                  .pipe(
                    switchMap(saleConfig => {
                      return this.apiService
                        .getAccountInfo(
                          token,
                          // tslint:disable-next-line:max-line-length
                          user.accountIds && user.accountIds.length > 0
                            ? user.accountIds[0].toString()
                            : sessionStorage.getItem('PaytronixAccountID')
                        )
                        .pipe(
                          switchMap(balance => {
                            return this.getLoyaltyLocations().pipe(
                              switchMap(locations => {
                                return this.apiService
                                  .getRewardDetails(
                                    locations[0].locationID,
                                    balance.rewardBalances.map(r => r.walletCode)
                                  )
                                  .pipe(
                                    map(rewards => {
                                      // tslint:disable-next-line:max-line-length
                                      return this.mappingService.rewardsBalancesToRewards(
                                        balance.rewardBalances.concat(balance.memberRewardBalances ?? []),
                                        saleConfig,
                                        pSettings.sandbox_mode ? this.sandboxBaseContentURL : this.baseContentURL,
                                        rewards.wallets
                                      );
                                    }),
                                    catchError(err => {
                                      return of(
                                        this.mappingService.rewardsBalancesToRewards(
                                          balance.rewardBalances.concat(balance.memberRewardBalances ?? []),
                                          saleConfig,
                                          pSettings.sandbox_mode ? this.sandboxBaseContentURL : this.baseContentURL,
                                          []
                                        )
                                      );
                                    })
                                  );
                              })
                            );
                          })
                        );
                    })
                  );
              })
            );
          })
        );
      })
    );
  }

  redeemReward(reward: Reward): Observable<Order | any> {
    return this.oloAPI.applyRewards(this.getBasketGuid(), reward.membershipID, [reward.externalRef.toString()]).pipe(
      switchMap(() => {
        return this.oloAPI.validateBasket(this.getBasketGuid(), false).pipe(
          switchMap(() => {
            return this.mapBasketResponseToOrder();
          })
          //     , catchError(err => {
          //   return this.mapBasketResponseToOrder().pipe(switchMap(order => {
          //     return of({
          //       err,
          //       order,
          //     });
          //   }));
          // })
        );
      })
    );
  }

  removeAppliedReward(reward: Reward): Observable<Order | any> {
    return this.oloAPI.removeAppliedReward(this.getBasketGuid(), Number(reward.rewardID)).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  mapBasketResponseToOrder() {
    return this.oloAPI.getBasket(this.getBasketGuid()).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  redeemInStoreReward(reward: Reward): Observable<Reward> {
    // return this.punchhAPI.getAccountBalance(this.getPunchhAuthToken()).pipe(switchMap(res => {
    //   const rewardID = res.rewards.find(rwrd => rwrd.redeemable_id === parseInt(reward.externalRef, 10)).id;
    //   return this.punchhAPI.redeemReward(rewardID, this.getPunchhAuthToken()).pipe(map(redemption => {
    //     reward.redemptionCode = redemption.internal_tracking_code;
    //     reward.expiryHours = redemption.expiry_hours;
    //     return reward;
    //   }));
    // }));
    throw new Error('Method not implemented.');
  }

  redeemBankedPoints(points: number): Observable<Reward> {
    // return this.punchhAPI.redeemPoints(points, this.getPunchhAuthToken()).pipe(map(redemption => {
    //   return {
    //     redemptionCode: redemption.internal_tracking_code,
    //     expiryHours: redemption.expiry_hours
    //   } as Reward;
    // }));
    throw new Error('Method not implemented.');
  }

  voidBankedPoints(reward: DollarReward): Observable<any> {
    // return this.punchhAPI.voidPoints(reward.rewardID, this.getPunchhAuthToken());
    throw new Error('Method not implemented.');
  }

  checkInAtStore(): Observable<CheckInCode> {
    return from(Geolocation.getCurrentPosition({ enableHighAccuracy: true })).pipe(
      switchMap(result => {
        return this.apiService.getNearbyLocations(result.coords.latitude, result.coords.longitude, 1, 1).pipe(
          switchMap(res => {
            if (res.locations.length === 0) {
              return throwError('No Locations Nearby');
            } else {
              return this.getLoggedInUserInfoRaw().pipe(
                switchMap(user => {
                  return this.apiService
                    .checkInAtStore(res.locations[0].code, {
                      printedCardNumber: user.primaryCardNumbers[0],
                    })
                    .pipe(
                      map(res2 => {
                        return {
                          code: res2.shortCardNumber,
                          expirationDate: res2.expirationTime ? moment(res2.expirationTime).toDate() : null,
                        };
                      })
                    );
                })
              );
            }
          })
        );
      }),
      catchError(err => {
        if (err === 'No Locations Nearby') {
          return throwError(err);
        } else {
          return throwError('Could not get Geolocation. Please make sure your location permissions are enabled.');
        }
      })
    );
  }

  getPurchaseableRewards(): Observable<PurchaseableReward[]> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        return this.directus.getPaytronixSettings().pipe(
          switchMap(pSettings => {
            return this.apiService
              .getSaleConfigurationForAccount(
                WebSaleProgramType.REWARDS_FROM_POINTS,
                auth.printedCardNumber ? auth.printedCardNumber : sessionStorage.getItem('PaytronixCardNum')
              )
              .pipe(
                map(saleConfig => {
                  // tslint:disable-next-line:max-line-length
                  return saleConfig.program?.itemConfigs?.map(item =>
                    this.mappingService.itemConfigToPurchaseableReward(
                      item,
                      pSettings.sandbox_mode ? this.sandboxBaseContentURL : this.baseContentURL
                    )
                  );
                })
              );
          })
        );
      })
    );
  }

  purchaseReward(reward: PurchaseableReward, variation?: Variation): Observable<boolean> {
    return this.getAuthToken().pipe(
      switchMap(auth => {
        const calculateBody: CalculatePriceForAccountRequest = {
          merchantId: null,
          programType: WebSaleProgramType.REWARDS_FROM_POINTS,
          printedCardNumber: auth.printedCardNumber ? auth.printedCardNumber : sessionStorage.getItem('PaytronixCardNum'),
          orderItemGroups: [this.mappingService.purchaseableRewardToOrderItemGroup(reward, variation)],
        };
        return this.apiService.calculatePriceForAccount(calculateBody).pipe(
          switchMap(calcRes => {
            const body: ExecuteSaleForAccountRequest = {
              merchantId: null,
              programType: WebSaleProgramType.REWARDS_FROM_POINTS,
              printedCardNumber: auth.printedCardNumber ? auth.printedCardNumber : sessionStorage.getItem('PaytronixCardNum'),
              billingContact: null,
              billingAddress: null,
              shippingSameAsBilling: true,
              paymentMethod: {
                paymentMethodType: PaymentMethodType.POINTS,
                cardType: null,
              },
              totalPrice: calcRes.order.totalPrice,
              orderItemGroups: [this.mappingService.purchaseableRewardToOrderItemGroup(reward, variation)],
            };
            return this.apiService.executeSaleForAccount(body).pipe(
              map(orderRes => {
                return orderRes.result === 'success';
              })
            );
          })
        );
      })
    );
  }

  canTransferGiftCardToAccount(): Observable<boolean> {
    return this.directus.getPaytronixSettings().pipe(map(pConfig => !!pConfig.allow_giftcard_transfer_to_loyalty));
  }

  transferGiftCardBalanceToAccount(from: GiftCard): Observable<{ success: boolean }> {
    // tslint:disable-next-line:max-line-length
    return this.apiService.transferBalances(from.cardNumber, from.cardPin, sessionStorage.getItem('PaytronixCardNum')).pipe(
      switchMap(res => {
        if (res.result === 'failure') {
          return throwError(this.mappingService.balanceTransferErrorToDEError(res as BalanceTransferServiceError));
        } else {
          return of({
            success: res.result === 'success',
          });
        }
      }),
      catchError(err => {
        return throwError(this.mappingService.balanceTransferErrorToDEError(err));
      })
    );
  }

  getLoyaltyStoredValueCardInfo(userID: string): Observable<GiftCard> {
    return of({
      cardNumber: sessionStorage.getItem('PaytronixCardNum'),
      cardPin: null,
      balance: null,
    });
  }

  getReferrals(userID: string): Observable<Referral[]> {
    return this.apiService.getListOfReferralsForUserByPrintedCardNumber(sessionStorage.getItem('PaytronixCardNum')).pipe(
      map(res => {
        return res.userReferrals.map(r => this.mappingService.ptxUserReferralToReferral(r));
      })
    );
  }

  sendReferrals(userID: string, emails: string[], message: string): Observable<Referral[]> {
    return this.apiService.addReferralsByPrintedCardNumber(sessionStorage.getItem('PaytronixCardNum'), emails, message).pipe(
      map(res => {
        return res.userReferrals.map(r => this.mappingService.ptxUserReferralToReferral(r));
      })
    );
  }

  getOloSSOToken(token: string): Observable<UserLogInResponse> {
    return this.loadOrGetUserInfo(token, null).pipe(
      switchMap(user => {
        return this.apiService.getOloSSOToken(token, user.primaryCardNumbers[0]);
      })
    );
  }

  private getLocation(locationID: number): Observable<Location> {
    return this.oloAPI.getRestaurant(locationID).pipe(
      switchMap(restaurant => {
        const loc = this.oloMapping.restaurantToLocation(restaurant);
        return this.contentService.getLocationWithMapIconURL(loc).pipe(
          switchMap(location => {
            return this.getLocationWithHours(location).pipe(
              switchMap(locWithHours => {
                return this.contentService.getSingleLocationWithSlug(locWithHours);
              })
            );
          })
        );
      })
    );
  }

  private getLocationWithHours(location: Location): Observable<Location> {
    // tslint:disable-next-line:max-line-length
    return this.oloAPI.getRestaurantOperatingHoursForThisWeek(Number(location.locationID), location.orderAheadDays).pipe(
      map(calendarRes => {
        return this.oloMapping.getLocationWithHours(location, calendarRes.calendar);
      })
    );
  }

  private loadOrGetUserInfo(token: string, username: string): Observable<UserInfoResponse> {
    if (this.userInfo) {
      return of(this.userInfo);
    } else {
      if (username) {
        return this.apiService.getUserInfo(token, username).pipe(
          map(user => {
            this.userInfo = user;
            return user;
          })
        );
      } else {
        return this.getAuthToken().pipe(
          switchMap(auth => {
            if (auth.username) {
              return this.loadOrGetUserInfo(token, auth.username);
            } else {
              // tslint:disable-next-line:max-line-length

              if (auth.printedCardNumber) {
                return this.apiService
                  .getUserInfoByCardNumber(
                    // tslint:disable-next-line:max-line-length
                    auth.printedCardNumber && Array.isArray(auth.printedCardNumber) ? auth.printedCardNumber[0] : auth.printedCardNumber
                  )
                  .pipe(
                    tap(user => {
                      this.userInfo = user;
                    }),
                    map(user => {
                      return user;
                    }),
                    catchError(error => {
                      return throwError(error);
                    })
                  );
              } else {
                return throwError('No username or card number found');
              }
            }
          })
        );
      }
    }
  }

  private tokenNeedsRefresh(): Observable<boolean> {
    return this.getAuthToken().pipe(
      map(auth => {
        if (!auth) {
          return false;
        }
        return auth.expires_date < Date.now();
      })
    );
  }

  private storeAuthToken(auth: UserLogInResponse): Observable<void> {
    if (!auth) {
      return of();
    }
    if (!auth.expires_date) {
      auth.expires_date = Date.now() + auth.expires_in * 1000;
    }
    return from(Storage.set({ key: this.accessTokenKey, value: auth.access_token })).pipe(
      switchMap(() => {
        return from(Storage.set({ key: this.authTokenKey, value: JSON.stringify(auth) }));
      })
    );
    // localStorage.setItem(this.authTokenKey, JSON.stringify(auth));
  }

  private deleteAuthToken() {
    Storage.remove({ key: this.authTokenKey }).then(() => console.log('auth deleted'));
    // localStorage.removeItem(this.authTokenKey);
  }

  getAuthToken(): Observable<UserLogInResponse> {
    return from(Storage.get({ key: this.authTokenKey })).pipe(
      switchMap(data => {
        const auth: UserLogInResponse = JSON.parse(data.value);
        if (auth) {
          // is right now before expiration?
          if (auth.expires_date && moment().isBefore(moment(auth.expires_date))) {
            // return from storage
            return of(auth);
          } else {
            // get refresh token
            const refresh = auth.refresh_token;
            // refresh token through API
            return this.apiService.logInByRefreshToken(refresh).pipe(
              switchMap(newAuth => {
                // save new token values
                return this.storeAuthToken(newAuth).pipe(
                  switchMap(() => {
                    // rerun this function with new expiration values
                    return this.getAuthToken();
                  })
                );
              })
            );
          }
        } else {
          return of(null);
        }
      })
    );
  }

  // private deleteAccessToken() {
  //   Storage.remove({key: this.accessTokenKey}).then(() => console.log('auth deleted'));
  //   // localStorage.removeItem(this.accessTokenKey);
  // }

  private getAccessToken(): Observable<string> {
    return from(Storage.get({ key: this.accessTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
  }

  private getOLOAuthToken(): Observable<string> {
    return from(Storage.get({ key: this.oloAuthTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.oloAuthTokenKey);
  }

  private deleteOLOAuthToken(): void {
    Storage.remove({ key: this.oloAuthTokenKey });
    // localStorage.removeItem(this.oloAuthTokenKey);
  }

  // private getOLOAccessToken(): Observable<string> {
  //   return from(Storage.get({key: this.oloAccessTokenKey})).pipe(map(data => {
  //     return data.value;
  //   }));
  //   // return localStorage.getItem(this.oloAccessTokenKey);
  // }

  private deleteOLOAccessToken(): void {
    Storage.remove({ key: this.oloAccessTokenKey });
    // localStorage.removeItem(this.oloAccessTokenKey);
  }

  private getBasketGuid(): string {
    return sessionStorage.getItem(this.basketGuidKey);
  }

  private getReadNotificationIds(): Observable<string[]> {
    return from(Storage.get({ key: this.readNotificationIdsKey })).pipe(
      map(data => {
        return data.value ? JSON.parse(data.value) : [];
      })
    );
  }

  private setReadNotificationIds(ids: string[]): Observable<void> {
    return from(
      Storage.set({
        key: this.readNotificationIdsKey,
        value: JSON.stringify(ids),
      })
    );
  }

  supportsReferrals(): boolean {
    return true;
  }
}
