import { Injectable } from '@angular/core';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Order } from '../../../interfaces/order.interface';
import { Location } from '../../../interfaces/location.interface';
import { CardDetails } from '../../../interfaces/card-details.interface';
import { PaymentTypes } from '../../../interfaces/payment-types.enum';
import { Event, NavigationEnd, Router } from '@angular/router';
import { AddressService } from '@modules/utility/services/address.service';
import { Capacitor } from '@capacitor/core';
import { UtilityService } from '@modules/utility/services/utility.service';
// import {NgxHotjarService} from 'ngx-hotjar';
import { MainSettings } from '../../../vendors/directus/interfaces/main-settings.interface';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Category } from '../../../interfaces/category.interface';
import { OrderItem, Product } from '../../../interfaces/product.interface';
import { Reward } from 'src/interfaces/reward.interface';
import { GTMImpression, GTMItem, GTMProduct, GTMPromotion } from '@app/services/analytics/interfaces/gtm.event';
import { Option } from '../../../interfaces/option.interface';
import { DirectusService } from '../../../vendors/directus/directus.service';
import { Platform } from '@ionic/angular';

// import posthog from 'posthog-js';
import { ProjectKeyService } from '../../../services/project-key.service';
import { User } from '../../../interfaces/user.interface';
import { HandoffType } from '../../../interfaces/handoff-type.enum';
import { Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../store/state.model';

declare let window: any;

export interface ProductAnalytics {
  item_id: string;
  item_name: string;
  quantity: number;
  price: number;
  currency: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  purchaseTotal = 0;
  win: any = null;
  fbq: any = null;
  useUA = true;

  /**
   * Constructor for the analytics service
   */
  constructor(
    private oneSignal: OneSignal,
    // protected $hotjar: NgxHotjarService,
    private addressService: AddressService,
    private router: Router,
    private util: UtilityService,
    private gtm: GoogleTagManagerService,
    private directus: DirectusService,
    public platform: Platform,
    private store: Store
  ) {
    this.directus.getSettings().subscribe(ms => {
      this.useUA = !!ms.use_universal_analytics;
    });

    this.oneSignalPromptUser();
    this.oneSignalGetCurrentTags().then((tags: any) => {
      if (tags) {
        tags.forEach((tag: any) => {
          if (tag.key === 'purchase_total') {
            this.purchaseTotal = tag.value;
          }
        });
      }
    });

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // this.$hotjar.stateChange(event.urlAfterRedirects);
        // this.$hotjar.virtualPageView(event.urlAfterRedirects);
        this.oneSignalPageData(event.url);

        if (this.fbq) {
          this.fbq('track', 'PageView');
        }
        if (this.gtm.getDataLayer()) {
          this.gtm.pushTag({
            event: 'page',
            pageName: event.url,
          });
        }
      }
    });
    this.win = this.util.nativeWindow;
  }

  initializeAnalytics(config: MainSettings) {
    if (config.ga_ua) {
      this.setupGA(config.ga_ua);
    }
    if (config.fb_pixel) {
      this.setupFB(config.fb_pixel);
    }
    // if (config.onesignal_app_id) {
    //   this.setupOnesignal(config.onesignal_app_id);
    // }
    if (config.gtm_key) {
      this.setupGTM(config.gtm_key);
    }
  }

  setupGA(id) {
    const ga = this.win.ga;
    if (!id || !ga) {
      return;
    }
    ga('create', id, 'auto');
    ga('send', 'pageview');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  setupFB(id) {
    this.fbq = this.win.fbq;
    if (!id || !this.fbq) {
      return;
    }
    this.fbq('init', id);
  }

  setupGTM(trackingCode: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.gtm.config.id = trackingCode;
    const emptyScript = document.getElementById('GTMscript');
    if (emptyScript) {
      emptyScript.remove();
    }
    this.gtm.addGtmToDom().catch(error => console.log(error));
  }

  async setupOnesignal(appID: string) {
    // window['plugins'].OneSignal.setLogLevel({logLevel: 6, visualLevel: 6});
    // tslint:disable-next-line
    window['plugins'].OneSignal.setAppId(appID);
    // tslint:disable-next-line
    await window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse();
    // this.oneSignal.startInit(appID);
    // this.oneSignal.inFocusDisplaying(OSDisplayType.Notification);
    // this.oneSignal.getPermissionSubscriptionState().then(result => {
    //   console.log(result);
    // });
    // this.oneSignal.iOSSettings({kOSSettingsKeyAutoPrompt: true, kOSSettingsKeyInAppLaunchURL: true});
    // this.oneSignal.endInit();
  }

  analyticsOnLogin(user: User): void {
    // posthog.identify([this.projectKey.projectKey, user.userID].join('-'), {
    //   email: user.email,
    //   firstName: user.firstName,
    //   lastName: user.lastName,
    //   phone: user.phoneNumber,
    //   brand: this.projectKey.projectKey,
    //   device: Capacitor.getPlatform(),
    // });
  }

  analyticsOnOrderCreation(order: Order): void {
    if (order) {
      this.gtmVendorInfo(order.location);
      // posthog.capture('Order Created', {
      //   order_id: order.orderID,
      //   location_id: order.location.slugURL ? order.location.slugURL : order.location.locationID,
      //   location_name: order.location.name,
      //   brand: this.projectKey.projectKey,
      // });
    }
  }

  analyticsOnCategoryView(products: Product[], location: Location): void {
    this.logProductListView(products, location);
    // posthog.capture('Category View', {
    //   products,
    //   brand: this.projectKey.projectKey,
    // });
  }

  analyticsOnProductClick(product: Product, location: Location): void {
    this.logProductClick(product, location);
    // posthog.capture('Product Click', {
    //   product,
    //   brand: this.projectKey.projectKey,
    // })
  }

  analyticsOnProductCustomize(product: Product, location: Location): void {
    this.logProductDetailView(product, location);
    // posthog.capture('Product Customize', {
    //   product,
    //   brand: this.projectKey.projectKey,
    // })
  }

  analyticsOnAddToCart(product: OrderItem, location: Location): void {
    this.logAddToCart(product, location);
    // posthog.capture('Add To Cart', {
    //   product,
    //   value: product.totalCents / 100,
    //   brand: this.projectKey.projectKey,
    // })
  }

  analyticsOnRemoveFromCart(product: OrderItem, location: Location): void {
    this.logRemoveFromCart(product, location);
    // posthog.capture('Remove From Cart', {
    //   product,
    //   value: product.totalCents / 100,
    //   brand: this.projectKey.projectKey,
    // })
  }

  analyticsOnRewardListView(rewards: Reward[], location: Location): void {
    this.logPromotionListView(rewards, location);
    // posthog.capture('Reward List View', {
    //   rewards,
    //   brand: this.projectKey.projectKey,
    // })
  }

  analyticsOnRewardSelect(reward: Reward, index: number, location: Location): void {
    this.logPromotionClick(reward, index, location);
    // posthog.capture('Reward Selected', {
    //   reward,
    //   brand: this.projectKey.projectKey,
    // })
  }

  analyticsOnCheckoutStart(products: OrderItem[], location: Location): void {
    this.logCheckoutStart(products, location);
    // posthog.capture('Checkout Started', {
    //   products,
    //   brand: this.projectKey.projectKey,
    // })
  }

  /**
   * Analytics typically sent on user submit. This flow is very likely to change and
   * this is the ideal point to add or remove key analytics points from the flow.
   * If you are adding data points to this flow, please add a function below and
   * run it in the appropriate section of this function.
   */
  analyticsOnSubmit(prevOrder: Order, order: Order, location: Location): void {
    const productAnalytics = this.orderToProductAnalytics(
      order,
      this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode)
    );

    // User Details
    if (prevOrder.deliveryAddress) {
      this.oneSignalDeliveryAddress(prevOrder);
    }

    // Cart Details
    this.oneSignalCartLocation(prevOrder);
    this.oneSignalCartItems(prevOrder);
    this.oneSignalCartTotal(prevOrder);
    this.oneSignalSendPurchaseOutcome(prevOrder);
    this.oneSignalSendItemOutcome(prevOrder);

    // 'Un-Abandon' Cart
    this.oneSignalAdoptCart();

    // Purchase Details
    this.oneSignalPurchaseMade();
    // this.firebasePurchaseDetails(order, productAnalytics);
    this.logPurchase(prevOrder, location);

    // posthog.identify([this.projectKey.projectKey, email].join('-'), {
    //   email,
    //   phone,
    //   firstName,
    //   lastName,
    //   brand: this.projectKey.projectKey
    // });
    // posthog.capture('Order Submitted', {
    //   order_id: order.orderID,
    //   location: order.location.name,
    //   location_id: order.location.slugURL ? order.location.slugURL : order.location.locationID,
    //   sub_total: order.subTotalCents / 100,
    //   tax: order.taxCents / 100,
    //   total: order.totalCents / 100,
    //   is_delivery: order.handoffType === HandoffType.delivery || order.handoffType === HandoffType.dispatch,
    //   items: productAnalytics,
    //   brand: this.projectKey.projectKey,
    // })
  }

  ////////////////////////////
  //////// ONE SIGNAL ////////
  ////////////////////////////

  /**
   * Tags the page data in OneSignal
   */
  oneSignalPromptUser() {
    if (this.platform.is('cordova')) {
      this.oneSignal.addTrigger('prompt_ios', 'true');
    }
  }

  /**
   * Tags the page data in OneSignal
   */
  oneSignalSendPurchaseOutcome(order: Order) {
    if (Capacitor.getPlatform() !== 'web') {
      // tslint:disable-next-line
      // window['plugins'].OneSignal.sendOutcomeWithValue('purchase_amount', order.subTotalCents / 100, () => {
      //   // console.log('Purchase Outcome Sent');
      // });
    }
  }

  /**
   * Tags the page data in OneSignal
   */
  oneSignalSendItemOutcome(order: Order) {
    if (Capacitor.getPlatform() !== 'web') {
      order.items.forEach(item => {
        // tslint:disable-next-line
        // window['plugins'].OneSignal.sendOutcomeWithValue(item.name + '_amount', item.totalCents / 100, () => {
        //   // console.log('Purchase Outcome Sent');
        // });
      });
    }
  }

  /**
   * Tags the page data in OneSignal
   */
  oneSignalNotificationClickedOutcome() {
    if (Capacitor.getPlatform() !== 'web') {
      // tslint:disable-next-line
      // window['plugins'].OneSignal.sendOutcome('notification_clicked');
    }
  }

  /**
   * Tags the page data in OneSignal
   */
  oneSignalPageData(name: string) {
    if (this.platform.is('cordova')) {
      this.oneSignal.sendTags({
        page_name: name,
      });
    }
  }

  /**
   * Tags the page data in OneSignal
   */
  oneSignalGetCurrentTags() {
    if (this.platform.is('cordova')) {
      return this.oneSignal.getTags();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Tags the user info in OneSignal
   */
  oneSignalUserInfo(cardDetails: CardDetails) {
    if (this.platform.is('cordova')) {
      this.oneSignal.sendTags({
        first_name: cardDetails.firstName,
        last_name: cardDetails.lastName,
        email: cardDetails.emailAddress,
        'email_opt-in': cardDetails.guestOptIn,
        phone: cardDetails.phoneNumber,
      });
    }
  }

  /**
   * Tags the user delivery address in OneSignal
   */
  oneSignalDeliveryAddress(order: Order) {
    if (this.platform.is('cordova')) {
      this.oneSignal.sendTags({
        address: order.deliveryAddress.address1,
        city: order.deliveryAddress.city,
        state: order.deliveryAddress.state
          ? order.deliveryAddress.state
          : this.addressService.getStateCodeFromZip(order.deliveryAddress.zipCode),
        postal_code: order.deliveryAddress.zipCode,
        country: 'USA',
      });
    }
  }

  /**
   * Tags the cart-container-container location in OneSignal
   */
  oneSignalCartLocation(order: Order) {
    if (this.platform.is('cordova')) {
      this.oneSignal.sendTags({
        location_id: order.location.locationID,
        location_name: order.location.name,
      });
    }
  }

  /**
   * Tags the cart-container-container items in OneSignal
   */
  oneSignalCartItems(order: Order) {
    if (this.platform.is('cordova')) {
      const onesignalItems = {};
      order.items.forEach(item => {
        onesignalItems[item.name.toLowerCase().replace(' ', '_').concat('_name')] = item.name;
        onesignalItems[item.name.toLowerCase().replace(' ', '_').concat('_price')] = item.totalCents / 100;
      });
      this.oneSignal.sendTags(onesignalItems);
    }
  }

  /**
   * Tags the cart-container-container items in OneSignal
   */
  oneSignalCartTotal(order: Order) {
    if (this.platform.is('cordova')) {
      this.oneSignal.sendTags({
        cart_total: order.subTotalCents / 100,
      });
    }
  }

  /**
   * Tags that the user made a purchase in OneSignal
   */
  oneSignalPurchaseMade() {
    if (this.platform.is('cordova')) {
      this.oneSignal.sendTags({
        purchase_made: 'true',
      });
    }
  }

  /**
   * Tags that the user made a purchase in OneSignal
   */
  oneSignalIncreasePurchaseTotal() {
    if (this.platform.is('cordova')) {
      this.oneSignal.sendTags({
        purchase_total: (this.purchaseTotal += 1),
      });
    }
  }

  /**
   * Tags the cart-container-container as un-abandoned in OneSignal
   */
  oneSignalAdoptCart() {
    if (this.platform.is('cordova')) {
      this.oneSignal.sendTags({
        cart_update: '',
        product_name: '',
        product_image: '',
      });
    }
  }

  ////////////////////////////////////
  //////// FIREBASE ANALYTICS ////////
  ////////////////////////////////////

  /**
   * Sends the order and payment details to Firebase analytics
   */
  firebasePaymentDetails(order: Order, cardDetails: CardDetails) {}

  /**
   * Sends the order and purchase details to Firebase analytics
   */
  firebasePurchaseDetails(order: Order, prodAnalytics: any[]) {}

  firebaseGenericLog(options) {}

  ////////////////////////////////////
  //////// GOOGLE TAG MANAGER ////////
  ////////////////////////////////////

  gtmVendorInfo(location: Location): void {
    this.gtm.pushTag({
      event: 'menuView',
      vendor: {
        id: location?.externalRef ? location.externalRef : location?.locationID,
        address: {
          streetAddress: location.address.address1,
          city: location.address.city,
          postalCode: location.address.zipCode,
          streetAddress2: location.address.address2 ? location.address.address2 : '',
          state: location.address.state,
          country: location.address.country ? location.address.country : '',
          crossStreet: location.address.crossStreet ? location.address.crossStreet : '',
        },
      },
    });
  }

  gtmGenericLog(options: object): void {
    this.gtm.pushTag(options);
  }

  private logProductListView(products: Product[], location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'viewItemList',
        ecommerce: {
          impressions: products.map((prod, i) => this.productToGTMImpression(prod, i)),
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'view_item_list',
        ecommerce: {
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
          items: products.map((prod, index) => this.productToGTMItem(prod, index)),
        },
      });
    }
  }

  private logProductClick(product: Product, location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'productClick',
        ecommerce: {
          products: [this.productToGTMImpression(product, 0)],
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'select_item',
        ecommerce: {
          items: [this.productToGTMItem(product, 0)],
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    }
  }

  private logProductDetailView(product: Product, location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'viewItem',
        ecommerce: {
          detail: {
            products: [this.productToGTMProduct(product, 0)],
            location_id: location?.externalRef ? location.externalRef : location?.locationID,
          },
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'view_item',
        ecommerce: {
          items: [this.productToGTMItem(product, 0)],
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    }
  }

  private logAddToCart(product: OrderItem, location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'addToCart',
        ecommerce: {
          add: {
            products: [
              this.orderItemToGTMProduct(
                product,
                this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode)
              ),
            ],
            is_upsell: document.location.pathname.includes('upsell'),
          },
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'add_to_cart',
        ecommerce: {
          items: [
            this.orderItemToGTMItem(
              product,
              this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode)
            ),
          ],
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
          is_upsell: document.location.pathname.includes('upsell'),
        },
      });
    }
  }

  private logRemoveFromCart(product: OrderItem, location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [
              this.orderItemToGTMProduct(
                product,
                this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode)
              ),
            ],
          },
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'remove_from_cart',
        ecommerce: {
          items: [this.orderItemToGTMItem(product)],
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    }
  }

  private logPromotionListView(rewards: Reward[], location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'viewPromotion',
        ecommerce: {
          promoView: {
            promotions: rewards.map((reward, index) => this.rewardToGTMPromotion(reward, index)),
          },
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'view_promotion',
        ecommerce: {
          items: rewards.map((reward, index) => this.rewardToGTMItem(reward, index)),
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    }
  }

  private logPromotionClick(reward: Reward, index: number, location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [this.rewardToGTMPromotion(reward, index)],
          },
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'select_promotion',
        ecommerce: {
          items: [this.rewardToGTMItem(reward, index)],
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    }
  }

  private logCheckoutStart(products: OrderItem[], location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'beginCheckout',
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: 'Begin Checkout' },
            products: products.map(prod =>
              this.orderItemToGTMProduct(
                prod,
                this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode)
              )
            ),
          },
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'begin_checkout',
        ecommerce: {
          items: products
            ? products.map(prod =>
                this.orderItemToGTMItem(
                  prod,
                  this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode)
                )
              )
            : [],
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    }
  }

  private logPurchase(order: Order, location: Location): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.gtm.pushTag({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: order.customerFacingID ? order.customerFacingID : order.orderID,
              affiliation: Capacitor.getPlatform(),
              revenue: order.totalCents / 100,
              tax: order.taxCents / 100,
              shipping: order.deliveryFee ? order.deliveryFee.feeCents / 100 : 0,
              coupon: order.appliedCouponCode ? order.appliedCouponCode : '',
            },
            products: order.items.map(prod =>
              this.orderItemToGTMProduct(
                prod,
                this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode)
              )
            ),
          },
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    } else {
      this.gtm.pushTag({
        event: 'purchase',
        ecommerce: {
          transaction_id: order.customerFacingID ? order.customerFacingID : order.orderID,
          affiliation: Capacitor.getPlatform(),
          currency: this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode),
          value: order.totalCents / 100,
          tax: order.taxCents / 100,
          shipping: order.deliveryFee ? order.deliveryFee.feeCents / 100 : 0,
          coupon: order.appliedCouponCode ? order.appliedCouponCode : '',
          items: order.items.map(prod =>
            this.orderItemToGTMItem(
              prod,
              this.store.selectSnapshot((state: GlobalStateModel) => state.order.currencyCode)
            )
          ),
          location_id: location?.externalRef ? location.externalRef : location?.locationID,
        },
      });
    }
  }

  private resetEcommerce(): void {
    this.gtm.pushTag({ ecommerce: null });
  }

  //////////////////////////
  //////// MAPPINGS ////////
  //////////////////////////

  /**
   * Maps the order to product analytics
   */
  private orderToProductAnalytics(order: Order, currencyCode = 'USD'): ProductAnalytics[] {
    const productAnalytics: ProductAnalytics[] = [];
    order.items.forEach(prod => {
      productAnalytics.push({
        item_id: prod.nameSlug,
        item_name: prod.name,
        quantity: prod.quantity,
        price: prod.totalCents / 100,
        currency: currencyCode,
      });
    });
    return productAnalytics;
  }

  // GOOGLE ANALYTICS 4

  private categoryToGTMItem(category: Category, index: number): GTMItem {
    return {
      item_name: category.name,
      item_id: String(category.categoryID),
      index,
      quantity: 1,
    } as GTMItem;
  }

  private productToGTMItem(product: Product, index: number): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.productID),
      item_variant: product.name,
      quantity: 1,
      index,
    } as GTMItem;
  }

  private productToGTMItemWithPrice(product: Product, currencyCode = 'USD'): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.productID),
      item_variant: product.name,
      price: Math.round(product.priceCents) / 100,
      currency: currencyCode,
      quantity: 1,
    } as GTMItem;
  }

  private optionToGTMItem(option: Option): GTMItem {
    return {
      item_name: option.name,
      item_id: String(option.optionID),
    } as GTMItem;
  }

  private orderItemToGTMItem(product: OrderItem, currencyCode = 'USD'): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.productID),
      item_variant: product.options[0] ? product.options[0].name : product.name,
      price: Math.round(product.totalCents) / 100,
      currency: currencyCode,
      quantity: product.quantity,
      item_modifiers: product.options ? product.options.map(opt => opt.name) : [],
    } as GTMItem;
  }

  private removeOrderItemToGTMItem(product: OrderItem, currencyCode = 'USD'): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.productID),
      item_variant: product.options[0] ? product.options[0].name : '',
      price: Math.round(product.totalCents) / 100,
      currency: currencyCode,
      quantity: product.quantity,
    } as GTMItem;
  }

  private rewardToGTMItem(reward: Reward, index: number): GTMItem {
    return {
      item_name: reward.name,
      item_id: reward.rewardID,
      promotion_name: reward.name,
      promotion_id: reward.rewardID,
      creative_name: reward.name,
      creative_slot: String(index),
      location_id: 'checkout',
      index,
    } as GTMItem;
  }

  // UNIVERSAL ANALYTICS

  private productToGTMImpression(product: Product, index: number): GTMImpression {
    return {
      id: String(product.productID),
      name: product.name,
    } as GTMImpression;
  }

  private productToGTMProduct(product: Product, index: number): GTMProduct {
    return {
      id: String(product.productID),
      name: product.name,
      variant: product.name,
      position: index,
    } as GTMProduct;
  }

  private productToGTMProductWithPrice(product: Product, index: number, currencyCode = 'USD'): GTMProduct {
    return {
      id: String(product.productID),
      name: product.name,
      variant: product.name,
      price: Math.round(product.priceCents) / 100,
      currency: currencyCode,
      position: index,
    } as GTMProduct;
  }

  private orderItemToGTMProduct(product: OrderItem, currencyCode = 'USD'): GTMProduct {
    return {
      id: String(product.productID),
      name: product.name,
      variant: product.name,
      price: Math.round(product.totalCents) / 100,
      currency: currencyCode,
      quantity: product.quantity,
    } as GTMProduct;
  }

  private removeOrderItemToGTMProduct(product: OrderItem, currencyCode = 'USD'): GTMProduct {
    return {
      id: String(product.productID),
      name: product.name,
      variant: product.options[0] ? product.options[0].name : '',
      price: Math.round(product.totalCents) / 100,
      currency: currencyCode,
      quantity: product.quantity,
    } as GTMProduct;
  }

  private rewardToGTMPromotion(reward: Reward, index: number): GTMPromotion {
    return {
      id: String(reward.rewardID),
      name: reward.name,
      position: 'checkout_'.concat(String(index)),
    } as GTMPromotion;
  }
}
