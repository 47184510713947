import { VendorSetup } from '../../interfaces/vendor.interface';
import { ThemeColor } from '../../vendors/directus/interfaces/theme-color.interface';
import { TextField } from '../../vendors/directus/interfaces/text-field.interface';
import { ContactForm } from '../../vendors/directus/interfaces/contact-form.interface';
import { Branding } from '../../vendors/directus/interfaces/branding.interface';
import { NavbarSettings } from '../../vendors/directus/interfaces/navbar-settings.interface';
import { Action, NgxsOnInit, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CustomPage } from '../../vendors/directus/interfaces/custom-page.interface';
import { CmsVendorSetupService } from '../../services/vendor-config-service/cms-vendor-setup.service';
import { ContentService } from '../../services/vendor-config-service/content-provider.service';
import {
  CloseAnnouncementBar,
  InitializeAppState,
  InitializeGoogleMaps,
  SetKioskConfig,
  SetLoading,
  SetRouteBack,
  SetShowFooter,
  SwitchMenuView,
  UpdateTitle,
} from '../actions/app.actions';
import { forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MainSettings } from '../../vendors/directus/interfaces/main-settings.interface';
import { Banner } from '../../vendors/directus/interfaces/banner.interface';
import { KioskContent } from '../../vendors/directus/interfaces/kiosk-content.interface';
import { environment } from '../../environments/environment';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ModeService } from '../../services/mode.service';
import { MobileAppSettings } from '../../vendors/directus/interfaces/mobile-app-settings.interface';
import { Onboarding } from '../../vendors/directus/interfaces/onboarding.interface';
import { CustomIcons } from '../../vendors/directus/interfaces/custom-icons.interface';
import { retryWithDelay } from '../../models/retryWithDelay.operator';
import { HttpClient } from '@angular/common/http';
import { PromotionalContent } from '../../vendors/directus/interfaces/promotional-content.interface';
import { from } from 'rxjs';
import { Preferences as Storage } from '@capacitor/preferences';
import { PwaSettings } from '../../vendors/directus/interfaces/pwa-settings.interface';
import { KioskConfig } from '../../vendors/directus/interfaces/kiosk-config.interface';
import { CardTerminalService } from '../../services/vendor-config-service/card-terminal.service';
import { Features } from '../../vendors/directus/interfaces/features.interface';
import { Capacitor } from '@capacitor/core';
import { RecaptchaConfiguration } from '../../vendors/directus/interfaces/recaptcha-configuration.interface';

export interface AppStateModel {
  vendorSetup: VendorSetup;
  title: string;
  theme: ThemeColor[];
  textField: TextField;
  contactForm: ContactForm[];
  branding: Branding;
  navbarSettings: NavbarSettings;
  mainSettings: MainSettings;
  customPages: CustomPage[];
  promotionalContent: PromotionalContent;
  announcementBar: boolean;
  banners: Banner[];
  kioskContent: KioskContent;
  mobileAppSettings: MobileAppSettings;
  backRoute: string;
  showFooter: boolean;
  onboardingSlides: Onboarding[];
  customIcons: CustomIcons;
  googleMapsLoaded: boolean;
  menuView: string;
  splashScreen: PwaSettings;
  loading: boolean;
  kioskConfig: KioskConfig;
  features: Features[];
  canRedeem: boolean;
  recaptchaConfiguration: RecaptchaConfiguration;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    vendorSetup: null,
    title: null,
    theme: null,
    textField: null,
    contactForm: null,
    branding: null,
    navbarSettings: null,
    mainSettings: null,
    customPages: null,
    promotionalContent: null,
    announcementBar: null,
    banners: null,
    kioskContent: null,
    mobileAppSettings: null,
    backRoute: '',
    showFooter: true,
    onboardingSlides: null,
    customIcons: null,
    googleMapsLoaded: false,
    menuView: null,
    splashScreen: null,
    loading: false,
    kioskConfig: null,
    features: null,
    canRedeem: true,
    recaptchaConfiguration: null,
  },
})
@Injectable()
export class AppState implements NgxsOnInit {
  constructor(
    private store: Store,
    private vendorService: CmsVendorSetupService,
    private contentService: ContentService,
    private router: Router,
    private mode: ModeService,
    private http: HttpClient,
    private terminal: CardTerminalService
  ) {
    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart:
          this.store.dispatch(new SetShowFooter(false));
          break;
        case event instanceof NavigationEnd:
          this.store.dispatch(new SetShowFooter(true));
          break;
      }
    });
  }

  ngxsOnInit(ctx: StateContext<AppStateModel>) {
    return ctx.dispatch(new InitializeAppState(this.mode.mode));
  }

  @Action(InitializeAppState)
  initializeAppState(ctx: StateContext<AppStateModel>, action: InitializeAppState) {
    if (localStorage.getItem('kioskConfig')) {
      ctx.dispatch(new SetKioskConfig(JSON.parse(localStorage.getItem('kioskConfig'))));
    }
    return from(Storage.get({ key: 'menuView' })).pipe(
      switchMap(result => {
        return forkJoin({
          vService: this.vendorService.getService(),
          cService: this.contentService.getService(),
        }).pipe(
          switchMap(services => {
            return forkJoin({
              vendorSetup: services.vService.getVendorSetup().pipe(retryWithDelay(100, 10)),
              branding: services.cService.getBranding().pipe(retryWithDelay(100, 10)),
              navbarSettings: services.cService.getNavbarSettings().pipe(retryWithDelay(100, 10)),
              theme: services.cService.getColors().pipe(retryWithDelay(100, 10)),
              textField: services.cService.getTextFields().pipe(retryWithDelay(100, 10)),
              customPages: services.cService.getCustomPages().pipe(retryWithDelay(100, 10)),
              contactForm: services.cService.getContactForm().pipe(retryWithDelay(100, 10)),
              mainSettings: services.cService.getSettings().pipe(retryWithDelay(100, 10)),
              promotionalContent: services.cService.getPromotionalContent().pipe(retryWithDelay(100, 10)),
              banners: services.cService.getBanners().pipe(retryWithDelay(100, 10)),
              kioskContent: action.mode === 'kiosk' ? services.cService.getKioskContent().pipe(retryWithDelay(100, 10)) : of(null),
              mobileAppSettings: services.cService.getMobileAppSettings().pipe(retryWithDelay(100, 10)),
              onboarding: services.cService.getOnboardingSlides().pipe(retryWithDelay(100, 10)),
              customIcons: services.cService.getCustomIcons().pipe(retryWithDelay(100, 10)),
              splashScreen: services.cService.getPwaSettings().pipe(retryWithDelay(100, 10)),
              features: services.cService.getFeatures().pipe(catchError(() => of([]))),
              oloConfig: services.cService.getOloConfiguration(),
              recaptchaConfiguration: services.cService.getRecaptchaConfig().pipe(catchError(() => of({ enabled: false, site_key: '' }))),
            }).pipe(
              map(results => {
                ctx.dispatch(new InitializeGoogleMaps(results.mainSettings.gmaps_key));
                return ctx.patchState({
                  vendorSetup: results.vendorSetup,
                  branding: results.branding,
                  navbarSettings: results.navbarSettings,
                  theme: results.theme,
                  textField: results.textField,
                  customPages: results.customPages,
                  contactForm: results.contactForm,
                  mainSettings: results.mainSettings,
                  promotionalContent: results.promotionalContent,
                  banners: results.banners,
                  kioskContent: results.kioskContent,
                  mobileAppSettings: results.mobileAppSettings,
                  onboardingSlides: results.onboarding,
                  customIcons: results.customIcons,
                  menuView: result?.value ? result.value : results.theme[0].default_menu_display,
                  splashScreen: results.splashScreen,
                  features: results.features.filter(f => !(f.status === 'draft' && environment.production)),
                  canRedeem: !(results.oloConfig.wrap_olo_serve && Capacitor.getPlatform() !== 'web'),
                  recaptchaConfiguration: results.recaptchaConfiguration,
                });
              })
            );
          })
        );
      })
    );
  }

  @Action(UpdateTitle)
  updateTitle(ctx: StateContext<AppStateModel>, action: UpdateTitle) {
    return ctx.patchState({
      title: action.title,
    });
  }

  @Action(SetRouteBack)
  setRouteBack(ctx: StateContext<AppStateModel>, action: SetRouteBack) {
    return ctx.patchState({
      backRoute: action.route,
    });
  }

  @Action(CloseAnnouncementBar)
  closeAnnouncementBar(ctx: StateContext<AppStateModel>, action: CloseAnnouncementBar) {
    return ctx.patchState({
      announcementBar: false,
    });
  }

  @Action(InitializeGoogleMaps)
  initGoogleMaps(ctx: StateContext<AppStateModel>, action: InitializeGoogleMaps) {
    environment.API_KEY_FOR_BROWSER_RELEASE = action.key;
    return this.http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${action.key}&libraries=geometry,places`, 'callback').pipe(
      map(() => {
        return ctx.patchState({
          googleMapsLoaded: true,
        });
      }),
      catchError(err => {
        return of(
          ctx.patchState({
            googleMapsLoaded: false,
          })
        );
      })
    );
  }

  @Action(SetShowFooter)
  setShowFooter(ctx: StateContext<AppStateModel>, action: SetShowFooter) {
    return ctx.patchState({
      showFooter: action.show,
    });
  }

  @Action(SwitchMenuView)
  switchMenuView(ctx: StateContext<AppStateModel>, action: SwitchMenuView) {
    return Storage.set({
      key: 'menuView',
      value: action.view,
    }).then(() => {
      return ctx.patchState({
        menuView: action.view,
      });
    });
  }

  @Action(SetLoading)
  setLoading(ctx: StateContext<AppStateModel>, action: SetLoading) {
    return ctx.patchState({
      loading: action.loading,
    });
  }

  @Action(SetKioskConfig)
  setKioskConfig(ctx: StateContext<AppStateModel>, action: SetKioskConfig) {
    localStorage.setItem('kioskConfig', JSON.stringify(action.config));
    return ctx.patchState({
      kioskConfig: action.config,
    });
  }
}
