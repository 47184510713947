<nav
        class="border-bottom"
        [style.background-color]="navSettings?.background_color">
    <ng-container
            *ngIf="
      (navSettings.additional_links &&
      navSettings.additional_links.length) || (navSettings.subnav_links && navSettings.subnav_links.length)
    ">
        <app-additional-desktop-links></app-additional-desktop-links>
    </ng-container>

    <ng-container *ngIf="navSettings.center_logo">
        <div class="d-flex flex-row flex-nowrap appHeader" #navbar [class.container]="navSettings?.fixed_navbar_width">
            <ng-container *ngIf="doneLoading">
                <div class="d-flex flex-row flex-nowrap align-items-center justify-content-center position-relative w-100">

                    <!--Left-->
                    <div class="position-absolute start-0" [class.ps-3]="!navSettings?.fixed_navbar_width">
                        <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                            <div
                                    class="d-flex flex-row flex-nowrap"
                                    *ngIf="!router.url.includes('confirmation')">
                                <div
                                        class="d-flex flex-row flex-nowrap"
                                        [@collapseHorizontally]="currentGroupOrder && currentGroupOrder?.role === 'member'">
                                    <ng-container *ngIf="order$ | async; else fakePills">
                                        <ng-template #currentHandoffType>
                                            <ng-container [ngSwitch]="order?.handoffType">
                                                <ng-container *ngSwitchCase="0">Pickup</ng-container>
                                                <ng-container *ngSwitchCase="2">Pickup</ng-container>
                                                <ng-container *ngSwitchCase="3">Pickup</ng-container>
                                                <ng-container *ngSwitchCase="1">Curbside</ng-container>
                                                <ng-container *ngSwitchCase="4">Drive-Thru</ng-container>
                                                <ng-container *ngSwitchCase="5">Dine In</ng-container>
                                            </ng-container>
                                        </ng-template>

                                        <div
                                                class="d-flex flex-nowrap h-100 align-items-center"
                                                [class.toggle-opacity]="deliveryToggleLoading">
                                            <ng-container *ngIf="vendorSetup.delivery_provider !== 'none'">
                                                <div
                                                        class="pill-btn-sm h-fit-content"
                                                        [ngClass]="order?.handoffType === 2 || order?.handoffType === 3 ? 'pill-btn-navbar' : 'pill-btn-navbar-inactive'"
                                                        (click)="handoffModeSelect('delivery')"
                                                        (keydown.enter)="handoffModeSelect('delivery')">
                                                    Delivery
                                                </div>
                                            </ng-container>

                                            <ng-container
                                                    *ngIf="numberOfHandoffs.length > 1 && vendorSetup.pickup_provider !== 'none'">
                                                <div
                                                        class="d-flex align-items-center h-100 ms-2"
                                                        ngbDropdown
                                                        #orderTypeDrop="ngbDropdown"
                                                        container="body"
                                                        display="dynamic">
                                                    <div
                                                            class="pill-btn-sm h-fit-content"
                                                            [ngClass]="order?.handoffType !== 2 && order?.handoffType !== 3 ? 'pill-btn-navbar' : 'pill-btn-navbar-inactive'"
                                                            ngbDropdownToggle>
                                                        <ng-container
                                                                *ngTemplateOutlet="currentHandoffType"></ng-container>
                                                        <ng-container
                                                                *ngIf="deliveryToggleLoading; else dropdownArrow">
                                                            <div
                                                                    class="de-sm-loader d-inline-block align-middle ms-2"
                                                                    [style.border-top]="'1px solid ' +(order?.handoffType !== 2 && order?.handoffType !== 3 ? navSettings?.background_color : navSettings?.text_color)"></div>
                                                        </ng-container>
                                                        <ng-template #dropdownArrow>
                                                            <div
                                                                    class="bi bi-chevron-down d-inline-block align-middle ps-1"
                                                                    [style.color]="order?.handoffType !== 2 && order?.handoffType !== 3 ? navSettings?.background_color : navSettings?.text_color"></div>
                                                        </ng-template>
                                                    </div>
                                                    <div
                                                            class="order-type-dropdown drop-shadow"
                                                            [style.background-color]="navSettings?.background_color"
                                                            ngbDropdownMenu>
                                                        <div class="handoff-dropdown-selection">
                                                            <div
                                                                    class="d-flex flex-row flex-nowrap justify-content-center align-content-center handoff-dropdown-item item-container pointer"
                                                                    style="color: var(--nav-text-color)"
                                                                    [class.fw-bold]="order?.handoffType === 0"
                                                                    [class.me-2]="order?.handoffType !== 0"
                                                                    *ngIf="this.order?.location.supportsPickup"
                                                                    (click)="handoffModeSelect('pickup')"
                                                                    (keydown.enter)="handoffModeSelect('pickup')"
                                                                    ngbDropdownItem>
                                                                Pickup
                                                                <i
                                                                        [class.me-2]="order?.handoffType !== 0"
                                                                        [class.bi-check]="order?.handoffType === 0"
                                                                        class="bi ms-2"
                                                                        style="color: var(--nav-text-color)"></i>
                                                            </div>
                                                        </div>
                                                        <div class="handoff-dropdown-selection">
                                                            <div
                                                                    class="d-flex flex-row flex-nowrap justify-content-center align-content-center handoff-dropdown-item item-container pointer"
                                                                    [style.color]="navSettings?.text_color"
                                                                    [class.fw-bold]="order?.handoffType === 1"
                                                                    [class.me-2]="order?.handoffType !== 1"
                                                                    *ngIf="this.order?.location.supportsCurbside"
                                                                    (click)="handoffModeSelect('curbside')"
                                                                    (keydown.enter)="handoffModeSelect('curbside')"
                                                                    ngbDropdownItem>
                                                                Curbside
                                                                <i
                                                                        [class.me-2]="order?.handoffType !== 1"
                                                                        [class.bi-check]="order?.handoffType === 1"
                                                                        class="bi ms-2"
                                                                        style="color: var(--nav-text-color)"></i>
                                                            </div>
                                                        </div>
                                                        <div class="handoff-dropdown-selection">
                                                            <div
                                                                    class="d-flex flex-row flex-nowrap justify-content-center align-content-center handoff-dropdown-item item-container pointer"
                                                                    style="color: var(--nav-text-color)"
                                                                    [class.fw-bold]="order?.handoffType === 4"
                                                                    *ngIf="this.order?.location.supportsDriveThru"
                                                                    (click)="handoffModeSelect('drive-thru')"
                                                                    (keydown.enter)="handoffModeSelect('drive-thru')"
                                                                    ngbDropdownItem>
                                                                Drive-Thru
                                                                <i
                                                                        [class.me-2]="order?.handoffType !== 4"
                                                                        [class.bi-check]="order?.handoffType === 4"
                                                                        class="bi ms-2"
                                                                        style="color: var(--nav-text-color)"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>

                                            <div
                                                    *ngIf="numberOfHandoffs.length <= 1"
                                                    class="pill-btn-sm h-fit-content ms-2"
                                                    [ngClass]="order?.handoffType !== 2 && order?.handoffType !== 3 ? 'pill-btn-navbar' : 'pill-btn-navbar-inactive'">
                                                <div (click)="handoffModeSelect('pickup')"
                                                     (keydown.enter)="handoffModeSelect('pickup')">
                                                    <ng-container
                                                            *ngTemplateOutlet="currentHandoffType"></ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-template #fakePills>
                                        <div
                                                class="d-flex flex-nowrap h-100 align-items-center ps-2"
                                                [class.toggle-opacity]="deliveryToggleLoading">
                                            <ng-container *ngIf="vendorSetup.delivery_provider !== 'none'">
                                                <button
                                                        type="button"
                                                        tabindex="0"
                                                        class="pill-btn-sm h-fit-content pill-btn-navbar-inactive ion-text-capitalize"
                                                        (click)="handoffModeSelect('delivery')">
                                                    Delivery
                                                </button>
                                            </ng-container>

                                            <button *ngIf="numberOfHandoffs.length <= 1  && vendorSetup.pickup_provider !== 'none'"
                                                    (click)="locationClicked()" (keydown.enter)="locationClicked()"
                                                    type="button"
                                                    class="pill-btn-sm h-fit-content ms-2 ion-text-capitalize"
                                                    [ngClass]="
                    router.url.includes('locations')
                      ? 'pill-btn-navbar'
                      : 'pill-btn-navbar-inactive'
                  ">Pickup
                                            </button>
                                        </div>
                                        <div
                                                style="color: var(--nav-text-color)"
                                                class="d-none d-xl-flex align-items-center ps-2">
                                            |
                                        </div>
                                    </ng-template>

                                    <div
                                            *ngIf="order$ | async"
                                            style="color: var(--nav-text-color)"
                                            class="d-none d-xl-flex align-items-center ps-2">
                                        |
                                    </div>

                                    <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                                        <ng-container *ngIf="vendorSetup.location_provider !== 'none'">
                                            <div
                                                    class="d-flex align-items-center ps-2"
                                                    size="auto"
                                                    id="cy-location-link">
                                                <div
                                                        ngbDropdown
                                                        #headerDrop="ngbDropdown"
                                                        container="body"
                                                        display="dynamic">
                                                    <app-nav-order-type
                                                            tabindex="0"
                                                            style="color: var(--nav-text-color)"
                                                            [order]="order"
                                                            id="location-dropdown"
                                                            (keydown.enter)="openDropdown('location-dropdown')"
                                                            ngbDropdownToggle
                                                    ></app-nav-order-type>
                                                    <app-order-type-list
                                                            class="location-dropdown drop-shadow"
                                                            ngbDropdownMenu
                                                            (addNewOrderType)="
                        newOrderTypeClicked(); headerDrop.close()
                      "
                                                            (startNewOrder)="
                        startOrder($event); headerDrop.close()
                      "></app-order-type-list>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <ng-container *ngIf="order$ | async as order">
                                    <div
                                            class="navbar-link other d-flex align-items-center"
                                            routerLinkActive="active-link"
                                            [routerLink]="
                '/menu/' +
                (order?.location?.slugURL
                  ? order?.location.slugURL
                  : order?.location.locationID)
              "
                                            [routerLinkActiveOptions]="{ exact: true }"
                                            style="color: var(--nav-text-color)">
                                        Menu
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <!--Logo-->
                    <ng-container *ngIf="order?.location.conceptLogoURL; else mainLogo">
                        <img
                                id="appNavbarHeader"
                                class="logo"
                                alt="logo"
                                tabindex="0"
                                (click)="homeClicked()"
                                (keydown.enter)="homeClicked()"
                                [class.m-3]="!navSettings?.fixed_navbar_width"
                                *ngIf="branding?.main_logo"
                                [src]="order.location.conceptLogoURL"/>
                    </ng-container>
                    <!--Right-->
                    <div class="position-absolute end-0 d-flex flex-row flex-nowrap align-items-center justify-content-end" [class.pe-3]="!navSettings?.fixed_navbar_width">
                        <div class="d-none d-xxl-flex flex-row flex-nowrap">
                          <app-page-link
                            *ngFor="let page of customPageLinks"
                            [page]="page"
                            [user]="user$ | async"
                            [isActive]="page.slug | navLink"
                            (childPageClicked)="childPageClicked($event)"
                            (pageClicked)="pageClicked(page)">
                          </app-page-link>
                        </div>

                        <ng-container *ngIf="customPageLinks.length > 0">
                            <div class="d-flex d-xxl-none" ngbDropdown container="body">
                                <a
                                        type="button"
                                        class="d-flex align-items-center justify-content-center navbar-link me-4 ms-0"
                                        id="more-dropdown"
                                        routerLinkActive="active-link"
                                        style="color: var(--nav-text-color)"
                                        tabindex="0"
                                        (keydown.enter)="openDropdown('more-dropdown')"
                                        ngbDropdownToggle>
                                    More
                                    <div
                                            class="bi bi-chevron-down ps-1 d-inline-block align-middle my-auto"
                                            style="padding-top: 3px; color: var(--nav-text-color)"></div>
                                </a>

                                <div
                                        ngbDropdownMenu="more-dropdown"
                                        class="dropdown-menu dropdown-menu-navbar drop-shadow"
                                        style="background-color: var(--nav-background-color)">
                                    <div class="inner-information">
                                        <ul class="m-0 p-0">
                                            <ng-container *ngFor="let page of customPageLinks; index as i">
                                                <ng-container *ngIf="user$ | async as user">
                                                    <ng-container
                                                            *ngIf="page.show_on_navbar && (!page.require_login || (page.require_login && !user?.isGuest))">
                                                        <a
                                                                class="item-container pointer account-item me-3"
                                                                (keydown.enter)="pageClicked(page)"
                                                                (click)="pageClicked(page)"
                                                                tabindex="0"
                                                                style="color: var(--nav-text-color)"
                                                                ngbDropdownItem>
                                                            {{ page?.title }}
                                                            <ng-container *ngIf="page?.icon">
                                                                <i
                                                                        style=" font-size: 16px; color: var(--nav-icon-color);"
                                                                        class="bi ms-auto"
                                                                        [ngClass]="'bi-' + page?.icon"></i>
                                                            </ng-container>
                                                        </a>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!(thirdPartyWrapped$ | async)">
                            <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                                <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
                                    <ng-container *ngIf="hasUser(); else noUser">
                                        <a
                                                id="userAcctLink"
                                                class="d-flex navbar-link flex-nowrap align-items-center me-4 ms-0"
                                                routerLinkActive="active-link"
                                                style="color: var(--nav-text-color)"
                                                container="body"
                                                [routerLink]="profileRoute">
                                            <app-custom-icon
                                                    class=""
                                                    default="bi-person"
                                                    customIconKey="profile_navigation_icon"
                                                    size="20">
                                            </app-custom-icon>
                                            <div class="ps-1 m-0">
                                                Hi, {{ user.firstName }}
                                            </div>
                                        </a>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <ng-template #noUser>
                                <a
                                        class="navbar-link ms-0 me-4"
                                        *ngIf="!hasUser()"
                                        routerLinkActive="active-link"
                                        [routerLink]="loginRoute"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        style="color: var(--nav-emphasized-text-color)">
                                    Sign In
                                </a>
                                <a
                                        class="navbar-link ms-0 me-4"
                                        *ngIf="!hasUser()"
                                        [routerLink]="creatAccountRoute"
                                        routerLinkActive="active-link"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        style="color: var(--nav-emphasized-text-color)">
                                    Join Now
                                </a>
                            </ng-template>
                        </ng-container>

                        <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                            <ng-container *ngIf="vendorSetup.order_provider !== 'none'">
                                <app-desktop-nav-cart
                                        [order]="order"
                                        [isProductPrice]="showPrice"
                                        [cartIcon]="cartIcon"
                                        [@collapseHorizontally]="currentGroupOrder?.role === 'member'"
                                        [currencyCode]="currencyCode"
                                        [buttonText]="buttonText"
                                        [showOrder]="showOrder"
                                        (addNewOrderType)="newOrderTypeClicked()"
                                        style="min-width: fit-content"></app-desktop-nav-cart>
                            </ng-container>
                        </ng-container>
                    </div>

                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngIf="!navSettings.center_logo">
        <div
                class="d-flex flex-row flex-nowrap appHeader"
                #navbar
                [class.container]="navSettings?.fixed_navbar_width">
            <ng-container *ngIf="doneLoading">
                <!-- left column -->
                <section
                        class="d-flex flex-row flex-nowrap align-items-center p-0 grow-1">
                    <!-- brand logo -->
                    <ng-container *ngIf="order?.location.conceptLogoURL; else mainLogo">
                        <img
                                id="appNavbarHeader"
                                class="logo"
                                alt="logo"
                                tabindex="0"
                                (click)="homeClicked()"
                                (keydown.enter)="homeClicked()"
                                [class.m-3]="!navSettings?.fixed_navbar_width"
                                *ngIf="branding?.main_logo"
                                [src]="order.location.conceptLogoURL"/>
                    </ng-container>

                    <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                        <div
                                class="d-flex flex-row flex-nowrap ps-3"
                                *ngIf="!router.url.includes('confirmation')">
                            <div
                                    class="d-flex flex-row flex-nowrap"
                                    [@collapseHorizontally]="currentGroupOrder && currentGroupOrder?.role === 'member'">
                                <ng-container *ngIf="order$ | async; else fakePills">
                                    <ng-template #currentHandoffType>
                                        <ng-container [ngSwitch]="order?.handoffType">
                                            <ng-container *ngSwitchCase="0">Pickup</ng-container>
                                            <ng-container *ngSwitchCase="2">Pickup</ng-container>
                                            <ng-container *ngSwitchCase="3">Pickup</ng-container>
                                            <ng-container *ngSwitchCase="1">Curbside</ng-container>
                                            <ng-container *ngSwitchCase="4">Drive-Thru</ng-container>
                                            <ng-container *ngSwitchCase="5">Dine In</ng-container>
                                        </ng-container>
                                    </ng-template>

                                    <div
                                            class="d-flex flex-nowrap h-100 align-items-center ps-2"
                                            [class.toggle-opacity]="deliveryToggleLoading">
                                        <ng-container *ngIf="vendorSetup.delivery_provider !== 'none'">
                                            <div
                                                    class="pill-btn-sm h-fit-content"
                                                    [ngClass]="order?.handoffType === 2 || order?.handoffType === 3 ? 'pill-btn-navbar' : 'pill-btn-navbar-inactive'"
                                                    (click)="handoffModeSelect('delivery')"
                                                    (keydown.enter)="handoffModeSelect('delivery')">
                                                Delivery
                                            </div>
                                        </ng-container>

                                        <ng-container
                                                *ngIf="numberOfHandoffs.length > 1 && vendorSetup.pickup_provider !== 'none'">
                                            <div
                                                    class="d-flex align-items-center h-100 ms-2"
                                                    ngbDropdown
                                                    #orderTypeDrop="ngbDropdown"
                                                    container="body"
                                                    display="dynamic">
                                                <div
                                                        class="pill-btn-sm h-fit-content"
                                                        [ngClass]="order?.handoffType !== 2 && order?.handoffType !== 3 ? 'pill-btn-navbar' : 'pill-btn-navbar-inactive'"
                                                        ngbDropdownToggle>
                                                    <ng-container
                                                            *ngTemplateOutlet="currentHandoffType"></ng-container>
                                                    <ng-container
                                                            *ngIf="deliveryToggleLoading; else dropdownArrow">
                                                        <div
                                                                class="de-sm-loader d-inline-block align-middle ms-2"
                                                                [style.border-top]="'1px solid ' +(order?.handoffType !== 2 && order?.handoffType !== 3 ? navSettings?.background_color : navSettings?.text_color)"></div>
                                                    </ng-container>
                                                    <ng-template #dropdownArrow>
                                                        <div
                                                                class="bi bi-chevron-down d-inline-block align-middle ps-1"
                                                                [style.color]="order?.handoffType !== 2 && order?.handoffType !== 3 ? navSettings?.background_color : navSettings?.text_color"></div>
                                                    </ng-template>
                                                </div>
                                                <div
                                                        class="order-type-dropdown drop-shadow"
                                                        [style.background-color]="navSettings?.background_color"
                                                        ngbDropdownMenu>
                                                    <div class="handoff-dropdown-selection">
                                                        <div
                                                                class="d-flex flex-row flex-nowrap justify-content-center align-content-center handoff-dropdown-item item-container pointer"
                                                                style="color: var(--nav-text-color)"
                                                                [class.fw-bold]="order?.handoffType === 0"
                                                                [class.me-2]="order?.handoffType !== 0"
                                                                *ngIf="this.order?.location.supportsPickup"
                                                                (click)="handoffModeSelect('pickup')"
                                                                (keydown.enter)="handoffModeSelect('pickup')"
                                                                ngbDropdownItem>
                                                            Pickup
                                                            <i
                                                                    [class.me-2]="order?.handoffType !== 0"
                                                                    [class.bi-check]="order?.handoffType === 0"
                                                                    class="bi ms-2"
                                                                    style="color: var(--nav-text-color)"></i>
                                                        </div>
                                                    </div>
                                                    <div class="handoff-dropdown-selection">
                                                        <div
                                                                class="d-flex flex-row flex-nowrap justify-content-center align-content-center handoff-dropdown-item item-container pointer"
                                                                [style.color]="navSettings?.text_color"
                                                                [class.fw-bold]="order?.handoffType === 1"
                                                                [class.me-2]="order?.handoffType !== 1"
                                                                *ngIf="this.order?.location.supportsCurbside"
                                                                (click)="handoffModeSelect('curbside')"
                                                                (keydown.enter)="handoffModeSelect('curbside')"
                                                                ngbDropdownItem>
                                                            Curbside
                                                            <i
                                                                    [class.me-2]="order?.handoffType !== 1"
                                                                    [class.bi-check]="order?.handoffType === 1"
                                                                    class="bi ms-2"
                                                                    style="color: var(--nav-text-color)"></i>
                                                        </div>
                                                    </div>
                                                    <div class="handoff-dropdown-selection">
                                                        <div
                                                                class="d-flex flex-row flex-nowrap justify-content-center align-content-center handoff-dropdown-item item-container pointer"
                                                                style="color: var(--nav-text-color)"
                                                                [class.fw-bold]="order?.handoffType === 4"
                                                                *ngIf="this.order?.location.supportsDriveThru"
                                                                (click)="handoffModeSelect('drive-thru')"
                                                                (keydown.enter)="handoffModeSelect('drive-thru')"
                                                                ngbDropdownItem>
                                                            Drive-Thru
                                                            <i
                                                                    [class.me-2]="order?.handoffType !== 4"
                                                                    [class.bi-check]="order?.handoffType === 4"
                                                                    class="bi ms-2"
                                                                    style="color: var(--nav-text-color)"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <div
                                                *ngIf="numberOfHandoffs.length <= 1"
                                                class="pill-btn-sm h-fit-content ms-2"
                                                [ngClass]="order?.handoffType !== 2 && order?.handoffType !== 3 ? 'pill-btn-navbar' : 'pill-btn-navbar-inactive'">
                                            <div (click)="handoffModeSelect('pickup')"
                                                 (keydown.enter)="handoffModeSelect('pickup')">
                                                <ng-container
                                                        *ngTemplateOutlet="currentHandoffType"></ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-template #fakePills>
                                    <div
                                            class="d-flex flex-nowrap h-100 align-items-center ps-2"
                                            [class.toggle-opacity]="deliveryToggleLoading">
                                        <ng-container *ngIf="vendorSetup.delivery_provider !== 'none'">
                                            <button
                                                    type="button"
                                                    tabindex="0"
                                                    class="pill-btn-sm h-fit-content pill-btn-navbar-inactive ion-text-capitalize"
                                                    (click)="handoffModeSelect('delivery')">
                                                Delivery
                                            </button>
                                        </ng-container>

                                        <button *ngIf="numberOfHandoffs.length <= 1  && vendorSetup.pickup_provider !== 'none'"
                                                (click)="locationClicked()" (keydown.enter)="locationClicked()"
                                                type="button"
                                                class="pill-btn-sm h-fit-content ms-2 ion-text-capitalize"
                                                [ngClass]="
                    router.url.includes('locations')
                      ? 'pill-btn-navbar'
                      : 'pill-btn-navbar-inactive'
                  ">Pickup
                                        </button>
                                    </div>
                                    <div
                                            style="color: var(--nav-text-color)"
                                            class="d-none d-xl-flex align-items-center ps-2">
                                        |
                                    </div>
                                </ng-template>

                                <div
                                        *ngIf="order$ | async"
                                        style="color: var(--nav-text-color)"
                                        class="d-none d-xl-flex align-items-center ps-2">
                                    |
                                </div>

                                <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                                    <ng-container *ngIf="vendorSetup.location_provider !== 'none'">
                                        <div
                                                class="d-flex align-items-center ps-2"
                                                size="auto"
                                                id="cy-location-link">
                                            <div
                                                    ngbDropdown
                                                    #headerDrop="ngbDropdown"
                                                    container="body"
                                                    display="dynamic">
                                                <app-nav-order-type
                                                        tabindex="0"
                                                        style="color: var(--nav-text-color)"
                                                        [order]="order"
                                                        id="location-dropdown"
                                                        (keydown.enter)="openDropdown('location-dropdown')"
                                                        ngbDropdownToggle
                                                ></app-nav-order-type>
                                                <app-order-type-list
                                                        class="location-dropdown drop-shadow"
                                                        ngbDropdownMenu
                                                        (addNewOrderType)="
                        newOrderTypeClicked(); headerDrop.close()
                      "
                                                        (startNewOrder)="
                        startOrder($event); headerDrop.close()
                      "></app-order-type-list>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>

                            <ng-container *ngIf="order$ | async as order">
                                <div
                                        class="navbar-link other d-flex align-items-center"
                                        routerLinkActive="active-link"
                                        [routerLink]="
                '/menu/' +
                (order?.location?.slugURL
                  ? order?.location.slugURL
                  : order?.location.locationID)
              "
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        style="color: var(--nav-text-color)">
                                    Menu
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </section>

                <!-- right column -->
                <section
                        class="d-flex flex-row flex-nowrap align-items-center justify-content-end py-0 pe-0 ps-3"
                        [class.pe-3]="!navSettings?.fixed_navbar_width"
                        style="overflow-x: hidden">
                    <div class="d-none d-xxl-flex flex-row flex-nowrap">
                      <app-page-link
                        *ngFor="let page of customPageLinks"
                        [page]="page"
                        [user]="user$ | async"
                        [isActive]="page.slug | navLink"
                        (childPageClicked)="childPageClicked($event)"
                        (pageClicked)="pageClicked(page)">
                      </app-page-link>
                    </div>

                    <ng-container *ngIf="customPageLinks.length > 0">
                        <div class="d-flex d-xxl-none" ngbDropdown container="body">
                            <a
                                    type="button"
                                    class="d-flex align-items-center justify-content-center navbar-link me-4 ms-0"
                                    id="more-dropdown"
                                    routerLinkActive="active-link"
                                    style="color: var(--nav-text-color)"
                                    tabindex="0"
                                    (keydown.enter)="openDropdown('more-dropdown')"
                                    ngbDropdownToggle>
                                More
                                <div
                                        class="bi bi-chevron-down ps-1 d-inline-block align-middle my-auto"
                                        style="padding-top: 3px; color: var(--nav-text-color)"></div>
                            </a>

                            <div
                                    ngbDropdownMenu="more-dropdown"
                                    class="dropdown-menu dropdown-menu-navbar drop-shadow"
                                    style="background-color: var(--nav-background-color)">
                                <div class="inner-information">
                                    <ul class="m-0 p-0">
                                        <ng-container *ngFor="let page of customPageLinks; index as i">
                                            <ng-container *ngIf="user$ | async as user">
                                                <ng-container
                                                        *ngIf="page.show_on_navbar && (!page.require_login || (page.require_login && !user?.isGuest))">
                                                    <a
                                                            class="text-decoration-none item-container pointer account-item me-3"
                                                            (keydown.enter)="pageClicked(page)"
                                                            (click)="pageClicked(page)"
                                                            tabindex="0"
                                                            style="color: var(--nav-text-color)"
                                                            ngbDropdownItem>
                                                        {{ page?.title }}
                                                        <ng-container *ngIf="page?.icon">
                                                            <i
                                                                    style=" font-size: 16px; color: var(--nav-icon-color);"
                                                                    class="bi ms-auto"
                                                                    [ngClass]="'bi-' + page?.icon"></i>
                                                        </ng-container>
                                                    </a>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!(thirdPartyWrapped$ | async)">
                        <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                            <ng-container *ngIf="vendorSetup.user_provider !== 'none'">
                                <ng-container *ngIf="hasUser(); else noUser">
                                    <a
                                            id="userAcctLink"
                                            class="d-flex navbar-link flex-nowrap align-items-center me-4 ms-0"
                                            routerLinkActive="active-link"
                                            style="color: var(--nav-text-color)"
                                            container="body"
                                            [routerLink]="profileRoute">
                                        <app-custom-icon
                                                class=""
                                                default="bi-person"
                                                customIconKey="profile_navigation_icon"
                                                size="20">
                                        </app-custom-icon>
                                        <div class="ps-1 m-0">
                                            Hi, {{ user.firstName }}
                                        </div>
                                    </a>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-template #noUser>
                            <a
                                    class="navbar-link ms-0 me-4"
                                    *ngIf="!hasUser()"
                                    routerLinkActive="active-link"
                                    [routerLink]="loginRoute"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    style="color: var(--nav-emphasized-text-color)">
                                Sign In
                            </a>
                            <a
                                    class="navbar-link ms-0 me-4"
                                    *ngIf="!hasUser()"
                                    [routerLink]="creatAccountRoute"
                                    routerLinkActive="active-link"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    style="color: var(--nav-emphasized-text-color)">
                                Join Now
                            </a>
                        </ng-template>
                    </ng-container>

                    <ng-container *ngIf="vendorSetup$ | async as vendorSetup">
                        <ng-container *ngIf="vendorSetup.order_provider !== 'none'">
                            <app-desktop-nav-cart
                                    [order]="order"
                                    [isProductPrice]="showPrice"
                                    [cartIcon]="cartIcon"
                                    [@collapseHorizontally]="currentGroupOrder?.role === 'member'"
                                    [currencyCode]="currencyCode"
                                    [buttonText]="buttonText"
                                    [showOrder]="showOrder"
                                    (addNewOrderType)="newOrderTypeClicked()"
                                    style="min-width: fit-content"></app-desktop-nav-cart>
                        </ng-container>
                    </ng-container>
                </section>
            </ng-container>
        </div>
    </ng-container>
</nav>

<ng-template #mainLogo>
    <img
            id="appNavbarHeader"
            class="logo"
            alt="logo"
            tabindex="0"
            (click)="homeClicked()"
            (keydown.enter)="homeClicked()"
            [class.m-3]="!navSettings?.fixed_navbar_width"
            *ngIf="branding?.main_logo"
            [src]="branding?.main_logo.data.full_url"/>
</ng-template>
