<div class="popup-body p-3" style="display:grid; width: 100%; place-items: center; grid-gap: 20px">
  <div style="display: grid; width: 100%; place-items: center end" (click)="activeModal.close('Close click')">
    <i class="bi bi-x-circle font-size-h1 d-flex"></i>
  </div>
  <i class="bi bi-cash-stack" style="font-size: 100px; height: 100px; width: 100px;"></i>
  <div class="header-font modal-title">Convert Rewards Dollars to a Reward</div>
  <div class="modal-text ln-1">
    Choose how many banked rewards dollars you would like to convert into a reward. When you create a reward, your selected amount of dollars will be deducted from your dollar count and turned into a redeemable reward.
  </div>
  <div style="font-size: 20px;" class="header-font modal-title">
    Current Dollars: {{ currentDollars | currency: currencyCode }}
  </div>

  <form (ngSubmit)="redeemClicked()" [formGroup]="dollarConversion" style="display:grid; width: 100%; place-items: center; grid-gap: 20px">
    <div class="form-group m-0 p-0 w-100">
      <label for="dollarAmount">Enter Dollar Amount</label>
      <input type="text" id="dollarAmount" formControlName="dollarAmount" class="form-control de-input m-0" [dropSpecialCharacters]="false" [specialCharacters]="['.']" mask="0*.00" placeholder="$0.00" inputmode="decimal">
    </div>
    <ng-container *ngIf="dollarAmount.invalid && (dollarAmount.dirty || dollarAmount.touched)">
      <small class="text-danger" *ngIf="dollarAmount.errors.required">Amount Required</small>
    </ng-container>
    <button class="de-primary-btn w-100" (click)="redeemClicked()">Convert to Reward</button>
<!--    <ion-list padding lines="full">-->

<!--      <ion-row>-->
<!--        <ion-label position="floating" style="margin-left: 5px;">Enter Dollar Amount</ion-label>-->
<!--      </ion-row>-->
<!--      <ion-row>-->
<!--        <ion-input formControlName="dollarAmount" name="dollarAmount" type="name" placeholder="$0.00"-->
<!--                   id="dollarAmount" class="de-input">-->
<!--        </ion-input>-->
<!--      </ion-row>-->

<!--      <ion-row *ngIf="dollarAmount.invalid && (dollarAmount.dirty || dollarAmount.touched)" class="de-form-error">-->
<!--        <ion-col>-->
<!--          <div *ngIf="dollarAmount.errors.required">Amount Required</div>-->
<!--        </ion-col>-->
<!--      </ion-row>-->

<!--      &lt;!&ndash; <div padding>-->
<!--        <button *ngIf="!isLoading" class="de-primary-btn primary-btn" id="confirm" expand="block" type="submit">-->
<!--          Sign In-->
<!--        </button>-->
<!--        <button *ngIf="isLoading" class="de-primary-btn primary-btn" color="primary" id="confirm" expand="block">-->
<!--          Submitting <ion-spinner name="dots"></ion-spinner>-->
<!--        </button>-->
<!--      </div> &ndash;&gt;-->

<!--      <ion-row class="modal-btns">-->
<!--        <button class="de-primary-btn" id="modify" expand="full" (click)="redeemClicked()">-->
<!--          Convert to Reward-->
<!--        </button>-->
<!--      </ion-row>-->
<!--    </ion-list>-->
  </form>
<!--  <ion-row class="ion-justify-content-end">-->
<!--    <ion-col class="ion-float-end" size="auto">-->
<!--      <i class="bi bi-x-circle font-size-h1 d-flex exit-icon" (click)="activeModal.close('Close click')"></i>-->
<!--    </ion-col>-->
<!--  </ion-row>-->

<!--  <ion-row style="padding: 0 20px;">-->
<!--    <ion-col class="modal-contents ion-no-padding">-->
<!--      -->
<!--      <ion-row class="ion-justify-content-center">-->
<!--            <i class="bi bi-cash-stack" style="font-size: 100px; height: 100px; width: 100px;"></i>-->
<!--      </ion-row>-->
<!--      <ion-row class="header-font modal-title">-->
<!--        Convert Rewards Dollars to a Reward-->
<!--      </ion-row>-->
<!--      <ion-row class="modal-text ln-1">-->
<!--        Choose how many banked rewards dollars you would like to convert into a reward. When you create a reward, your selected amount of dollars will be deducted from your dollar count and turned into a redeemable reward.-->
<!--      </ion-row>-->
<!--      <ion-row style="font-size: 20px;" class="header-font modal-title">-->
<!--        Current Dollars: {{ currentDollars | currency }}-->
<!--      </ion-row>-->

<!--      <form (ngSubmit)="redeemClicked()" [formGroup]="dollarConversion">-->
<!--        <ion-list padding lines="full">-->

<!--          <ion-row>-->
<!--            <ion-label position="floating" style="margin-left: 5px;">Enter Dollar Amount</ion-label>-->
<!--          </ion-row>-->
<!--          <ion-row>-->
<!--            <ion-input formControlName="dollarAmount" name="dollarAmount" type="name" placeholder="$0.00"-->
<!--              id="dollarAmount" class="de-input">-->
<!--            </ion-input>-->
<!--          </ion-row>-->

<!--          <ion-row *ngIf="dollarAmount.invalid && (dollarAmount.dirty || dollarAmount.touched)" class="de-form-error">-->
<!--            <ion-col>-->
<!--              <div *ngIf="dollarAmount.errors.required">Amount Required</div>-->
<!--            </ion-col>-->
<!--          </ion-row>-->

<!--        &lt;!&ndash; <div padding>-->
<!--          <button *ngIf="!isLoading" class="de-primary-btn primary-btn" id="confirm" expand="block" type="submit">-->
<!--            Sign In-->
<!--          </button>-->
<!--          <button *ngIf="isLoading" class="de-primary-btn primary-btn" color="primary" id="confirm" expand="block">-->
<!--            Submitting <ion-spinner name="dots"></ion-spinner>-->
<!--          </button>-->
<!--        </div> &ndash;&gt;-->

<!--          <ion-row class="modal-btns">-->
<!--            <button class="de-primary-btn" id="modify" expand="full" (click)="redeemClicked()">-->
<!--              Convert to Reward-->
<!--            </button>-->
<!--          </ion-row>-->
<!--        </ion-list>-->
<!--      </form>-->

<!--    </ion-col>-->
<!--  </ion-row>-->
</div>
