import { Injectable } from '@angular/core';
import { MessagingProvider } from '../../providers/messaging-provider.interface';
import { OneSignal, OSDisplayType } from '@awesome-cordova-plugins/onesignal/ngx';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { MainSettings } from '../directus/interfaces/main-settings.interface';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnesignalProviderService implements MessagingProvider{

  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  constructor(
    private oneSignal: OneSignal
  ) {}

  registerApp(): Observable<boolean> {
    return this.mainSettings$.pipe(filter(ms => ms !== null), take(1), map(ms => {
      if (ms.onesignal_app_id) {
        window['plugins'].OneSignal.setAppId(ms.onesignal_app_id);
        window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse();
        this.oneSignal.startInit(ms.onesignal_app_id);
        this.oneSignal.inFocusDisplaying(OSDisplayType.Notification);
        this.oneSignal.iOSSettings({ kOSSettingsKeyAutoPrompt: true, kOSSettingsKeyInAppLaunchURL: true });
        this.oneSignal.endInit();
      }
      return true;
    }));
  }
}
