import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { IonicModule, Platform } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../environments/environment';

/* third party modules */
import { IvyCarouselModule } from 'ngx-ivy-responsive-carousel';

import { QRCodeModule } from 'angularx-qrcode';
import * as Sentry from '@sentry/angular-ivy';
import { GooglePlaceModule } from '@barkhub/ngx-google-places-autocomplete';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';

/* modules */
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@common/shared.module';
import { PayInStorePage } from '@modules/loyalty/components/pay-in-store/pay-in-store.page';

/* components */
import { AppComponent } from './app.component';
import { TimeSelectionComponent } from '@modules/cart/components/time-selection/time-selection.component';
import { MobileRedemptionComponent } from '@modules/loyalty/components/mobile-redemption/mobile-redemption.component';
import { RewardsBalancesComponent } from '@modules/loyalty/components/rewards-balances/rewards-balances.component';
import { HomeReorderComponent } from '@modules/brochure/components/home-reorder/home-reorder.component';
import { InstafeedComponent } from '@modules/brochure/components/instafeed/instafeed.component';
import { FeaturedItemsComponent } from '@modules/brochure/components/featured-items/featured-items.component';
import { InStoreRedemptionComponent } from '@modules/loyalty/components/in-store-redemption/in-store-redemption.page';
import { HomeMenuComponent } from '@modules/brochure/components/home-menu/home-menu.component';

/* services */
import { ErrorService } from '../services/error.service';
import { ThemeService } from '../services/theme.service';
import { BrandingService } from '../services/branding.service';
import { MobileService } from '../services/mobile.service';
import { GeocodingService } from '@modules/locations/services/geocoding.service';

import { LocationSearchService } from '@modules/locations/services/locations-search.service';
import { AnnouncementService } from '@modules/brochure/services/announcement.service';
import { UserLocationService } from '@modules/locations/services/user-locations.service';
import { SentryErrorHandler } from '../services/sentry-error.service';
import { HtmlParserService } from '@modules/brochure/services/html-parser.service';

/* guards */
import { ForceLoginGuard } from '../guards/force-login.guard';
import { AuthGuard } from '../guards/auth.guard';
import { MobileGuard } from '../guards/mobile.guard';
import { VendorGuard } from '../guards/vendor.guard';
import { TablesideGuard } from '../guards/tableside.guard';
import { CheckoutGuard } from '@modules/checkout/guards/checkout.guard';
import { BrochureGuard } from '@modules/brochure/guards';
import { HomePageGuard } from '@modules/auth/guards/home-page.guard';
import { SitemapGuard } from '../guards/sitemap.guard';
import { TablesideHiddenGuard } from '../guards/tableside-hidden.guard';

/* store */
import { OrderState } from '../store/state/order.state';
import { AppState } from '../store/state/app.state';
import { UserState } from '../store/state/user.state';
import { LocationState } from '../store/state/location.state';
import { MenuState } from '../store/state/menu.state';
import { NavigationModule } from '@modules/navigation/navigation.module';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { GiftCardOrderState } from '@modules/gift-card/store/state/gift-card-order.state';
import { ProjectKeyService } from '../services/project-key.service';
import { PipesModule } from '../pipes/pipes.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MenuGuard } from '@modules/menu/guards';
import { HttpLoadingInterceptor } from '@app/interceptors/http-loading.interceptor';
import { PendingRequestsService } from '@app/services/pending-requests.service';
import { UtilityModule } from '@modules/utility/utility.module';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxBarcodeModule } from 'ngx-ivy-barcode';
import { DeviceDetectionService } from '../services/device-detection.service';
import { CompleteProfileGuard } from '../guards/complete-profile.guard';
import { NotificationService } from '../services/notification.service';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

const httpInterceptorProviders: Provider[] = [{ provide: HTTP_INTERCEPTORS, useClass: HttpLoadingInterceptor, multi: true }];

function initializeAppFactory(projectKeyService: ProjectKeyService): () => Promise<string> {
  return () => projectKeyService.getProjectKeyCall().toPromise();
}

@NgModule({
  declarations: [AppComponent, PayInStorePage, HomeMenuComponent, HomeReorderComponent, InstafeedComponent, FeaturedItemsComponent],
  imports: [
    NgxsModule.forRoot([MenuState, LocationState, OrderState, AppState, UserState, GiftCardOrderState], {
      developmentMode: environment.debug,
    }),
    // NgxsLoggerPluginModule.forRoot({
    //   disabled: environment.production,
    // }),
    // NgxsStoragePluginModule.forRoot({
    //   key: [AppState],
    //   storage: StorageOption.LocalStorage,
    // }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxSpinnerModule,
    BrowserModule,
    // NgxHotjarModule.forRoot(environment.hotjar),
    BrowserAnimationsModule,
    QRCodeModule,
    HttpClientModule,
    HttpClientJsonpModule,
    // NgHttpLoaderModule.forRoot(),
    NgbModule,
    IonicModule.forRoot({ animated: true, _forceStatusbarPadding: true }),
    AppRoutingModule,
    SharedModule,
    GooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    IvyCarouselModule,
    NavigationModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-NLBZ78K',
    }),
    PipesModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
    UtilityModule,
    NgxBarcodeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [ProjectKeyService],
      multi: true,
    },
    Platform,
    ForceLoginGuard,
    AuthGuard,
    ThemeService,
    BrandingService,
    MobileService,
    GeocodingService,
    ErrorService,
    MobileGuard,
    VendorGuard,
    TablesideGuard,
    TablesideHiddenGuard,
    LocationSearchService,
    NgbActiveModal,
    AnnouncementService,
    CheckoutGuard,
    UserLocationService,
    HtmlParserService,
    SitemapGuard,
    {
      provide: ErrorHandler,
      useValue: new SentryErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    OneSignal,
    BrochureGuard,
    HomePageGuard,
    MenuGuard,
    PendingRequestsService,
    DeviceDetectionService,
    CompleteProfileGuard,
    NotificationService,
    // Keep this last
    ...httpInterceptorProviders,
  ],
  exports: [HomeReorderComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
