import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyBaseURLService } from '../../services/proxy-base-url.service';
import { BehaviorSubject, from, Observable, switchMap } from 'rxjs';
import { CreatePaymentIntentResponse, GetTokenResponse } from './interfaces/responses.interface';
import {
  DiscoverResult,
  ErrorResponse,
  IClearCachedCredentialsResponse,
  IClearReaderDisplayResponse,
  ICollectRefundPaymentMethodResponse,
  IDisconnectResponse,
  IPaymentIntent,
  IRefund,
  ISdkManagedPaymentIntent,
  ISetReaderDisplayRequest,
  ISetReaderDisplayResponse,
  Reader,
  StripeTerminal,
  Terminal,
} from '@stripe/terminal-js';
import { loadStripeTerminal } from '@stripe/terminal-js/pure';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { ToastService } from '../../services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class StripeApiService {
  private vendorName = 'stripe';
  private stripe: StripeTerminal;
  private _terminal: Terminal;
  private terminalSubject = new BehaviorSubject<Terminal>(null);
  private terminal$ = this.terminalSubject.asObservable();

  assignedReader: Reader;

  constructor(
    private http: HttpClient,
    private urlService: ProxyBaseURLService,
    private toast: ToastService
  ) {}

  retrieveToken(): Observable<GetTokenResponse> {
    const resource = '/token';
    return this.urlService
      .getVendorBaseURL(this.vendorName)
      .pipe(switchMap(baseURL => this.http.post<GetTokenResponse>(baseURL + resource, {})));
  }

  initializeSDK(): Observable<Terminal> {
    return from(loadStripeTerminal()).pipe(
      map(stripeTerminal => {
        this.terminalSubject.next(null);
        this.stripe = stripeTerminal;
        return this.stripe.create({
          onFetchConnectionToken: () =>
            this.retrieveToken()
              .pipe(map(res => res.secret))
              .toPromise(),
          onUnexpectedReaderDisconnect: async event => {
            this.terminalSubject.next(null);
            await this.initializeSDK().toPromise();
            if (this.assignedReader) {
              const readers = await this.discoverReaders().toPromise();
              const reader = readers.discoveredReaders.find(r => r.id === this.assignedReader.id);
              this.assignedReader = null;
              try {
                await this.disconnectReader().toPromise();
                await this.connectReader(reader).toPromise();
              } catch (e) {
                await this.connectReader(reader).toPromise();
              }
            }
          },
        });
      }),
      tap(terminal => this.terminalSubject.next(terminal))
    );
  }

  private handleErrorResponse(response: ErrorResponse | any): any {
    if (typeof response === 'object' && 'error' in response) {
      if (response.error.code === 'terminal_reader_timeout') {
        throw new Error('terminal_reader_timeout');
      } else {
        switch (response.error.code) {
          case 'terminal_location_country_unsupported':
            throw new Error('Terminals cannot be used in the specified country');
          case 'terminal_reader_busy':
            throw new Error('Reader is busy');
          case 'terminal_reader_hardware_fault':
            throw new Error('Reader hardware fault');
          case 'terminal_reader_invalid_location_for_payment':
            throw new Error('Reader is not in a valid location for payment');
          case 'terminal_reader_offline':
            throw new Error('Reader is offline');
        }
      }
      throw new Error(response.error.message);
    }
    return response;
  }

  discoverReaders(): Observable<DiscoverResult> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.discoverReaders())),
      map(this.handleErrorResponse)
    );
  }

  connectReader(reader: Reader): Observable<{ reader: Reader }> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.connectReader(reader))),
      tap(res => {
        if (!(typeof res === 'object' && 'error' in res)) {
          this.assignedReader = res.reader;
        }
      }),
      map(this.handleErrorResponse)
    );
  }

  disconnectReader(): Observable<IDisconnectResponse> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.disconnectReader())),
      map(this.handleErrorResponse)
    );
  }

  getConnectionStatus(): Observable<string> {
    return this.terminal$.pipe(
      filter(terminal => !!(terminal && this.assignedReader)),
      take(1),
      map(terminal => terminal.getConnectionStatus()),
      map(this.handleErrorResponse)
    );
  }

  getPaymentStatus(): Observable<string> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      map(terminal => terminal.getPaymentStatus()),
      map(this.handleErrorResponse)
    );
  }

  clearCachedCredentials(): Observable<IClearCachedCredentialsResponse> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.clearCachedCredentials())),
      map(this.handleErrorResponse)
    );
  }

  createPaymentIntent(amount: number): Observable<CreatePaymentIntentResponse> {
    const resource = '/paymentIntent';
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => this.http.post<CreatePaymentIntentResponse>(baseURL + resource, { amount })),
      map(this.handleErrorResponse)
    );
  }

  collectPaymentMethod(paymentIntentID: string): Observable<object> {
    return this.terminal$.pipe(
      filter(terminal => !!(terminal && this.assignedReader)),
      take(1),
      switchMap(terminal => from(terminal.collectPaymentMethod(paymentIntentID))),
      map(this.handleErrorResponse),
      catchError(err => {
        return this.clearReaderDisplay().pipe(
          map(() => {
            throw err;
          })
        );
      })
    );
  }

  cancelCollectPaymentMethod(): Observable<object> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.cancelCollectPaymentMethod())),
      map(this.handleErrorResponse)
    );
  }

  processPayment(paymentIntent: ISdkManagedPaymentIntent): Observable<{ paymentIntent: IPaymentIntent }> {
    return this.terminal$.pipe(
      filter(terminal => !!(terminal && this.assignedReader)),
      take(1),
      switchMap(terminal => from(terminal.processPayment(paymentIntent))),
      map(this.handleErrorResponse)
    );
  }

  setReaderDisplay(displayInfo: ISetReaderDisplayRequest): Observable<ISetReaderDisplayResponse> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.setReaderDisplay(displayInfo))),
      map(this.handleErrorResponse)
    );
  }

  clearReaderDisplay(): Observable<IClearReaderDisplayResponse> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.clearReaderDisplay())),
      map(this.handleErrorResponse)
    );
  }

  collectRefundablePaymentMethod(chargeID: string, amount: number, currency = 'usd'): Observable<ICollectRefundPaymentMethodResponse> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.collectRefundPaymentMethod(chargeID, amount, currency))),
      map(this.handleErrorResponse)
    );
  }

  processRefund(): Observable<{ refund: IRefund }> {
    return this.terminal$.pipe(
      filter(terminal => !!terminal),
      take(1),
      switchMap(terminal => from(terminal.processRefund())),
      map(this.handleErrorResponse)
    );
  }

  capturePaymentIntent(paymentIntentID: string): Observable<{ description: string; id: string; status: string }> {
    const resource = '/paymentIntent/capture';
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL =>
        this.http.post<{ id: string; status: string; description: string }>(baseURL + resource, { id: paymentIntentID })
      ),
      map(this.handleErrorResponse)
    );
  }

  refundTransaction(paymentIntentID: string): Observable<{ refund: string; success: boolean }> {
    const resource = '/paymentIntent/refund';
    return this.urlService.getVendorBaseURL(this.vendorName).pipe(
      switchMap(baseURL => this.http.post<{ refund: string; success: boolean }>(baseURL + resource, { intent_id: paymentIntentID })),
      map(this.handleErrorResponse)
    );
  }
}
