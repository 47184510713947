import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from 'src/services/vendor-config-service/user.service';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, filter, take } from 'rxjs/operators';
import { DirectusService } from 'src/vendors/directus/directus.service';
import { Store } from '@ngxs/store';
import { User } from '../interfaces/user.interface';
import { CustomPage } from '../vendors/directus/interfaces/custom-page.interface';
import { NavigationService } from '@modules/navigation/services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  page;

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private userService: UserService,
    private directus: DirectusService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url = state.url;
    let endIndex = url.length;

    const queryIndex = url.indexOf('?');
    const fragmentIndex = url.indexOf('#');

    if (queryIndex !== -1 && queryIndex < endIndex) {
      endIndex = queryIndex;
    }

    if (fragmentIndex !== -1 && fragmentIndex < endIndex) {
      endIndex = fragmentIndex;
    }

    // Remove the leading '/' and extract the slug
    const currentSlug = url.slice(1, endIndex);

    let redirect = true;

    return this.store
      .select(state => state.app.customPages)
      .pipe(
        filter(cp => cp !== null),
        take(1),
        switchMap((customPages: CustomPage[]) => {
          return this.store
            .select(state => state.user.user)
            .pipe(
              filter(u => u !== null),
              take(1),
              map((user: User) => {
                if (customPages) {
                  customPages.forEach(page => {
                    if (page.slug === currentSlug && !page.require_login) {
                      redirect = false;
                    }
                  });
                }

                if (!user.isGuest || !redirect) {
                  return true;
                } else {
                  this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                  return false;
                }
              }),
              catchError(() => {
                return of(false);
              })
            );
        })
      );
  }
}
