import { AfterViewInit, Component, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Balance, RewardsBalances } from '../../../../interfaces/rewards-balances.interface';
import { LoyaltyInstructionsModalComponent } from '@modules/loyalty/components';
import { ModalController } from '@ionic/angular';
import { TextField } from '../../../../vendors/directus/interfaces/text-field.interface';
import { DirectusService } from '../../../../vendors/directus/directus.service';
import { PaytronixConfiguration } from '../../../../vendors/directus/interfaces/paytronix-configuration.interface';
import { GlobalStateModel } from '../../../../store/state.model';
import { MobileAppSettings } from '../../../../vendors/directus/interfaces/mobile-app-settings.interface';

@Component({
  selector: 'app-loyalty-tier-progress',
  templateUrl: './loyalty-tier-progress.component.html',
  styleUrls: ['loyalty-tier-progress.component.scss'],
})
// export class LoyaltyTierProgressComponent extends SubscriptionComponent implements AfterViewInit {
export class LoyaltyTierProgressComponent implements AfterViewInit {
  @Select(state => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  @Select((state: GlobalStateModel) => state.app.mobileAppSettings) mobileAppSettings$: Observable<MobileAppSettings>;

  @Input() layout: 'compact' | 'standard' | 'desktop' | 'responsive' = 'standard';
  loyaltyWallet: number = null;
  titleFieldCopy = 'Loyalty Tier';
  tierDetailsCopy = 'Tier Details';
  currentTierLabel = 'Current Tier';
  currentTierValue = 0;
  tierValueType = 'Points';
  nextTierLimit = 100;
  nextTierLabel = 'Next Tier';
  loyaltyTiers = [];
  tierFound = false;
  currentLastTier: any = null;
  lastTier = false;
  noTiers = false;

  constructor(
    private modalController: ModalController,
    private directus: DirectusService
  ) {
    // super();
    this.directus.getPaytronixSettings().subscribe((settings: PaytronixConfiguration) => {
      this.loyaltyWallet = Number(settings.tier_wallet_code);
      this.loyaltyTiers = settings.loyalty_tiers;
    });
    this.textFields$.subscribe((textFields: TextField) => {
      this.titleFieldCopy = textFields.tier_title_copy;
      this.tierDetailsCopy = textFields.tier_details_copy;
    });
  }

  ngAfterViewInit() {
    // this.subs.push(
    this.rewardPoints$.subscribe(balances => {
      if (balances) {
        if (balances.tier) {
          this.currentTierLabel = balances.tier.name;
        }
        if (balances.pointBalances && balances.pointBalances.length) {
          const balanceIndex = balances.pointBalances.findIndex((balance: Balance) => {
            return balance.code === this.loyaltyWallet;
          });
          this.currentTierValue = balanceIndex > 0 ? balances.pointBalances[balanceIndex].value : balances.pointBalances[0].value;
          this.tierValueType = balanceIndex > 0 ? balances.pointBalances[balanceIndex].name : balances.pointBalances[0].name;
          if (!this.loyaltyTiers || this.loyaltyTiers.length === 0) {
            this.noTiers = true;
          }
          if (!this.noTiers) {
            this.loyaltyTiers.forEach(tier => {
              this.currentLastTier = tier;
              if (!this.tierFound && this.currentTierValue < Number(tier.threshold)) {
                this.nextTierLimit = tier.threshold;
                this.nextTierLabel = tier.label;
                this.tierFound = true;
              }
            });
            if (!this.tierFound) {
              this.lastTier = true;
              if (this.currentLastTier) {
                this.nextTierLimit = this.currentLastTier.threshold;
                this.nextTierLabel = this.currentLastTier.label;
              }
              this.tierFound = true;
            }
          }
        }
      }
    });
    // );
  }

  openLoyaltyInfo() {
    this.modalController
      .create({
        component: LoyaltyInstructionsModalComponent,
        showBackdrop: true,
      })
      .then(modal => modal.present());
  }
}
