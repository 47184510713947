export enum LocalStorageKey {
  NOVADINE_USER_ID = 'NovaDineUserKey',
  OLO_ACCESS_TOKEN = 'OLOAccessToken',
  OLO_AUTH_TOKEN = 'OLOAuthToken',
  PUNCHH_AUTH_TOKEN = 'PunchhAuthToken',
  PUNCHH_ACCESS_TOKEN = 'PunchhAccessToken',
  PUNCHH_MOBILE_AUTH_TOKEN = 'PunchhMobileAuthToken',
  PUNCHH_MOBILE_ACCESS_TOKEN = 'PunchhMobileAccessToken',
  PUNCHH_OAUTH_JWT_TOKEN = 'PunchhOauthJwtToken',
  PUNCHH_OAUTH_CODE = 'PunchhOauthCode',
  PUNCHH_APPLE_USER_DATA = 'PunchhAppleUserData',
  PAYTRONIX_AUTH_TOKEN = 'PaytronixAuthToken',
  PAYTRONIX_ACCESS_TOKEN = 'PaytronixAccessToken',
  PAYTRONIX_READ_NOTIFICATIONS = 'PaytronixReadNotifications',
  PERSONICA_ACCESS_TOKEN = 'PersonicaAccessToken',
  PERSONICA_MEMBER_ID = 'PersonicaMemberID',
  PERSONICA_ACCESS_TOKEN_EXPIRATION = 'PersonicaAccessTokenExpiration',
  SPENDGO_MEMBER_ID = 'SpendgoMemberID',
  SPENDGO_ACCESS_TOKEN = 'SpendgoAccessToken',
  SPENDGO_ACCESS_TOKEN_EXPIRATION = 'SpendgoAccessTokenExpiration',
  SPENDGO_REFRESH_TOKEN = 'SpendgoRefreshToken',
  SPENDGO_REFRESH_TOKEN_EXPIRATION = 'SpendgoRefreshTokenExpiration',
  GOOGLE_RWG_TOKEN = 'rwg_token',
  GOOGLE_RWG_TOKEN_EXPIRATION = 'rwg_token_expiration',
}

export enum Browser {
  CHROME = 'Chrome',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
  OPERA = 'Opera',
  IE = 'IE',
  UNKNOWN = 'unknown',
}
