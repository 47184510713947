import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GlobalStateModel } from '../../../../store/state.model';
import { PwaSettings } from '../../../../vendors/directus/interfaces/pwa-settings.interface';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from '../../../../environments/environment';
import { Capacitor } from '@capacitor/core';
import { BundleInfo, CapacitorUpdater, latestVersion } from '@appmassive/capacitor-updater';
import { ModalController } from '@ionic/angular';
import { filter, take } from 'rxjs/operators';
import { Preferences as Storage } from '@capacitor/preferences';
import { OnboardingSlidesComponent } from '@common/components';
import { Onboarding } from '../../../../vendors/directus/interfaces/onboarding.interface';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';

enum DownloadState {
  CHECKING,
  FOUND,
  DOWNLOADING,
}

@Component({
  selector: 'app-appmassive-updater',
  templateUrl: './appmassive-updater.component.html',
  styleUrls: ['appmassive-updater.component.scss'],
})
export class AppmassiveUpdaterComponent implements AfterViewInit {
  @Select((state: GlobalStateModel) => state.app.splashScreen) splashScreen$: Observable<PwaSettings>;
  @Select((state: GlobalStateModel) => state.app.onboardingSlides) onboardingSlides$: Observable<Onboarding[]>;
  @Select((state: GlobalStateModel) => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  constructor(private modalCtrl: ModalController) {}

  currentState = DownloadState.CHECKING;
  downloadState = DownloadState; // for template use
  capacitor = Capacitor; // for template use

  checkingCopy = 'Checking for fresh goodness…🥖';

  newVersionFoundCopy = 'Deliciousness found…☕';

  private downloadStrings = [
    'Cooking Up New Features! 🍳',
    'Seasoning our App with Excellence! 🌶️',
    'Stirring in Fresh Enhancements! 🥄',
    'Rolling Out the Dough of Innovation! 🍕',
    'Whisking Up Some App Magic! ✨',
    'Baking Fresh Updates Just for You! 🥖',
    'Adding Some Extra Cheese to Our Experience! 🧀',
    'Mixing a Fresh Batch of Capabilities! 🍲',
    'Serving Up a Hot Plate of Newness! 🍽️',
    'App-etizers On the Way! 🥗',
    'Tossing Up Some Fresh New Features! 🥗',
    'Blending the Best for Our Users! 🍹',
    'Order Up! Fresh New Features Coming Your Way! 📣',
    "Sizzlin' Up Some App Enhancements! 🔥",
    'Whipping Up a Delightful Experience! 🍦',
    'Refreshing the Digital Menu! 🍹',
    'Setting the Table with New Tools! 🍽️',
    'Pouring a Fresh Cup of Innovation! ☕',
    'Dishing Out the Latest and Greatest! 🍲',
  ];

  downloadCopy = this.downloadStrings[Math.floor(Math.random() * this.downloadStrings.length)];

  ngAfterViewInit() {
    setTimeout(async () => {
      await SplashScreen.hide({ fadeOutDuration: 300 });
      await this.startDownloadProcess();
    }, 1000);
  }

  async startDownloadProcess() {
    if (environment.debug) {
      return this.finishDownload();
    }
    if (Capacitor.getPlatform() !== 'web') {
      try {
        const shouldUpdate = await this.checkVersion();
        if (shouldUpdate) {
          this.currentState = DownloadState.FOUND;
          try {
            const newestVersion = await CapacitorUpdater.getLatest();
            this.currentState = DownloadState.DOWNLOADING;
            const version: BundleInfo = await CapacitorUpdater.download({
              url: newestVersion.url,
              version: newestVersion.version,
            });
            if (version !== null) {
              try {
                await SplashScreen.show({ autoHide: false });
                await CapacitorUpdater.set(version);
              } catch (error: any) {
                await this.finishDownload(); // in case the set fail, otherwise the new app will have to hide it
                return;
              }
            } else {
              await this.finishDownload();
              return;
            }
          } catch (e) {
            await this.finishDownload();
            return;
          }
        } else {
          await this.finishDownload();
          return;
        }
      } catch (e) {
        await this.finishDownload();
        return;
      }
    } else {
      await this.finishDownload();
      return;
    }
  }

  async finishDownload() {
    await CapacitorUpdater.notifyAppReady();
    // const versionList = await CapacitorUpdater.list();
    // const latestVersion = await CapacitorUpdater.getLatest();
    // if (versionList.bundles.length > 1) {
    //   versionList.bundles.forEach(async (bundle: BundleInfo) => {
    //     if (bundle.version !== latestVersion.version) {
    //       await CapacitorUpdater.delete({
    //         id: bundle.id,
    //       });
    //     }
    //   });
    // }
    await this.modalCtrl.dismiss();
    this.openOnboarding();
  }

  async checkVersion() {
    const currentVersion = await CapacitorUpdater.current();
    let newestVersion: latestVersion;
    try {
      newestVersion = await CapacitorUpdater.getLatest();
    } catch (e) {
      return false;
    }
    return currentVersion.bundle.version !== newestVersion.version;
  }

  openOnboarding() {
    this.onboardingSlides$
      .pipe(
        filter(onboardingSlides => !!onboardingSlides),
        take(1)
      )
      .subscribe(async onboardingSlides => {
        const data = await Storage.get({ key: 'seenOnboarding' });
        if (!JSON.parse(data.value)) {
          if (Capacitor.getPlatform() !== 'web' && onboardingSlides.length) {
            const onboarding = await this.modalCtrl.create({
              component: OnboardingSlidesComponent,
              keyboardClose: false,
              animated: true,
              mode: 'ios',
              backdropDismiss: false,
            });
            await onboarding.present();
          }
        }
      });
  }
}
