import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
  TransferStoredValueToLoyaltyAccount,
} from '../../../../store/actions/user.actions';
import {GiftCard} from '../../../../interfaces/giftcard.interface';
import {ToastService} from '../../../../services/toast.service';
import {Select, Store} from '@ngxs/store';
import {DirectusService} from '../../../../vendors/directus/directus.service';
import {GlobalStateModel} from '../../../../store/state.model';
import {Observable} from 'rxjs';
import {RewardsBalances} from '../../../../interfaces/rewards-balances.interface';

@Component({
  selector: 'app-transfer-balance-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './transfer-balance-modal.component.html',
  styleUrls: ['transfer-balance-modal.component.scss'],
})
export class TransferBalanceModalComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.user.rewardPoints) rewardPoints$: Observable<RewardsBalances>;
  @Select((state: GlobalStateModel) => state.order.currencyCode) currencyCode$: Observable<string>;

  cardNumberForm = new UntypedFormGroup({
    cardNumber: new UntypedFormControl(null, [Validators.required]),
    pinNumber: new UntypedFormControl(null, [Validators.required]),
  });

  isLoading = false;

  errorMessage: string;
  errorOpen = false;
  successMessage: string;
  successOpen = false;
  canPurchaseNewCard = false;
  canReloadCard = false

  transferBalanceCopy = 'Already Have a Gift Card? Add Card Here';

  constructor(
      private modalController: ModalController,
      private store: Store,
      private toast: ToastService,
      private directus: DirectusService
  ) {
    this.cardNumberForm.valueChanges.subscribe(() => {
      this.errorOpen = false;
      this.successOpen = false;
      this.errorMessage = null;
      this.successMessage = null;
    });
  }

  ngOnInit() {
    this.directus.getPaytronixSettings().subscribe((settings) => {
      this.canPurchaseNewCard = settings.purchase_egift_cards;
      this.canReloadCard = settings.reload_loyalty_balance_via_gift_cards
    })
  }

  close(): void {
    this.modalController.dismiss();
  }

  startTransfer(): void {
    if (this.isLoading) {
      return;
    }
    if (this.cardNumberForm.valid) {
      const card: GiftCard = {
        cardNumber: this.cardNumber.value,
        cardPin: this.pinNumber.value,
        balance: null,
      };
      this.isLoading = true;
      this.store.dispatch(new TransferStoredValueToLoyaltyAccount(card)).subscribe(() => {
        this.toast.success('Balance Transferred');
        this.successMessage = 'Balance Transfer Successful';
        this.cardNumberForm.reset();
        this.successOpen = true;
        this.isLoading = false;
        setTimeout(() => {
          this.successOpen = false;
          this.successMessage = null;
        }, 5000);
      }, (error) => {
        this.isLoading = false;
        this.errorMessage = error.message;
        this.errorOpen = true;
        this.toast.danger(this.errorMessage);
      });
    } else {
      this.errorMessage = 'Please enter a valid card number and pin';
      this.errorOpen = true;
    }
  }

  get cardNumber(): AbstractControl {
    return this.cardNumberForm.get('cardNumber');
  }

  get pinNumber(): AbstractControl {
    return this.cardNumberForm.get('pinNumber');
  }
}
