import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { MobileService } from 'src/services/mobile.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LocationSearchService } from '@modules/locations/services/locations-search.service';
import { AddressSearch } from '@modules/locations/models/locations.address-search';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Order } from 'src/interfaces/order.interface';
import { Select, Store } from '@ngxs/store';
import { HandoffType } from '../../../../interfaces/handoff-type.enum';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { SetAddressSearch, SetDeliveryLocations } from '../../../../store/actions/location.actions';
import { ICoordinates } from '@modules/locations/models/coordinates.interface';
import { Options } from '@barkhub/ngx-google-places-autocomplete';
import { Location as DineEngineLocation, Location } from '../../../../interfaces/location.interface';
import { filter, first, take } from 'rxjs/operators';
import { GlobalStateModel } from '../../../../store/state.model';
import { Branding } from 'src/vendors/directus/interfaces/branding.interface';
import { NavigationService } from '@modules/navigation/services';
import { LocationsService } from '@modules/locations/services';
import { DistanceConfirmationComponent } from '@modules/locations/components';

declare let google: any;

export interface AddressEmit {
  address: AddressSearch;
  secondAddress: string;
  instructions: string;
  location?: Location;
}

@Component({
  selector: 'app-order-type-modal',
  templateUrl: './order-type-modal.component.html',
  styleUrls: ['./order-type-modal.component.scss'],
  host: {
    style: 'width: 100%;',
  },
})
export class OrderTypeModalComponent implements OnInit, OnDestroy {
  private _location: DineEngineLocation;

  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.app.branding) branding$: Observable<Branding>;
  @Select(state => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select(state => state.location.deliveryLocations)
  deliveryLocations$: Observable<Location[]>;
  @Select((state: GlobalStateModel) => state.location.addressSearch)
  addressSearch$: Observable<AddressSearch>;

  @Input() orderType = null;
  @Input() provider = 'google';
  @Input() cardEmphasis: boolean;

  @Output() deliveryAddressChange = new EventEmitter<AddressSearch>();
  @Output() startDeliveryOrderEmit = new EventEmitter<AddressEmit>();
  @Output() startPickupOrderEmit = new EventEmitter<number>();
  @Output() startDeliveryWithLocation = new EventEmitter<AddressEmit>();
  @Output() closeModalClicked = new EventEmitter<any>();
  @Output() hoveredIndex = new EventEmitter<number>();
  @Output() orderNowButtonClick = new EventEmitter<any>();

  @ViewChild('autocompletemodal', { read: ElementRef })
  searchElementRef: ElementRef;

  displayError = null;
  fullAddress: AddressSearch;
  isLoading = false;
  displayOrder: Order;
  private subs: Subscription[] = [];

  selectedLocation: Location;

  handoffs = HandoffType;

  deliveryForm = new UntypedFormGroup({
    deliveryAddress: new UntypedFormControl('', [Validators.required]),
    secondDeliveryAddress: new UntypedFormControl(''),
    deliveryInstructions: new UntypedFormControl(),
  });

  chooseDelivery = false;

  autocompleteOptions: Partial<Options> = {
    fields: ['address_components', 'formatted_address', 'geometry'],
    types: ['address'],
  };

  deliveryInstructionsLength = 128;

  pickupLoading = false;

  distance;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    public mobile: MobileService,
    private locationsSearch: LocationSearchService,
    private store: Store,
    private navigation: NavigationService,
    public locations: LocationsService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.subs.push(
      combineLatest([this.vendorSetup$, this.order$]).subscribe(([vendorSetup, order]) => {
        if (vendorSetup.order_provider !== 'olo' && vendorSetup.order_provider !== 'punchh-olo') {
          if (order) {
            this.deliveryInstructionsLength = order.location.specialInstructionsMaxLength;
          }
        }
      })
    );
    this.subs.push(
      this.order$.pipe(filter(o => o !== null)).subscribe(order => {
        this.displayOrder = order;
      })
    );
    this.subs.push(
      this.addressSearch$.pipe(first()).subscribe(address => {
        if (address) {
          this.fullAddress = address;
          this.deliveryForm.patchValue({
            deliveryAddress: address.formattedAddress,
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  typeSelected(event: Event, num: number) {
    event.preventDefault();
    event.stopPropagation();
    if (num === 0) {
      this.pickupLoading = true;
      try {
        this.startPickupOrderEmit.emit();
      } catch (e) {
        this.pickupLoading = false;
      }
    } else {
      this.store
        .selectOnce((state: GlobalStateModel) => state.user.user)
        .subscribe(user => {
          if (!user.isGuest && !(user.firstName && user.lastName && user.email && user.phoneNumber)) {
            this.navigation.navigateToIncompleteProfilePage();
            this.activeModal.close();
          }
        });
      this.orderType = 1;
    }
  }

  closeModal() {
    this.closeModalClicked.emit();
    this.activeModal.close('Close click');
  }

  startDeliveryOrder() {
    const user = this.store.selectSnapshot((state: GlobalStateModel) => state.user.user);
    if (!user.isGuest && !(user.firstName && user.lastName && user.email && user.phoneNumber)) {
      this.navigation.navigateToIncompleteProfilePage();
      this.activeModal.close();
      return;
    }
    if (!this.fullAddress) {
      return;
    } else {
      this.displayError = '';
    }
    this.isLoading = true;
    this.displayError = null;
    if (this.deliveryForm.invalid) {
      this.displayError = 'Please fill out all required information.';
      return;
    }
    const info: AddressEmit = {
      address: this.fullAddress,
      secondAddress: this.address2.value,
      instructions: this.instructions.value,
    };
    this.startDeliveryOrderEmit.emit(info);
  }

  deliveryAddressChanged(event: any) {
    if (this.fullAddress && this.fullAddress.addressComponents && this.fullAddress.formattedAddress) {
      this.deliveryAddressChange.emit(this.fullAddress);
    }
  }

  placeChanged(place) {
    this.displayError = '';
    this.fullAddress = this.locationsSearch.googlePlaceToAddressSearch(place, this.deliveryForm.get('deliveryAddress').value);
    if (this.fullAddress.formattedAddress) {
      this.deliveryForm.patchValue({
        deliveryAddress: this.fullAddress.formattedAddress,
      });
    }
    this.store.dispatch(new SetAddressSearch(this.fullAddress));
    // this.deliveryForm.get('deliveryAddress').setValue(this.fullAddress.formattedAddress);
  }

  checkAddress() {
    this.store
      .selectOnce((state: GlobalStateModel) => state.user.user)
      .subscribe(user => {
        if (!user.isGuest && !(user.firstName && user.lastName && user.email && user.phoneNumber)) {
          this.navigation.navigateToIncompleteProfilePage();
          this.activeModal.close();
          return;
        }
      });
    if (!this.fullAddress) {
      return;
    } else {
      this.displayError = '';
    }
    this.isLoading = true;
    const coords: ICoordinates = {
      latitude: this.fullAddress.addressComponents.latitude,
      longitude: this.fullAddress.addressComponents.longitude,
    };

    this.store.dispatch(new SetDeliveryLocations(coords, 25, this.fullAddress.addressComponents)).subscribe(
      state => {
        if (state.location.deliveryLocations.length > 0 && state.location.deliveryLocations.find(loc => loc.canDeliver)) {
          this.chooseDelivery = true;
          this.isLoading = false;
        } else if (!state.location.deliveryLocations.find(loc => loc.canDeliver)) {
          this.displayError = 'There are no locations that can currently deliver to you';
          this.isLoading = false;
        } else {
          this.displayError = 'We could not find any location near you';
          this.isLoading = false;
        }
      },
      error => {
        let errorMessage = 'We ran into an issue finding locations. Please try again later.';
        if (error.error?.message) {
          errorMessage = error.error.message;
        } else if (error.message) {
          errorMessage = error.message;
        }
        this.displayError = errorMessage;
        this.isLoading = false;
      }
    );
  }

  deliveryLocationSelect(location: Location) {
    const info: AddressEmit = {
      address: this.fullAddress,
      secondAddress: this.address2.value,
      instructions: this.instructions.value,
      location,
    };
    this.startDeliveryWithLocation.emit(info);
  }

  get address2() {
    return this.deliveryForm.get('secondDeliveryAddress');
  }

  get instructions() {
    return this.deliveryForm.get('deliveryInstructions');
  }

  cardHovered(index: number) {
    this.hoveredIndex.emit(index);
  }
}
