<ion-card tabindex="0" class="m-0" [class.h-100]="!option.optionGroups[0]" (click)="optionClicked(option)"
          (keydown.enter)="optionClicked(option)" [class.option-selected-card]="option.isSelected && isValid"
          [class.option-warning-card]="option.isSelected && !isValid"
          [class.option-disabled]="hitMaxGroupQuantity && !option.isSelected">
    <ng-container *ngIf="!isLoading; else skeleton">
        <div class="image-fit">
            <img class="image" [alt]="option.name" *ngIf="option?.thumbnailImageURL"
                 [ngClass]="option.isSelected ? 'option-selected': ''" [defaultImage]="'assets/img/600x600.png'"
                 [lazyLoad]="option.thumbnailImageURL"/>
            <div class="no-image-card" *ngIf="!option?.thumbnailImageURL"
                 [ngClass]="option.isSelected ? 'option-selected': ''">
                <img class="image" src="../../../../assets/img/600x600.png" [alt]="option.name">
                <div class="no-image-text" style="font-size: 1.1em;">{{ option.name}}</div>
            </div>
            <ng-container *ngIf="option.isSelected">
                <div class="d-flex flex-column gap-2 option-checked justify-content-center align-items-center">
                    <i class="bi bi-check-circle-fill option-checked-mark"></i>
                    <div class="p-0 d-flex flex-nowrap align-items-center" *ngIf="allowsOptionQuantity">
                        <div class="crementers align-items-center">
                            <i
                                    tabindex="0"
                                    (click)="decreaseQuantity($event)"
                                    (keydown.enter)="decreaseQuantity($event)"
                                    *ngIf="option.quantity > optionGroup.minChoiceQuantity"
                                    class="bi bi-dash-circle text-dark qty_selector h-100 remove d-flex align-items-center"></i>
                            <i *ngIf="option.quantity <= optionGroup.minChoiceQuantity"
                               class="bi bi-slash-circle text-muted qty_selector h-100 qty-one remove d-flex align-items-center"></i>

                            <div class="number bg-white d-flex align-items-center text-center justify-content-center h-100 p-3">
                                {{ quantity }}
                            </div>
                            <i
                                    tabindex="0"
                                    (click)="increaseQuantity($event)"
                                    (keydown.enter)="increaseQuantity($event)"
                                    *ngIf="option.quantity < (optionGroup.maxChoiceQuantity < maxChoiceQuantity ? optionGroup.maxChoiceQuantity : maxChoiceQuantity)"
                                    class="bi bi-plus-circle qty_selector text-dark h-100 add d-flex align-items-center"></i>
                            <i *ngIf="option.quantity >= (optionGroup.maxChoiceQuantity < maxChoiceQuantity ? optionGroup.maxChoiceQuantity : maxChoiceQuantity)"
                               tabindex="0"
                               (click)="$event.stopPropagation(); $event.preventDefault();"
                               (keydown.enter)="$event.stopPropagation(); $event.preventDefault();"
                               class="bi bi-slash-circle text-muted qty_selector h-100 qty-one add d-flex align-items-center"></i>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>

        <ion-card-header class="m-0 p-0 d-flex justify-content-start align-items-center">
            <ion-card-title class="text-start w-100 justify-content-start align-items-center px-3 py-2 option-name">
                <div class="option-name-text">{{option.name}}</div>
                <ng-container *ngIf="showPricing">
                    <div *ngIf="option.addedCents > 0" class="text-muted">
                        +{{(option.addedCents / 100) | currency: currencyCode}}</div>
                    <div *ngIf="option.optionGroups[0] && option.isSelected" class="text-muted">
                        <ng-container *ngFor="let suboption of option.optionGroups[0].options">
                            <ng-container *ngIf="suboption.isSelected && suboption.addedCents > 0">
                                +{{(suboption.addedCents / 100) | currency: currencyCode}}
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
                <div *ngIf="option.optionGroups && option.optionGroups.length && option.isSelected && (isModifierFlow && optionGroup.displayType !== 'button-group')">
                    <span class="badge rounded-pill mt-1"
                          [ngClass]="isValid ? 'bg-success' : 'bg-warning'">{{isValid ? 'Completed' : 'Choices Needed'}}</span>
                </div>
            </ion-card-title>
        </ion-card-header>

        <ng-container *ngIf="option.optionGroups[0]">
            <div
                 [@growInOut]="option.isSelected" (@growInOut.start)="animationStart()"
                 (@growInOut.done)="animationDone()" (click)="$event.stopPropagation()"
                 (keydown.enter)="$event.stopPropagation()">
                <ng-container *ngIf="option.isSelected">
                    <app-child-option-selector
                            class="w-100"
                            [optionGroup]="option.optionGroups[0]"
                            [optionSubGroup]="optionSubGroup"
                            (optionClick)="subOptionClicked($event)"
                            (subOptionClick)="subOptionClicked($event.option)"
                    ></app-child-option-selector>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #skeleton>
        <div class="custom-skeleton">
            <ion-skeleton-text animated class="skeleton-menu-card"></ion-skeleton-text>
        </div>
    </ng-template>

</ion-card>
