import {Injectable} from '@angular/core';
import {Address} from 'src/interfaces/address.interface';
import {ISavedAddress} from '@modules/locations/models/saved-address.interface';
import {Location as DineEngineLocation} from 'src/interfaces/location.interface';

@Injectable({
  providedIn: 'root',
})
export class UserLocationService {

  savedAddressesKey = 'savedAddresses';

  userLocation: Address = null;
  savedAddresses = [];

  storeLimit = 5;

  /**
   * Constructor for the address service
   * @constructor
   */
  constructor() {
    this.getUserLocation();
    this.getSavedAddresses();
  }

  storeUserLocation(address: Address) {
    this.userLocation = address;
    // localStorage.setItem(this.userLocationKey, JSON.stringify(this.userLocation));
  }

  getUserLocation(): Address {
    // this.userLocation = JSON.parse(localStorage.getItem(this.userLocationKey));
    return this.userLocation;
  }

  storeSavedAddress(address: Address, orderType: 'pickup' | 'delivery', location: DineEngineLocation) {
    const newSavedAddress: ISavedAddress = {
      address,
      orderType,
      location: location,
      isFavorite: false,
      lastUsed: new Date(),
      frequency: 1,
    };
    this.savedAddresses = Object.assign([], this.savedAddresses);
    this.savedAddresses.push(newSavedAddress);
    this.savedAddresses = this.deDupeSavedAddresses(this.savedAddresses);
    // this will limit the number of addresses stored, at the moment i think the idea is to not limit them?
    // while (this.savedAddresses.length > storeLimit) {
    //     this.savedAddresses.shift();
    // }
    localStorage.setItem(this.savedAddressesKey, JSON.stringify(this.savedAddresses));
  }

  getSavedAddresses() {
    this.savedAddresses = [];
    if (localStorage.getItem(this.savedAddressesKey)) {
      this.savedAddresses = JSON.parse(localStorage.getItem(this.savedAddressesKey));
    }
    return this.savedAddresses;
  }

  getMostRecentLocation() {
    let mostRecentLocation;
    const savedAddresses = this.getSavedAddresses();
    mostRecentLocation = savedAddresses[0];
    return mostRecentLocation;
  }

  private deDupeSavedAddresses(savedAddresses: ISavedAddress[]): ISavedAddress[] {
    const uniqueAddresses: ISavedAddress[] = [];
    for (let i = savedAddresses.length - 1; i >= 0; i--) {
      if (uniqueAddresses.some(savedAddress => savedAddress.address.address1 === savedAddresses[i].address.address1)) {
        const candidate = uniqueAddresses.find(savedAddress => savedAddress.address.address1 === savedAddresses[i].address.address1);
        if (!this.isSameSavedAddress(candidate, savedAddresses[i])) {
          uniqueAddresses.push(savedAddresses[i]);
        }
        // TODO increment the frequency when is is in the array already
      } else {
        uniqueAddresses.push(savedAddresses[i]);
      }
    }
    uniqueAddresses.sort((a, b) => a.lastUsed < b.lastUsed ? 1 : -1);
    return uniqueAddresses.reverse();
  }

  private isSameSavedAddress(address1: ISavedAddress, address2: ISavedAddress): boolean {
    return address1.address.address1 === address2.address.address1 &&
        address1.address.address2 === address2.address.address2 &&
        address1.address.city === address2.address.city &&
        address1.address.state === address2.address.state &&
        address1.address.zipCode === address2.address.zipCode &&
        address1.address.latitude === address2.address.latitude &&
        address1.address.longitude === address2.address.longitude;
  }

}
