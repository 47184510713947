<div
  class="mt-2 width-wrapper cms-card-radius"
  style="box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important">
  <div class="m-0 border-bottom recents-section d-flex flex-row flex-nowrap">
    <div
      class="px-3 py-2 fw-bold text-uppercase font-size-body-small header-font w-100 me-2">
      Recents
    </div>
  </div>
  <ng-container *ngIf="savedAddress.length > 0; else noLocations">
    <div
      class="bg-white position-relative order-list-top"
      [class.order-list-top]="order?.handoffType !== 1"
      [class.list-card]="savedAddress.length > 0"
      style="z-index: 10002">
      <div
        tabindex="0"
        class="row m-0"
        style="cursor: pointer"
        *ngFor="let order of savedAddress; index as i"
        (click)="startOrder(order)"
        (keydown.enter)="startOrder(order)">
        <div
          class="col px-3 py-2"
          [ngClass]="i === savedAddress.length - 1 ? '' : 'border-bottom'">
          <div class="text-uppercase fw-bold fs-12">
            <ng-container [ngSwitch]="order.orderType">
              <div *ngSwitchCase="'pickup'" class="handoff-overline">
                <div>Pickup From</div>
              </div>
              <div *ngSwitchDefault class="handoff-overline">
                <div>Deliver To</div>
              </div>
            </ng-container>
          </div>
          <div class="fs-16">
            <ng-container [ngSwitch]="order.orderType">
              <div *ngSwitchCase="'pickup'">
                {{ order.location.name }}
              </div>
              <div *ngSwitchDefault>
                <div class="fs-16">
                  {{ order.address.address1 }}
                </div>
                <div class="fs-12">
                  {{ order.address.city }}, {{ order.address.state }}
                  {{ order.address.zipCode }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!--    </ng-template>-->

  <ng-template #noLocations>
    <div class="w-100 p-3 bg-white">You have no recent locations.</div>
  </ng-template>

  <button
    type="button"
    class="de-primary-btn py-2 px-3 width-wrapper fw-bold remove-top-radius cms-card-radius"
    style="cursor: pointer; z-index: 10002;"
    (click)="addNewClicked()">
    <div
      class="w-100"
      style="
        display: grid;
        grid-template-columns: 1fr auto;
        place-items: center;
        height: 100%;
      ">
      <div class="w-100 text-start">{{ chooseLocationCopy }}</div>
      <i class="bi bi-plus-circle add-icon d-flex align-items-center"></i>
    </div>
  </button>
</div>
