<h4 class="text-center m-0 pb-2">Contact Us</h4>
<ng-container *ngIf="content; else noContent">
  <div [innerHTML]="content"></div>
</ng-container>
<ng-template #noContent>
  <h1 class="header-font fw-bold text-center">We Want Your Feedback</h1>
  <p style="max-width: 500px;" class="mx-auto text-center mb-3">We are happy to answer any questions or
    concerns that you may have. Please fill out the form and we
    will be in touch with you as soon as possible.
  </p>
</ng-template>

<form class="w-100 px-1" [formGroup]="contactForm" (ngSubmit)="submitClick()">
  <ng-container *ngIf="recaptchaConfig.enabled && recaptchaConfig.site_key">
    <app-recaptcha #recaptchaRef [siteKey]="recaptchaConfig.site_key" (token)="handleToken($event)" action="form_submit"></app-recaptcha>
  </ng-container>
  <div class="form-row">
    <div class="col-6 form-group">
      <label for="firstName" class="fw-bold">First Name</label>
      <input id="firstName" formControlName="firstName" class="form-control de-input de-border" type="text" placeholder="First Name">
      <ng-container *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
        <small class="text-danger" *ngIf="firstName.errors.pattern">Invalid Name</small>
        <small class="text-danger" *ngIf="firstName.errors.required">First Name Required</small>
      </ng-container>
    </div>
    <div class="col-6 form-group">
      <label for="lastName" class="fw-bold">Last Name</label>
      <input id="lastName" class="form-control de-input de-border" formControlName="lastName" type="text" placeholder="Last Name">
      <ng-container *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
        <small class="text-danger" *ngIf="lastName.errors.pattern">Invalid Name</small>
        <small class="text-danger" *ngIf="lastName.errors.required">Last Name Required</small>
      </ng-container>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 form-group">
      <label for="emailAddress" class="fw-bold">Email Address</label>
      <input id="emailAddress" class="form-control de-input de-border" formControlName="emailAddress" type="email" placeholder="Email Address">
      <ng-container *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched)">
        <small class="text-danger" *ngIf="emailAddress.errors.email">Invalid Email</small>
        <small class="text-danger" *ngIf="emailAddress.errors.required">Email Address Required</small>
      </ng-container>
    </div>
  </div>
  <div class="form-row">
    <div class="col-6 form-group">
      <label for="phoneNumber" class="fw-bold">Phone Number</label>
      <input id="phoneNumber" class="form-control de-input de-border" formControlName="phoneNumber" type="tel" placeholder="Phone Number" mask="(000)000-0000">
      <ng-container *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)">
        <small class="text-danger" *ngIf="phoneNumber.errors.pattern">Invalid Phone Number</small>
        <small class="text-danger" *ngIf="phoneNumber.errors.required">Phone Number Required</small>
      </ng-container>
    </div>
    <div class="col-6 form-group">
      <label for="dateOfVisit" class="fw-bold">Date of Visit</label>
      <input id="dateOfVisit" class="form-control de-input de-border" formControlName="eventDate" type="date">
    </div>
  </div>
  <ng-container *ngIf="vendorSetup$ | async as vendorSetup"><!--Only show on mobile-->
    <ng-container *ngIf="vendorSetup.order_provider !== 'none'">
      <div class="form-row">
        <div class="col-12 form-group">
          <ng-container *ngIf="contactLocations.length > 0 else locationsLoading">
            <ng-container *ngIf="mainSettings$ | async as mainSettings">
              <ng-container *ngIf="!requireLocationSelection else requiredLocation">
                <label for="location" class="fw-bold">Location</label>
                <select id="location" class="form-control de-input de-border" formControlName="location">
                  <option value="" disabled selected>Select a location</option>
                  <option>No Location</option>
                  <ng-container *ngFor="let state of contactStates">
                    <optgroup [label]="state">
                      <ng-container *ngFor="let location of contactLocations; index as i;">
                        <ng-container *ngIf="location.address.state === state">
                          <option [value]="location.locationID + '&&' + location.address.city + ' - ' + location.name + ' (' + (location.address | streetAddress) + ')'">
                            {{ location.address.city }} - {{location.name}} ({{location.address | streetAddress}})
                          </option>
                        </ng-container>
                      </ng-container>
                    </optgroup>
                  </ng-container>
                </select>
              </ng-container>
              <ng-template #requiredLocation>
                <label for="locationRequired" class="fw-bold">Location (Required)</label>
                <select id="locationRequired" class="form-control de-input de-border" formControlName="location" required>
                  <option value="" disabled selected>Select a location</option>
                  <ng-container *ngFor="let state of contactStates">
                    <optgroup [label]="state">
                      <ng-container *ngFor="let location of contactLocations; index as i;">
                        <ng-container *ngIf="location.address.state === state">
                          <option [value]="location.locationID + '&&' + location.address.city + ' - ' + location.name + ' (' + location.address.address1 + ')'">
                            {{ location.address.city }} - {{location.name}} ({{location.address | streetAddress}})
                          </option>
                        </ng-container>
                      </ng-container>
                    </optgroup>
                  </ng-container>
                </select>
                <ng-container *ngIf="location.invalid && (location.dirty || location.touched)">
                  <small class="text-danger" *ngIf="location.errors.pattern">Location Selection Required</small>
                  <small class="text-danger" *ngIf="location.errors.required">Location Selection Required</small>
                </ng-container>
              </ng-template>
            </ng-container>

          </ng-container>
          <ng-template #locationsLoading>
            <div class="de-btn-loader-secondary"></div>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="form-row">
    <div class="col-12 form-group">
      <label for="reasonForContact" class="fw-bold">Reason for Contact</label>
      <select id="reasonForContact" class="form-control de-input de-border" formControlName="contactReason">
        <option value="" disabled selected>Select a reason for contact</option>
        <ng-container *ngFor="let contact of displayContactForm; index as i;">
          <option [value]="contact.id">
            {{ contact.inquiry_type }}
          </option>
        </ng-container>
      </select>
      <ng-container *ngIf="contactReason.invalid && (contactReason.dirty || contactReason.touched)">
        <small class="text-danger" *ngIf="contactReason.errors.required">Reason for Contact Required</small>
      </ng-container>
    </div>
  </div>
  <div class="form-row">
    <div class="col-12 form-group">
      <label for="message" class="fw-bold">Message</label>
      <textarea id="message" formControlName="messageContent" class="form-control de-input de-border" rows="2" placeholder="Please enter any other information that you would like to relay to us..."
      ></textarea>
    </div>
  </div>
  <div class="w-100 text-center">
    <button type="submit" class="de-primary-btn w-100 mx-auto" style="max-width: 300px">
      <div *ngIf="sendingForm === true" class="de-btn-loader"></div>
      <div *ngIf="sendingForm === false">Submit</div>
    </button>
  </div>
</form>
