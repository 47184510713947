<ng-container *ngIf="order else nextOrder">
    <div class="reorder-card-container">
        <div class="reorder-card-header">

                <a type="button" tabindex="0" class="location text-decoration-none" title="{{order.location.name}}"
                     [routerLink]="'/menu/' + (order.location.slugURL ? order.location.slugURL : order.location.locationID)">
                    {{order.location.name}}
                </a>
            <div class="price d-flex w-100 m-0">
                <div class="d-flex grow-1">
                    {{order.orderReadyTimestamp | date: 'M/d/yyyy': order.location.utcOffset}}
                </div>
                <div>
                    {{order.totalCents / 100 | currency: currencyCode}}
                </div>
            </div>
        </div>

        <div class="reorder-card-body">

            <div class="body-contents">

                <div class="order-type">
                    {{(handoffs[order.handoffType] === 'dispatch' ? 'delivery' : handoffs[order.handoffType]) | titlecase}}
                    :
                    <div class="badge rounded-pill"
                         [ngClass]="{
                             'bg-success' : order.orderStatus === 'Completed' || order.orderStatus === 'completed',
                             'bg-secondary' : order.orderStatus === 'Canceled',
                             'bg-info' : order.orderStatus === 'in-progress' || order.orderStatus === 'In Progress' || order.orderStatus === 'Transmitting' || order.orderStatus === 'Pending Manual Fire',
                             'bg-light border' : order.orderStatus === 'Scheduled'}">
                        {{order.orderStatus | titlecase}}
                    </div>
                </div>
                <div class="">
                    <div class="product">
                        <ng-container *ngFor="let item of order.items; first as first; last as last, index as index">
                            {{item.name | truncate: 30 | titlecase}}
                            <ng-container *ngIf="!last && order.items.length > 1">
                                •
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="w-100 d-flex flex-column gap-2 justify-content-end align-items-center">
                <a tabindex="0" type="button" class="show-details" (click)="viewConfirmationClicked()" (keydown.enter)="viewConfirmationClicked()">
                    Receipt
                </a>
                <ng-container
                        *ngIf="!order.isEditable">
                    <button class="order-btn de-primary-btn-sm" (click)="reorderClicked($event)">
                        <ng-container *ngIf="!isLoading">
                            Reorder
                        </ng-container>
                        <ng-container *ngIf="isLoading">
                            <div class="de-btn-loader"></div>
                        </ng-container>
                    </button>
                </ng-container>
                <ng-container
                        *ngIf="order.isEditable">
                    <button class="order-btn de-danger-btn-sm" (click)="cancelOrderClicked()">
                        <ng-container *ngIf="!isLoading">
                            Cancel
                        </ng-container>
                        <ng-container *ngIf="isLoading">
                            <div class="de-btn-loader"></div>
                        </ng-container>
                    </button>
                </ng-container>

            </div>
        </div>
    </div>
</ng-container>

<ng-template #nextOrder>
    <div class="reorder-card-container-empty">
        <!--        <div class="reorder-card-header" style="border-bottom: 1px solid var(&#45;&#45;de-border-color);">-->
        <!--            <h6 class="price m-0">-->
        <!--                test-->
        <!--            </h6>-->
        <!--            <div class="location">-->
        <!--                test-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="reorder-card-body bg-white text-muted">
            <div class="body-contents">
                <div class="d-flex flex-column align-items-center justify-content-center h-100" style="gap: 0.5rem;">
                    <ng-container *ngIf="theme$ | async as theme">
                        <app-custom-icon class=""
                                         default="bi-receipt-cutoff"
                                         customIconKey="orders_navigation_icon"
                                         size="48"
                        ></app-custom-icon>
                    </ng-container>
                </div>
                <small class="text-center pb-2">
                    Place an order to start tracking your recent orders
                </small>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #reorderModal let-modal>
    <div class="px-3 pt-3 pb-5">
        <div class="d-flex justify-content-end w-100">
            <i class="bi bi-x-circle d-flex pointer" style="font-size: 30px;" (click)="modal.close()"></i>
        </div>
        <div class="d-flex flex-column gap-3">
            <div class="w-100 modal-heading m-0 header-font">
                <h1 class="font-size-h1 m-0 fw-bold text-center">Order History</h1>
            </div>
            <div class="w-100 d-flex justify-content-center">
                <div class="header-underline m-0"></div>
            </div>
            <div class="d-flex flex-row flex-nowrap pt-4 pt-md-0">
                <div class="grow-1 basis-0 d-sm-block d-none"></div>
                <div class="reorder-card-container-modal grow-3 basis-0">
                    <div class="reorder-card-header">
                        <h5 class="location">
                            {{order.location.name}}
                        </h5>
                        <div class="price">
                            {{order.orderReadyTimestamp | date: 'M/d/yyyy': order.location.utcOffset}}
                            {{order.totalCents / 100 | currency: currencyCode}}
                        </div>
                    </div>
                    <div class="reorder-card-body">
                        <div class="body-contents">
                            <div class="order-type">
                                {{(handoffs[order.handoffType] === 'dispatch' ? 'delivery' : handoffs[order.handoffType]) | titlecase}}
                                : {{order.orderStatus | titlecase}}
                            </div>
                            <div class="d-flex flex-column" style="gap: 0.5rem;">
                                <ng-container *ngFor="let item of order.items, index as i">
                                    <ng-container *ngIf="i < 2">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex flex-nowrap gap-3">
                                                <div class="product grow-1">
                                                    {{order.items[0]?.name | truncate: 30}}
                                                </div>
                                                <div>
                                                    {{item.totalCents / 100 | currency: currencyCode}}
                                                </div>
                                            </div>
                                            <ng-container *ngIf="item.options && item.options.length > 0">
                                                <small>{{item.options | optionsList}}</small>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-center pb-2" style="gap: 1rem;">
                            <div class="order-btn de-primary-btn-sm text-center" (click)="reorderClicked()">
                                <ng-container *ngIf="!isLoading">
                                    Order Again
                                </ng-container>
                                <ng-container *ngIf="isLoading">
                                    <div class="de-btn-loader"></div>
                                </ng-container>
                            </div>
                            <div class="show-details de-primary-link" (click)="viewConfirmationClicked()">
                                View Confirmation
                            </div>
                        </div>
                    </div>
                </div>
                <!--            <div class="d-flex flex-row flex-wrap gap-3 w-100 grow-3 basis-0">-->
                <!--                <div class="w-100 modal-heading m-0 header-font">-->
                <!--                    <h1 class="font-size-h1 m-0 fw-bold text-center">Order History</h1>-->
                <!--                </div>-->
                <!--                <div class="w-100 d-flex justify-content-center">-->
                <!--                    <div class="header-underline m-0"></div>-->
                <!--                </div>-->
                <!--                <div class="w-100 d-flex flex-row flex-wrap gap-3"-->
                <!--                     style="border-radius: var(&#45;&#45;cms-card-radius)">-->
                <!--                    <div class="location">-->
                <!--                        Ordered From: {{order.location.name}}-->
                <!--                    </div>-->
                <!--                    <div class="w-100">-->
                <!--                        <div class="fw-bold w-100">{{order.orderReadyTimestamp | date: 'M/d/yyyy h:mm a'}} |-->
                <!--                            {{order.totalCents / 100 | currency: currencyCode}}</div>-->
                <!--                        <div class="font-size-body-small w-100">-->
                <!--                            {{(handoffs[order.handoffType] === 'dispatch' ? 'delivery' : handoffs[order.handoffType]) | titlecase}}-->
                <!--                            :-->
                <!--                            {{order.orderStatus | titlecase}}</div>-->
                <!--                    </div>-->
                <!--                    <div class="d-flex flex-row flex-wrap w-100 gap-2">-->
                <!--                        <ng-container *ngFor="let item of order.items">-->
                <!--                            <div class="w-100">-->
                <!--                                <div class="d-flex flex-row flex-nowrap w-100">-->
                <!--                                    <div class="fw-bold grow-1">{{item.name}}</div>-->
                <!--                                    <div class="fw-bold">{{item.totalCents / 100 | currency: currencyCode}}</div>-->
                <!--                                </div>-->
                <!--                                <ng-container *ngIf="item.options && item.options.length > 0">-->
                <!--                                    <div style="font-size: 12px">{{item.options | optionsList}}</div>-->
                <!--                                </ng-container>-->
                <!--                            </div>-->
                <!--                        </ng-container>-->
                <!--                    </div>-->
                <!--                </div>-->
                <!--                <button class="link w-100 de-primary-btn" (click)="reorderClicked()">-->
                <!--                    <ng-container *ngIf="!isLoading">Order Again</ng-container>-->
                <!--                    <ng-container *ngIf="isLoading">-->
                <!--                        <div class="de-btn-loader"></div>-->
                <!--                    </ng-container>-->
                <!--                </button>-->
                <!--                <ng-container *ngIf="order.isEditable">-->
                <!--                    <button class="link w-100 de-secondary-btn" (click)="editOrderClicked()">Edit Order</button>-->
                <!--                    <div class="link w-100 text-center fw-bold"-->
                <!--                         style="text-decoration: underline; color: var(&#45;&#45;ion-color-primary)"-->
                <!--                         (click)="cancelOrderClicked()">Cancel-->
                <!--                        Order-->
                <!--                    </div>-->
                <!--                </ng-container>-->
                <!--                <button type="button" id="view-confirmation" class="de-secondary-link w-100 text-center pb-3"-->
                <!--                        (click)="viewConfirmationClicked()">-->
                <!--                    View Confirmation-->
                <!--                </button>-->
                <!--            </div>-->
                <div class="grow-1 basis-0 d-sm-block d-none"></div>
            </div>
        </div>
    </div>
</ng-template>
