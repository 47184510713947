import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, from, of } from 'rxjs';
import { DirectusClientService } from './directus-client.service';
import { switchMap, map, tap } from 'rxjs/operators';
import { Ingredient } from './interfaces/ingredient.interface';
import { ModifierGroup } from './interfaces/modifier-group.interface';
import { Product } from './interfaces/product.interface';
import { ResponseWrapper } from './interfaces/response-wrapper.interface';
import { CustomPage } from './interfaces/custom-page.interface';
import { Banner } from './interfaces/banner.interface';
import { ContactForm } from './interfaces/contact-form.interface';
import { ThemeColor } from './interfaces/theme-color.interface';
import { Category } from './interfaces/category.interface';
import { VendorSetup } from './interfaces/vendor.interface';
import { TextField } from './interfaces/text-field.interface';
import { Branding } from './interfaces/branding.interface';
import { KioskContent } from './interfaces/kiosk-content.interface';
import { PaytronixConfiguration } from './interfaces/paytronix-configuration.interface';
import { FishbowlConfiguration } from './interfaces/fishbowl-configuration.interface';
import { NovaDineConfiguration } from './interfaces/novadine-configuration.interface';
import { HeartlandConfiguration } from './interfaces/heartland-configuration.interface';
import { PunchhConfiguration } from './interfaces/punchh-configuration.interface';
import { MainSettings } from './interfaces/main-settings.interface';
import { OloConfiguration } from './interfaces/olo-configuration.interface';
import { Location } from './interfaces/location.interface';
import { ItwercsConfiguration } from './interfaces/itwercs-configuration.interface';
import { ModeService } from '../../services/mode.service';
import { NavbarSettings } from './interfaces/navbar-settings.interface';
import { DatacapConfiguration } from './interfaces/datacap-configuration.interface';
import { PwaSettings } from './interfaces/pwa-settings.interface';
import { Cacheable } from 'ts-cacheable';
import { PersonicaConfiguration } from './interfaces/personica-configuration.interface';
import { SpendgoConfiguration } from './interfaces/spendgo-configuration.interface';
import { MobileAppSettings } from './interfaces/mobile-app-settings.interface';
import { Onboarding } from './interfaces/onboarding.interface';
import { CustomIcons } from './interfaces/custom-icons.interface';
import { PromotionalContent } from './interfaces/promotional-content.interface';
import { KioskConfig } from './interfaces/kiosk-config.interface';
import { Features } from './interfaces/features.interface';
import { RecaptchaConfiguration } from './interfaces/recaptcha-configuration.interface';

/**
 * @deprecated Use Directus Service instead
 */
@Injectable({
  providedIn: 'root',
})
export class DirectusConnectorService {
  private getAllFromDirectus = '*.*.*.*';
  private cachedPunchhConfiguration: ResponseWrapper<PunchhConfiguration>;
  private cachedPaytronixConfiguration: ResponseWrapper<PaytronixConfiguration>;

  constructor(
    private http: HttpClient,
    private clientService: DirectusClientService,
    private mode: ModeService
  ) {}

  getIngredients(): Observable<ResponseWrapper<Ingredient[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<Ingredient[]>>('ingredients', body);
  }

  @Cacheable({
    maxAge: 2000,
  })
  getModifierGroups(): Observable<ResponseWrapper<ModifierGroup[]>> {
    const body = { limit: -1 };
    return this.getDirectusItems<ResponseWrapper<ModifierGroup[]>>('modifier_groups', body);
  }

  getProducts(): Observable<ResponseWrapper<Product[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<Product[]>>('products', body);
  }

  getProductInfo(productID: string): Observable<ResponseWrapper<any>> {
    const body = this.getSingleParams();
    body['filter[name][eq]'] = productID;
    return this.getDirectusItems<ResponseWrapper<any>>('products', body);
  }

  getCustomPages(): Observable<ResponseWrapper<CustomPage[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<CustomPage[]>>('custom_pages', body);
  }

  getSubnav(): Observable<ResponseWrapper<any>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<any>>('subnav', body);
  }

  getAccountSubnav(): Observable<ResponseWrapper<any>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<any>>('account_subnav', body);
  }

  getBanners(): Observable<ResponseWrapper<Banner[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<Banner[]>>('banners', body);
  }

  getKioskContent(): Observable<ResponseWrapper<KioskContent>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<KioskContent>>('kiosk_content', body);
  }

  getBrochure(): Observable<ResponseWrapper<any>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<any>>('brochure', body);
  }

  getBrochureContent(): Observable<ResponseWrapper<any>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<any>>('brochure_content', body);
  }

  getPromotionalContent(): Observable<ResponseWrapper<PromotionalContent>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<any>>('promotional_content', body);
  }

  getThemeColors(): Observable<ResponseWrapper<ThemeColor[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<ThemeColor[]>>('theme_colors', body);
  }

  getCategories(): Observable<ResponseWrapper<Category[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<Category[]>>('categories', body);
  }

  /*     getCategoryInfo(category: string): Observable<Directus.ResponseWrapper<Directus.Category>> {
            const body = this.getCollectionParams();
            body.filter = { category_slug: { eq: category } };
            return this.getDirectusItems<Directus.ResponseWrapper<Directus.Category>>('categories', body);
        } */

  @Cacheable({})
  getLocations(): Observable<ResponseWrapper<Location[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<Location[]>>('locations', body);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getLocationInfo(name: string): Observable<ResponseWrapper<Location>> {
    const body = this.getSingleParams();
    body['filter[location_name][eq]'] = name;
    return this.getDirectusItems<ResponseWrapper<Location>>('locations', body);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getLocationByID(id: string): Observable<ResponseWrapper<Location>> {
    const body = this.getSingleParams();
    body['filter[menu_id][eq]'] = id;
    return this.getDirectusItems<ResponseWrapper<Location>>('locations', body);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getLocationBySlug(slug: string): Observable<ResponseWrapper<Location>> {
    const body = this.getSingleParams();
    body['filter[location_slug][eq]'] = slug;
    return this.getDirectusItems<ResponseWrapper<Location>>('locations', body);
  }

  /*     getThemeColorInfo(): Observable<Directus.ResponseWrapper<any>> {
            const body = this.getSingleParams();
            return this.getDirectusItems<Directus.ResponseWrapper<any>>('theme_colors', body);
        } */

  getMainSettings(): Observable<ResponseWrapper<MainSettings>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<MainSettings>>('main_settings', body);
  }

  getOloConfiguration(): Observable<ResponseWrapper<OloConfiguration>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<OloConfiguration>>('olo_configuration', body);
  }

  getHeartlandConfiguration(): Observable<ResponseWrapper<HeartlandConfiguration>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<HeartlandConfiguration>>('heartland_configuration', body);
  }

  getNovaDineConfiguration(): Observable<ResponseWrapper<NovaDineConfiguration>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<NovaDineConfiguration>>('novadine_config', body);
  }

  getPunchhConfiguration(): Observable<ResponseWrapper<PunchhConfiguration>> {
    const body = this.getSingleParams();
    return this.cachedPunchhConfiguration
      ? of(this.cachedPunchhConfiguration)
      : this.getDirectusItems<ResponseWrapper<PunchhConfiguration>>('punchh_configuration', body).pipe(
          tap(config => {
            this.cachedPunchhConfiguration = config;
          })
        );
  }

  getFishbowlConfiguration(): Observable<ResponseWrapper<FishbowlConfiguration>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<FishbowlConfiguration>>('fishbowl_configuration', body);
  }

  getPersonicaConfiguration(): Observable<ResponseWrapper<PersonicaConfiguration>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<PersonicaConfiguration>>('personica_config', body);
  }

  getSpendgoConfiguration(): Observable<ResponseWrapper<SpendgoConfiguration>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<SpendgoConfiguration>>('spendgo_configuration', body);
  }

  getPaytronixConfiguration(): Observable<ResponseWrapper<PaytronixConfiguration>> {
    const body = this.getSingleParams();
    return this.cachedPaytronixConfiguration
      ? of(this.cachedPaytronixConfiguration)
      : this.getDirectusItems<ResponseWrapper<PaytronixConfiguration>>('paytronix_configuration', body).pipe(
          tap(config => {
            this.cachedPaytronixConfiguration = config;
          })
        );
  }

  getItwercsConfiguration(): Observable<ResponseWrapper<ItwercsConfiguration>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<ItwercsConfiguration>>('itwercs_config', body);
  }

  getDatacapConfiguration(): Observable<ResponseWrapper<DatacapConfiguration>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<DatacapConfiguration>>('datacap_config', body);
  }

  getPwaSettings(): Observable<ResponseWrapper<PwaSettings>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<PwaSettings>>('pwa_settings', body);
  }

  getBranding(): Observable<ResponseWrapper<Branding>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<Branding>>('branding', body);
  }

  getNavbarSettings(): Observable<ResponseWrapper<NavbarSettings>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<NavbarSettings>>('navbar_settings', body);
  }

  getTextFields(): Observable<ResponseWrapper<TextField>> {
    const body = this.getSingleParams();
    return this.getDirectusItems<ResponseWrapper<TextField>>('text_fields', body);
  }

  getContactForm(): Observable<ResponseWrapper<ContactForm[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<ContactForm[]>>('contact_form', body);
  }

  getVendorSetup(): Observable<ResponseWrapper<VendorSetup>> {
    const body = this.getSingleParams();
    const mode = sessionStorage.getItem('mode');
    switch (mode) {
      case 'tableside':
        return this.getDirectusItems<ResponseWrapper<VendorSetup>>('tableside_vendor_setup', body).pipe(
          switchMap(res => {
            if (res.data.menu_provider === 'none') {
              return this.getDirectusItems<ResponseWrapper<VendorSetup>>('vendor_setup', body).pipe(
                map(vendor => {
                  this.mode.setMobileOrDesktopMode();
                  return vendor;
                })
              );
            } else {
              return this.getDirectusItems<ResponseWrapper<VendorSetup>>('tableside_vendor_setup', body).pipe(
                map(vendor => {
                  return vendor;
                })
              );
            }
          })
        );
      default:
        return this.getDirectusItems<ResponseWrapper<VendorSetup>>('vendor_setup', body);
    }
  }

  sendContactForm(data: any): Observable<any> {
    const queryParams = new HttpParams({
      fromObject: this.getPostParams(),
    });
    let headers = new HttpHeaders();
    if (sessionStorage.getItem('recaptchaToken')) {
      headers = headers.set('X-Captcha-Token', sessionStorage.getItem('recaptchaToken'));
    }
    // TODO obviously these two 'any' need to be typed
    return this.clientService.getClient().pipe(
      switchMap(client => {
        return this.http.post<string>(environment.domainAPI + '/' + client.project + '/custom/dineengine/contact', data, {
          params: queryParams,
          headers,
        });
      })
    );
  }

  sendFeedback(data): Observable<any> {
    const queryParams = new HttpParams({
      fromObject: this.getPostParams(),
    });
    return this.clientService.getClient().pipe(
      switchMap(client => {
        return this.http.post<string>(environment.domainAPI + '/' + client.project + '/items/ratings', data, { params: queryParams });
      })
    );
  }

  sendServiceRequest(data): Observable<any> {
    const queryParams = new HttpParams({
      fromObject: this.getPostParams(),
    });
    return this.clientService.getClient().pipe(
      switchMap(client => {
        return this.http.post<string>(environment.domainAPI + '/' + client.project + '/items/service_requests', data, {
          params: queryParams,
        });
      })
    );
  }

  getKioskConfig(kioskNumber: any): Observable<ResponseWrapper<KioskConfig>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<KioskConfig[]>>('customer_kiosk_configuration', body).pipe(
      map(res => {
        return {
          data: res.data.find((item: KioskConfig) => item.kiosk_id === kioskNumber),
          public: true,
          messages: [],
        };
      })
    );
  }

  getKioskConfigs(): Observable<ResponseWrapper<KioskConfig[]>> {
    const body = this.getCollectionParams();
    return this.getDirectusItems<ResponseWrapper<KioskConfig[]>>('customer_kiosk_configuration', body);
  }

  getMobileAppSettings(): Observable<ResponseWrapper<MobileAppSettings>> {
    return this.getDirectusItems<ResponseWrapper<MobileAppSettings>>('mobile_app_settings', this.getSingleParams());
  }

  getOnboardingSlides(): Observable<ResponseWrapper<Onboarding[]>> {
    return this.getDirectusItems<ResponseWrapper<Onboarding[]>>('onboarding', {
      fields: ['*.*'],
      // single: false,
      limit: -1,
    });
  }

  getCustomIcons(): Observable<ResponseWrapper<CustomIcons>> {
    return this.getDirectusItems<ResponseWrapper<CustomIcons>>('custom_icons', this.getSingleParams());
  }

  getFeatures(): Observable<ResponseWrapper<Features[]>> {
    return this.getDirectusItems<ResponseWrapper<Features[]>>('features', this.getCollectionParams());
  }

  getRecaptchaConfig(): Observable<ResponseWrapper<RecaptchaConfiguration>> {
    return this.getDirectusItems<ResponseWrapper<RecaptchaConfiguration>>('recaptcha_configuration', this.getSingleParams());
  }

  private getDirectusItems<T>(collection: string, body: any): Observable<T> {
    return this.clientService.getClient().pipe(
      switchMap(client => {
        return from(client.getItems<T>(collection, body));
      })
    );
  }

  private getSingleParams(): any {
    return {
      fields: [this.getAllFromDirectus],
      single: true,
      activity_skip: 1,
    };
  }

  private getCollectionParams(): any {
    return {
      fields: [this.getAllFromDirectus],
      single: false,
      limit: -1,
      activity_skip: 1,
    };
  }

  private getPostParams() {
    return {
      activity_skip: 1,
    };
  }
}
