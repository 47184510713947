import {Injectable} from '@angular/core';
import {PaymentProcessingProvider} from '../../providers/payment-processing-provider.interface';
import {Observable, of} from 'rxjs';
import {User} from '../../interfaces/user.interface';
import {CreditCard} from '../../interfaces/creditcard.interface';
import {Order} from '../../interfaces/order.interface';
import {BillingDetails} from './interfaces/billing-details.interface';
import {
    DigitalWalletsOptions,
    PaymentMethod,
} from '@olo/pay';

@Injectable({
    providedIn: 'root'
})
export class OloPayProviderService implements PaymentProcessingProvider {

    paymentMethod: PaymentMethod | undefined;
    integrationCMSName = 'olo-pay';

    constructor() {}

    // tslint:disable-next-line:max-line-length
    initializePaymentInterfaces(type: 'multi-field' | 'single-field' | 'wallet',
                                walletConfig?: DigitalWalletsOptions,
                                callback?: (paymentMethod: PaymentMethod) => void): Observable<any> {
        return of(null);
    }

    // tslint:disable-next-line:max-line-length
    submitPaymentNewCard(order: Order, payProcId: number, card: CreditCard, token: string, billingDetails?: BillingDetails): Observable<any> {
        return of(null);
    }

    submitPaymentSavedCard(order: Order, payProcId: number, user: User, card: CreditCard, save: boolean, token: string): Observable<any> {
        return of(null);
    }

    checkPaymentStatus(paymentToken: string, payProcID: string): Observable<string> {
        return of(null);
    }

    authorizePayment(): Observable<{ 'success': boolean, 'message': string }> {
        return of(null);
    }

    saveCard(): Observable<any> {
        return of(null);
    }

    removeCard(): Observable<any> {
        return of(null);
    }

    getSavedCards(custID: number): Observable<any> {
        return of(null);
    }
}
