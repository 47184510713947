<div class="activity-card border">
  <div class="pb-1">
    {{ event.timeStamp | date: 'longDate' }} • {{ event.timeStamp | date: 'h:mm a' }}
  </div>
  <h6 class="header-font font-size-subheading-2 fw-bold">
    {{ event.type }}
  </h6>
  <ng-container *ngIf="event.description">
    <p>{{ event.description }}</p>
  </ng-container>
  <app-activity-event-details
    [event]="event"
  ></app-activity-event-details>
</div>
