<ng-container *ngIf="navbarSettings$ | async as navbarSettings">
<div id="test" class="option-selector-wrapper p-3"
     [class.ps-0]="navbarSettings.fixed_navbar_width && mode.mode !== 'kiosk' && !isModal">
  <div *ngIf="optionGroups && !isLoading" class="d-flex flex-column gap-5">
    <ng-container *ngFor="let optionGroup of optionGroups; index as i; trackBy: optionGroupTrackBy">
<!--      <ng-container *ngFor="let ingredientGroup of product.ingredientGroups; index as j">-->
      <ng-container *ngIf="!optionGroup.hideOnEdit">
        <div class="d-flex flex-column gap-3">

          <app-option-group-info
            [optionGroup]="optionGroup"
            [optionGroups]="displayOptionGroups"
          ></app-option-group-info>

          <div class="d-flex flex-row flex-wrap" *ngIf="optionGroup.options">
            <ng-container [ngSwitch]="optionGroup.displayType">

              <ng-container *ngSwitchCase="'images'">
                <app-option-card-selector
                  [optionGroup]="optionGroup"
                  [displayOptionGroups]="displayOptionGroups"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                ></app-option-card-selector>
              </ng-container>

              <ng-container *ngSwitchCase="'simple'">
                <app-radio-checkbox-selector
                  class="w-100"
                  [optionGroup]="optionGroup"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                  [displayOptionGroups]="displayOptionGroups"
                ></app-radio-checkbox-selector>
              </ng-container>

              <ng-container *ngSwitchCase="'button-group'">
                <app-option-card-selector
                  [optionGroup]="optionGroup"
                  [displayOptionGroups]="displayOptionGroups"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                ></app-option-card-selector>
              </ng-container>

              <ng-container *ngSwitchCase="'half-and-half'">
                <app-radio-checkbox-selector
                  class="w-100"
                  [optionGroup]="optionGroup"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                  [displayOptionGroups]="displayOptionGroups"
                ></app-radio-checkbox-selector>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <app-option-card-selector
                  [optionGroup]="optionGroup"
                  [displayOptionGroups]="displayOptionGroups"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                ></app-option-card-selector>
              </ng-container>

            </ng-container>
          </div>

        </div>
      </ng-container>
<!--      </ng-container>-->
    </ng-container>
  </div>
</div>
</ng-container>