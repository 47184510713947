import {Injectable} from '@angular/core';
import {PaymentProcessingProvider} from '../../providers/payment-processing-provider.interface';
import {DatacapApiService} from './datacap-api.service';
import {DatacapMappingService} from './datacap-mapping.service';
import {Observable, of} from 'rxjs';
import {User} from '../../interfaces/user.interface';
import {CreditCard} from '../../interfaces/creditcard.interface';
import {Order} from '../../interfaces/order.interface';
import {map} from 'rxjs/operators';
import {PaymentResponse} from '../../interfaces/payment-response.interface';
import {DigitalWalletsOptions, PaymentMethod} from '@olo/pay';



@Injectable({
    providedIn: 'root'
})
export class DatacapProviderService implements PaymentProcessingProvider {
    // TODO: Add back gift card functionality once in sprint
    integrationCMSName = 'datacap';

    constructor(private datacapAPI: DatacapApiService, private mapping: DatacapMappingService) {}

    // tslint:disable-next-line:max-line-length
    initializePaymentInterfaces(type: 'multi-field' | 'single-field' | 'wallet', walletConfig?: DigitalWalletsOptions, callback?: (paymentMethod: PaymentMethod) => void): Observable<any> {
        return of(null);
    }

    submitPaymentNewCard(order: Order, payProcId: number, card: CreditCard, token: string): Observable<PaymentResponse> {
        return this.datacapAPI.creditCardPreAuth(card, order.subTotalCents, order.tipCents, order.taxCents, token).pipe(map(cardRes => {
            return this.mapping.creditResponseToPaymentResponse(cardRes);
        }));
    }

    submitPaymentSavedCard(order: Order, payProcId: number, user: User, card: CreditCard, save: boolean, token: string): Observable<any> {
        return this.datacapAPI.creditCardPreAuth(card, order.subTotalCents, order.tipCents, order.taxCents, token).pipe(map(cardRes => {
            return cardRes;
        }));
    }

    checkPaymentStatus(paymentToken: string, payProcID: string): Observable<string> {
        return of(null);
    }

    authorizePayment(): Observable<{ 'success': boolean, 'message': string }> {
        return of(null);
    }

    saveCard(): Observable<any> {
        return of(null);
    }

    removeCard(): Observable<any> {
        return of(null);
    }

    saveCardAsDefault(card: any): Observable<any> {
        return of(null);
    }

    getSavedCards(custID: number): Observable<any> {
        return of(null);
    }

    // getBalance(card: GiftCard): Observable<number> {
    //     return this.datacapAPI.giftCardBalanceManualEntry(card).pipe(map(balanceRes => {
    //         return Number(balanceRes.Balance);
    //         }
    //     ));
    // }
    // purchase(card: GiftCard, chargeCents: number): Observable<any> {
    //     return this.datacapAPI.giftCardSaleManualEntry(card, chargeCents).pipe(map(giftRes => {
    //         return this.mapping.giftResponseToPaymentResponse(giftRes);
    //     }))
    // }
}
