import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { OLOHttpService } from './olo-http.service';
import { KeyValuePair } from './interfaces/key-value-pair.interface';
import { AllRestaurantsResponse, Restaurant } from './interfaces/all-restaurants-response.interface';
import { RestaurantDeliveryRequest } from './interfaces/restaurant-delivery-request.interface';
import { RestaurantDeliveryResponse } from './interfaces/restaurant-delivery-response.interface';
import { CalendarResponse } from './interfaces/calendar-response.interface';
import { RestaurantMenu } from './interfaces/restaurant-menu.interface';
import { RestaurantDisclaimerResponse } from './interfaces/restaurant-disclaimers.interface';
import { ProductModifiersResponse } from './interfaces/product-modifiers-response.interface';
import { MenuItemLabelsResponse } from './interfaces/menu-item-labels-response.interface';
import { CreateBasketResponse } from './interfaces/create-basket-response.interface';
import { GroupOrderResponse } from './interfaces/group-order.interface';
import { AddProductRequest } from './interfaces/add-product-request.interface';
import { AddChainProductRequest } from './interfaces/add-chain-product-request.interface';
import { AddProductsRequest } from './interfaces/add-products-request.interface';
import { BasketResponse } from './interfaces/basket-response.interface';
import { AddUpsellItemsRequest } from './interfaces/add-upsell-items-request.interface';
import { AddChainProductsRequest } from './interfaces/add-chain-products-request.interface';
import { UpsellItemsResponse } from './interfaces/upsell-items-response.interface';
import { LoyaltySchemesResponse } from './interfaces/loyalty-schemes-response.inteface';
import { AddUserToLoyaltySchemeRequest } from './interfaces/add-user-to-loyalty-scheme-request.interface';
import { RewardsResponse } from './interfaces/rewards-response.interface';
import { Address } from './interfaces/address.interface';
import { SetBasketTimeRequest } from './interfaces/set-basket-time-request.interface';
import { TransferBasketResponse } from './interfaces/transfer-basket-response.interface';
import { GiftCardBalance } from './interfaces/gift-card-balance.interface';
import { Billingscheme, BillingSchemesAndAccountsResponse } from './interfaces/billing-schemes-and-account-response.interface';
import { SubmitOrderMultiPaymentRequest } from './interfaces/submit-order-multi-payment-request.interface copy';
import { SubmitOrderMultiPaymentResponse } from './interfaces/submit-order-multi-payment-response.interface';
import { ValidateBasketResponse } from './interfaces/validate-basket-response.interface';
import { OrderResponse } from './interfaces/order-response.interface';
import { DispatchStatusResponse } from './interfaces/dispatch-status-response.interface';
import { ManualFireResponse } from './interfaces/manual-fire-response.interface';
import { ManualFireRequest } from './interfaces/manual-fire-request.intferface';
import { CreateUserRequest } from './interfaces/create-user-request.interface';
import { CreateUserFromGuestOrderResponse } from './interfaces/create-user-from-guest-order-response.interface';
import { UserResponse } from './interfaces/user-response.interface';
import { UserDetailsResponse } from './interfaces/user-details-response.interface';
import { UserExistenceResponse } from './interfaces/user-existence-response.interface';
import { UserCommunicationPreferences } from './interfaces/user-communication-preferences.interface';
import { DeliveryAddressesResponse } from './interfaces/delivery-addresses-response.interface';
import { BillingAccountsResponse } from './interfaces/billing-accounts-response.interface';
import { Cardbalance, GiftCardBalancesResponse } from './interfaces/gitf-card-balances-response.interface';
import { FavoriteOrdersResponse } from './interfaces/favorite-orders-response.interface';
import { Favelocation, FavoriteLocationsResponse } from './interfaces/favorite-locations-response.interface';
import { RecentOrdersResponse } from './interfaces/recent-orders-response.interface';
import { VendorsResponse } from './interfaces/vendors-response.interface';
import { CreateUserResponse } from './interfaces/create-user-response.interface';
import {
  SubmitOrderRequestWithCreditCardAsGuest,
  SubmitOrderRequestWithCreditCardAsUser,
  SubmitOrderRequestWithDefaultCreditCard,
} from './interfaces/submit-order-request-with-credit-card.interface';
import {
  SubmitOrderRequestWithGiftCardAsGuest,
  SubmitOrderRequestWithGiftCardAsUser,
} from './interfaces/submit-order-request-with-gift-card.interface';
import {
  SubmitOrderRequestWithSavedCardAsGuest,
  SubmitOrderRequestWithSavedCardAsUser,
} from './interfaces/submit-order-request-with-saved-card.interface';
import moment from 'moment-timezone';
import { Cacheable } from 'ts-cacheable';
import { SubmitOrderRequestWithCashAsGuest, SubmitOrderRequestWithCashAsUser } from './interfaces/submit-order-request-with-cash.interface';
import { RetrieveBrandLevelSettingsResponse } from './interfaces/retrieve-brand-level-settings-response.interface';
import {
  SubmitOrderWithPrepaidTransactionAsGuest,
  SubmitOrderWithPrepaidTransactionAsUser,
} from './interfaces/submit-order-with-prepaid-transaction.interface';
import {
  SubmitOrderRequestWithCreditCardTokenAsGuest,
  SubmitOrderRequestWithCreditCardTokenAsUser,
} from './interfaces/submit-order-request-with-credit-card-token.interface';
import { AvailableTimeWantedTimesResponse } from './interfaces/available-time-wanted-times-response.interface';
import { Moment } from 'moment-timezone';
import { Referral } from './interfaces/add-referral-to-basket-request.interface';

@Injectable({
  providedIn: 'root',
})
export class OloAPIService {
  constructor(private oloHttp: OLOHttpService) {}

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getBrandLevelSettings(): Observable<RetrieveBrandLevelSettingsResponse> {
    return this.oloHttp.get<RetrieveBrandLevelSettingsResponse>('/brand');
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getAllParticipatingRestaurants(includePrivate: boolean): Observable<AllRestaurantsResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'includePrivate', value: includePrivate }]);
    const resource = '/restaurants';
    return this.oloHttp.get<AllRestaurantsResponse>(resource + paramsStr);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  // tslint:disable-next-line: max-line-length
  getNearbyParticipatingRestaurants(
    latitude: number,
    longitude: number,
    milesRadius: number,
    maxResults: number,
    from: Date,
    to: Date
  ): Observable<AllRestaurantsResponse> {
    const paramsStr = this.getQueryParamsString([
      { key: 'lat', value: latitude },
      { key: 'long', value: longitude },
      { key: 'radius', value: milesRadius },
      { key: 'limit', value: maxResults },
      { key: 'calendarstart', value: moment(from).format('YYYYMMDD') },
      { key: 'calendarend', value: moment(to).format('YYYYMMDD') },
    ]);
    const resource = '/restaurants/near';
    return this.oloHttp.get<AllRestaurantsResponse>(resource + paramsStr);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  // tslint:disable-next-line: max-line-length
  getNearbyParticipatingRestaurantsWithInHouseDelivery(
    latitude: number,
    longitude: number,
    maxResults: number
  ): Observable<AllRestaurantsResponse> {
    const paramsStr = this.getQueryParamsString([
      { key: 'lat', value: latitude },
      { key: 'long', value: longitude },
      { key: 'limit', value: maxResults },
    ]);
    const resource = '/restaurants/deliveringto';
    return this.oloHttp.get<AllRestaurantsResponse>(resource + paramsStr);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getRestaurantsWithPhoneNumber(phone: string): Observable<AllRestaurantsResponse> {
    const resource = `/restaurants/bytelephone/${this.getNumbersOnlyString(phone)}`;
    return this.oloHttp.get<AllRestaurantsResponse>(resource);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
    maxCacheCount: 100,
  })
  getRestaurant(restaurantID: number): Observable<Restaurant> {
    const resource = `/restaurants/${restaurantID}`;
    return this.oloHttp.get<Restaurant>(resource);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getRestaurantsByExternalID(externalID: string): Observable<AllRestaurantsResponse> {
    const resource = `/restaurants/${externalID}`; // the docs indicate this^ object instead of single restaurant
    return this.oloHttp.get<AllRestaurantsResponse>(resource);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000,
  })
  getRestaurantsBySlug(slug: string): Observable<Restaurant> {
    const resource = `/restaurants/byslug/${slug}`;
    return this.oloHttp.get<Restaurant>(resource);
  }

  checkRestaurantDelivery(restaurantID: number, body: RestaurantDeliveryRequest): Observable<RestaurantDeliveryResponse> {
    const resource = `/restaurants/${restaurantID}/checkdeliverycoverage`;
    return this.oloHttp.post<RestaurantDeliveryResponse>(resource, body);
  }

  @Cacheable({
    maxAge: 15 * 60 * 1000,
    maxCacheCount: 100,
  })
  getRestaurantOperatingHours(restaurantID: number, from: Date, to: Date): Observable<CalendarResponse> {
    const paramsStr = this.getQueryParamsString([
      { key: 'from', value: formatDate(from, 'yyyyMMdd', 'en-US') },
      { key: 'to', value: formatDate(to, 'yyyyMMdd', 'en-US') },
    ]);
    const resource = `/restaurants/${restaurantID}/calendars`;
    return this.oloHttp.get<CalendarResponse>(resource + paramsStr);
  }

  @Cacheable({
    maxAge: 15 * 60 * 1000,
  })
  getRestaurantOperatingHoursForThisWeek(restaurantID: number, endDate?: number): Observable<CalendarResponse> {
    const yesterday = new Date();
    const nextWeek = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    nextWeek.setDate(nextWeek.getDate() + (endDate ? endDate : 6));
    return this.getRestaurantOperatingHours(restaurantID, yesterday, nextWeek);
  }

  getAvailableTimeWantedTimes(
    restaurantID: number,
    basketID: string,
    day: Date,
    utcOffset: number
  ): Observable<AvailableTimeWantedTimesResponse> {
    const now = moment().utcOffset(utcOffset); // Current time with the correct offset
    let starttime = moment(day).utcOffset(utcOffset).startOf('day'); // Start of the day with the correct offset

    // If the start of the day is before the current time, use a starttime that is in the future.
    if (starttime.isSameOrBefore(now)) {
      starttime = now.add(5, 'minutes');
    }

    const endtime = moment(day).utcOffset(utcOffset).endOf('day'); // End of the day with the correct offset

    const resource = `/restaurants/${restaurantID}/availabletimes`;
    const params = new HttpParams({
      fromObject: {
        basketid: basketID,
        starttime: starttime.format('YYYYMMDD HH:mm'),
        endtime: endtime.format('YYYYMMDD HH:mm'),
      },
    });

    return this.oloHttp.get<AvailableTimeWantedTimesResponse>([resource, params.toString()].join('?'));
  }

  getRestaurantDisclaimers(restaurantID: number): Observable<RestaurantDisclaimerResponse> {
    const resource = `/restaurants/${restaurantID}/disclaimers`;
    return this.oloHttp.get<RestaurantDisclaimerResponse>(resource);
  }

  getRestaurantMenu(restaurantID: string): Observable<RestaurantMenu> {
    const resource = `/restaurants/${restaurantID}/menu`;
    return this.oloHttp.get<RestaurantMenu>(resource);
  }

  @Cacheable({
    maxAge: 15 * 60 * 1000,
  })
  getProductModifiersAndOptions(productID: number): Observable<ProductModifiersResponse> {
    const resource = `/products/${productID}/modifiers`;
    return this.oloHttp.get<ProductModifiersResponse>(resource);
  }

  @Cacheable({
    maxAge: 15 * 60 * 1000,
  })
  getTopLevelModifiersAndOptions(productID: number): Observable<ProductModifiersResponse> {
    const resource = `/products/${productID}/options`;
    return this.oloHttp.get<ProductModifiersResponse>(resource);
  }

  getOptionChildren(optionID: number): Observable<ProductModifiersResponse> {
    const resource = `/options/${optionID}/nested`;
    return this.oloHttp.get<ProductModifiersResponse>(resource);
  }

  getMenuItemLabels(productID: number): Observable<MenuItemLabelsResponse> {
    const resource = `/menuitemlabels`;
    return this.oloHttp.get<MenuItemLabelsResponse>(resource);
  }

  createBasket(restaurantID: number, authToken: string): Observable<CreateBasketResponse> {
    const body = {
      vendorid: restaurantID,
      // authtoken: authToken
    };
    if (authToken) {
      body['authtoken'] = authToken;
    }
    const resource = `/baskets/create`;
    return this.oloHttp.post<CreateBasketResponse>(resource, body);
  }

  // tslint:disable-next-line: max-line-length
  createBasketFromPreviousOrder(
    authToken: string,
    externalOrderRef: string,
    orderID: string,
    ignoreUnavailableProducts: boolean
  ): Observable<CreateBasketResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'authtoken', value: authToken }]);
    const body = {
      id: orderID,
      ignoreunavailableproducts: ignoreUnavailableProducts,
    };
    const resource = `/baskets/createfromorder`;
    return this.oloHttp.post<CreateBasketResponse>(resource + paramsStr, body);
  }

  createBasketFromFavorite(authToken: string, favoriteID: string, ignoreUnavailableProducts: boolean): Observable<CreateBasketResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'authtoken', value: authToken }]);
    const body = {
      faveid: favoriteID,
      ignoreunavailableproducts: ignoreUnavailableProducts,
    };
    const resource = `/baskets/createfromfave`;
    return this.oloHttp.post<CreateBasketResponse>(resource + paramsStr, body);
  }

  getBasket(basketGuid: string): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}`;
    return this.oloHttp.get<CreateBasketResponse>(resource);
  }

  // tslint:disable-next-line: max-line-length
  createGroupOrder(
    authToken: string,
    restaurantID: number,
    basketGuid: string,
    deadline: Date,
    note: string
  ): Observable<GroupOrderResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'authtoken', value: authToken }]);
    const body = {
      restaurantid: restaurantID,
      basketid: basketGuid,
      deadline: formatDate(deadline, 'yyyyMMdd HH:mm', 'en-US'),
      note,
    };
    const resource = `/grouporders`;
    return this.oloHttp.put<GroupOrderResponse>(resource + paramsStr, body);
  }

  updateGroupOrder(authToken: string, groupOrderGuid: string, deadline: Date, note: string): Observable<GroupOrderResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'authtoken', value: authToken }]);
    const body = {
      grouporderid: groupOrderGuid,
      deadline: formatDate(deadline, 'yyyyMMdd HH:mm', 'en-US'),
      note,
    };
    const resource = `/grouporders`;
    return this.oloHttp.post<GroupOrderResponse>(resource + paramsStr, body);
  }

  sendGroupOrderInvites(groupOrderGuid: string, authToken: string, emails: string[]): Observable<any> {
    const paramsStr = this.getQueryParamsString([{ key: 'authtoken', value: authToken }]);
    const body = {
      emailaddresses: emails,
    };
    const resource = `/grouporders/${groupOrderGuid}/invite`;
    return this.oloHttp.post<any>(resource + paramsStr, body);
  }

  getGroupOrder(groupOrderGuid: string, authToken: string, isGuest: boolean, basketID: string): Observable<GroupOrderResponse> {
    let paramsStr;
    if (isGuest) {
      paramsStr = this.getQueryParamsString([{ key: 'basket', value: basketID }]);
    } else {
      paramsStr = this.getQueryParamsString([{ key: 'authtoken', value: authToken }]);
    }
    const resource = `/grouporders/${groupOrderGuid}`;
    return this.oloHttp.get<GroupOrderResponse>(resource + paramsStr);
  }

  addProductToBasket(basketGuid: string, body: AddProductRequest): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/products`;
    return this.oloHttp.post<CreateBasketResponse>(resource, body);
  }

  addProductToBasketByChainID(basketGuid: string, body: AddChainProductRequest): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/products/bychainid`;
    return this.oloHttp.post<CreateBasketResponse>(resource, body);
  }

  updateProductInBasket(basketGuid: string, basketProductID: number, body: AddProductRequest): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/products/${basketProductID}`;
    return this.oloHttp.put<CreateBasketResponse>(resource, body);
  }

  removeProductFromBasket(basketGuid: string, basketProductID: number): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/products/${basketProductID}`;
    return this.oloHttp.delete<CreateBasketResponse>(resource);
  }

  updateProductsInBasket(basketGuid: string, body: AddProductsRequest): Observable<BasketResponse> {
    const resource = `/baskets/${basketGuid}/products/batch`;
    return this.oloHttp.put<BasketResponse>(resource, body);
  }

  addProductsToBasket(basketGuid: string, body: AddProductsRequest): Observable<BasketResponse> {
    const resource = `/baskets/${basketGuid}/products/batch`;
    return this.oloHttp.post<BasketResponse>(resource, body);
  }

  addProductsToBasketByChainID(basketGuid: string, body: AddChainProductsRequest): Observable<BasketResponse> {
    const resource = `/baskets/${basketGuid}/products/batch/bychainid`;
    return this.oloHttp.post<BasketResponse>(resource, body);
  }

  addFavoriteProductsToBasket(basketGuid: string, authToken: string, favoriteID: number): Observable<BasketResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'authtoken', value: authToken }]);
    const resource = `/baskets/${basketGuid}/products/byfave`;
    return this.oloHttp.post<BasketResponse>(resource + paramsStr, {
      faveid: favoriteID,
    });
  }

  getEligibleUpsellItems(basketGuid: string | number): Observable<UpsellItemsResponse> {
    const resource = `/baskets/${basketGuid}/upsell`;
    return this.oloHttp.get<UpsellItemsResponse>(resource);
  }

  addUpsellItemsToBasket(basketGuid: string, body: AddUpsellItemsRequest): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/upsell`;
    return this.oloHttp.post<CreateBasketResponse>(resource, body);
  }

  setTip(basketGuid: string, amount: number): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/tip`;
    return this.oloHttp.put<CreateBasketResponse>(resource, { amount: amount });
  }

  applyCoupon(basketGuid: string, couponCode: string): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/coupon`;
    return this.oloHttp.put<CreateBasketResponse>(resource, {
      couponcode: couponCode,
    });
  }

  removeCoupon(basketGuid: string): Observable<any> {
    const resource = `/baskets/${basketGuid}/coupon`;
    return this.oloHttp.delete<any>(resource);
  }

  // tslint:disable-next-line: max-line-length
  getLoyaltySchemes(basketGuid: string, authToken: string, checkBalance = false, checkRewards = false): Observable<LoyaltySchemesResponse> {
    const paramsStr = this.getQueryParamsString([
      { key: 'authtoken', value: authToken },
      { key: 'checkbalance', value: checkBalance },
      { key: 'checkrewards', value: checkRewards },
    ]);
    const resource = `/baskets/${basketGuid}/loyaltyschemes`;
    return this.oloHttp.get<LoyaltySchemesResponse>(resource + paramsStr);
  }

  addUserToLoyaltyScheme(basketGuid: string, body: AddUserToLoyaltySchemeRequest): Observable<LoyaltySchemesResponse> {
    const resource = `/baskets/${basketGuid}/loyaltyschemes`;
    return this.oloHttp.post<LoyaltySchemesResponse>(resource, body);
  }

  getLoyaltyRewards(basketGuid: string, membershipID: number): Observable<RewardsResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'membershipid', value: membershipID }]);
    const resource = `/baskets/${basketGuid}/loyaltyrewards`;
    return this.oloHttp.get<RewardsResponse>(resource + paramsStr);
  }

  @Cacheable({
    maxAge: 3000,
  })
  getQualifyingRewards(basketGuid: string, membershipID: number, authToken: string): Observable<RewardsResponse> {
    const paramsStr = this.getQueryParamsString([
      { key: 'authtoken', value: authToken },
      { key: 'membershipid', value: membershipID },
    ]);
    const resource = `/baskets/${basketGuid}/loyaltyrewards/qualifying`;
    return this.oloHttp.get<RewardsResponse>(resource + paramsStr);
  }

  applyRewards(basketGuid: string, membershipID: number, externalRefs: string[]): Observable<CreateBasketResponse> {
    const body = {
      membershipid: membershipID,
      references: externalRefs,
    };
    const resource = `/baskets/${basketGuid}/loyaltyrewards/byref`;
    return this.oloHttp.put<CreateBasketResponse>(resource, body);
  }

  removeAppliedReward(basketGuid: string, rewardID: number): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/loyaltyrewards/${rewardID}`;
    return this.oloHttp.delete<CreateBasketResponse>(resource);
  }

  // tslint:disable-next-line: max-line-length
  setHandoffMethod(
    basketGuid: string,
    handoffType: 'delivery' | 'dispatch' | 'curbside' | 'pickup' | 'drivethru' | 'dinein'
  ): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/deliverymode`;
    return this.oloHttp.put<CreateBasketResponse>(resource, {
      deliverymode: handoffType,
    });
  }

  setBasketOnPremiseDetails(basketGuid: string, tableposref: string): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/onpremisedetails`;
    const body = {
      tableposref,
    };
    return this.oloHttp.put<CreateBasketResponse>(resource, body);
  }

  getBasketDeliveryAddress(basketGuid: string): Observable<Address> {
    const resource = `/baskets/${basketGuid}/deliveryaddress`;
    return this.oloHttp.get<Address>(resource);
  }

  setBasketDeliveryAddress(basketGuid: string, body: Address): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/deliveryaddress`;
    return this.oloHttp.put<CreateBasketResponse>(resource, body);
  }

  getBasketDispatchAddress(basketGuid: string): Observable<Address> {
    const resource = `/baskets/${basketGuid}/dispatchaddress`;
    return this.oloHttp.get<Address>(resource);
  }

  setBasketDispatchAddress(basketGuid: string, body: Address): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/dispatchaddress`;
    return this.oloHttp.put<CreateBasketResponse>(resource, body);
  }

  setBasketTimeWanted(basketGuid: string, dt: Moment): Observable<CreateBasketResponse> {
    const body: SetBasketTimeRequest = {
      ismanualfire: false,
      year: dt.year(),
      month: Number(dt.format('M')), // JS month starts January = 0...
      day: Number(dt.format('D')),
      hour: dt.hour(),
      minute: dt.minute(),
    };
    const resource = `/baskets/${basketGuid}/timewanted`;
    return this.oloHttp.put<CreateBasketResponse>(resource, body);
  }

  setBasketTimeModeToManualFire(basketGuid: string): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/timewanted`;
    return this.oloHttp.put<CreateBasketResponse>(resource, {
      ismanualfire: true,
    });
  }

  setBasketTimeModeToASAP(basketGuid: string): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/timewanted`;
    return this.oloHttp.delete<CreateBasketResponse>(resource);
  }

  setBasketCustomFieldValue(basketGuid: string, customId: number | string, value: any): Observable<CreateBasketResponse> {
    const body = {
      id: customId,
      value: value,
    };
    const resource = `/baskets/${basketGuid}/customfields`;
    return this.oloHttp.put<CreateBasketResponse>(resource, body);
  }

  removeBasketCustomFieldValue(basketGuid: string, customFieldID: number): Observable<CreateBasketResponse> {
    const resource = `/baskets/${basketGuid}/customfields/${customFieldID}`;
    return this.oloHttp.delete<CreateBasketResponse>(resource);
  }

  transferBasketToAnotherRestaurant(basketGuid: string, restaurantID: number): Observable<TransferBasketResponse> {
    const resource = `/baskets/${basketGuid}/transfer`;
    return this.oloHttp.post<TransferBasketResponse>(resource, {
      vendorid: restaurantID,
    });
  }

  transferBasketToOLOWebsite(basketGuid: string): Observable<{ web: string; mobileweb: string }> {
    const resource = `/baskets/${basketGuid}/platformtransfer`;
    return this.oloHttp.post<{ web: string; mobileweb: string }>(resource, {});
  }

  addReferralToBasket(basketGuid: string, referrals: Referral[]): Observable<void> {
    const resource = `/baskets/${basketGuid}/referrals`;
    return this.oloHttp.put<void>(resource, { referrals });
  }

  @Cacheable({
    maxAge: 15 * 60 * 1000,
  })
  getAllBillingSchemesAndAccounts(basketGuid: string): Observable<BillingSchemesAndAccountsResponse> {
    const resource = `/baskets/${basketGuid}/billingschemes`;
    return this.oloHttp.get<BillingSchemesAndAccountsResponse>(resource);
  }

  getBillingScheme(basketGuid: string, billingSchemeID: number): Observable<Billingscheme> {
    const resource = `/baskets/${basketGuid}/billingschemes/${billingSchemeID}`;
    return this.oloHttp.get<Billingscheme>(resource);
  }

  getGiftCardBalance(basketGuid: string, billingSchemeID: number, cardNumber: string, pin: string): Observable<GiftCardBalance> {
    const body = {
      cardnumber: cardNumber,
      pin: pin,
    };
    const resource = `/baskets/${basketGuid}/billingschemes/${billingSchemeID}/retrievebalance`;
    return this.oloHttp.post<GiftCardBalance>(resource, body);
  }

  verifyGiftCardPINRequirement(billingSchemeID: number, cardNumber: string): Observable<{ ispinrequired: boolean }> {
    const resource = `/billingschemes/${billingSchemeID}/binvalidation`;
    return this.oloHttp.post<{ ispinrequired: boolean }>(resource, {
      cardnumber: cardNumber,
    });
  }

  validateBasket(basketGuid: string, checkUpsell: boolean): Observable<ValidateBasketResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'checkupsell', value: checkUpsell }]);
    const resource = `/baskets/${basketGuid}/validate`;
    return this.oloHttp.post<ValidateBasketResponse>(resource + paramsStr, {});
  }

  // tslint:disable-next-line: max-line-length
  submitOrderWithSinglePayment(
    basketGuid: string,
    body:
      | SubmitOrderRequestWithCreditCardAsUser
      | SubmitOrderRequestWithDefaultCreditCard
      | SubmitOrderRequestWithCreditCardAsGuest
      | SubmitOrderRequestWithGiftCardAsUser
      | SubmitOrderRequestWithGiftCardAsGuest
      | SubmitOrderRequestWithSavedCardAsUser
      | SubmitOrderRequestWithSavedCardAsGuest
      | SubmitOrderRequestWithCashAsUser
      | SubmitOrderRequestWithCashAsGuest
      | SubmitOrderWithPrepaidTransactionAsGuest
      | SubmitOrderWithPrepaidTransactionAsUser
      | SubmitOrderRequestWithCreditCardTokenAsGuest
      | SubmitOrderRequestWithCreditCardTokenAsUser
  ): Observable<OrderResponse> {
    const resource = `/baskets/${basketGuid}/submit`;
    return this.oloHttp.post<OrderResponse>(resource, body);
  }

  submitOrderWithMultiplePayments(basketGuid: string, body: SubmitOrderMultiPaymentRequest): Observable<SubmitOrderMultiPaymentResponse> {
    const resource = `/baskets/${basketGuid}/submit/multiplepayments`;
    return this.oloHttp.post<SubmitOrderMultiPaymentResponse>(resource, body);
  }

  editOrder(orderGuid: string): Observable<CreateBasketResponse> {
    // this endpoint makes no sense.
    const resource = `/orders/${orderGuid}/edit`;
    return this.oloHttp.post<CreateBasketResponse>(resource, {});
  }

  cancelOrder(orderGuid: string): Observable<OrderResponse> {
    const resource = `/orders/${orderGuid}/cancel`;
    return this.oloHttp.post<OrderResponse>(resource, {});
  }

  checkOrderStatus(orderGuid: string): Observable<OrderResponse> {
    const resource = `/orders/${orderGuid}`;
    return this.oloHttp.get<OrderResponse>(resource);
  }

  checkOrderStatusByExternalRef(orderRef: string): Observable<OrderResponse> {
    const resource = `/orders/byref/${orderRef}`;
    return this.oloHttp.get<OrderResponse>(resource);
  }

  checkOrderDispatchStatus(orderGuid: string, authToken: string): Observable<DispatchStatusResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'authtoken', value: authToken }]);
    const resource = `/orders/${orderGuid}/deliverystatus`;
    return this.oloHttp.get<DispatchStatusResponse>(resource + paramsStr);
  }

  checkGuestOrderDispatchStatus(orderGuid: string): Observable<DispatchStatusResponse> {
    const resource = `/orders/${orderGuid}/deliverystatus`;
    return this.oloHttp.get<DispatchStatusResponse>(resource);
  }

  submitManualFireOrderByExternalRef(orderRef: string, body: ManualFireRequest): Observable<ManualFireResponse> {
    const resource = `/orders/${orderRef}/manualfire`;
    return this.oloHttp.post<ManualFireResponse>(resource, body);
  }

  submitManualFireOrder(orderGuid: string, body: ManualFireRequest): Observable<ManualFireResponse> {
    const resource = `/orders/byid/${orderGuid}/manualfire`;
    return this.oloHttp.post<ManualFireResponse>(resource, body);
  }

  notifyOfArrival(orderGuid: string, extraMessage: string): Observable<OrderResponse> {
    const body = {
      message: extraMessage,
    };
    const resource = `/orders/${orderGuid}/arrival`;
    return this.oloHttp.post<OrderResponse>(resource, body);
  }

  createUser(body: CreateUserRequest): Observable<CreateUserResponse> {
    const resource = `/users/create`;
    return this.oloHttp.post<CreateUserResponse>(resource, body); // it uh...returns the same thing as the request.
  }

  createOrGetSSOLinkedOLO(provider: string, token: string, basketID: string): Observable<CreateUserResponse> {
    const body = {
      provider,
      providertoken: token,
      basketid: basketID,
    };
    const resource = `/users/getorcreate`;
    return this.oloHttp.post<CreateUserResponse>(resource, body);
  }

  createUserFromGuestOrder(orderGuid: string, password: string, emailOptIn: boolean): Observable<CreateUserFromGuestOrderResponse> {
    const body = {
      password,
      optin: emailOptIn,
    };
    const resource = `/orders/${orderGuid}/createuser`;
    return this.oloHttp.post<CreateUserFromGuestOrderResponse>(resource, body);
  }

  authenticateUser(username: string, password: string): Observable<UserResponse> {
    const body = {
      login: username,
      password,
    };
    const resource = `/users/authenticate`;
    return this.oloHttp.post<UserResponse>(resource, body);
  }

  changePassword(authToken: string, oldPassword: string, newPassword: string): Observable<any> {
    const body = {
      currentpassword: oldPassword,
      newpassword: newPassword, // min 7 characters
    };
    const resource = `/users/${authToken}/password`;
    return this.oloHttp.post<any>(resource, body); // docs don't specify a response
  }

  startForgotPasswordProcess(email: string): Observable<any> {
    const body = {
      emailaddress: email,
    };
    const resource = `/users/forgotpassword`;
    return this.oloHttp.post<any>(resource, body); // docs don't specify a response
  }

  getUserDetails(authToken: string): Observable<UserDetailsResponse> {
    const resource = `/users/${authToken}`;
    return this.oloHttp.get<UserDetailsResponse>(resource);
  }

  updateUserDetails(authToken: string, firstName: string, lastName: string, email: string): Observable<UserDetailsResponse> {
    const body = {
      firstname: firstName,
      lastname: lastName,
      emailaddress: email,
    };
    const resource = `/users/${authToken}`;
    return this.oloHttp.put<UserDetailsResponse>(resource, body);
  }

  disableAuthToken(authToken: string): Observable<UserDetailsResponse> {
    const resource = `/users/${authToken}`;
    return this.oloHttp.delete<UserDetailsResponse>(resource);
  }

  deleteUserAccount(authToken: string): Observable<any> {
    const resource = `/users/${authToken}/account`;
    return this.oloHttp.delete<any>(resource);
  }

  determineUserExistence(email: string): Observable<UserExistenceResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'email', value: email }]);
    const resource = `/users/exists`;
    return this.oloHttp.get<UserExistenceResponse>(resource + paramsStr);
  }

  getUserContactNumber(authToken: string): Observable<{ contactdetails: string }> {
    const resource = `/users/${authToken}/contactdetails`;
    return this.oloHttp.get<{ contactdetails: string }>(resource);
  }

  updateUserContactNumber(authToken: string, phone: string): Observable<{ contactdetails: string }> {
    const body = {
      contactdetails: phone,
    };
    const resource = `/users/${authToken}/contactdetails`;
    return this.oloHttp.put<{ contactdetails: string }>(resource, body);
  }

  getUserCommunicationPreferences(authToken: string): Observable<UserCommunicationPreferences> {
    const resource = `/users/${authToken}/contactoptions`;
    return this.oloHttp.get<UserCommunicationPreferences>(resource);
  }

  updateUserCommunicationPreferences(authToken: string, body: UserCommunicationPreferences): Observable<UserCommunicationPreferences> {
    const resource = `/users/${authToken}/contactoptions`;
    return this.oloHttp.put<UserCommunicationPreferences>(resource, body);
  }

  getUserDeliveryAddresses(authToken: string): Observable<any> {
    const resource = `/users/${authToken}/userdeliveryaddresses`;
    return this.oloHttp.get<any>(resource);
  }

  setUserDefaultDeliveryAddresses(authToken: string, addressID: number): Observable<DeliveryAddressesResponse> {
    const body = {
      addressid: addressID,
    };
    const resource = `/users/${authToken}/userdeliveryaddresses/default`;
    return this.oloHttp.put<DeliveryAddressesResponse>(resource, body);
  }

  deleteUserDeliveryAddresses(authToken: string, addressID: number): Observable<any> {
    const resource = `/users/${authToken}/userdeliveryaddresses/${addressID}`;
    return this.oloHttp.delete<any>(resource);
  }

  getUserBillingAccounts(authToken: string): Observable<BillingAccountsResponse> {
    const resource = `/users/${authToken}/billingaccounts`;
    return this.oloHttp.get<BillingAccountsResponse>(resource);
  }

  getQualifyingUserBillingAccounts(authToken: string, basketGuid: string): Observable<BillingAccountsResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'basket', value: basketGuid }]);
    const resource = `/users/${authToken}/billingaccounts`;
    return this.oloHttp.get<BillingAccountsResponse>(resource + paramsStr);
  }

  deleteUserBillingAccount(authToken: string, billingAccountID: number): Observable<any> {
    const resource = `/users/${authToken}/billingaccounts/${billingAccountID}`;
    return this.oloHttp.delete<any>(resource);
  }

  getUserGiftCardBalances(authToken: string): Observable<GiftCardBalancesResponse> {
    const resource = `/users/${authToken}/billingaccounts/storedvalue`;
    return this.oloHttp.get<GiftCardBalancesResponse>(resource);
  }

  getUserGiftCardBalance(authToken: string, billingAccountID: number): Observable<Cardbalance> {
    const resource = `/users/${authToken}/billingaccounts/storedvalue/${billingAccountID}`;
    return this.oloHttp.get<Cardbalance>(resource);
  }

  setUserDefaultCreditCard(authToken: string, billingAccountID: number): Observable<any> {
    const resource = `/users/${authToken}/creditcards/${billingAccountID}`;
    return this.oloHttp.put<any>(resource, { isdefault: true });
  }

  getUserFavoriteOrders(authToken: string): Observable<FavoriteOrdersResponse> {
    const resource = `/users/${authToken}/faves`;
    return this.oloHttp.get<FavoriteOrdersResponse>(resource);
  }

  // tslint:disable-next-line: max-line-length
  saveUserFavoriteOrder(
    authToken: string,
    basketGuid: string,
    description: string,
    setAsDefault: boolean
  ): Observable<FavoriteOrdersResponse> {
    const body = {
      basketid: basketGuid,
      description,
      isDefault: setAsDefault,
    };
    const resource = `/users/${authToken}/faves`;
    return this.oloHttp.post<FavoriteOrdersResponse>(resource, body);
  }

  deleteUserFavoriteOrder(authToken: string, favoriteID: number): Observable<any> {
    const resource = `/users/${authToken}/faves/${favoriteID}`;
    return this.oloHttp.delete<any>(resource);
  }

  getUserFavoriteLocations(authToken: string): Observable<FavoriteLocationsResponse> {
    const resource = `/users/${authToken}/favelocations`;
    return this.oloHttp.get<FavoriteLocationsResponse>(resource);
  }

  saveUserFavoriteLocation(authToken: string, restaurantID: number, setAsDefault: boolean): Observable<Favelocation> {
    const paramsStr = this.getQueryParamsString([{ key: 'isdefault', value: setAsDefault }]);
    const resource = `/users/${authToken}/favelocations/${restaurantID}`;
    return this.oloHttp.post<Favelocation>(resource + paramsStr, {});
  }

  deleteUserFavoriteLocation(authToken: string, restaurantID: number): Observable<any> {
    const resource = `/users/${authToken}/favelocations/${restaurantID}`;
    return this.oloHttp.delete<any>(resource);
  }

  getUserRecentOrders(authToken: string): Observable<RecentOrdersResponse> {
    const resource = `/users/${authToken}/recentorders`;
    return this.oloHttp.get<RecentOrdersResponse>(resource);
  }

  getUserRecentOrdersByExternalRef(externalRef: string): Observable<RecentOrdersResponse> {
    const resource = `/users/byref/${externalRef}/recentorders`;
    return this.oloHttp.get<RecentOrdersResponse>(resource);
  }

  getUsersOpenManualFireOrders(authToken: string): Observable<RecentOrdersResponse> {
    const resource = `/users/${authToken}/openorders/manualfire`;
    return this.oloHttp.get<RecentOrdersResponse>(resource);
  }

  @Cacheable({
    maxAge: 1000,
  })
  getUsersQualifyingRewards(authToken: string, restaurantID: string): Observable<RewardsResponse> {
    const paramsStr = this.getQueryParamsString([{ key: 'vendorid', value: restaurantID }]);
    const resource = `/users/${authToken}/qualifyingrewards`;
    return this.oloHttp.get<RewardsResponse>(resource + paramsStr);
  }

  // tslint:disable-next-line: max-line-length
  getUsersNearbyQualifyingRewards(
    authToken: string,
    latitude: number,
    longitude: number,
    withinMiles: number,
    maxResults: number
  ): Observable<VendorsResponse> {
    const paramsStr = this.getQueryParamsString([
      { key: 'lat', value: latitude },
      { key: 'long', value: longitude },
      { key: 'radius', value: withinMiles },
      { key: 'limit', value: maxResults },
    ]);
    const resource = `/users/${authToken}/rewardsnear`;
    return this.oloHttp.get<VendorsResponse>(resource + paramsStr);
  }

  deleteAccount(authToken: string) {
    const resource = `/users/${authToken}/account`;
    return this.oloHttp.delete<VendorsResponse>(resource);
  }

  addDonation(basketGuid: string, id: number, amount: number) {
    const body = {
      id,
      amount,
    };
    const resource = `/baskets/${basketGuid}/donations`;
    return this.oloHttp.put<OrderResponse>(resource, body);
  }

  private getQueryParamsString(pairs: KeyValuePair[]): string {
    let params = new HttpParams();
    pairs.forEach(p => {
      if (p.value !== undefined && p.value !== null) {
        params = params.set(p.key, p.value);
      }
    });
    return params && params.keys().length > 0 ? '?' + params.toString() : '';
  }

  private getNumbersOnlyString(str: string): string {
    return str.replace(/\D/g, '');
  }
}
