import {Component, Input, Output, EventEmitter, OnInit, ViewChild, TemplateRef} from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { Order } from 'src/interfaces/order.interface';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import {MobileService} from '../../../../services/mobile.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {stopPropagation} from 'leaflet-geosearch/lib/domUtils';
import {take} from 'rxjs/operators';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {NavigationService} from '@modules/navigation/services';
import {OrderTypeService} from '@modules/cart/services/order-type.service';
import {ThemeColor} from '../../../../vendors/directus/interfaces/theme-color.interface';

@Component({
  selector: 'app-reorder-card',
  templateUrl: './reorder-card.component.html',
  styleUrls: ['./reorder-card.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('400ms ease-in'))
    ])
  ]
})
export class ReorderCardComponent implements OnInit {
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;

  @Input() order: Order;
  @Input() isLoading: boolean;
  @Input() showShadow = false;
  @Input() currencyCode: string;

  @Output() clickedAddToOrder = new EventEmitter<any>();
  @Output() clickedReorder = new EventEmitter<any>();
  @Output() clickedViewConfirmation = new EventEmitter<any>();
  @Output() editOrder = new EventEmitter<any>();
  @Output() cancelOrder = new EventEmitter<any>();

  @ViewChild('reorderModal') reorderModalRef: TemplateRef<any>;

  pickup = HandoffType.pickup;
  curbside = HandoffType.curbside;
  driveThru = HandoffType.driveThru;
  hasDate = false;
  orderNowLoading = false;

  handoffs = HandoffType;

  constructor(
      public mobile: MobileService,
      public modalService: NgbModal,
      private navigation: NavigationService,
      private orderTypeService: OrderTypeService,
  ) {}

  ngOnInit() {
    if (this.order) {
      this.hasDate = !isNaN(this.order.orderReadyTimestamp.getTime());
    }
  }

  addToOrderClicked() {
    this.clickedAddToOrder.emit();
  }

  reorderClicked(event) {
    this.clickedReorder.emit();
    event.stopPropagation();
  }

  viewConfirmationClicked() {
    this.clickedViewConfirmation.emit();
  }

  editOrderClicked() {
    this.editOrder.emit();
  }

  cancelOrderClicked() {
    this.cancelOrder.emit();
  }

  openOrderModal() {
    this.modalService.dismissAll();
    this.modalService.open(this.reorderModalRef, {
      centered: true,
      animation: true,

    });
  }

  orderNow() {
    this.orderNowLoading = true;
    this.order$.pipe(take(1)).toPromise().then(order => {
      if (order) {
        const menuID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
        this.navigation.navigateToMenuPage(menuID).then(() => this.orderNowLoading = false);
      } else {
        this.orderTypeService.setNewOrderType(false);
      }
    });
  }

}
