import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {Select} from '@ngxs/store';
import {GlobalStateModel} from '../../../../store/state.model';
import {Observable} from 'rxjs';
import {VendorSetup} from '../../../../interfaces/vendor.interface';
import {User} from '../../../../interfaces/user.interface';
import {MobileAppSettings} from '../../../../vendors/directus/interfaces/mobile-app-settings.interface';
import {Router} from '@angular/router';

@Component({
    selector: 'app-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit {
    @Select((state: GlobalStateModel) => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
    @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>
    @Select((state: GlobalStateModel) => state.app.mobileAppSettings) mobileAppSettings$: Observable<MobileAppSettings>;

    constructor(
        public router: Router
    ) {}
    ngOnInit() {}
}
