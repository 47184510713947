import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class SentryService {
    constructor() {}

    getSentry$(): Observable<{}> {
        return of({});
    }

    setEmail(email: string) {
      Sentry.setUser({ email });
    }

    setBasketID(basketID: string) {
      Sentry.setUser({ basketID });
    }

    setTransactionName(transactionName: string) {
      Sentry.configureScope(scope => scope.setTransactionName(transactionName));
    }
}
