<div class="mobile-modal-header-close">
  <div class="header-title fw-bold header-font">{{message ? 'Message' : 'Notification' }}</div>
  <button class="back-button" (click)="close()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      class="bi bi-x-circle"
      viewBox="0 0 16 16">
      <path
        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path
        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  </button>
</div>
<ion-content>
  <ng-container *ngIf="message">
    <div class="d-flex flex-column py-3 h-100 overflow-auto">
      <ng-container *ngIf="message.imageURL">
        <div class="px-3 mb-3">
          <img
            class="message-image w-100 cms-card-radius"
            [src]="message.imageURL"
            alt="" />
        </div>
      </ng-container>

      <div class="px-3 d-flex flex-column align-self-stretch flex-grow-1">
        <small class="text-muted mb-1" *ngIf="message.date">{{
          message.date | date: 'shortDate'
        }}</small>
        <h3 class="header-font fw-bold mb-2">
          {{ message.title }}
        </h3>
        <p>
          {{ message.body }}
        </p>
      </div>

      <div
        class="d-flex w-100 align-items-end justify-content-center px-3 mt-3">
        <button
          class="de-danger-btn me-3 px-4 d-flex align-items-center"
          (click)="deleteMessage()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-trash"
            viewBox="0 0 16 16">
            <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
            <path
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
          </svg>
        </button>
        <button class="de-primary-btn w-100" (click)="close()">Close</button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="notification">
    <div class="d-flex flex-column py-3 h-100 overflow-auto">
      <div class="px-3 d-flex flex-column align-self-stretch flex-grow-1">
        <!--                <small class="text-muted mb-1">5/7/2023</small>-->
        <ng-container *ngIf="notification.title">
          <h3 class="header-font fw-bold mb-2">
          {{ notification.title }}
        </h3>
        </ng-container>
        <p>
          {{ notification.body }}
        </p>
      </div>

      <div
        class="d-flex w-100 align-items-end justify-content-center px-3 mt-3">
        <button
          class="de-danger-btn me-3 px-4 d-flex align-items-center"
          (click)="deleteNotification()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-trash"
            viewBox="0 0 16 16">
            <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
            <path
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
          </svg>
        </button>
        <button class="de-primary-btn w-100" (click)="close()">Close</button>
      </div>
    </div>
  </ng-container>
</ion-content>
